import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import OrderStatusChip from '../../../components/OrderStatusChip/OrderStatusChip.jsx';
import PaymentDueDate from '../../../components/PaymentDueDate/PaymentDueDate.jsx';
import { Tide } from '../../../theme.js';
import OrderCardHeader from './OrderCardHeader.jsx';
import { OrderItems } from './orderItems/OrderItems.jsx';

export default function RetailOrderListItem(props) {
    const { t } = useTranslation();
    const { business, order } = props;

    return (
        <Card key={order?.id}>
            <OrderCardHeader order={order} business={business} />
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box></Box>
                    <Box></Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <Box>
                        <Stack alignItems="center" direction="row" spacing={1} gap={2}>
                            <OrderStatusChip order={order} />
                            <PaymentDueDate order={order} />
                        </Stack>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>{/* Order Items */}</Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                            sx={{ color: Tide, borderColor: Tide }}
                            fullWidth
                            variant="outlined"
                            size="small"
                            href={`/retail/orders/${order.id}`}>
                            {t('RetailOrderListItem.viewDetailsLabel')}
                        </Button>
                    </Box>
                </Box>
                <OrderItems order={order} />
            </CardContent>
        </Card>
    );
}

RetailOrderListItem.propTypes = {
    business: PropTypes.any,
    order: PropTypes.any,
};
