import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import * as React from 'react';
import CaretDownIcon from '../../../../icons/CaretDownIcon';
import { Teal } from '../../../../theme';

export default function ProductVariantSelect(props) {
    const { defaultValue = '', fullWidth, variantId, label, name, onChange, options } = props;
    const [value, setValue] = React.useState(props.value ?? defaultValue);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Box sx={{ minWidth: 120, width: fullWidth ? '100%' : 'initial' }}>
            <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id={`product-variant-select-label-${variantId}`}>{label}</InputLabel>
                <Select
                    IconComponent={(props) => <CaretDownIcon {...props} sx={{ width: 24, height: 24, color: Teal }} />}
                    fullWidth
                    label={label}
                    name={name}
                    labelId={`product-variant-select-label-${variantId}`}
                    id={`product-variant-select-${variantId}`}
                    value={props.value ?? value}
                    onChange={handleChange}>
                    {options?.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                            {i.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

ProductVariantSelect.propTypes = {
    defaultValue: PropTypes.string,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })).isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    variantId: PropTypes.string.isRequired,
};
