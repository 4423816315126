import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher } from 'react-router-dom';
import * as api from '../../api.js';
import ShopifyIcon from '../../icons/ShopifyIcon.jsx';
import { useError } from '../Error/ErrorProvider.jsx';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';

export const action = async ({ request }) => {
    const formData = await request.formData();
    const intent = formData.get('intent');
    const shop = formData.get('shop');
    const businessId = formData.get('businessId');
    if (intent === 'disconnect') {
        try {
            await api.disconnectShopifySession(shop, { businessId });
            return { ok: true };
        } catch (error) {
            return { ok: false, error };
        }
    }
    return { ok: false, error: 'Invalid intent' };
};

export default function DisconnectShopifyModal({ open, shop: session, onClose, business, onDidDisconnect }) {
    const { handleError } = useError();
    const { t } = useTranslation();
    const fetcher = useFetcher();

    const style = {
        position: 'absolute',
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        backgroundColor: 'background.paper',
        boxShadow: 24,
        borderRadius: '6px',
    };

    const handleDisconnect = () => {
        const session = business?.shopifySessions[0];
        const formData = new FormData();
        formData.append('intent', 'disconnect');
        formData.append('shop', session.shop);
        formData.append('businessId', business.businessId);
        fetcher.submit(formData, { method: 'post', action: '/shopify/disconnect' });
    };

    useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data?.ok) {
            onDidDisconnect();
        }
        if (fetcher.state === 'idle' && fetcher.data?.error) {
            handleError(fetcher.data?.error);
        }
    }, [fetcher]);

    return (
        <Modal open={open}>
            <Box sx={{ position: 'relative', minHeight: '100vh' }}>
                <Stack gap={5} sx={{ ...style, pl: 4, pr: 4, pt: 3, pb: 3 }}>
                    <Stack gap={1}>
                        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                            <ShopifyIcon sx={{ width: 35, height: 35 }} />
                            <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                                {`Disconnect: ${session?.shop}`}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Typography>
                            <strong>{t('Integrations.warningLabel')}:</strong> {t('Integrations.warningDescription')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Button variant="outlined" color="secondary" onClick={onClose} sx={{ height: 40 }}>
                            {t('Integrations.cancelButton')}
                        </Button>
                        <LoadingButton
                            loadingColor="white"
                            isLoading={fetcher.state === 'loading' || fetcher.state === 'submitting'}
                            onClick={handleDisconnect}
                            variant="contained"
                            sx={{ height: 40 }}>
                            {t('Integrations.disconnectButton')}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

DisconnectShopifyModal.propTypes = {
    open: PropTypes.bool,
    shop: PropTypes.object,
    onClose: PropTypes.func,
    business: PropTypes.object,
    onDidDisconnect: PropTypes.func,
};
