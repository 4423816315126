import React from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import UserCell from '../UserCell.jsx';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

export default function UserTable(props) {
    const { users } = props;

    return (
        <List>
            <Stack gap={2}>
                {users.map((user, index) => (
                    <Stack key={index} gap={2}>
                        <UserCell user={user} />
                        {index !== users.length - 1 && <Divider />}
                    </Stack>
                ))}
            </Stack>
        </List>
    );
}

UserTable.propTypes = {
    users: PropTypes.array,
    onDeleteUser: PropTypes.func,
    isLoading: PropTypes.bool,
};
