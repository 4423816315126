import { Button, Stack, TextField, Typography, Snackbar, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { Midnight, Misty, Tide } from '../../theme.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import PlusCircleIcon from '../../icons/PlusCircleIcon.jsx';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { isValidEmail } from '../../helpers/HelperFunctions.jsx';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import { useError } from '../../components/Error/ErrorProvider.jsx';
import { useSettingsContext } from '../../routes/settings/SettingsContext.jsx';
import PropTypes from 'prop-types';

export default function InviteBusiness({ onSuccess }) {
    const rootData = useRouteLoaderData('root');
    const context = useSettingsContext();
    const business = context?.business || rootData?.business;
    const { t } = useTranslation();
    const fetcher = useFetcher();
    const [invitees, setInvitees] = useState([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [isSendDisabled, setIsSendDisabled] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
    const isLoading = fetcher.state === 'submitting' || fetcher.state === 'loading';
    const { handleError } = useError();

    // Handle email change for the input fields
    const handleEmailChange = (event) => {
        setCurrentEmail(event.target.value);
    };

    // Handle adding a new invitee
    const handleAddInvitee = () => {
        if (currentEmail.trim()) {
            setInvitees((prevInvitees) => [...prevInvitees, currentEmail]);
            setCurrentEmail('');
        }
    };

    // Handle editing an existing invitee
    const handleEditInvitee = (index, value) => {
        const updatedInvitees = [...invitees];
        updatedInvitees[index] = value;
        setInvitees(updatedInvitees);
    };

    // Handle removing an invitee
    const handleRemoveInvitee = (index) => {
        const updatedInvitees = invitees.filter((_, i) => i !== index);
        setInvitees(updatedInvitees);
    };

    // Determine if the button should be disabled
    const determineButtonState = () => {
        const isCurrentEmailValid = currentEmail.trim() === '' || isValidEmail(currentEmail);
        const areInviteesValid = invitees.every((email) => email.trim() === '' || isValidEmail(email));
        const hasAtLeastOneValidEmail = invitees.some((email) => isValidEmail(email)) || isValidEmail(currentEmail);

        return !(isCurrentEmailValid && areInviteesValid && hasAtLeastOneValidEmail);
    };

    const onInvitesSent = () => {
        setInvitees([]);
        setCurrentEmail('');
        setOpenSnackbar(true);
        if (onSuccess) {
            onSuccess();
        }
    };

    // Update send button disabled state
    useEffect(() => {
        setIsSendDisabled(determineButtonState());
    }, [invitees, currentEmail]);

    useEffect(() => {
        if (fetcher.data?.results) {
            onInvitesSent();
        }
        if (fetcher.data?.error) {
            handleError(t('Invite.errorMessage'));
        }
    }, [fetcher.data]);

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        const emailsToInvite = [...invitees];
        if (currentEmail.trim() !== '' && isValidEmail(currentEmail)) {
            emailsToInvite.push(currentEmail);
        }

        const formData = new FormData();
        formData.append('businessName', business?.name || '');
        formData.append('businessId', business?.id || '');
        formData.append('emails', JSON.stringify(emailsToInvite));

        fetcher.submit(formData, { method: 'post', action: '/brand/settings/invite' });
    };

    // Handle Snackbar close
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack gap={3} sx={{ maxWidth: 600 }}>
                    <Card>
                        <CardContent>
                            <Stack gap={2}>
                                <Typography variant="textLg" fontWeight={700} color={Tide}>
                                    {t('Invite.sendInviteLabel')}
                                </Typography>
                                <List>
                                    <Stack gap={2} sx={{ alignItems: 'flex-start' }}>
                                        {invitees.map((email, index) => (
                                            <TextField
                                                key={index}
                                                value={email}
                                                onChange={(event) => handleEditInvitee(index, event.target.value)}
                                                fullWidth
                                                placeholder={t('Invite.emailPlaceholder')}
                                                error={email.trim() !== '' && !isValidEmail(email)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={() => handleRemoveInvitee(index)}
                                                            aria-label="remove">
                                                            <CloseIcon sx={{ color: Misty, width: 24, height: 24 }} />
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        ))}
                                        <TextField
                                            value={currentEmail}
                                            onChange={handleEmailChange}
                                            placeholder={t('Invite.emailPlaceholder')}
                                            fullWidth
                                            error={currentEmail.trim() !== '' && !isValidEmail(currentEmail)}
                                        />
                                        <Button sx={{ padding: 0 }} onClick={handleAddInvitee}>
                                            <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                                <PlusCircleIcon sx={{ width: 24, height: 24, color: Midnight }} />
                                                <Typography variant="textMd" sx={{ fontWeight: 700, color: Midnight }}>
                                                    {t('Invite.addButtonLabel')}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    </Stack>
                                </List>
                                <LoadingButton
                                    isLoading={isLoading}
                                    onClick={handleSubmit}
                                    variant="contained"
                                    disabled={isSendDisabled}>
                                    {t('Invite.sendInviteButtonLabel')}
                                </LoadingButton>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </form>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Invite Sent!
                </Alert>
            </Snackbar>
        </>
    );
}

InviteBusiness.propTypes = {
    onSuccess: PropTypes.func,
};
