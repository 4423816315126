import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BorderRadius, BoxShadow, LightGray, Midnight, Misty, Teal, White } from '../../theme.js';
import { uploadImage } from '../../api.js';
import { useError } from '../Error/ErrorProvider.jsx';
import { CircularProgress, IconButton } from '@mui/material';
import PencilSimpleIcon from '../../icons/PencilSimpleIcon.jsx';
import CoverPhoto from '../CoverPhoto/CoverPhoto.jsx';
import { useTranslation } from 'react-i18next';
import ItemEditMenu from '../ItemEditMenu/ItemEditMenu.jsx';
import ImagesIcon from '../../icons/ImagesIcon.jsx'; // Import ItemEditMenu

export default function CoverPhotoUploader({ coverPhoto, onImageChange }) {
    const { t } = useTranslation();
    const [imageSrc, setImageSrc] = useState(coverPhoto);
    const [isLoading, setIsLoading] = useState(false);
    const { handleError } = useError();
    const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
    const menuOpen = Boolean(anchorEl); // Boolean to check if the menu is open

    const onButtonClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.style.opacity = '0';
        // Append input to the DOM (necessary for Safari on iOS)
        document.body.appendChild(input);

        // Use addEventListener for onchange event (necessary for Safari on iOS)
        input.addEventListener('change', (event) => {
            if (event.target.files && event.target.files[0]) {
                handleImageChange(event);
            }
            document.body.removeChild(input);
        });
        input.click();
    };

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        setIsLoading(true);
        try {
            const response = await uploadImage(file);
            const url = response.url;
            setImageSrc(url);
            onImageChange(url);
        } catch (error) {
            handleError('An error occurred changing your image. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        onButtonClick();
    };

    const handleDelete = () => {
        handleMenuClose();
        setImageSrc(null);
        onImageChange(null);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: BorderRadius,
                position: 'relative',
                overflow: 'hidden',
            }}>
            {_.isEmpty(imageSrc) && !isLoading ? (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        border: `2px dashed ${LightGray}`,
                        borderRadius: BorderRadius,
                    }}>
                    <Stack gap={1} sx={{ alignItems: 'center', justifyContent: 'center', p: 4 }}>
                        <ImagesIcon sx={{ color: Misty }} />
                        <Button variant="outlined" color="secondary" onClick={onButtonClick} sx={{ height: 40 }}>
                            Upload New
                        </Button>
                        <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="textMd" sx={{ color: Midnight, fontWeight: 500 }}>
                                {t('CoverPhoto.promptLabel')}
                            </Typography>
                            <Typography variant="textSm" sx={{ color: Teal, fontWeight: 500 }}>
                                {t('CoverPhoto.recommendationLabel')}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            ) : (
                <Box>
                    <CoverPhoto image={imageSrc} />
                    <IconButton
                        onClick={handleMenuOpen}
                        sx={{
                            position: 'absolute',
                            bottom: 5,
                            right: 5,
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            backgroundColor: Midnight,
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: BoxShadow,
                            zIndex: 2,
                            '&:hover': {
                                backgroundColor: Midnight,
                            },
                        }}>
                        <PencilSimpleIcon sx={{ color: White, width: 24, height: 24 }} />
                    </IconButton>
                    <ItemEditMenu
                        open={menuOpen}
                        anchorEl={anchorEl}
                        onClose={handleMenuClose}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                    />
                </Box>
            )}
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: Midnight,
                        opacity: '50%',
                        zIndex: 3,
                    }}>
                    <CircularProgress sx={{ color: White }} />
                </Box>
            )}
        </Box>
    );
}

CoverPhotoUploader.propTypes = {
    coverPhoto: PropTypes.string,
    onImageChange: PropTypes.func,
};
