import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SealPercentIcon from '../../icons/SealPercentIcon.jsx';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import Box from '@mui/material/Box';
import CurrencyInput from '../CurrencyInput/CurrencyInput.jsx';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { OrderDiscountTypes } from '../../constants/OrderDiscountTypes.jsx';
import { Teal, White } from '../../theme.js';
import DollarSignIcon from '../../icons/DollarSignIcon.jsx';
import PercentIcon from '../../icons/PercentIcon.jsx';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OrderStatuses } from '../../constants/OrderStatuses.jsx';
import PaymentTermsSelect from '../PaymentTermsSelect/PaymentTermsSelect.jsx';

export default function FinancialSettings({ order, onChange }) {
    const { t } = useTranslation();
    const [shippingCost, setShippingCost] = useState(order.shippingCost);
    const [discountType, setDiscountType] = React.useState(order.discountType || OrderDiscountTypes.DOLLARS);
    const [discountValue, setDiscountValue] = React.useState(order.discountAmount || '0.00');
    const [paymentTermId, setPaymentTermId] = React.useState(order.paymentTermId || 0);
    const isDisabledStatus = order.status === OrderStatuses.DECLINED || order.status === OrderStatuses.CANCELLED;

    const handleChange = (event, data) => {
        onChange({ discountType, discountAmount: discountValue, shippingCost, paymentTermId, ...data });
    };

    useEffect(() => {
        setShippingCost(order.shippingCost);
        setDiscountValue(order.discountAmount);
    }, [order.shippingCost, order.discountAmount]);

    useEffect(() => {
        setDiscountType(order.discountType || OrderDiscountTypes.DOLLARS);
    }, [order.discountType]);

    const handlePaymentTermChange = (value) => {
        setPaymentTermId(value);
        handleChange(null, { paymentTermId: value });
    };

    return (
        <Card>
            <CardContent sx={{ padding: '24px' }}>
                <Stack spacing={2}>
                    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                fontWeight: 700,
                            }}
                            variant="textLg">
                            <SealPercentIcon />
                            {t('FinancialSettings.title')}
                        </Typography>
                    </Stack>
                    <FormInputLabel text={t('FinancialSettings.shippingLabel')} sx={{ fontSize: 10 }}>
                        <Stack gap={2} sx={{ width: '100%' }}>
                            <Typography variant="textSm">{t('FinancialSettings.shippingMessage')}</Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }}>
                                <CurrencyInput
                                    value={shippingCost}
                                    name="shipping"
                                    onChange={(e) => {
                                        setShippingCost(e.target.value);
                                    }}
                                    disabled={isDisabledStatus}
                                    sx={{ width: 136 }}
                                />
                                <Button
                                    disabled={shippingCost === null || isDisabledStatus}
                                    onClick={handleChange}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ width: 100 }}>
                                    {t('FinancialSettings.addButton')}
                                </Button>
                            </Box>
                        </Stack>
                    </FormInputLabel>
                    <FormInputLabel text={t('FinancialSettings.discountLabel')} sx={{ fontSize: 10 }}>
                        <Stack gap={2}>
                            <Typography variant="textSm">{t('FinancialSettings.discountMessage')}</Typography>
                            <Stack spacing={2} direction="row">
                                <ToggleButtonGroup
                                    color="info"
                                    exclusive
                                    size="small"
                                    onChange={(e, value) => {
                                        setDiscountType(value);
                                    }}
                                    value={discountType}
                                    sx={{ borderRadius: '12px', height: 50, width: 100 }}>
                                    <ToggleButton
                                        value={OrderDiscountTypes.DOLLARS}
                                        sx={{
                                            width: 50,
                                            '&.Mui-selected': {
                                                backgroundColor: Teal,
                                                color: White,
                                                '&:hover': {
                                                    backgroundColor: Teal,
                                                },
                                            },
                                        }}>
                                        <DollarSignIcon sx={{ width: 24, height: 24 }} />
                                    </ToggleButton>
                                    <ToggleButton
                                        value={OrderDiscountTypes.PERCENT}
                                        sx={{
                                            width: 50,
                                            '&.Mui-selected': {
                                                backgroundColor: Teal,
                                                color: White,
                                                '&:hover': {
                                                    backgroundColor: Teal,
                                                },
                                            },
                                        }}>
                                        <PercentIcon sx={{ width: 16.5, height: 16.5 }} />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <CurrencyInput
                                    inputProps={{
                                        prefix: discountType === OrderDiscountTypes.DOLLARS ? '$' : undefined,
                                        suffix: discountType === OrderDiscountTypes.PERCENT ? '%' : undefined,
                                    }}
                                    disabled={isDisabledStatus}
                                    value={discountValue}
                                    name="discount"
                                    onChange={(e) => setDiscountValue(e.target.value)}
                                />
                            </Stack>
                            <Button
                                onClick={handleChange}
                                disabled={discountValue === null || isDisabledStatus}
                                variant="outlined"
                                color="secondary"
                                sx={{ width: 128 }}>
                                {t('FinancialSettings.updateButton')}
                            </Button>
                        </Stack>
                    </FormInputLabel>
                    <PaymentTermsSelect
                        label={t('FinancialSettings.termsLabel')}
                        value={paymentTermId}
                        onChange={handlePaymentTermChange}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
}

FinancialSettings.propTypes = {
    order: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};
