import CircularProgress from '@mui/material/CircularProgress';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher } from 'react-router-dom';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';

export default function PaymentTermsSelect({ isLoading, label, onChange, value }) {
    const flags = useFlags();
    const fetcher = useFetcher();
    const { t } = useTranslation();

    useEffect(() => {
        if (flags.paymentTerms && fetcher.state === 'idle' && !fetcher.data) {
            fetcher.load('/api/payment-terms');
        }
    }, [flags, fetcher]);

    const defaultTerms = (fetcher.data?.defaultPaymentTerms?.data?.data || []).map((i) => ({
        value: i.id,
        label: i.title,
    }));
    const customTerms = (fetcher.data?.customPaymentTerms?.data?.data || []).map((i) => ({
        value: i.id,
        label: i.title,
    }));

    const handleOnChange = (event) => {
        onChange(event.target.value);
    };

    const showLoadingIndicator = isLoading || (fetcher.state !== 'idle' && !fetcher.data);

    return (
        <>
            {flags.paymentTerms ? (
                <FormInputLabel
                    text={label}
                    sx={{ fontSize: 12 }}
                    adornment={showLoadingIndicator ? <CircularProgress size={15} /> : null}>
                    <Select
                        value={defaultTerms.length > 0 ? value : ''}
                        onChange={handleOnChange}
                        sx={{ width: '100%', height: 40 }}>
                        <ListSubheader>{t('PaymentTermsSelect.defaultPaymentTermsLabel')}</ListSubheader>
                        {defaultTerms.map((i) => (
                            <MenuItem key={i.value} value={i.value}>
                                <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                    {i.label}
                                </Typography>
                            </MenuItem>
                        ))}
                        <ListSubheader>{t('PaymentTermsSelect.customPaymentTermsLabel')}</ListSubheader>
                        {customTerms.map((i) => (
                            <MenuItem key={i.value} value={i.value}>
                                <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                                    {i.label}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormInputLabel>
            ) : null}
        </>
    );
}

PaymentTermsSelect.propTypes = {
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
