import { redirect } from 'react-router-dom';
import { BusinessTypes } from '../../constants/BusinessTypes';
import * as businessProvider from '../../providers/businessProvider';

/**
 * Retail loader prevents brands from viewing retail pages
 * @param {import("react-router-dom").LoaderFunctionArgs} params
 */
export async function loader({ request }) {
    const business = await businessProvider.getBusiness({ signal: request.signal });
    if (business?.type === BusinessTypes.RETAIL) {
        return redirect('/retail/connections');
    }
    return null;
}
