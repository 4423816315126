import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function HTMLDisplay({ content }) {
    return <Box dangerouslySetInnerHTML={{ __html: content }} />;
}

HTMLDisplay.propTypes = {
    content: PropTypes.string,
};
