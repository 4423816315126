import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';

/**
 * A component that provides infinite scolling
 * @param {*} param0
 * @returns
 */
export default function InfiniteScroller(props) {
    const { children, hasMore, loading, loadNext } = props;
    const scrollListener = React.useRef(loadNext);
    const hasMoreRef = React.useRef(hasMore);

    React.useEffect(() => {
        scrollListener.current = loadNext;
    }, [loadNext]);

    const onScroll = _.throttle(() => {
        const documentHeight = document.documentElement.scrollHeight;
        const scrollDifference = Math.floor(window.innerHeight + window.scrollY);
        const scrollEnded = documentHeight === scrollDifference;

        if (scrollEnded && !loading && hasMoreRef.current) {
            scrollListener.current();
        }
    }, 600);

    React.useEffect(() => {
        hasMoreRef.current = hasMore;
        if (hasMore === false) {
            window.removeEventListener('scroll', onScroll);
        }
    }, [hasMore]);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', onScroll);
        }

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <>
            {children}
            <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 80, py: 2 }}>
                {!loading ? null : <CircularProgress />}
            </Box>
        </>
    );
}

InfiniteScroller.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    hasMore: PropTypes.bool,
    loading: PropTypes.bool,
    loadNext: PropTypes.func,
};
