import { groupBy } from 'lodash';

const sizeOrder = ['XS', 'S', 'SMALL', 'M', 'MEDIUM', 'L', 'LARGE', 'XL', 'XXL', 'XXXL'];

// Sorting functions
const sortByTagOptions = (a, b) => {
    return a?.variantTagOptions?.Size || a?.variantTagOptions?.size
        ? sortBySize(a.variantTagOptions, b.variantTagOptions)
        : sortAlphabetically(Object.values(a.variantTagOptions).join(''), Object.values(b.variantTagOptions).join(''));
};

function sortBySize(a, b) {
    return (
        sizeOrder.indexOf((a?.Size || a?.size).toUpperCase()) - sizeOrder.indexOf((b?.Size || b?.size).toUpperCase())
    );
}

const sortAlphabetically = (a, b) => a.localeCompare(b);

/**
 * A helper function that takes a product and returns an array of options for the product variants
 * options are grouped by color and then by other options
 * @param {object} product
 * @returns {array}
 */
const mapProductVariantsToOptions = (product) => {
    const options = product?.productVariantTagOptions;
    const allOptions = options?.map((variant) => ({
        label: variant.name,
        values: variant.values,
    }));
    const colorOptions = allOptions.filter((option) => option.label.toLowerCase().includes('color'));
    const nonColorOptions = allOptions.filter((option) => !option.label.toLowerCase().includes('color'));
    return [...colorOptions, ...nonColorOptions];
};

/**
 * A react hook that takes an array of products and returns an array of grouped products by color with items sorted by size
 * @param {*} products
 */
export function useGroupedProducts(product) {
    const variantOptions = mapProductVariantsToOptions(product);
    const activeProductVariants = product?.productVariants
        .filter((variant) => variant.active)
        .map((v) => {
            return {
                ...v,
                variantTagOptions: v.variantTags.reduce((acc, tag) => {
                    acc[tag.name] = tag.value;
                    return acc;
                }, {}),
            };
        });
    const colorIndex = variantOptions.findIndex((option) => option.label.toLowerCase().includes('color'));
    const groupByIndex = colorIndex > -1 ? colorIndex : 0;
    const variantsGroupedByHeader = groupBy(
        activeProductVariants,
        `variantTagOptions.${variantOptions[groupByIndex].label}`
    );
    const activeProductVariantGroups = Object.keys(variantsGroupedByHeader)
        .map((key, i) => ({
            label: key,
            images: [variantsGroupedByHeader[key][0]?.imageLinks?.[0] || product?.imageLinks?.[0]],
            rowIndex: i,
            rows: variantsGroupedByHeader[key]
                .map((variant) => ({
                    ...variant,
                    images: [variant.imageLinks?.[0] || product?.imageLinks?.[0]],
                }))
                .sort(sortByTagOptions),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return {
        groupedProducts: activeProductVariantGroups,
        variantOptions,
    };
}
