import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';

export default function CartCheckoutError() {
    const error = useRouteError();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [snackbarOpen, setSnackbarOpen] = React.useState(true);

    const handleClose = () => {
        setSnackbarOpen(false);
        navigate('/retail/cart');
    };

    return (
        <Snackbar open={snackbarOpen} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {t('Error.somethingWrong')}
                {error?.message}
            </Alert>
        </Snackbar>
    );
}
