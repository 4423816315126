import * as api from '../../api.js';
import { differenceWith, isEmpty, isEqual, omit } from 'lodash';
import { formatPriceRangeString } from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';

// Helper function to transform product variants into an object
export const mapOverridesByVariantId = (productVariants) => {
    return productVariants?.reduce((acc, variant) => {
        if (variant.variantPricingGroups) {
            variant.variantPricingGroups.forEach((group) => {
                const override = {
                    ...group,
                    discountPercentage: Number(group.discountPercentage),
                };

                if (!acc[variant.id]) {
                    acc[variant.id] = [];
                }
                acc[variant.id].push(override);
            });
        }
        return acc;
    }, {});
};

export function mapOverridesForSubmit({ overrides, initialOverrides }) {
    // Filter overrides to only include those that are new or have been updated
    const updatedOverrides = overrides.filter((override) => {
        const initialOverride = initialOverrides.find((init) => init.id === override.id);
        return !initialOverride || initialOverride.discountPercentage !== override.discountPercentage;
    });

    return updatedOverrides.map((override) => {
        delete override.name;
        delete override.createdAt;
        delete override.updatedAt;
        delete override.product;
        return override;
    });
}

export function hasMadeChanges(overrides, initialOverrides) {
    const excludedProperties = ['id', 'createdAt', 'updatedAt'];
    const sanitizedInitialOverrides = initialOverrides.map((override) => omit(override, excludedProperties));
    const sanitizedOverrides = overrides.map((override) => omit(override, excludedProperties));

    return (
        sanitizedInitialOverrides.length !== sanitizedOverrides.length ||
        !isEmpty(differenceWith(sanitizedOverrides, sanitizedInitialOverrides, isEqual))
    );
}

export async function savePricingGroups({ signal, body }) {
    const { productId, updates, deletes } = body;
    // Update Variant Pricing Groups
    const variantIds = updates.map((override) => override.variantId);
    const updatePromises = variantIds.map((variantId) => {
        const variantUpdates = updates.filter((override) => override.variantId === variantId);
        return api.updateVariantPricingGroups(productId, variantId, variantUpdates, { signal });
    });
    // Delete Variant Pricing Groups
    const deleteVariantIds = deletes.map((override) => override.variantId);
    const deletePromises = deleteVariantIds.map((variantId) => {
        const variantDeletes = deletes.filter((override) => override.variantId === variantId).map((del) => del.id);
        return api.deleteVariantPricingGroups(productId, variantId, variantDeletes, { signal });
    });
    // Await all promises
    const response = await Promise.all([...updatePromises, ...deletePromises]);
    return { response, updates };
}

export function sortPricingGroups(pricingGroups) {
    return pricingGroups.sort((a, b) => {
        if (b.is_system_generated !== a.is_system_generated) {
            return b.is_system_generated - a.is_system_generated;
        }
        return new Date(a.createdAt) - new Date(b.createdAt);
    });
}

export const formatPercentageValue = (value) => {
    if (!value) return '0%';
    const [integer, decimal] = value.toString().split('.');
    if (!decimal || decimal === '0' || parseInt(decimal, 10) === 0) {
        return `${integer}%`;
    }
    const trimmedDecimal = decimal.substring(0, 2).replace(/0+$/, '');
    return trimmedDecimal ? `${integer}.${trimmedDecimal}%` : `${integer}%`;
};

const wholesalePricesForBasePricingGroups = (msrp, pricingGroups) => {
    const allPrices = [];
    pricingGroups?.forEach((group) => {
        const pricingGroupPrice = msrp - msrp * (group.discountPercentage / 100);
        if (!allPrices.some((price) => price === pricingGroupPrice)) {
            allPrices.push(pricingGroupPrice);
        }
    });
    return allPrices;
};

const getBasePricingGroupsIncludedInRange = (product, pricingGroups) => {
    return pricingGroups.filter(
        (group) =>
            !product.productVariants?.every(
                (variant) =>
                    Array.isArray(variant.variantPricingGroups) &&
                    variant.variantPricingGroups?.some((variantGroup) => variantGroup.pricingGroupId === group.id)
            )
    );
};

// Price Helpers

const overriddenPricesForVariants = (variants, msrp, pricingGroups) => {
    let allPrices = [];

    // Extract pricingGroupIds from the pricingGroups parameter
    const pricingGroupIds = pricingGroups.map((group) => group.id);

    variants?.forEach((variant) => {
        if (!isEmpty(variant.variantPricingGroups)) {
            // Filter variant pricing groups by matching pricingGroupIds
            const overriddenPrices = variant.variantPricingGroups
                .filter((variantGroup) => pricingGroupIds.includes(variantGroup.pricingGroupId))
                .map((variantGroup) => {
                    const adjustedPrice = Number(msrp) - Number(msrp) * (Number(variantGroup.discountPercentage) / 100);
                    return Number(adjustedPrice.toFixed(2));
                });

            allPrices = allPrices.concat(overriddenPrices);
        }
    });

    return allPrices;
};

export const getWholesaleRange = (product, pricingGroups) => {
    let allPrices = [];
    const msrp = Number(product.msrp).toFixed(2);

    // Filter out pricingGroups overridden by all variants
    const baseGroupsToInclude = getBasePricingGroupsIncludedInRange(product, pricingGroups);

    // Add prices from non-overridden base pricing groups
    allPrices = allPrices.concat(wholesalePricesForBasePricingGroups(msrp, baseGroupsToInclude));

    // Add prices from all variant overrides
    const overriddenPrices = overriddenPricesForVariants(product.productVariants, msrp, pricingGroups);
    allPrices = allPrices.concat(overriddenPrices);

    // Calculate min and max wholesale range
    const minDiscount = Math.min(...allPrices).toFixed(2);
    const maxDiscount = Math.max(...allPrices).toFixed(2);
    return { min: minDiscount, max: maxDiscount };
};

export const getWholesaleRangeString = (product, pricingGroups) => {
    return formatPriceRangeString(getWholesaleRange(product, pricingGroups));
};

export const getWholesaleRangeForVariant = (variant, pricingGroups) => {
    let allPrices = [];
    const msrp = Number(variant.msrp).toFixed(2);
    const wholesale = Number(variant.wholesale).toFixed(2);

    // Filter out pricingGroups completely overridden by the variant
    const baseGroupsToInclude = pricingGroups.filter(
        (group) =>
            !(
                Array.isArray(variant.variantPricingGroups) &&
                variant.variantPricingGroups.some((variantGroup) => variantGroup.pricingGroupId === group.id)
            )
    );

    // Add prices from non-overridden base pricing groups
    allPrices = allPrices.concat(wholesalePricesForBasePricingGroups(msrp, baseGroupsToInclude));

    // Add prices from all overridden pricing groups
    const overriddenPrices = overriddenPricesForVariants([variant], msrp, pricingGroups);
    allPrices = allPrices.concat(overriddenPrices);

    // If no prices are available, default to the variant's wholesale value
    if (isEmpty(allPrices)) {
        return { min: wholesale, max: wholesale };
    }

    // Calculate the min and max prices from all prices
    const minDiscount = Math.min(...allPrices).toFixed(2);
    const maxDiscount = Math.max(...allPrices).toFixed(2);
    return { min: minDiscount, max: maxDiscount };
};

export const getWholesaleRangeForVariantString = (variant, pricingGroups) => {
    return formatPriceRangeString(getWholesaleRangeForVariant(variant, pricingGroups));
};

// Percentage Helpers

const overriddenPercentagesForVariants = (variants, pricingGroups) => {
    let allPercentages = [];

    // Extract pricingGroupIds from the pricingGroups parameter
    const pricingGroupIds = pricingGroups.map((group) => group.id);

    variants?.forEach((variant) => {
        if (!isEmpty(variant.variantPricingGroups)) {
            // Filter variant pricing groups by matching pricingGroupIds
            const overriddenPercentages = variant.variantPricingGroups
                .filter((variantGroup) => pricingGroupIds.includes(variantGroup.pricingGroupId))
                .map((variantGroup) => variantGroup.discountPercentage);

            allPercentages = allPercentages.concat(overriddenPercentages);
        }
    });

    return allPercentages;
};

const wholesalePercentagesForBasePricingGroups = (msrp, pricingGroups) => {
    const allPercentages = [];
    pricingGroups?.forEach((group) => {
        const pricingGroupPercentage = group.discountPercentage;
        if (!allPercentages.some((percentage) => percentage === pricingGroupPercentage)) {
            allPercentages.push(pricingGroupPercentage);
        }
    });
    return allPercentages;
};

export const getPercentageRange = (product, pricingGroups) => {
    let allPercentages = [];
    const msrp = Number(product.msrp).toFixed(2);

    // Filter out pricingGroups overridden by all variants
    const baseGroupsToInclude = getBasePricingGroupsIncludedInRange(product, pricingGroups);

    // Add prices from non-overridden base pricing groups
    allPercentages = allPercentages.concat(wholesalePercentagesForBasePricingGroups(msrp, baseGroupsToInclude));

    // Add prices from all variant overrides
    const overriddenPercentages = overriddenPercentagesForVariants(product.productVariants, pricingGroups);
    allPercentages = allPercentages.concat(overriddenPercentages);

    // Calculate min and max wholesale range
    const minPercentage = Math.min(...allPercentages).toFixed(2);
    const maxPercentage = Math.max(...allPercentages).toFixed(2);
    return { min: minPercentage, max: maxPercentage };
};
export const getPercentageRangeString = (product, pricingGroups) => {
    const range = getPercentageRange(product, pricingGroups);
    return `${formatPercentageValue(range.min)} - ${formatPercentageValue(range.max)}`;
};
