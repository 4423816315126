import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CancelOrderDialog from './CancelOrderDialog';

export default function CancelOrderButton(props) {
    const { order, onSuccess } = props;
    const { t } = useTranslation();
    const [showDialogue, setShowDialogue] = useState(false);

    const handleCancelOrder = () => {
        setShowDialogue(true);
    };
    return (
        <>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleCancelOrder}>
                {t('OrderSummaryCard.buttons.cancelOrderLabel')}
            </Button>
            <CancelOrderDialog
                orderId={order?.id}
                open={showDialogue}
                onClose={() => setShowDialogue(false)}
                onSuccess={onSuccess}
                businessId={order?.businessId}
            />
        </>
    );
}

CancelOrderButton.propTypes = {
    order: PropTypes.object,
    onSuccess: PropTypes.func,
};
