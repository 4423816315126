import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';
import PlusCircleIcon from '../../icons/PlusCircleIcon.jsx';
import CompanyUsers from '../../routes/settings/CompanyUsers/CompanyUsers.jsx';
import AddUserDialog from '../AddUserDialog/AddUserDialog.jsx';
import PropTypes from 'prop-types';
import * as api from '../../api.js';
import { useError } from '../Error/ErrorProvider.jsx';

export default function ManageUsers({ businessId }) {
    const [users, setUsers] = useState([]);
    const [showAddUser, setShowAddUser] = useState(false);
    const { handleError } = useError();
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [offset, setOffset] = useState(0);
    const limit = 10;
    const loadUsers = async () => {
        setIsLoadingUsers(true);
        try {
            const response = await api.getUsers({ limit, offset, sort: '-updatedAt' });
            setUsers((prevItems) => [...prevItems, ...response.rows]);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoadingUsers(false);
        }
    };

    const handleScroll = () => {
        // Calculate if the scroll is at the bottom
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 10 &&
            !isLoadingUsers
        ) {
            setOffset((prevOffset) => prevOffset + limit);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoadingUsers]);

    const handleDeleteUser = (userId) => {
        setUsers(users.filter((user) => user.id !== userId));
    };

    const handleUserAdded = () => {
        setUsers([]);
        setOffset(0);
        setShowAddUser(false);
    };

    useEffect(() => {
        loadUsers();
    }, [offset]);

    return (
        <>
            <Stack gap={3} sx={{ display: 'flex', width: '100%' }}>
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="textLg" sx={{ fontWeight: 'bold' }}>
                        Manage Users
                    </Typography>
                    <Button
                        onClick={() => setShowAddUser(true)}
                        color="secondary"
                        variant="outlined"
                        sx={{ paddingX: '16px', height: 40 }}>
                        <Stack gap={1} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <PlusCircleIcon sx={{ width: 24, height: 24 }} />
                            <Typography
                                noWrap
                                variant="textMd"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                Add User
                            </Typography>
                        </Stack>
                    </Button>
                </Stack>
                <CompanyUsers users={users} isLoadingUsers={isLoadingUsers} handleDeleteUser={handleDeleteUser} />
            </Stack>
            <AddUserDialog
                businessId={businessId}
                onClose={() => setShowAddUser(false)}
                open={showAddUser}
                onUserAdded={handleUserAdded}
            />
        </>
    );
}

ManageUsers.propTypes = {
    businessId: PropTypes.string,
};
