import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import OrderTermsSelect from '../../../../components/OrderTermsSelect/OrderTermsSelect';
import { OrderDiscountTypes } from '../../../../constants/OrderDiscountTypes';
import { OrderTermsTypes } from '../../../../constants/OrderTermsTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function EditLineItemsDialog(props) {
    const { open, onClose, onConfirm, error } = props;
    const flags = useFlags();

    const [discountType, setDiscountType] = React.useState(props.discountType || OrderDiscountTypes.DOLLARS);
    const [discountValue, setDiscountValue] = React.useState(props.discountValue || '0.00');
    const [shippingValue, setShippingValue] = React.useState(props.shippingValue || '0.00');
    const [termsValue, setTermsValue] = React.useState(props.termsValue || OrderTermsTypes.PAY_UP_FRONT);

    const { t } = useTranslation();

    React.useEffect(() => {
        if (open) {
            setDiscountType(props.discountType);
            setDiscountValue(props.discountValue || '0.00');
            setShippingValue(props.shippingValue || '0.00');
            setTermsValue(props.termsValue);
        }
    }, [open]);

    const handleReset = (e) => {
        setDiscountType(OrderDiscountTypes.DOLLARS);
        setDiscountValue(0);
        setShippingValue(0);
        setTermsValue(OrderTermsTypes.PAY_UP_FRONT);
    };
    const handleApply = (e) => {
        if (onConfirm) {
            onConfirm(e, {
                shippingValue,
                discountType,
                discountValue,
                termsValue,
            });
        }
    };
    const handleClose = (e) => {
        if (onClose) {
            onClose(e);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{t('EditLineItemsDialog.title')}</DialogTitle>
            <Divider />
            <DialogContent sx={{ width: { sm: 372 } }}>
                <Stack spacing={3} direction="column">
                    <Box>
                        <Typography variant="h6">{t('EditLineItemsDialog.shippingLabel')}</Typography>
                        <Typography color={'common.borderColor'} variant="subtitle2">
                            {t('EditLineItemsDialog.shippingSubtitle')}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 1 }}>
                            <Box sx={{ maxWidth: 120 }}>
                                <CurrencyInput
                                    value={shippingValue}
                                    name="shipping"
                                    size="small"
                                    onChange={(e) => setShippingValue(e.target.value)}
                                    autoFocus
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h6">{t('EditLineItemsDialog.discountLabel')}</Typography>
                        <Typography color={'common.borderColor'} variant="subtitle2">
                            {t('EditLineItemsDialog.discountSubtitle')}
                        </Typography>
                        <Stack sx={{ py: 1 }} spacing={2} justifyContent="flex-end" direction="row" flexWrap="wrap">
                            <ToggleButtonGroup
                                color="info"
                                exclusive
                                size="small"
                                onChange={(e, value) => {
                                    setDiscountType(value);
                                }}
                                value={discountType}>
                                <ToggleButton value={OrderDiscountTypes.DOLLARS}>
                                    <AttachMoneyIcon />
                                </ToggleButton>
                                <ToggleButton value={OrderDiscountTypes.PERCENT}>
                                    <PercentIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Box sx={{ maxWidth: 120 }}>
                                <CurrencyInput
                                    inputProps={{
                                        prefix: discountType === OrderDiscountTypes.DOLLARS ? '$' : undefined,
                                        suffix: discountType === OrderDiscountTypes.PERCENT ? '%' : undefined,
                                    }}
                                    value={discountValue}
                                    name="discount"
                                    size="small"
                                    onChange={(e) => setDiscountValue(e.target.value)}
                                />
                            </Box>
                        </Stack>
                    </Box>
                    <Box>
                        {flags.displayOrderTerms ? (
                            <Typography variant="h6">{t('EditLineItemsDialog.termsLabel')}</Typography>
                        ) : null}
                        <Typography color={'common.borderColor'} variant="subtitle2">
                            {t('EditLineItemsDialog.termsSubtitle')}
                        </Typography>
                        <Stack justifyContent="flex-end" sx={{ py: 1 }} direction="row">
                            <OrderTermsSelect
                                disabled
                                size="small"
                                defaultValue={termsValue}
                                onChange={(e) => setTermsValue(e.target.value)}
                            />
                        </Stack>
                    </Box>
                </Stack>
                {!error ? null : <Alert severity="error">{t('Error.somethingWrong')}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="contained" color="error" onClick={handleReset}>
                    {t('EditLineItemsDialog.buttons.resetLabel')}
                </Button>
                <Button color="secondary" size="small" variant="contained" onClick={handleClose}>
                    {t('EditLineItemsDialog.buttons.secondaryLabel')}
                </Button>
                <Button size="small" variant="contained" onClick={handleApply}>
                    {t('EditLineItemsDialog.buttons.primaryLabel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditLineItemsDialog.propTypes = {
    discountType: PropTypes.string,
    discountValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.any,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
    shippingValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    termsValue: PropTypes.string,
};
