import React from 'react';
import { TextField, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import TrashIcon from '../../../icons/TrashIcon.jsx';
import { NumericFormat } from 'react-number-format';
import isEmpty from 'lodash/isEmpty';
import { formatPercentageValue } from '../pricingGroupUtils.js';

export default function PricingGroupRow({ group, onChange, onRemove }) {
    return (
        <Box display="flex" alignItems="flex-start" gap={2} mb={2}>
            <TextField
                size="small"
                disabled={group.is_system_generated}
                value={group.name}
                error={isEmpty(group.name)}
                onChange={(e) => onChange(group.id, 'name', e.target.value)}
                fullWidth
                sx={{ flex: 3, height: 40, fontSize: 14, fontWeight: 500 }}
            />
            <NumericFormat
                value={
                    group.discountPercentage === undefined || group.discountPercentage === ''
                        ? ''
                        : formatPercentageValue(group.discountPercentage)
                }
                required
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={false}
                onValueChange={(values) => onChange(group.id, 'discountPercentage', values.floatValue)}
                isAllowed={(values) => {
                    const { floatValue, formattedValue } = values;
                    const hasValidDecimals = formattedValue.split('.')[1]?.length <= 2 || !formattedValue.includes('.');
                    return ((floatValue >= 0 && floatValue <= 100) || floatValue === undefined) && hasValidDecimals;
                }}
                valueIsNumericString
                customInput={TextField}
                size="small"
                error={group.discountPercentage === '' || group.discountPercentage === undefined}
                sx={{
                    flex: 1,
                    height: 40,
                    fontSize: 14,
                    fontWeight: 500,
                }}
                suffix="%"
            />
            <Box sx={{ width: 40, height: 40 }}>
                {!group.is_system_generated ? (
                    <IconButton onClick={() => onRemove(group.id)} sx={{ flex: 'none' }}>
                        <TrashIcon />
                    </IconButton>
                ) : null}
            </Box>
        </Box>
    );
}

PricingGroupRow.propTypes = {
    group: PropTypes.object,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
};
