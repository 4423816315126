import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function RemoveOrderItemDialog(props) {
    const { open, onClose, onConfirm } = props;
    const { t } = useTranslation();

    const handleConfirm = (e) => {
        if (onConfirm) {
            onConfirm(e);
        }
    };
    const handleCancel = (e) => {
        if (onClose) {
            onClose(e);
        }
    };
    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{t('RemoveOrderItemDialog.title')}</DialogTitle>
            <DialogContent>
                <Typography>{t('RemoveOrderItemDialog.description')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    {t('RemoveOrderItemDialog.cancelBtnLabel')}
                </Button>
                <Button onClick={handleConfirm} variant="contained" color="destructive">
                    {t('RemoveOrderItemDialog.confirmBtnLabel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

RemoveOrderItemDialog.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
};
