import PropTypes from 'prop-types';
import * as React from 'react';
import { useMatches, useNavigate, useNavigation, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import * as businessProvider from '../../../providers/businessProvider';
import NoConnectionsFound from './components/NoConnectionsFound';
import { Box } from '@mui/material';
import ConnectionsDrawer from './products/ConnectionsDrawer';
import AccountMenu from '../../../components/AccountMenu/AccountMenu.jsx';
import { useBusinesses } from '../../../hooks/useBusinesses.js';

export const routeId = 'connections';

export async function loader({ request }) {
    const { signal } = request;
    const results = await businessProvider.getBusiness({ signal });
    return {
        businesses: results?.connectedBusiness,
    };
}

export default function Connections({ routeId }) {
    const root = useRouteLoaderData('root');
    const [businesses] = useBusinesses(routeId);
    const matches = useMatches();
    const navigate = useNavigate();
    const { state } = useNavigation();
    const [search] = useSearchParams();

    React.useEffect(() => {
        const currentRoute = matches[matches.length - 1]?.id;
        // TODO enum for routenames
        if (currentRoute === 'ConnectionsView' && businesses?.[0]) {
            navigate(`/retail/connections/${businesses[0].id}/products`, { replace: true });
        }
    }, [matches]);

    const activeBusiness =
        root?.businessesByBusinessId?.[search.get('businessId')] || root?.business?.connectedBusiness?.[0];

    return (
        <Box sx={{ display: 'flex' }}>
            <ConnectionsDrawer activeBusiness={activeBusiness} />
            <Box component="main" sx={{ flexGrow: 1 }}>
                {state === 'idle' ? <NoConnectionsFound businessType={root?.business?.type} /> : null}
            </Box>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', p: 2 }}>
                <Box>
                    <AccountMenu />
                </Box>
            </Box>
        </Box>
    );
}

Connections.propTypes = {
    routeId: PropTypes.string,
    businessType: PropTypes.string,
};
