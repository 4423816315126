import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Midnight, White } from '../../theme.js'; // Assuming these are your color values

export const statuses = [
    { value: 'In Review', label: 'In Review' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Cancelled', label: 'Cancelled' },
    { value: 'Shipped', label: 'Shipped' },
];

function OrderStatusSelect(props) {
    const { label, onChange, selectProps, defaultValue = '' } = props;
    const { t } = useTranslation();
    const emptyLabel = t('OrderStatusSelect.emptyLabel');
    const [value, setValue] = React.useState(defaultValue || emptyLabel);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    const backgroundColor = value === emptyLabel ? 'transparent' : Midnight;
    const fontColor = value === emptyLabel ? Midnight : White;

    return (
        <Box sx={{ maxWidth: 150 }}>
            <FormControl fullWidth>
                <InputLabel id="order-status-select-label" sx={{ color: Midnight }}>
                    {label}
                </InputLabel>
                <Select
                    label={label}
                    labelId="order-status-select-label"
                    id="order-status-select"
                    value={value}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Typography
                            variant="textTiny"
                            sx={{
                                color: selected === emptyLabel ? Midnight : White,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                pt: 0.5,
                            }}>
                            {selected === emptyLabel
                                ? emptyLabel
                                : statuses.find((status) => status.value === selected)?.label}
                        </Typography>
                    )}
                    sx={{
                        backgroundColor,
                        '.MuiSelect-icon': {
                            color: fontColor,
                        },
                        transition: 'background-color 0.3s ease, color 0.3s ease',
                    }}
                    {...selectProps}>
                    <MenuItem value={emptyLabel}>
                        <Typography
                            variant="textTiny"
                            sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5, color: Midnight }}>
                            {emptyLabel}
                        </Typography>
                    </MenuItem>
                    {statuses.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                            <Typography
                                variant="textTiny"
                                sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5, color: Midnight }}>
                                {i.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

OrderStatusSelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};

export default OrderStatusSelect;
