/**
 * Enums representing the payment term types
 */
export const PaymentTermTypes = {
    CONSIGNMENT: 'CONSIGNMENT',
    CUSTOM: 'CUSTOM',
    EARLY_PAYMENT_DISCOUNT: 'EARLY_PAYMENT_DISCOUNT',
    NET_DAYS: 'NET_DAYS',
    PAY_ON_SCAN: 'PAY_ON_SCAN',
    PAY_UP_FRONT: 'PAY_UP_FRONT',
};
