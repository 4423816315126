import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as api from '../../api.js';
import ShopifyImportProductCard from '../ShopifyImportProductCard/ShopifyImportProductCard.jsx';
import _ from 'lodash';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import theme from '../../theme.js';
export default function ShopifyImportModal(props) {
    const { shops, open, businessId, onClose, onCancel } = props;
    const [isLoading, setIsLoading] = useState(false);
    const style = {
        position: 'absolute',
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 650,
        height: 700,
        backgroundColor: 'background.paper',
        boxShadow: 24,
        borderRadius: '6px',
    };

    const confirmProductsImport = async () => {
        setIsLoading(true);
        for (const shop of shops) {
            if (shop.products.length > 0) {
                try {
                    await api.upsertProducts({ businessId, products: shop.products }, {});
                    onClose();
                } catch (error) {
                    console.error(`Failed to import products from ${shop.name}:`, error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    return (
        <Modal open={open}>
            <Box sx={{ position: 'relative', minHeight: '100vh' }}>
                <Stack sx={{ ...style, pl: 4, pr: 4, pt: 3, pb: 3 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Products
                    </Typography>
                    <Divider />
                    <List
                        sx={{
                            flexGrow: 1,
                            overflowY: 'auto',
                            '::-webkit-scrollbar': {
                                width: '8px',
                            },
                            '::-webkit-scrollbar-thumb': {
                                background: '#1DD75BFF',
                                borderRadius: '4px',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                                background: '#1AA64CFF',
                            },
                        }}>
                        {shops.map((shop, shopIndex) => (
                            <Stack gap={1} key={shopIndex}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '17px' }}>{shop.name}</Typography>
                                <Stack>
                                    {_.isEmpty(shop.products) ? (
                                        <Typography>No Shopify Products</Typography>
                                    ) : (
                                        shop.products?.map((product, index) => (
                                            <Box key={index} sx={{ padding: 1 }}>
                                                <ShopifyImportProductCard product={product} />
                                            </Box>
                                        ))
                                    )}
                                </Stack>
                            </Stack>
                        ))}
                    </List>
                    <Divider />
                    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', pt: 3 }}>
                        <Button sx={{ textTransform: 'none', width: '150px' }} onClick={() => onCancel()}>
                            Close
                        </Button>
                        <LoadingButton
                            loadingColor="white"
                            isLoading={isLoading}
                            onClick={confirmProductsImport}
                            sx={{
                                backgroundColor: theme.palette.shopify.primary,
                                color: 'white',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                width: '150px',
                                '&:hover': {
                                    backgroundColor: theme.palette.shopify.primary,
                                },
                            }}>
                            Confirm Import
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
}

ShopifyImportModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    shops: PropTypes.array,
    businessId: PropTypes.string,
};
