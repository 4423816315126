import { useTranslation } from 'react-i18next';
import { Form, useLoaderData, useFetcher } from 'react-router-dom';
import PhoneInput from '../../../components/PhoneInput/PhoneInput.jsx';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import UserProfileHeader from '../../../components/Profiles/UserProfileDetails/UserProfileHeader.jsx';
import { UserProfileInputTypes } from '../../../constants/UserProfileInputTypes.jsx';
import { matchIsValidTel } from 'mui-tel-input';
import { Alert, Snackbar, Typography } from '@mui/material';
import _ from 'lodash';
import * as api from '../../../api.js';
import ConnectionsDrawer from '../../retail/connections/products/ConnectionsDrawer.jsx';
import AccountMenu from '../../../components/AccountMenu/AccountMenu.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormInputLabel from '../../../components/FormInputLabel/FormInputLabel.jsx';
import LoadingButton from '../../../components/LoadingButton/LoadingButton.jsx';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import * as userProvider from '../../../providers/userProvider.js';
import * as businessProvider from '../../../providers/businessProvider.js';
import { useError } from '../../../components/Error/ErrorProvider.jsx';

export async function loader({ request }) {
    const { signal } = request;
    const rootUser = await userProvider.getUser({ signal });
    const [user, business] = await Promise.all([
        api.getUserById({ id: rootUser.user.id, signal }),
        businessProvider.getBusiness({ signal }),
    ]);

    return {
        business,
        user,
    };
}

export async function action({ request }) {
    const formData = await request.formData();
    const user = JSON.parse(formData.get('user'));
    try {
        const response = await api.updateUser(user, {});
        userProvider.clearCache();
        businessProvider.clearCache();
        return { success: true, response };
    } catch (error) {
        return { success: false, error };
    }
}

export default function UserProfile() {
    const { t } = useTranslation();
    const { user: dataUser, business } = useLoaderData();
    const [initialUser, setInitialUser] = useState(dataUser);
    const [user, setUser] = useState(initialUser);
    const fetcher = useFetcher();
    const { handleError } = useError();
    const [isFormValid, setIsFormValid] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const responsiveStyle = {
        sx: {
            alignItems: {
                xs: 'flex-start',
                sm: 'center',
            },
            justifyContent: {
                xs: 'flex-start',
                sm: 'center',
            },
            width: '100%',
        },
    };

    const [requiredFieldErrors, setRequiredFieldErrors] = useState({
        firstName: false,
        lastName: false,
        position: false,
        phone: false,
        secondaryPhone: false,
    });

    const hasUserChanged = () => JSON.stringify(initialUser) !== JSON.stringify(user);

    const validateForm = () => {
        const invalidFirstName = user.firstName === '';
        const invalidLastName = user.lastName === '';
        const invalidPosition = user.position === '';
        const phone = user.contacts.find((item) => item.type === 'phone')?.number ?? '';
        const invalidPhoneNumber = !_.isEmpty(phone) && !matchIsValidTel(phone);
        const secondaryPhoneNumber = user.contacts.find((item) => item.type === 'mobile')?.number ?? '';
        const invalidSecondaryPhoneNumber = !_.isEmpty(secondaryPhoneNumber) && !matchIsValidTel(secondaryPhoneNumber);

        setRequiredFieldErrors({
            firstName: user.firstName !== '' && invalidFirstName,
            lastName: user.lastName !== '' && invalidLastName,
            position: user.position !== '' && invalidPosition,
            phone: phone !== '' && invalidPhoneNumber,
            secondaryPhone: secondaryPhoneNumber !== '' && invalidSecondaryPhoneNumber,
        });

        return !invalidFirstName && !invalidLastName && !invalidPhoneNumber && !invalidSecondaryPhoneNumber;
    };

    const isSaveButtonEnabled = () => hasUserChanged() && isFormValid;

    const updatePhoneNumbers = (phoneNumber, numberType) => {
        const newPhoneNumber = {
            type: numberType,
            number: phoneNumber,
        };
        let updatedContacts = [];
        const existingIndex = user.contacts.findIndex((phoneNumber) => phoneNumber.type === numberType);
        if (newPhoneNumber.number === '' && existingIndex !== -1) {
            updatedContacts = user.contacts.filter((phoneNumber, index) => index !== existingIndex);
        } else {
            updatedContacts =
                existingIndex !== -1
                    ? user.contacts.map((phoneNumber, index) =>
                          index === existingIndex ? newPhoneNumber : phoneNumber
                      )
                    : [...user.contacts, newPhoneNumber];
        }
        setUser({
            ...user,
            contacts: updatedContacts,
        });
    };

    const handleTextFieldChange = (event, textField) => {
        switch (textField) {
            case UserProfileInputTypes.FIRSTNAME:
                setUser({
                    ...user,
                    firstName: event.target.value,
                });
                break;
            case UserProfileInputTypes.LASTNAME:
                setUser({
                    ...user,
                    lastName: event.target.value,
                });
                break;
            case UserProfileInputTypes.POSITION:
                setUser({
                    ...user,
                    position: event.target.value,
                });
                break;
            case UserProfileInputTypes.EMAIL:
                setUser({
                    ...user,
                    username: event.target.value,
                });
                break;
            case UserProfileInputTypes.PHONE:
                updatePhoneNumbers(_.trim(event), 'phone');
                break;
            case UserProfileInputTypes.SECONDARYPHONE:
                updatePhoneNumbers(_.trim(event), 'mobile');
                break;
            default:
                break;
        }
    };

    const handleSave = (e) => {
        e.preventDefault();
        setIsLoading(true);
        fetcher.submit(
            {
                user: JSON.stringify(user),
            },
            { method: 'post' }
        );
    };
    const handleImageChange = (image) => {
        fetcher.submit(
            {
                user: JSON.stringify({ ...user, profileImageUrl: image }),
            },
            { method: 'post' }
        );
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [user]);

    useEffect(() => {
        if (fetcher.state === 'idle') {
            setIsLoading(false);
            if (fetcher.data?.success) {
                setShowSuccess(true);
                setInitialUser(user);
            } else if (fetcher.data?.error) {
                handleError(fetcher.data.error);
            }
        }
    }, [fetcher]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                position: 'relative',
            }}>
            {business.type === BusinessTypes.RETAIL ? (
                <>
                    <ConnectionsDrawer activeBusiness={business} />
                    <Box sx={{ position: 'absolute', top: 0, right: 0, p: 3 }}>
                        <AccountMenu />
                    </Box>
                </>
            ) : null}

            <Stack gap={3} sx={{ width: '100%', maxWidth: 600, my: { xs: 0, sm: 5 }, mx: { xs: 0, sm: 5 } }}>
                <Typography variant="displaySm">{t('UserProfile.title')}</Typography>
                <UserProfileHeader
                    user={user}
                    businessName={business.name}
                    isEditable={true}
                    onImageChange={handleImageChange}
                />
                <Card>
                    <CardContent>
                        <Form method="post">
                            <input type="hidden" name="user" value={JSON.stringify(user)} />
                            <Stack gap={2}>
                                <Typography variant="textLg" sx={{ fontWeight: 'bold' }}>
                                    {' '}
                                    Personal Details{' '}
                                </Typography>
                                <Stack gap={1} direction={responsiveDirection} sx={responsiveStyle}>
                                    <Box sx={{ flex: 1 }}>
                                        <FormInputLabel text={t('UserProfile.firstNameInputLabel')} required>
                                            <TextField
                                                required
                                                fullWidth
                                                name="firstname"
                                                value={user.firstName}
                                                placeholder={t('UserProfile.firstNameInputPlaceholder')}
                                                error={requiredFieldErrors.firstName && hasUserChanged()}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, UserProfileInputTypes.FIRSTNAME)
                                                }
                                            />
                                        </FormInputLabel>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <FormInputLabel text={t('UserProfile.lastNameInputLabel')} required>
                                            <TextField
                                                required
                                                fullWidth
                                                name="lastname"
                                                value={user.lastName}
                                                placeholder={t('UserProfile.lastNameInputPlaceholder')}
                                                error={requiredFieldErrors.lastName && hasUserChanged()}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, UserProfileInputTypes.LASTNAME)
                                                }
                                            />
                                        </FormInputLabel>
                                    </Box>
                                </Stack>
                                <Stack gap={1} direction={responsiveDirection} sx={responsiveStyle}>
                                    <Box sx={{ flex: 1 }}>
                                        <FormInputLabel text={t('UserProfile.titleInputLabel')}>
                                            <TextField
                                                fullWidth
                                                name="position"
                                                value={user.position}
                                                placeholder={t('UserProfile.titleInputPlaceholder')}
                                                error={requiredFieldErrors.position && hasUserChanged()}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, UserProfileInputTypes.POSITION)
                                                }
                                            />
                                        </FormInputLabel>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <FormInputLabel text={t('UserProfile.emailInputLabel')} required>
                                            <TextField
                                                fullWidth
                                                disabled={true}
                                                name="email"
                                                value={user.username}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, UserProfileInputTypes.EMAIL)
                                                }
                                            />
                                        </FormInputLabel>
                                    </Box>
                                </Stack>
                                <Stack gap={2} direction={responsiveDirection} sx={{ ...responsiveStyle }}>
                                    <Box sx={{ flex: 1 }}>
                                        <FormInputLabel text={t('UserProfile.phoneInputLabel')}>
                                            <PhoneInput
                                                name="phone"
                                                value={
                                                    user.contacts.find((item) => item.type === 'phone')?.number ?? ''
                                                }
                                                error={requiredFieldErrors.phone && hasUserChanged()}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, UserProfileInputTypes.PHONE)
                                                }
                                                fullWidth
                                            />
                                        </FormInputLabel>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <FormInputLabel text={t('UserProfile.secondaryPhoneInputLabel')}>
                                            <PhoneInput
                                                name="secondaryPhone"
                                                value={
                                                    user.contacts.find((item) => item.type === 'mobile')?.number ?? ''
                                                }
                                                error={requiredFieldErrors.secondaryPhone && hasUserChanged()}
                                                onChange={(event) =>
                                                    handleTextFieldChange(event, UserProfileInputTypes.SECONDARYPHONE)
                                                }
                                                fullWidth
                                            />
                                        </FormInputLabel>
                                    </Box>
                                </Stack>
                                <LoadingButton
                                    onClick={handleSave}
                                    isLoading={isLoading}
                                    disabled={!isSaveButtonEnabled()}
                                    variant="outlined"
                                    color="secondary">
                                    {t('UserProfile.saveButton')}
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </CardContent>
                </Card>
            </Stack>
            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {t('UserProfile.successMessage')}
                </Alert>
            </Snackbar>
        </Box>
    );
}
