import { useFetcher } from 'react-router-dom';

export function useSignOut() {
    const fetcher = useFetcher();

    const signOut = async () => {
        await fetcher.submit({}, { method: 'post', action: '/logout' });
    };

    return signOut;
}
