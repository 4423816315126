import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Fog, Midnight, Teal, Tide } from '../../theme.js';
import BusinessLogo from '../BusinessLogo.jsx';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import OrderStatusChip from '../OrderStatusChip/OrderStatusChip.jsx';

export default function OrderListItem({ order }) {
    return (
        <ListItem>
            <Stack gap={1} sx={{ width: '100%' }}>
                <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                    <Box
                        sx={{
                            overflow: 'hidden',
                            borderRadius: '8px',
                            border: '1px solid',
                            borderColor: Fog,
                        }}>
                        <BusinessLogo business={order.dealer} sx={{ width: 48, height: 48 }} />
                    </Box>
                    <Stack
                        direction="row"
                        sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Stack>
                            <Typography variant="textMd" sx={{ color: Midnight }}>
                                {order.dealer.name}
                            </Typography>
                            <Typography variant="textMd" sx={{ color: Teal }}>
                                {order.dealer.addresses[0].city}
                            </Typography>
                        </Stack>
                        <OrderStatusChip order={order} />
                    </Stack>
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        {order.orderNumber}
                    </Typography>
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        {order.createdAt}
                    </Typography>
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        QTY {order.quantity}
                    </Typography>
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        <NumericFormat
                            prefix="$"
                            displayType="text"
                            value={order.total}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>
                </Stack>
            </Stack>
        </ListItem>
    );
}

OrderListItem.propTypes = {
    order: PropTypes.object.isRequired,
};
