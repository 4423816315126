import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

export default function NoConnectionsFound({ businessType }) {
    const { t } = useTranslation();

    // Define links based on the business type
    let linkTo = '';
    if (businessType === 'Brand') {
        linkTo = '/brand/settings/invite';
    } else if (businessType === 'Retail') {
        linkTo = '/retail/settings/invite';
    } else {
        Sentry.captureException(new Error('Invalid business type'));
    }

    return (
        <Box sx={{ mx: 5, my: 4, textAlign: 'center' }}>
            <Typography variant="subtitle1">{t('Connections.emptyStateLabel')}</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
                {t('Connections.pleaseClickLabel')}{' '}
                <Link to={linkTo} color="primary">
                    {t('Connections.hereLabel')}
                </Link>{' '}
                {t('Connections.toConnectLabel')}
            </Typography>
        </Box>
    );
}

NoConnectionsFound.propTypes = {
    businessType: PropTypes.string.isRequired,
};
