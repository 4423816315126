import React, { useState } from 'react';
import { Box, Button, List, ListItem, TextField, Typography, Alert, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import Stack from '@mui/material/Stack';
import FormInputLabel from '../../../../components/FormInputLabel/FormInputLabel.jsx';
import PencilSimpleIcon from '../../../../icons/PencilSimpleIcon.jsx';
import { Tide } from '../../../../theme.js';
import ItemEditMenu from '../../../../components/ItemEditMenu/ItemEditMenu.jsx';
import ShippedOnLabel from '../../../../components/ShippedOnLabel/ShippedOnLabel.jsx';
import { useTranslation } from 'react-i18next';

export default function TrackingNumbers({ initialTrackingNumbers, onTrackingNumbersUpdate, shippedOnDate }) {
    const [trackingNumbers, setTrackingNumbers] = useState(initialTrackingNumbers || []);
    const [newTrackingNumber, setNewTrackingNumber] = useState('');
    const [error, setError] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [numberToEdit, setNumberToEdit] = useState(null);
    const { t } = useTranslation();
    const open = Boolean(anchorEl);

    const handleAddTrackingNumber = () => {
        if (trackingNumbers.includes(newTrackingNumber)) {
            const errorMessage = 'This tracking number has already been added.';
            setError(errorMessage);
            return;
        }
        setError('');
        try {
            const updatedTrackingNumbers = [...trackingNumbers, newTrackingNumber];
            setTrackingNumbers(updatedTrackingNumbers);
            setNewTrackingNumber('');
            onTrackingNumbersUpdate(updatedTrackingNumbers);
        } catch (error) {
            Sentry.captureException(error);
            setError('An error occurred while adding the tracking number.');
        }
    };

    const handlePopoverOpen = (event, number) => {
        setNumberToEdit(number);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setNumberToEdit(null);
    };

    const handleNumberEdit = () => {
        const updatedNumbers = trackingNumbers.filter((number) => number !== numberToEdit);
        setTrackingNumbers(updatedNumbers);
        setNewTrackingNumber(numberToEdit);
        setAnchorEl(null);
    };

    const handleDeleteTrackingNumber = () => {
        setAnchorEl(null);
        try {
            const updatedNumbers = trackingNumbers.filter((number) => number !== numberToEdit);
            setTrackingNumbers(updatedNumbers);
            onTrackingNumbersUpdate(updatedNumbers);
        } catch (error) {
            Sentry.captureException(error);
            setError('An error occurred while removing the tracking number.');
        }
    };

    return (
        <Box>
            <FormInputLabel text={t('TrackingNumbers.title')} sx={{ fontSize: 10 }}>
                <Stack gap={1} sx={{ width: '100%' }} direction="row" alignItems="center">
                    <TextField
                        placeholder="Add Tracking Number"
                        value={newTrackingNumber}
                        onChange={(e) => setNewTrackingNumber(e.target.value)}
                        size="small"
                        error={!!error}
                        sx={{ width: 275 }}
                    />
                    <Button
                        sx={{ width: 120, height: 40, fontSize: 14 }}
                        variant="outlined"
                        color="secondary"
                        onClick={handleAddTrackingNumber}
                        disabled={!newTrackingNumber}>
                        Add
                    </Button>
                </Stack>
            </FormInputLabel>
            {error && <Alert severity="error">{error}</Alert>}
            <List>
                {shippedOnDate ? <ShippedOnLabel date={shippedOnDate} /> : null}
                {trackingNumbers.map((number, index) => (
                    <ListItem
                        sx={{ width: '100%', display: 'flex', paddingBottom: '4px', paddingTop: '4px', paddingX: 0 }}
                        key={number}>
                        <Stack gap={1} sx={{ display: 'flex', width: '100%' }} direction="row" alignItems="center">
                            <Typography
                                align="left"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textDecoration: 'underline',
                                    color: Tide,
                                }}>
                                #{number}
                            </Typography>
                            <IconButton sx={{ padding: 0 }} onClick={(event) => handlePopoverOpen(event, number)}>
                                <PencilSimpleIcon
                                    sx={{
                                        width: 12.5,
                                        height: 12.5,
                                    }}
                                />
                            </IconButton>
                        </Stack>
                    </ListItem>
                ))}
            </List>
            <ItemEditMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                onDelete={handleDeleteTrackingNumber}
                onEdit={handleNumberEdit}
            />
        </Box>
    );
}

TrackingNumbers.propTypes = {
    initialTrackingNumbers: PropTypes.arrayOf(PropTypes.string),
    onTrackingNumbersUpdate: PropTypes.func.isRequired,
    shippedOnDate: PropTypes.string,
};
