import React from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { DarkTide, Straw, White } from '../../theme.js';
import Box from '@mui/material/Box';

export default function LandingIconCard({ icon: Icon, title, description, sx }) {
    return (
        <Card sx={{ backgroundColor: DarkTide, ...sx, position: 'relative' }}>
            <Stack gap={2} sx={{ alignItems: 'center', mx: 2.5, mt: 5, mb: 2 }}>
                <Box sx={{ position: 'relative', zIndex: 2 }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            backgroundColor: White,
                            opacity: 0.12,
                            top: '-10px',
                            left: '10px',
                            zIndex: 1,
                        }}
                    />
                    <Icon sx={{ width: 47.68, height: 47.68, color: Straw }} />
                </Box>
                <Typography variant="displayXs" sx={{ color: White, zIndex: 2, textAlign: 'center' }}>
                    {title}
                </Typography>
                <Typography variant="textMd" sx={{ color: White, lineHeight: '26px', zIndex: 2, textAlign: 'center' }}>
                    {description}
                </Typography>
            </Stack>
        </Card>
    );
}

LandingIconCard.propTypes = {
    icon: PropTypes.elementType.isRequired,
    title: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    sx: PropTypes.any,
};
