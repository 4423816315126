import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import AccountMenu from '../../../../components/AccountMenu/AccountMenu';
import BusinessLogo from '../../../../components/BusinessLogo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Midnight } from '../../../../theme';
import { getPriceRangeString } from '../../../brand/Products/ProductVariants/ProductVariantTable/productUtil.js';

export default function ProductHeader(props) {
    const { t } = useTranslation();
    const flags = useFlags();
    const isBulkVariantPickerEnabled = flags?.bulkVariantPicker;

    return (
        <Stack gap={3} sx={{ flexDirection: 'row', flexWrap: { xs: 'wrap-reverse', md: 'nowrap' } }}>
            <Stack direction="row" gap={3} sx={{ width: { xs: '100%', sm: 'initial' } }}>
                <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                    <BusinessLogo
                        business={props?.business}
                        sx={{ height: 80, width: 80 }}
                        CardMediaProps={{
                            sx: {
                                borderRadius: 4,
                                boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                                objectFit: 'cover',
                            },
                        }}
                    />
                </Box>
                <Box sx={isBulkVariantPickerEnabled ? undefined : { display: { xs: 'block', md: 'none' } }}>
                    <Typography
                        variant="textXl"
                        sx={{
                            color: Midnight,
                            fontWeight: 700,
                            lineHeight: 'normal',
                        }}>
                        {props?.product?.title}
                    </Typography>
                    <Stack direction="row" gap={2} alignItems="flex-end">
                        <Typography
                            sx={{ fontSize: '32px', color: 'common.Tide', fontWeight: 600, lineHeight: 'normal' }}>
                            {getPriceRangeString(props.product)}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                color: 'common.Teal',
                                fontWeight: 600,
                                lineHeight: 'normal',
                                pb: '6px',
                            }}>
                            {t('ProductHeader.msrpLabel')}{' '}
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={props?.product?.msrp}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <Box>
                    <AccountMenu />
                </Box>
            </Box>
        </Stack>
    );
}

ProductHeader.propTypes = {
    business: PropTypes.any.isRequired,
    product: PropTypes.any.isRequired,
};
