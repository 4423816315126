import { useEffect } from 'react';

export default function HubSpotAnalytic() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hs-scripts.com/23975078.js';
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = 'hs-script-loader';

        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
}
