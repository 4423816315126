import { Box } from '@mui/material';
import * as React from 'react';
import { Outlet, useLoaderData, useMatches, useNavigate, useParams } from 'react-router-dom';
import * as api from '../../../../api';
import { CartProvider } from '../../../../cart/CartContext';
import ConnectionsDrawer from './ConnectionsDrawer';
import PropTypes from 'prop-types';

export async function loader({ params, request }) {
    const business = await api.getBusiness(params?.id, { signal: request.signal });
    return {
        business,
    };
}

export default function BusinessDetailsLayout(props) {
    const data = useLoaderData();
    const matches = useMatches();
    const navigate = useNavigate();
    const params = useParams();

    // temporary redirect until there is a landing page for business
    React.useEffect(() => {
        const currentRoute = matches[matches.length - 1]?.id;
        // TODO enum for routenames
        if (currentRoute === 'BusinessLandingPage') {
            navigate(`/retail/connections/${params?.id}/products`, { replace: true });
        }
    }, [matches]);

    return (
        <Box className="BusinessDetailsLayout" {...props}>
            <Box sx={{ display: 'flex' }}>
                <ConnectionsDrawer activeBusiness={data?.business} />
                <CartProvider storageKey={`cart-${params?.id}`}>
                    <Box component="main" sx={{ flexGrow: 1 }}>
                        <Outlet />
                    </Box>
                </CartProvider>
            </Box>
        </Box>
    );
}

BusinessDetailsLayout.propTypes = {
    'data-testid': PropTypes.string,
};
