import MailIcon from '@mui/icons-material/MailOutline';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React from 'react';

export default function Buttons() {
    return (
        <Box>
            <Typography variant="h4">Primary (Contained)</Typography>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button color="primary" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button color="primary" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button color="primary" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled color="primary" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button disabled color="primary" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button disabled color="primary" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button startIcon={<MailIcon />} color="primary" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="primary" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="primary" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled startIcon={<MailIcon />} color="primary" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="primary" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="primary" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button endIcon={<MailIcon />} color="primary" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="primary" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="primary" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled endIcon={<MailIcon />} color="primary" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="primary" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="primary" variant="contained">
                    Button CTA
                </Button>
            </Stack>

            <Divider />

            <Typography variant="h4">Secondary (Outlined) </Typography>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button color="secondary" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button color="secondary" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button color="secondary" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled color="secondary" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button disabled color="secondary" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button disabled color="secondary" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button startIcon={<MailIcon />} color="secondary" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="secondary" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="secondary" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled startIcon={<MailIcon />} color="secondary" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="secondary" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="secondary" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button endIcon={<MailIcon />} color="secondary" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="secondary" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="secondary" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled endIcon={<MailIcon />} color="secondary" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="secondary" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="secondary" variant="outlined">
                    Button CTA
                </Button>
            </Stack>

            <Divider />

            <Typography variant="h4">White (Contained)</Typography>
            <Box sx={{ bgcolor: 'common.Midnight' }}>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button color="white" variant="contained" size="xs">
                        Button CTA
                    </Button>
                    <Button color="white" variant="contained" size="small">
                        Button CTA
                    </Button>
                    <Button color="white" variant="contained">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button disabled color="white" variant="contained" size="xs">
                        Button CTA
                    </Button>
                    <Button disabled color="white" variant="contained" size="small">
                        Button CTA
                    </Button>
                    <Button disabled color="white" variant="contained">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button startIcon={<MailIcon />} color="white" variant="contained" size="xs">
                        Button CTA
                    </Button>
                    <Button startIcon={<MailIcon />} color="white" variant="contained" size="small">
                        Button CTA
                    </Button>
                    <Button startIcon={<MailIcon />} color="white" variant="contained">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button disabled startIcon={<MailIcon />} color="white" variant="contained" size="xs">
                        Button CTA
                    </Button>
                    <Button disabled startIcon={<MailIcon />} color="white" variant="contained" size="small">
                        Button CTA
                    </Button>
                    <Button disabled startIcon={<MailIcon />} color="white" variant="contained">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button endIcon={<MailIcon />} color="white" variant="contained" size="xs">
                        Button CTA
                    </Button>
                    <Button endIcon={<MailIcon />} color="white" variant="contained" size="small">
                        Button CTA
                    </Button>
                    <Button endIcon={<MailIcon />} color="white" variant="contained">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button disabled endIcon={<MailIcon />} color="white" variant="contained" size="xs">
                        Button CTA
                    </Button>
                    <Button disabled endIcon={<MailIcon />} color="white" variant="contained" size="small">
                        Button CTA
                    </Button>
                    <Button disabled endIcon={<MailIcon />} color="white" variant="contained">
                        Button CTA
                    </Button>
                </Stack>
            </Box>

            <Divider />
            <Typography variant="h4">White (Outlined)</Typography>
            <Box sx={{ bgcolor: 'common.Midnight' }}>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button color="white" variant="outlined" size="xs">
                        Button CTA
                    </Button>
                    <Button color="white" variant="outlined" size="small">
                        Button CTA
                    </Button>
                    <Button color="white" variant="outlined">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button disabled color="white" variant="outlined" size="xs">
                        Button CTA
                    </Button>
                    <Button disabled color="white" variant="outlined" size="small">
                        Button CTA
                    </Button>
                    <Button disabled color="white" variant="outlined">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button startIcon={<MailIcon />} color="white" variant="outlined" size="xs">
                        Button CTA
                    </Button>
                    <Button startIcon={<MailIcon />} color="white" variant="outlined" size="small">
                        Button CTA
                    </Button>
                    <Button startIcon={<MailIcon />} color="white" variant="outlined">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button disabled startIcon={<MailIcon />} color="white" variant="outlined" size="xs">
                        Button CTA
                    </Button>
                    <Button disabled startIcon={<MailIcon />} color="white" variant="outlined" size="small">
                        Button CTA
                    </Button>
                    <Button disabled startIcon={<MailIcon />} color="white" variant="outlined">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button endIcon={<MailIcon />} color="white" variant="outlined" size="xs">
                        Button CTA
                    </Button>
                    <Button endIcon={<MailIcon />} color="white" variant="outlined" size="small">
                        Button CTA
                    </Button>
                    <Button endIcon={<MailIcon />} color="white" variant="outlined">
                        Button CTA
                    </Button>
                </Stack>
                <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                    <Button disabled endIcon={<MailIcon />} color="white" variant="outlined" size="xs">
                        Button CTA
                    </Button>
                    <Button disabled endIcon={<MailIcon />} color="white" variant="outlined" size="small">
                        Button CTA
                    </Button>
                    <Button disabled endIcon={<MailIcon />} color="white" variant="outlined">
                        Button CTA
                    </Button>
                </Stack>
            </Box>

            <Divider />

            <Typography variant="h4">Destructive (Contained)</Typography>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button color="destructive" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button color="destructive" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button color="destructive" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled color="destructive" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button disabled color="destructive" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button disabled color="destructive" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button startIcon={<MailIcon />} color="destructive" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="destructive" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="destructive" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled startIcon={<MailIcon />} color="destructive" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="destructive" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="destructive" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button endIcon={<MailIcon />} color="destructive" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="destructive" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="destructive" variant="contained">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled endIcon={<MailIcon />} color="destructive" variant="contained" size="xs">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="destructive" variant="contained" size="small">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="destructive" variant="contained">
                    Button CTA
                </Button>
            </Stack>

            <Divider />

            <Typography variant="h4">Destructive (Outlined)</Typography>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button color="destructive" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button color="destructive" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button color="destructive" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled color="destructive" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button disabled color="destructive" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button disabled color="destructive" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button startIcon={<MailIcon />} color="destructive" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="destructive" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button startIcon={<MailIcon />} color="destructive" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled startIcon={<MailIcon />} color="destructive" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="destructive" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button disabled startIcon={<MailIcon />} color="destructive" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button endIcon={<MailIcon />} color="destructive" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="destructive" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button endIcon={<MailIcon />} color="destructive" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
            <Stack gap={1} flexDirection="row" justifyContent="flex-start" alignItems="center" sx={{ p: 1 }}>
                <Button disabled endIcon={<MailIcon />} color="destructive" variant="outlined" size="xs">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="destructive" variant="outlined" size="small">
                    Button CTA
                </Button>
                <Button disabled endIcon={<MailIcon />} color="destructive" variant="outlined">
                    Button CTA
                </Button>
            </Stack>
        </Box>
    );
}
