import * as Sentry from '@sentry/react';
import * as authProvider from './auth';
import * as config from './config/config';

const { THATCH_API_URL } = config.ENVIRONMENT_VARIABLES_ENUMS;

/**
 * This is a util that wraps fetch to provide the Authorization header to requests
 */

/**
 * del - calls fetch with http DELETE method
 * @param {string} url
 * @param {Object} body payload to send in the request
 * @param {*} options fetch options
 * @returns
 */
export const del = async (url, body, options = {}) => {
    // Base URL and headers setup
    const apiUrl = config.get(THATCH_API_URL);
    const headers = {
        Authorization: await authProvider.getAuthToken(),
        'Content-Type': 'application/json',
    };

    // Final fetch call using adjusted configuration
    return fetch(`${apiUrl}${url}`, {
        method: 'DELETE',
        body: JSON.stringify(body),
        headers,
        ...options,
    });
};
/**
 * get - calls fetch with http GET method
 * @param {string} url
 * @param {*} options fetch options
 * @returns
 */
export const get = async (url, options = {}) => {
    const apiUrl = config.get(THATCH_API_URL);
    const headers = {
        Authorization: await authProvider.getAuthToken(),
        'Content-Type': 'application/json',
    };

    try {
        // Final fetch call using adjusted configuration
        const response = await fetch(`${apiUrl}${url}`, {
            headers,
            ...options,
        });

        // Check if the response status is not successful
        if (!response.ok) {
            // Log error to Sentry
            Sentry.captureException(new Error(`API Request Failed: ${response.status} ${response.statusText}`));
        }
        return response;
    } catch (error) {
        // Log error to Sentry
        Sentry.captureException(error);
        throw error;
    }
};

/**
 * Perform an HTTP POST request.
 *
 * This function can handle both JSON and FormData bodies. When provided with FormData,
 * the Content-Type header is set automatically by the browser to 'multipart/form-data'
 * with the proper boundary. When given a JSON body, it sets the Content-Type to 'application/json'
 * and stringifies the body. It optionally accepts a base URL to override the default API URL.
 *
 * @param {string} url The endpoint to which the POST request should be sent.
 * @param {Object|FormData} body The payload to be sent. Can be a JSON object or FormData.
 * @param {Object} [options={}] Optional fetch options.
 * @param {string} [baseUrl=null] Optional base URL to override the default API URL.
 * @returns {Promise<Response>} A Promise that resolves to the response of the fetch request.
 */
export const post = async (url, body, options = {}, baseUrl = null) => {
    const apiUrl = baseUrl || config.get(THATCH_API_URL); // Use the provided base URL if it's specified
    const headers = {
        Authorization: await authProvider.getAuthToken(),
    };

    // If we're sending FormData, we'll let the browser set the Content-Type header
    if (!(body instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(body);
    }

    // Final fetch call using adjusted configuration
    return fetch(`${apiUrl}${url}`, {
        method: 'POST',
        body,
        headers,
        ...options,
    });
};

/**
 * put - calls fetch with http PUT method
 * @param {string} url
 * @param {Object} body payload to send in the request
 * @param {*} options fetch options
 * @returns
 */
export const put = async (url, body, options = {}) => {
    // Base URL and headers setup
    const apiUrl = config.get(THATCH_API_URL);
    const headers = {
        Authorization: await authProvider.getAuthToken(),
        'Content-Type': 'application/json',
    };

    // Final fetch call using adjusted configuration
    return fetch(`${apiUrl}${url}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers,
        ...options,
    });
};

/**
 * patch - calls fetch with HTTP PATCH method
 * @param {string} url - The endpoint URL
 * @param {Object} body - Payload to send in the request
 * @param {*} options - Fetch options
 * @returns {Promise<Response>} - The fetch response
 */
export const patch = async (url, body, options = {}) => {
    // Base URL and headers setup
    const apiUrl = config.get(THATCH_API_URL);
    const headers = {
        Authorization: await authProvider.getAuthToken(),
        'Content-Type': 'application/json',
    };

    // Final fetch call using adjusted configuration
    return fetch(`${apiUrl}${url}`, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers,
        ...options,
    });
};

/**
 *
 * @param {Response} response
 * @returns {Promise<Object>}
 */
export async function handleFetchResponse(response) {
    const { status } = response;

    // Handle HTTP errors
    if (status >= 400) {
        let message = '';
        try {
            const body = await response.json();
            const result = {
                url: response.url,
                ...body,
            };
            message = JSON.stringify(result);
        } catch (error) {
            // ignore parsing error
        }
        throw new Response(message, { status });
    }

    return response.json();
}
