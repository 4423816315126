import React from 'react';
import PropTypes from 'prop-types';
import RetailSettingsGearIconDropdown from './retail/RetailSettingsGearIconDropdown.jsx';
import BrandSettingsGearIconDropdown from './brand/BrandSettingsGearIconDropdown.jsx';

function SettingsGearIconDropdown({ type, id, open, anchorEl, onClose }) {
    if (type === 'Retail') {
        return <RetailSettingsGearIconDropdown id={id} open={open} anchorEl={anchorEl} onClose={onClose} />;
    } else if (type === 'Brand') {
        return <BrandSettingsGearIconDropdown id={id} open={open} anchorEl={anchorEl} onClose={onClose} />;
    } else {
        console.error(
            'No type provided to SettingsGearIconDropdown component. Its likely that a parent component rendered this component without providing a type prop.'
        );
        return null;
    }
}

SettingsGearIconDropdown.propTypes = {
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    open: PropTypes.bool,
    anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element)]),
    onClose: PropTypes.func,
};

export default SettingsGearIconDropdown;
