import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

/**
 * A layout that overlays as a bottom drawer on mobile and a side column on desktop
 * @param {React.ReactNode} children
 * @returns {React.ReactNode}
 */
export default function ColumnOverlayLayout({ children }) {
    return (
        <Box
            sx={{
                display: { xs: 'block', md: 'flex' },
                position: { xs: 'fixed', md: 'relative' },
                bottom: { xs: 0, md: 'unset' },
                left: { xs: 0, sm: 112, md: 'unset' },
                right: { xs: 0, sm: 32, md: 'unset' },
                flexDirection: { md: 'column' },
                gap: { md: 3 },
                flexShrink: { md: 0 },
                width: { md: 318 },
            }}>
            {children}
        </Box>
    );
}

ColumnOverlayLayout.propTypes = {
    children: PropTypes.node,
};
