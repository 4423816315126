import ThatchAvatar from './ThatchAvatar.jsx';
import { IconButton, CircularProgress } from '@mui/material';
import { BorderRadius, Midnight, White } from '../../theme.js';
import CameraIcon from '../../icons/CameraIcon.jsx';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { uploadImage } from '../../api.js';
import { useError } from '../Error/ErrorProvider.jsx';
import ItemEditMenu from '../ItemEditMenu/ItemEditMenu.jsx';

export default function EditableThatchAvatar({ src, sx, alt, variant, onImageChange, ...props }) {
    const [imageSrc, setImageSrc] = useState(src);
    const [isLoading, setIsLoading] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const { handleError } = useError();

    const onButtonClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleEdit = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.value = '';
        input.onchange = handleImageChange;
        input.style.opacity = '0';

        // Append input to the DOM (necessary for Safari on iOS)
        document.body.appendChild(input);

        // Use addEventListener for onchange event (necessary for Safari on iOS)
        input.addEventListener('change', (event) => {
            if (event.target.files && event.target.files[0]) {
                handleImageChange(event);
            }
            document.body.removeChild(input);
        });
        input.click();
    };

    const handleDelete = () => {
        setImageSrc(null);
        onImageChange(null);
    };
    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        setIsLoading(true);
        try {
            const response = await uploadImage(file);
            const url = response.url;
            setImageSrc(url);
            onImageChange(url);
        } catch (error) {
            handleError('An error occurred changing your image. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
            }}>
            <ThatchAvatar src={imageSrc} sx={sx} alt={alt} variant={variant} {...props} />
            <IconButton
                onClick={onButtonClick}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    backgroundColor: Midnight,
                    zIndex: 2,
                    '&:hover': {
                        backgroundColor: Midnight,
                    },
                }}>
                <CameraIcon sx={{ color: White }} />
            </IconButton>
            <ItemEditMenu
                open={Boolean(menuAnchorEl)}
                anchorEl={menuAnchorEl}
                onClose={handleMenuClose}
                onEdit={() => {
                    handleEdit();
                    handleMenuClose();
                }}
                onDelete={() => {
                    handleDelete();
                    handleMenuClose();
                }}
            />
            {isLoading && (
                <Box
                    sx={{
                        borderRadius: variant === 'user' ? '50%' : BorderRadius,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: Midnight,
                        opacity: '50%',
                        zIndex: 3,
                    }}>
                    <CircularProgress sx={{ color: White }} />
                </Box>
            )}
        </Box>
    );
}

EditableThatchAvatar.propTypes = {
    variant: PropTypes.oneOf(['business', 'user']),
    src: PropTypes.string,
    alt: PropTypes.string,
    sx: PropTypes.object,
    onImageChange: PropTypes.func,
};
