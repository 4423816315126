/**
 * Sanitizes the file name to be URL and filesystem friendly.
 * @param {string} filename - The original file name
 * @returns {string} - The sanitized file name
 */
export default function sanitizeFileName(filename) {
    return (
        filename
            // Replace spaces with underscores
            .replace(/\s+/g, '_')
            // Remove all characters that are not alphanumeric, dashes, underscores, or periods
            .replace(/[^a-zA-Z0-9._-]/g, '')
    );
}
