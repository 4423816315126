import _ from 'lodash';

export const productShape = {
    id: '',
    title: '',
    gender: '',
    description: '',
    sku: '',
    mpn: '',
    upc: '',
    businessId: '',
    year: null,
    msrp: null,
    minimumAdvertisePrice: null,
    wholesale: null,
    retailPrice: null,
    active: true,
    productVariants: [],
    imageLinks: [],
    specs: [],
    categories: [],
    productVariantTagOptions: [],
};
export const variantShape = {
    id: '',
    title: '',
    gender: '',
    description: '',
    sku: '',
    mpn: '',
    upc: '',
    businessId: '',
    year: null,
    msrp: null,
    minimumAdvertisePrice: null,
    wholesale: null,
    retailPrice: null,
    active: true,
    isDefault: false,
    imageLinks: [],
    specs: [],
    categories: [],
    variantTags: [],
};

export function mergeTagNamesToColumns(tagOptions, columns) {
    const tagNameToColumnHeader = tagOptions.map((tag) => {
        return {
            field: tag.name,
            headerName: tag.name,
            type: 'string',
            headerAlign: 'left',
            align: 'left',
        };
    });

    const columnsWithTags = [...tagNameToColumnHeader, ...columns];
    return columnsWithTags;
}

export function addTagValuesToRows(productVariants) {
    const variants = productVariants || [];
    const r = variants.map((variant) => {
        const tags = variant?.variantTags || [];
        tags.forEach((tag) => {
            variant[tag.name] = tag.value;
        });
        return variant;
    });
    return r;
}

export const filterInactiveFromProduct = (product) => {
    const filtered = {
        ...product,
        productVariants: product.productVariants?.filter((variant) => variant.active),
    };
    return filtered;
};

export function filterInactiveAndSetDefault(productVariants, hideInactive) {
    const dex = _.findIndex(productVariants, { isDefault: true, active: true });
    if (dex === -1) {
        productVariants.map((variant) => {
            _.set(variant, 'isDefault', false);

            return variant;
        });

        const someActive = _.findIndex(productVariants, { active: true });
        _.set(productVariants[someActive], 'isDefault', true);
    }

    if (hideInactive) {
        return productVariants.filter((variant) => {
            return variant.active;
        });
    } else {
        return productVariants;
    }
}

export function cleanVariants(productVariants) {
    if (!_.isArray(productVariants)) {
        return [];
    } else {
        return productVariants.map((variant) => {
            const withOutExtras = _.pick(variant, Object.keys(variantShape));
            return withOutExtras;
        });
    }
}

export function revertTagColumnsToTags(productVariants, productVariantTagOptions) {
    if (!productVariantTagOptions) {
        return productVariants.map((variant) => {
            return cleanData(variant);
        });
    }
    const v = productVariants.map((variant) => {
        const variantTags = productVariantTagOptions.reduce((acc, option) => {
            if (!variant[option.name]) {
                return acc;
            }
            const tag = {
                name: option.name,
                value: variant[option.name],
            };
            acc.push(tag);
            delete variant[option.name];
            return acc;
        }, []);

        if (_.isEmpty(variantTags)) {
            const WOE = _.pick(variant, Object.keys(variantShape));
            return cleanData(WOE);
        }

        const withOutExtras = _.pick(variant, Object.keys(variantShape));
        const cleanVariant = cleanData(withOutExtras);

        return { ...cleanVariant, variantTags };
    });
    return v;
}

export function cleanData(data) {
    const noEmpty = _.omitBy(data, (v) => v === '');
    const noNil = _.omitBy(noEmpty, _.isNil);
    const clean = _.omitBy(noNil, (v, i) => {
        return _.startsWith(v, '_') || _.startsWith(i, '_');
    });

    return clean;
}

export function createAllVariantsFromOptions(product) {
    const { productVariants, productVariantTagOptions, ...baseProps } = product;

    //  cartesian product of two arrays
    function cartesianProduct(arr1, arr2) {
        return arr1.flatMap((d) => arr2.map((v) => [...d, v]));
    }

    // generate all combinations of variant tags
    const productVariantsCombos = productVariantTagOptions.reduce((acc, option) => {
        // Map the values of the current option to variant tag objects
        const currentVariants = option.values.map((value) => ({
            name: option.name,
            value,
        }));

        // Initialize the accumulator with the current variants if it's the first iteration
        if (acc.length === 0) {
            return currentVariants.map((variant) => [variant]);
        }

        // Perform the cartesian product of accumulated variants and current variants
        return cartesianProduct(acc, currentVariants);
    }, []);

    // Map each combination of variant tags to the desired structure
    const newVariantsFromTags = productVariantsCombos.map((variantTags) => {
        return {
            ...baseProps, // Spread the base properties into each variant
            id: _.uniqueId('_new'), // Assign a unique ID to each variant
            variantTags,
        };
    });

    // Return the transformed product with generated variants, excluding the original productVariants array
    return {
        ...baseProps, // Include all other base product properties
        productVariants: newVariantsFromTags,
        productVariantTagOptions,
    };
}

export function variantsAllSame(productWAllVariants, prevProduct, tagOptions) {
    const productWithAllVariantsCopyForComparison = _.cloneDeep(productWAllVariants);

    delete productWithAllVariantsCopyForComparison?.productVariantTagOptions;
    delete prevProduct?.productVariantTagOptions;

    const prevVariants = prevProduct?.productVariants || [];
    const propsVariants = productWithAllVariantsCopyForComparison?.productVariants || [];

    prevVariants.map((variant) => {
        delete variant.id;
        return variant;
    });
    propsVariants.map((variant) => {
        delete variant.id;
        return variant;
    });

    const withNoExtraColumns = revertTagColumnsToTags(_.cloneDeep(prevVariants), tagOptions);
    const propsWithNoExtraColumns = revertTagColumnsToTags(_.cloneDeep(propsVariants), tagOptions);
    const r = _.isEqual(propsWithNoExtraColumns, withNoExtraColumns);

    return r;
}

export function mergeOldAndNewVariants(oldProductVariants, newProductVariants, newProduct) {
    const newProductClone = _.cloneDeep(newProduct);
    delete newProductClone.id;
    delete newProductClone.productVariants;
    const oldPVClone = _.cloneDeep(oldProductVariants);
    const newPVClone = _.cloneDeep(newProductVariants);

    const oldPVWithNewOptions = oldPVClone.map((oldPV) => {
        const found = _.find(newPVClone, (variant) => {
            return _.isEqual(variant.variantTags, oldPV.variantTags);
        });
        if (found) {
            oldPV.variantTags = found.variantTags;
            return oldPV;
        }
        return oldPV;
    });

    const newMerged = newPVClone.map((newPv) => {
        const containsOld = _.find(oldPVWithNewOptions, (variant) => {
            return _.isEqual(variant.variantTags, newPv.variantTags);
        });

        if (containsOld) {
            return containsOld;
        } else {
            const newProductWithPV = {
                id: _.uniqueId('_new'),
                ...newProductClone,
                variantTags: newPv.variantTags,
            };
            const withOutExtras = _.pick(newProductWithPV, Object.keys(variantShape));
            return withOutExtras;
        }
    });

    return newMerged;
}

export const excludeKeys = (obj, keysToExclude) => {
    if (_.isArray(obj)) {
        return obj.map((item) => excludeKeys(item, keysToExclude));
    } else if (_.isObject(obj)) {
        return _.reduce(
            obj,
            (result, value, key) => {
                if (!keysToExclude.includes(key)) {
                    result[key] = excludeKeys(value, keysToExclude);
                }
                return result;
            },
            {}
        );
    }
    return obj;
};

export const findDifferences = (obj1, obj2, keysToExclude = []) => {
    const compare = (obj1, obj2, path) => {
        const differences = {};

        for (const key in obj1) {
            if (!_.has(obj2, key)) {
                differences[path.concat(key).join('.')] = { value1: obj1[key], value2: undefined };
            } else if (!areValuesEqual(obj1[key], obj2[key])) {
                if (_.isObject(obj1[key]) && _.isObject(obj2[key]) && !_.isArray(obj1[key]) && !_.isArray(obj2[key])) {
                    Object.assign(differences, compare(obj1[key], obj2[key], path.concat(key)));
                } else {
                    differences[path.concat(key).join('.')] = { value1: obj1[key], value2: obj2[key] };
                }
            }
        }

        for (const key in obj2) {
            if (!_.has(obj1, key)) {
                differences[path.concat(key).join('.')] = { value1: undefined, value2: obj2[key] };
            }
        }

        return differences;
    };

    const areValuesEqual = (value1, value2) => {
        if ((value1 === null && value2 === undefined) || (value1 === undefined && value2 === null)) {
            return true;
        }
        if (_.isNumber(value1) && _.isString(value2)) {
            return value1 === Number(value2);
        }
        if (_.isString(value1) && _.isNumber(value2)) {
            return Number(value1) === value2;
        }
        return _.isEqual(value1, value2);
    };

    const obj1Filtered = excludeKeys(obj1, keysToExclude);
    const obj2Filtered = excludeKeys(obj2, keysToExclude);

    return compare(obj1Filtered, obj2Filtered, []);
};

export const requiredFields = ['title', 'msrp', 'categories'];

export function checkRequiredFieldValues(product, requiredFields) {
    return _.reduce(
        requiredFields,
        (acc, name) => {
            if (
                (_.isArray(product[name]) && _.isEmpty(product[name])) ||
                _.isNil(product[name]) ||
                product[name] === ''
            ) {
                acc[name] = true;
            }
            return acc;
        },
        {}
    );
}

export const createProductsFromShopify = (shopifyProducts, businessId) => {
    return shopifyProducts.map((shopifyProduct) => {
        const product = { ...productShape };
        const price = shopifyProduct.variants?.[0].price ?? 0;
        const description = shopifyProduct.body_html ?? shopifyProduct.variants?.[0].body_html ?? '';
        const sku = shopifyProduct.variants?.[0].sku ?? '';

        product.title = shopifyProduct.title;
        product.description = description;
        product.sku = sku;
        product.mpn = sku; // No difference of MPN and SKU in shopify
        product.upc = shopifyProduct.variants?.[0].barcode ?? '';
        product.businessId = shopifyProduct.admin_graphql_api_id;
        product.active = shopifyProduct.status === 'active';
        product.imageLinks = shopifyProduct.images.map((image) => image.src);
        product.retailPrice = price;
        product.msrp = shopifyProduct.variants?.[0].compare_at_price ?? price;
        product.wholesale = 0;
        product.thirdPartyId = shopifyProduct.id.toString();
        product.categories = ['gid://shopify/TaxonomyCategory/ap'];
        product.businessId = businessId;

        product.productVariants = shopifyProduct.variants.map((variant, index) => {
            const variantObj = { ...variantShape };
            const isAutogenerated = variant.title === 'Default Title'; // variant created automatically without title by Shopify
            variant.title = isAutogenerated ? product.title : variant.title;
            variantObj.isDefault = index === 0;
            variantObj.businessId = businessId.toString();
            variantObj.thirdPartyId = variant.id.toString();
            variantObj.title = variant.title;
            variantObj.sku = variant.sku;
            variantObj.mpn = variant.sku; // No difference of MPN and SKU in shopify
            variantObj.upc = variant.barcode;
            variantObj.msrp = variant.compare_at_price ? parseFloat(variant.compare_at_price) : null;
            variantObj.wholesale = 0;
            variantObj.retailPrice = variant.price ? parseFloat(variant.price) : null;
            variantObj.active = product.active; // not active flag on variant from shopify
            variantObj.categories = product.categories;
            variantObj.imageLinks = variant.image_id
                ? [shopifyProduct.images.find((img) => img.id === variant.image_id)?.src]
                : [];

            if (isAutogenerated) {
                variant.option1 = 'Standard';
            }
            shopifyProduct.options = createOptionsFromShopifyOptions(shopifyProduct.options);
            variantObj.variantTags = mapOptionsToVariant(shopifyProduct.options, variant);
            delete variantObj.id;
            return variantObj;
        });
        delete product.productVariantTagOptions;
        return cleanData(product);
    });
};

function createOptionsFromShopifyOptions(shopifyOptions) {
    // variant options created automatically without title or helpful data by Shopify
    const isOptionsAutogenerated =
        shopifyOptions.length === 1 &&
        shopifyOptions[0].name === 'Title' &&
        shopifyOptions[0].values?.[0] === 'Default Title';
    if (isOptionsAutogenerated) {
        const defaultOption = shopifyOptions[0];
        // create an option of name 'Option' with value 'Standard' for a better UI/UX in our app instead of Shopify's auto generated option.
        return [
            {
                id: defaultOption.id,
                product_id: defaultOption.product_id,
                name: 'Option',
                position: defaultOption.position,
                values: ['Standard'],
            },
        ];
    } else {
        return shopifyOptions;
    }
}

function mapOptionsToVariant(options, variant) {
    return options
        .map((option) => {
            const name = option.name;
            const valueKey = `option${option.position}`;
            const value = variant[valueKey];

            return value ? { name, value } : null;
        })
        .filter((option) => option !== null);
}

export const formattedPriceString = (value) => {
    // Formatter for thousands separators
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const string = formatter.format(value);
    return `$${string}`;
};

export const formatPriceRangeString = (range) => {
    if (range.min === range.max) {
        return `${formattedPriceString(range.min)}`;
    }
    return `${formattedPriceString(range.min)} - ${formattedPriceString(range.max)}`;
};

export const getPriceRange = (product) => {
    if (!product || !product.productVariants || product.productVariants.length === 0) {
        return { min: product.wholesale, max: product.wholesale };
    }

    // Extract all wholesale values from productVariants
    const wholesaleValues = product.productVariants.map((variant) => variant.wholesale);

    // Calculate the minimum and maximum values
    const min = Math.min(...wholesaleValues);
    const max = Math.max(...wholesaleValues);

    return { min, max }; // Return the price range as an object
};

export const getPriceRangeString = (product) => {
    return formatPriceRangeString(getPriceRange(product));
};
