import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function TagIcon(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="ArrowCircleLeft">
                <path
                    d="M13.2069 7.00001L7.00001 0.79313C6.90748 0.699867 6.79735 0.625926 6.67599 0.575604C6.55464 0.525283 6.4245 0.499585 6.29313 0.500005H0.500005C0.367397 0.500005 0.24022 0.552684 0.146452 0.646452C0.0526836 0.74022 5.05934e-06 0.867397 5.05934e-06 1.00001V6.79313C-0.000414649 6.9245 0.025283 7.05464 0.0756045 7.17599C0.125926 7.29735 0.199867 7.40748 0.29313 7.50001L6.50001 13.7069C6.59287 13.7998 6.70312 13.8734 6.82446 13.9237C6.9458 13.974 7.07585 13.9999 7.20719 13.9999C7.33853 13.9999 7.46859 13.974 7.58993 13.9237C7.71127 13.8734 7.82152 13.7998 7.91438 13.7069L13.2069 8.41438C13.2998 8.32152 13.3734 8.21127 13.4237 8.08993C13.474 7.96859 13.4999 7.83853 13.4999 7.70719C13.4999 7.57585 13.474 7.4458 13.4237 7.32446C13.3734 7.20312 13.2998 7.09287 13.2069 7.00001ZM7.20688 13L1.00001 6.79313V1.50001H6.29313L12.5 7.70688L7.20688 13ZM4.00001 3.75001C4.00001 3.89834 3.95602 4.04335 3.87361 4.16668C3.7912 4.29002 3.67406 4.38615 3.53702 4.44291C3.39997 4.49968 3.24917 4.51453 3.10369 4.48559C2.9582 4.45666 2.82456 4.38522 2.71967 4.28034C2.61479 4.17545 2.54335 4.04181 2.51442 3.89632C2.48548 3.75084 2.50033 3.60004 2.5571 3.46299C2.61386 3.32595 2.70999 3.20881 2.83333 3.1264C2.95666 3.04399 3.10167 3.00001 3.25001 3.00001C3.44892 3.00001 3.63968 3.07902 3.78034 3.21967C3.92099 3.36033 4.00001 3.55109 4.00001 3.75001Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}

TagIcon.propTypes = {
    sx: PropTypes.any,
};
