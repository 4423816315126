import React from 'react';
import PropTypes from 'prop-types';
import { PaymentStatuses } from '../../constants/PaymentStatuses.jsx';
import { Chip } from '@mui/material';
import { OrderStatuses } from '../../constants/OrderStatuses.jsx';
import ThumbsUpIcon from '../../icons/ThumbsUpIcon.jsx';
import FileXIcon from '../../icons/FileXIcon.jsx';
import ProhibitIcon from '../../icons/ProhibitIcon.jsx';
import EyeIcon from '../../icons/EyeIcon.jsx';
import TruckIcon from '../../icons/TruckIcon.jsx';
import SealCheckIcon from '../../icons/SealCheckIcon.jsx';
import WarningIcon from '../../icons/WarningIcon.jsx';
import { PaymentTermTypes } from '../../constants/PaymentTermTypes.jsx';
import { useTranslation } from 'react-i18next';
import { addDays, endOfDay } from 'date-fns';

export default function OrderStatusChip({ order }) {
    const { t } = useTranslation();
    // TODO: lastPayment may not be the completed payment
    const lastPayment = [...(order.payments || [])].pop();
    const hasCompletedPayment = lastPayment?.status === PaymentStatuses.COMPLETE;

    let chipColor = {
        [OrderStatuses.APPROVED]: 'approved',
        [OrderStatuses.CANCELLED]: 'cancelled',
        [OrderStatuses.DECLINED]: 'destructive',
        [OrderStatuses.IN_REVIEW]: 'review',
        [OrderStatuses.SHIPPED]: 'shipped',
        [PaymentStatuses.COMPLETE]: 'complete',
        [PaymentStatuses.FAILED]: 'destructive',
    }[hasCompletedPayment || lastPayment?.status === PaymentStatuses.FAILED ? lastPayment?.status : order.status];
    let ChipIcon = {
        [OrderStatuses.APPROVED]: ThumbsUpIcon,
        [OrderStatuses.CANCELLED]: FileXIcon,
        [OrderStatuses.DECLINED]: ProhibitIcon,
        [OrderStatuses.IN_REVIEW]: EyeIcon,
        [OrderStatuses.SHIPPED]: TruckIcon,
        [OrderStatuses.PAID]: SealCheckIcon,
        [PaymentStatuses.COMPLETE]: SealCheckIcon,
        [PaymentStatuses.FAILED]: WarningIcon,
    }[hasCompletedPayment || lastPayment?.status === PaymentStatuses.FAILED ? lastPayment?.status : order.status];

    let label = order.status;
    if (order.status === OrderStatuses.SHIPPED && order.shippedOn) {
        const netDays = order?.paymentTermData?.netDays || 0;
        const dueDate =
            order?.paymentTermData?.type === PaymentTermTypes.NET_DAYS || !order?.paymentTermId
                ? endOfDay(addDays(new Date(order?.shippedOn), netDays))
                : null;
        const today = endOfDay(new Date());
        if (dueDate && dueDate <= today && !hasCompletedPayment) {
            label = t('OrderStatusChip.paymentDueLabel');
            chipColor = 'destructive';
            ChipIcon = WarningIcon;
        }
    }
    if (hasCompletedPayment || lastPayment?.status === PaymentStatuses.FAILED) {
        label = order?.shippedOn ? lastPayment?.status : order?.status;
    }

    return (
        <Chip
            icon={ChipIcon ? <ChipIcon className="MuiIcon-root" sx={{ width: '16px', height: '16px' }} /> : undefined}
            variant="orderStatus"
            color={chipColor}
            label={label}
            sx={{ minWidth: 100 }}
        />
    );
}

OrderStatusChip.propTypes = {
    order: PropTypes.object.isRequired,
};
