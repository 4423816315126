import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

export default function DealerAddressListItem({ address, onClick }) {
    return (
        <ListItemButton
            disableRipple
            disableTouchRipple
            onClick={onClick}
            sx={{
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}>
            <Stack gap={0.5} direction="row">
                <Typography>
                    <strong>{address.name} </strong>
                    {address.street1}, {address.city}, {address.state}, {address.postalCode}, {address.countryCode}
                </Typography>
                {address.label === 'Primary' ? (
                    <Box sx={{ paddingX: 1 }}>
                        <Chip
                            label="Primary"
                            color="primary"
                            variant="filled"
                            sx={{
                                height: 24,
                                fontSize: '0.75rem',
                                borderRadius: '12px',
                            }}
                        />
                    </Box>
                ) : null}
            </Stack>
        </ListItemButton>
    );
}

DealerAddressListItem.propTypes = {
    address: PropTypes.object,
    onClick: PropTypes.func,
};
