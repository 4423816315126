import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AccountMenu from '../../components/AccountMenu/AccountMenu';
import BusinessLogo from '../../components/BusinessLogo';

export default function CartHeader(props) {
    const { t } = useTranslation();
    return (
        <Stack gap={3} sx={{ flexDirection: 'row', flexWrap: { xs: 'wrap-reverse', md: 'nowrap' } }}>
            <Stack direction="row" gap={3}>
                <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                    <BusinessLogo
                        business={props?.business}
                        sx={{ height: 80, width: 80 }}
                        CardMediaProps={{
                            sx: {
                                borderRadius: 4,
                                boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                                objectFit: 'cover',
                            },
                        }}
                    />
                </Box>
                <Box sx={{ pb: 2, pt: { md: 2 } }}>
                    <Typography
                        variant="displayMd"
                        sx={{
                            lineHeight: 'normal',
                        }}>
                        {t('CartHeader.title')}
                    </Typography>
                </Box>
            </Stack>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <Box>
                    <AccountMenu />
                </Box>
            </Box>
        </Stack>
    );
}

CartHeader.propTypes = {
    business: PropTypes.any.isRequired,
};
