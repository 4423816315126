import { Box, Chip } from '@mui/material';
import React, { useState } from 'react';
import { get, without, clone, indexOf } from 'lodash';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AddVariantTagDialog } from './AddVariantTagDialog/AddVariantTagDialog.jsx';
import Button from '@mui/material/Button';

export function TagOptionValues(props) {
    const values = get(props, 'values') || [];
    const [isOpen, setIsOpen] = useState(false);

    function handleDelete(chip) {
        return () => {
            const newChips = without(values, chip);
            props.onChange(newChips);
        };
    }

    function handleOpen() {
        setIsOpen(true);
    }

    function handleAdd(newTag) {
        setIsOpen(false);
        const valuesCopy = clone(values);
        if (indexOf(valuesCopy, newTag) < 0) {
            valuesCopy.push(newTag);
            props.onChange(valuesCopy);
        }
    }

    function handleCancel() {
        setIsOpen(false);
    }

    function getValues(values) {
        const chips = values.map((value, i) => {
            return <Chip key={value + i} label={value} onDelete={handleDelete(value)} />;
        });
        return (
            <>
                {chips}
                <Button sx={{ cursor: 'pointer' }} onClick={handleOpen}>
                    <AddCircleOutlineIcon />
                </Button>
            </>
        );
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
            padding={2}
            border={1}
            borderColor="grey.300"
            borderRadius={1}
            minHeight={48}
            sx={{ marginLeft: 2 }}>
            {getValues(values)}
            <AddVariantTagDialog onCancel={handleCancel} open={isOpen} value={'test'} onAdd={handleAdd} />
        </Box>
    );
}

TagOptionValues.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
};
