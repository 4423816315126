import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import PlusCircleIcon from '../../../icons/PlusCircleIcon.jsx';
import { Button, Dialog } from '@mui/material';
import ThatchGridPro from '../../../components/ThatchGridPro/ThatchGridPro.jsx';
import SearchBar from '../../../components/SearchBar/SearchBar.jsx';
import BusinessLogo from '../../../components/BusinessLogo.jsx';
import { BorderRadius, Fog, Midnight, SoftBG, Teal } from '../../../theme.js';
import { useLoaderData, useNavigate } from 'react-router-dom';
import * as api from '../../../api.js';
import { NumericFormat } from 'react-number-format';
import _ from 'lodash';
import QuickActionButton from '../../../components/QuickActionButton/QuickActionButton.jsx';
import backgroundImage from '../../../assets/Pattern.webp';
import emptyIllustration from '../../../assets/DealerEmptyIllustration.webp';
import IconButton from '@mui/material/IconButton';
import CircleXIcon from '../../../icons/CircleXIcon.jsx';
import InviteBusiness from '../../../components/InviteBusiness/InviteBusiness.jsx';

export async function loader({ request }) {
    const dealers = await api.getDealers(request.signal);
    return { dealers };
}

function BrandConnections() {
    const { t } = useTranslation();
    const { dealers = [] } = useLoaderData();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState('');
    const [showInviteBusiness, setShowInviteBusiness] = useState(false);

    const columns = [
        {
            field: 'dealerInfo',
            headerName: t('Dealers.dealerInfo').toUpperCase(),
            flex: 1,
            minWidth: 250,
            sortable: false,
            renderCell: (params) => (
                <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                    <Box
                        sx={{
                            overflow: 'hidden',
                            borderRadius: '8px',
                            border: '1px solid',
                            borderColor: Fog,
                        }}>
                        <BusinessLogo business={params.row.dealerInfo} sx={{ width: 48, height: 48 }} />
                    </Box>
                    <Stack>
                        <Typography variant="textMd" sx={{ color: Midnight }}>
                            {params.row.dealerInfo.name}
                        </Typography>
                        <Typography variant="textMd" sx={{ color: Teal }}>
                            {params.row.dealerInfo.addresses[0].city}
                        </Typography>
                    </Stack>
                </Stack>
            ),
        },
        {
            field: 'email',
            headerName: t('Dealers.emailLabel').toUpperCase(),
            flex: 1,
            minWidth: 175,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => (
                <Typography variant="textSm" sx={{ color: Midnight }}>
                    {params.row.email}
                </Typography>
            ),
        },
        {
            field: 'lastOrder',
            headerName: t('Dealers.lastOrderLabel').toUpperCase(),
            flex: 1,
            minWidth: 200,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => (
                <Typography variant="textSm" sx={{ color: Midnight }}>
                    {params.row.lastOrder}
                </Typography>
            ),
        },
        {
            field: 'totalOrders',
            headerName: t('Dealers.ordersLabel').toUpperCase(),
            flex: 1,
            minWidth: 150,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => (
                <Typography variant="textSm" sx={{ color: Midnight }}>
                    {params.row.totalOrders}
                </Typography>
            ),
        },
        {
            field: 'lastOrder$',
            headerName: t('Dealers.lastOrderAmountLabel').toUpperCase(),
            flex: 1,
            minWidth: 150,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => (
                <Typography variant="textSm" sx={{ color: Midnight }}>
                    <NumericFormat
                        value={params.row.lastOrderSpent}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale={2}
                        valueIsNumericString
                        prefix={'$'}
                    />
                </Typography>
            ),
        },
    ];

    const rows = dealers.map((dealer) => ({
        id: dealer.id,
        dealerInfo: dealer,
        email: dealer.addresses[0].emails.find((email) => email.default)?.default,
        lastOrder: _.isEmpty(dealer.lastOrder)
            ? 'N/A'
            : new Date(dealer.lastOrder.createdAt).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
              }),
        totalOrders: dealer.totalOrders ?? 0,
        lastOrderSpent: dealer.lastOrder?.total ?? 0,
    }));

    // TODO: Have the search bar search the BE and not just filter
    const filteredRows = rows.filter((row) => {
        const searchValue = searchTerm.toLowerCase();
        return (
            row.dealerInfo.name.toLowerCase().includes(searchValue) ||
            row.dealerInfo.addresses[0].city.toLowerCase().includes(searchValue) ||
            row.email.toLowerCase().includes(searchValue)
        );
    });

    const handleDealerSelect = (dealer) => {
        navigate(`/brand/connections/${dealer.id}`);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const navigateToInvite = () => {
        setShowInviteBusiness(true);
    };

    return (
        <Box>
            <Container>
                <Stack gap={3}>
                    <Stack
                        gap={2}
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                        }}>
                        <Typography variant="displayMd">{t('Dealers.title')}</Typography>
                        <Button
                            onClick={navigateToInvite}
                            color="secondary"
                            variant="outlined"
                            sx={{
                                paddingX: '16px',
                                height: 44,
                                width: 'auto',
                            }}>
                            <Stack
                                gap={1}
                                direction="row"
                                sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <PlusCircleIcon sx={{ width: 24, height: 24 }} />
                                <Typography
                                    noWrap
                                    variant="textMd"
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {t('Dealers.inviteButton')}
                                </Typography>
                            </Stack>
                        </Button>
                    </Stack>
                    <Box sx={{ maxWidth: 350 }}>
                        <SearchBar placeholder="Search" onChange={handleSearchChange} />
                    </Box>
                    {!_.isEmpty(rows) ? (
                        <ThatchGridPro
                            columns={columns}
                            rows={filteredRows}
                            rowHeight={70}
                            onRowClick={(params) => handleDealerSelect(params.row.dealerInfo)}
                        />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                                height: { xs: 525, sm: 400, md: 556 },
                                backgroundImage: `url(${backgroundImage})`,
                                backgroundColor: SoftBG,
                                borderRadius: BorderRadius,
                            }}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ height: '100vh' }}>
                                <Stack
                                    gap={2}
                                    sx={{
                                        justifyContent: 'center',
                                        p: { xs: 4, sm: 8 },
                                        width: { xs: '100%', sm: '45%' },
                                    }}>
                                    <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                                        {t('Dealers.emptyDealersTitle')}
                                    </Typography>
                                    <Typography variant="textMd">{t('Dealers.emptyDealersSubtitle')}</Typography>
                                    <Button
                                        onClick={navigateToInvite}
                                        color="secondary"
                                        variant="outlined"
                                        sx={{
                                            paddingX: '16px',
                                            height: 44,
                                            width: 170,
                                            backgroundColor: 'transparent',
                                        }}>
                                        <Stack
                                            gap={1}
                                            direction="row"
                                            sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                            <PlusCircleIcon sx={{ width: 24, height: 24 }} />
                                            <Typography
                                                noWrap
                                                variant="textMd"
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>
                                                {t('Dealers.inviteButton')}
                                            </Typography>
                                        </Stack>
                                    </Button>
                                </Stack>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%',
                                        width: { xs: '100%', sm: '55%' },
                                    }}>
                                    <Box
                                        component="img"
                                        src={emptyIllustration}
                                        alt="Empty"
                                        sx={{ width: { xs: '70%', sm: '70%' } }}
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    )}
                </Stack>
                <Box sx={{ padding: 0, position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>
                    <QuickActionButton />
                </Box>
            </Container>
            <Dialog open={showInviteBusiness} onClose={() => setShowInviteBusiness(false)} fullWidth>
                <Box sx={{ width: '100%', maxWidth: 600, position: 'relative' }}>
                    <IconButton
                        onClick={() => setShowInviteBusiness(false)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}>
                        <CircleXIcon />
                    </IconButton>
                    <InviteBusiness
                        onSuccess={() => {
                            setTimeout(() => {
                                setShowInviteBusiness(false);
                            }, 1000);
                        }}
                    />
                </Box>
            </Dialog>
        </Box>
    );
}

export default BrandConnections;

BrandConnections.propTypes = {
    routeId: PropTypes.string,
    businessType: PropTypes.string,
};
