import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function Editor(props) {
    const { value, placeholder = '', onChange } = props;

    return (
        <Box>
            <ReactQuill placeholder={placeholder} value={value} onChange={onChange} style={{ height: '100%' }} />
        </Box>
    );
}

Editor.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};
