import { Typography } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React from 'react';
import { useMatches } from 'react-router-dom';

// https://reactrouter.com/en/main/hooks/use-matches
export default function Crumbs() {
    const matches = useMatches();

    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb({ data: match.data, params: match.params }));
    const separator = (
        <Typography component="span" sx={{ color: 'common.LightGray' }}>
            /
        </Typography>
    );
    return (
        <Breadcrumbs
            sx={{
                lineHeight: 'normal',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                '& a': {
                    color: 'common.Tide',
                    textDecoration: 'none',
                    '&:hover': {
                        color: 'common.Midnight70',
                    },
                },
            }}
            separator={separator}
            aria-label="breadcrumb"
            data-testid="product-details-breadcrumbs">
            {crumbs.map((crumb) => crumb)}
        </Breadcrumbs>
    );
}
