import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';
import Typography from '@mui/material/Typography';
import { LightGray } from '../../theme.js';

export default function LandingFooter() {
    return (
        <Box sx={{ display: 'flex', width: '100%', height: 114, alignItems: 'center', justifyContent: 'center' }}>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                gap={1}
                sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', mx: 10 }}>
                <WholeshopLogo sx={{ width: 198.94, height: 30.73 }} />
                <Typography variant="textSm" sx={{ color: LightGray, textAlign: 'center' }}>
                    © 2024 Thatch Corporation LLC. All Rights Reserved
                </Typography>
            </Stack>
        </Box>
    );
}
