import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';

export default function CreatePaymentTerms() {
    const [selectedTerm] = useState();

    const handleTermSelection = (event) => {};

    return (
        <Card sx={{ width: 500 }}>
            <CardContent>
                <Stack>
                    <Typography variant="textSm" sx={{ fontWeight: 700 }}>
                        New Custom Payment Term
                    </Typography>
                    <Select id="term-select" value={selectedTerm} onChange={handleTermSelection}></Select>
                    <Select />
                </Stack>
            </CardContent>
        </Card>
    );
}
