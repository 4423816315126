import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import OrderListItem from '../OrderListItem/OrderListItem.jsx';
import { BorderRadius, SoftBG, White } from '../../theme.js';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'; // Assuming you're using MUI for styling

export default function BrandOrdersList({ orders = [], onRowClick }) {
    return (
        <Paper sx={{ borderRadius: BorderRadius }}>
            <List>
                {orders.map((order, index) => (
                    <Box
                        key={index}
                        onClick={() => onRowClick({ id: order.id })}
                        sx={{
                            backgroundColor: index % 2 === 0 ? White : SoftBG,
                        }}>
                        <OrderListItem order={order} />
                    </Box>
                ))}
            </List>
        </Paper>
    );
}

BrandOrdersList.propTypes = {
    orders: PropTypes.array,
    onRowClick: PropTypes.func,
};
