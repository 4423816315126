/**
 * By default Vite only accepts env vars that start with VITE_
 * This method allows us to specify the prefix based the given `env` value
 * @param {'development'|'staging'|'production'} env
 * @returns
 */
export const getPrefix = (env) => {
    const prefix =
        {
            development: 'DEV_',
            staging: 'STAGING_',
            production: 'PROD_',
        }[env] ?? 'VITE_';
    return prefix;
};
