import React, { useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import EditableThatchAvatar from '../../ThatchAvatar/EditableThatchAvatar.jsx';
import ThatchAvatar from '../../ThatchAvatar/ThatchAvatar.jsx';
import Typography from '@mui/material/Typography';
import { Teal } from '../../../theme.js';
import { useLoaderData } from 'react-router-dom';

export default function UserProfileHeader(props) {
    const { onImageChange, isEditable = false } = props;
    const { business, user } = useLoaderData();
    const [imgSrc] = useState(user?.profileImageUrl);

    const formattedName = () => {
        return user.firstName + ' ' + user.lastName;
    };

    return (
        <Card>
            <Stack direction="row" gap={2} sx={{ padding: 3, alignItems: 'center' }}>
                {isEditable ? (
                    <EditableThatchAvatar variant="user" src={imgSrc} onImageChange={onImageChange} />
                ) : (
                    <ThatchAvatar variant="user" src={user?.profileImageUrl} />
                )}

                <Stack>
                    <Typography variant="displayXs">{formattedName()}</Typography>
                    <Typography variant="textMd" sx={{ color: Teal }}>
                        {business.name}
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
}

UserProfileHeader.propTypes = {
    user: PropTypes.any,
    businessName: PropTypes.string,
    onImageChange: PropTypes.func,
    isEditable: PropTypes.bool,
};
