import StoreIcon from '@mui/icons-material/Store';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export default function BusinessLogo({ business, sx, CardMediaProps }) {
    const [imgSource, setImgSource] = useState(business?.imageLinks?.[0] ?? '');

    useEffect(() => {
        setImgSource(business?.imageLinks?.[0] ?? '');
    }, [business]);

    const handleImageError = () => {
        setImgSource(null);
    };
    return (
        <Box
            sx={{
                ...sx,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            {imgSource ? (
                <CardMedia
                    component="img"
                    sx={{ width: '100%', height: '100%', objectFit: 'contain', ...(CardMediaProps?.sx ?? {}) }}
                    alt={`${business?.name} logo`}
                    image={imgSource}
                    onError={handleImageError}
                />
            ) : (
                <StoreIcon sx={{ width: '55%', height: '75%', color: 'gray' }} />
            )}
        </Box>
    );
}

BusinessLogo.propTypes = {
    business: PropTypes.object,
    sx: PropTypes.object,
    CardMediaProps: PropTypes.object,
};
