import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Editor from '../../../../components/Editor/Editor.jsx';

const GeneralProductInfo = ({ formValues, handleInputChange, fieldsValidity, handleValidity }) => {
    const handleDescriptionChange = (content) => {
        const event = {
            target: {
                value: content,
                name: 'description',
            },
        };

        handleInputChange(event);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    error={fieldsValidity?.title}
                    onBlur={handleValidity}
                    label="Title (Product Name)"
                    name="title"
                    fullWidth
                    value={formValues.title || ''}
                    onChange={handleInputChange}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    label="Year"
                    name="year"
                    fullWidth
                    value={formValues.year || ''}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth error={fieldsValidity?.gender} onBlur={handleValidity}>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                        labelId="gender-label"
                        label="Gender"
                        name="gender"
                        value={formValues.gender || ''}
                        onChange={handleInputChange}>
                        <MenuItem value="unisex">Unisex</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Editor
                    value={formValues.description || ''}
                    placeholder="Description"
                    onChange={handleDescriptionChange}
                />
            </Grid>
        </Grid>
    );
};

GeneralProductInfo.propTypes = {
    formValues: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    fieldsValidity: PropTypes.object.isRequired,
    handleValidity: PropTypes.func.isRequired,
};

export default GeneralProductInfo;
