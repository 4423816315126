import React from 'react';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';

/**
 *
 * @param {import('@mui/material').InputBaseProps} props
 * @returns
 */
export default function SearchBar({ display, placeholder, sx, InputBaseRef, ...otherProps }) {
    return (
        <Box className="SearchBar" sx={{ ...sx, display }}>
            <TextField
                size="small"
                placeholder={placeholder}
                fullWidth
                {...otherProps}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
}

SearchBar.propTypes = {
    ...InputBase.propTypes,
    placeholder: PropTypes.string,
    sx: PropTypes.any,
    display: PropTypes.string,
};
