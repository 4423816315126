import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductIcon from '../../../components/ProductIcon';
import theme, {
    Alert as Red,
    Alert,
    BoxShadow,
    Fog,
    Midnight,
    sanSarifFont,
    SoftBG,
    Teal,
    Tide,
    White,
} from '../../../theme';
import { sortTags } from './orderUtil';
import QuantityInput from '../../../components/QuantityInput/QuantityInput.jsx';
import { IconButton } from '@mui/material';
import TrashIcon from '../../../icons/TrashIcon.jsx';
import { CurrencyInput } from '../../../components/index.jsx';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function OrderProductSummary(props) {
    const { productTitle, quantity, items = [], editable = false, onDelete, onUpdate } = props;
    const { t } = useTranslation();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const imageSize = isSmBreakpoint ? '64px' : '48px';
    const handleQuantityChange = (id) => (event, value) => {
        onUpdate({ quantity: `${value}`, id });
    };

    const handlePriceChange = (id) => (event) => {
        onUpdate({ price: event.target.value, id });
    };
    const productIcon = (item) => {
        return (
            <ProductIcon
                sx={{ height: imageSize, width: imageSize }}
                src={item?.productVariant?.imageLinks?.[0] || item?.productVariant?.product?.imageLinks?.[0]}
                CardMediaProps={{
                    sx: {
                        objectFit: 'cover',
                        aspectRatio: '1 / 1',
                        width: imageSize,
                        height: imageSize,
                        borderRadius: 1,
                        bgColor: White,
                        boxShadow: BoxShadow,
                        overflow: 'hidden',
                        display: 'flex',
                    },
                }}
            />
        );
    };

    const itemDetails = (item) => {
        return (
            <Stack sx={{ alignItems: 'flex-start', height: '100%', width: '100%' }}>
                {!item?.productVariant?.variantTags ? null : (
                    <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                        {sortTags(item?.productVariant)
                            .map((tag) => tag?.value)
                            .join(' | ')}
                    </Typography>
                )}
                <Stack direction="row" gap={2} sx={{ alignItems: 'top' }}>
                    {!item?.productVariant?.sku ? null : (
                        <Typography variant="textSm" sx={{ color: Teal }}>
                            {t('RetailOrderDetails.skuLabel')}: {item?.productVariant?.sku}
                        </Typography>
                    )}
                    {!item?.productVariant?.mpn ? null : (
                        <Typography variant="textSm" sx={{ color: Teal }}>
                            {t('RetailOrderDetails.mpnLabel')}: {item?.productVariant?.mpn}
                        </Typography>
                    )}
                </Stack>
            </Stack>
        );
    };

    const editableProductComponent = (item) => {
        return (
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                gap={1}
                key={item.id}
                sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}>
                <Stack gap={1} direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                    {productIcon(item)}
                    {itemDetails(item)}
                </Stack>
                <Stack
                    gap={1}
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}>
                    <CurrencyInput
                        name="price"
                        value={item.price}
                        size="small"
                        onChange={handlePriceChange(item.id)}
                        sx={{ width: 120 }}
                    />
                    <Typography variant="label" sx={{ fontSize: 12 }}>
                        <QuantityInput
                            sx={{
                                color: Midnight,
                                fontFamily: sanSarifFont,
                                lineHeight: 'unset',
                                width: 32,
                                '& input': {
                                    fontSize: 10,
                                    fontWeight: 700,
                                    padding: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 32,
                                    minHeight: 28,
                                    backgroundColor: SoftBG,
                                },
                                '& .QuantityInput-inputBorder': {
                                    border: `1px solid ${Fog}`,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    '&.error': {
                                        borderColor: Red,
                                    },
                                },
                            }}
                            value={Number(item.quantity)}
                            hideButtons
                            min={1}
                            max={999}
                            onChange={(e, value) => handleQuantityChange(item.id)(e, value)}
                        />
                    </Typography>
                    <IconButton onClick={() => onDelete(item.id)} sx={{ padding: 0 }}>
                        <TrashIcon sx={{ color: Alert }} />
                    </IconButton>
                </Stack>
            </Stack>
        );
    };

    const productComponent = (item) => {
        return (
            <Stack
                direction="row"
                gap={2}
                key={item.id}
                sx={{
                    alignItems: {
                        xs: 'flex-start',
                        sm: 'center',
                    },
                }}>
                {productIcon(item)}
                <Stack
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                    {itemDetails(item)}
                    <Stack
                        gap={{ xs: 0, sm: 1 }}
                        direction={{ xs: 'column', sm: 'row' }}
                        sx={{
                            alignItems: { xs: 'flex-end', sm: 'center' },
                            width: '100%',
                            justifyContent: 'flex-end',
                        }}>
                        <Typography sx={{ fontWeight: 600, color: Tide }}>
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={item.price}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Typography>
                        <Typography
                            variant="label"
                            sx={{
                                fontSize: 12,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {t('RetailOrderDetails.quantityLabel')} {item.quantity}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        );
    };

    return (
        <Box>
            <Stack direction="row" alignItems="flex-end" gap={1} sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 700, lineHeight: 'normal' }} variant="textXl">
                    {productTitle}
                </Typography>
                <Typography sx={{ fontWeight: 700, color: Teal, lineHeight: '26px' }} variant="textSm">
                    ({quantity})
                </Typography>
            </Stack>
            <Stack gap={1}>
                {items.map((item) => (editable ? editableProductComponent(item) : productComponent(item)))}
            </Stack>
        </Box>
    );
}

OrderProductSummary.propTypes = {
    productTitle: PropTypes.string,
    quantity: PropTypes.number,
    items: PropTypes.array,
    editable: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
};
