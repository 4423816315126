import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

export default function BasicModal({ open, title, message, content, buttons }) {
    return (
        <Modal open={open}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Paper sx={{ width: '400px', minHeight: '280px', display: 'flex', flexDirection: 'column' }}>
                    <Stack sx={{ padding: 2, flexGrow: 1 }}>
                        <Typography component="h2" sx={{ fontWeight: 'bold', fontSize: '32px' }}>
                            {title}
                        </Typography>
                        <Divider />
                        <Stack
                            spacing={1}
                            sx={{
                                flexGrow: 1,
                                justifyContent: 'space-between',
                            }}>
                            <Typography sx={{ paddingTop: 1, paddingLeft: 1, fontSize: '12px' }}>{message}</Typography>
                            {content}
                            <Box display="flex" gap="10px" width="100%" height="35px">
                                {buttons}
                            </Box>
                        </Stack>
                    </Stack>
                </Paper>
            </Box>
        </Modal>
    );
}

BasicModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    content: PropTypes.node,
    buttons: PropTypes.array,
};
