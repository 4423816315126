import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function PasswordTextField({
    label,
    placeholder,
    InputProps,
    value,
    onChange,
    defaultValue,
    fullWidth,
    height,
    name,
    autoComplete,
    required,
    ...props
}) {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            name={name}
            placeholder={placeholder}
            fullWidth={fullWidth}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            type={showPassword ? 'text' : 'password'} // Always password, toggled by showPassword
            autoComplete={autoComplete}
            required={required}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ paddingRight: '16px' }}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
}

PasswordTextField.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    InputProps: PropTypes.shape({
        sx: PropTypes.object,
        startAdornment: PropTypes.node,
        endAdornment: PropTypes.node,
        inputComponent: PropTypes.elementType,
        inputProps: PropTypes.object,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fullWidth: PropTypes.bool,
    height: PropTypes.number,
    autoComplete: PropTypes.string,
    required: PropTypes.bool,
    ...TextField.propTypes,
};

PasswordTextField.defaultProps = {
    fullWidth: true,
    height: 50,
};

export default PasswordTextField;
