import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import thatchLogo from '../../../assets/ThatchLogo--black.svg';
import thatchLogoMini from '../../../assets/ThatchLogo-mini.svg';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import SettingsAvatar from '../../SettingsAvatar/SettingsAvatar.jsx';
import { SettingsProfileType } from '../../../constants/SettingsProfileType.jsx';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { BRAND_HOME_URL } from '../../../routes/routesUtil.js';
import isEmpty from 'lodash/isEmpty';

export default function LogoSection({ height }) {
    const data = useRouteLoaderData('root') || {};
    const { business = {}, userById } = data;
    const { type } = business;
    const [profileImage, setProfileImage] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        userById
            .then((resolvedUser) => {
                const lastName = resolvedUser.lastName ? ` ${resolvedUser.lastName[0]}` : '';
                const firstName = resolvedUser.firstName ?? '';
                const fullName = firstName + lastName;
                setName(isEmpty(fullName) ? '!' : ` ${fullName}!`);
                return resolvedUser;
            })
            .catch((error) => {
                console.error('Error fetching user by id:', error);
            });

        if (business?.imageLinks) setProfileImage(business.imageLinks[0]);
    }, [business, userById]);

    if (!type) {
        console.error('Type is required when using LogoSection component');
        return null;
    }
    const navigate = useNavigate();

    const handleThatchLogoClick = () => {
        if (type === 'Brand') {
            return () => {
                navigate(BRAND_HOME_URL);
            };
        }
        if (type === 'Retail') {
            return () => {
                navigate('/retail/connections');
            };
        }
    };

    const handleProfileIconClick = () => {
        if (type === 'Brand') {
            return () => {
                navigate('brand/settings/user-profile');
            };
        }
        if (type === 'Retail') {
            return () => {
                navigate('/retail/settings/user-profile');
            };
        }
    };

    return (
        <div data-testid={`${type.toLowerCase()}-LogoSection`}>
            <Box sx={{ display: 'flex', alignItems: 'center', height }}>
                <IconButton
                    onClick={handleThatchLogoClick(type)}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}>
                    <Box
                        component="img"
                        src={thatchLogo}
                        alt="Thatch logo"
                        sx={{ display: { xs: 'none', sm: 'block' }, width: '150px', height: '50px' }}
                    />
                    <Box
                        component="img"
                        src={thatchLogoMini}
                        alt="Thatch logo"
                        sx={{ display: { xs: 'block', sm: 'none' }, width: '40px', height: '30px' }}
                    />
                </IconButton>

                <Button
                    onClick={handleProfileIconClick}
                    sx={{
                        display: { xs: 'none', sm: 'initial' },
                    }}>
                    <Box
                        sx={{
                            borderRadius: '5px',
                            overflow: 'hidden',
                            width: 35,
                            height: 35,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <SettingsAvatar type={SettingsProfileType.BUSINESS} size={35} src={profileImage} />
                    </Box>
                </Button>
                <Typography
                    variant="h6"
                    sx={{
                        display: { xs: 'none', sm: 'initial' },
                        ml: 2,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        fontSize: {
                            xs: '1rem',
                            sm: '1.25rem',
                        },
                    }}>
                    Welcome{name}
                </Typography>
            </Box>
        </div>
    );
}

LogoSection.propTypes = {
    height: PropTypes.string,
};
