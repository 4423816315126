import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';

export default function ClipboardTextIcon(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.75 14.25C15.75 14.4489 15.671 14.6397 15.5303 14.7803C15.3897 14.921 15.1989 15 15 15H9C8.80109 15 8.61032 14.921 8.46967 14.7803C8.32902 14.6397 8.25 14.4489 8.25 14.25C8.25 14.0511 8.32902 13.8603 8.46967 13.7197C8.61032 13.579 8.80109 13.5 9 13.5H15C15.1989 13.5 15.3897 13.579 15.5303 13.7197C15.671 13.8603 15.75 14.0511 15.75 14.25ZM15 10.5H9C8.80109 10.5 8.61032 10.579 8.46967 10.7197C8.32902 10.8603 8.25 11.0511 8.25 11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12H15C15.1989 12 15.3897 11.921 15.5303 11.7803C15.671 11.6397 15.75 11.4489 15.75 11.25C15.75 11.0511 15.671 10.8603 15.5303 10.7197C15.3897 10.579 15.1989 10.5 15 10.5ZM20.25 4.49999V20.25C20.25 20.6478 20.092 21.0293 19.8107 21.3106C19.5294 21.592 19.1478 21.75 18.75 21.75H5.25C4.85218 21.75 4.47064 21.592 4.18934 21.3106C3.90804 21.0293 3.75 20.6478 3.75 20.25V4.49999C3.75 4.10216 3.90804 3.72063 4.18934 3.43933C4.47064 3.15802 4.85218 2.99999 5.25 2.99999H8.64937C9.07079 2.52817 9.58709 2.15066 10.1645 1.8922C10.7419 1.63373 11.3674 1.50012 12 1.50012C12.6326 1.50012 13.2581 1.63373 13.8355 1.8922C14.4129 2.15066 14.9292 2.52817 15.3506 2.99999H18.75C19.1478 2.99999 19.5294 3.15802 19.8107 3.43933C20.092 3.72063 20.25 4.10216 20.25 4.49999ZM9 5.99999H15C15 5.20434 14.6839 4.44127 14.1213 3.87867C13.5587 3.31606 12.7956 2.99999 12 2.99999C11.2044 2.99999 10.4413 3.31606 9.87868 3.87867C9.31607 4.44127 9 5.20434 9 5.99999ZM18.75 4.49999H16.2422C16.4128 4.98169 16.5 5.48896 16.5 5.99999V6.74999C16.5 6.9489 16.421 7.13966 16.2803 7.28032C16.1397 7.42097 15.9489 7.49999 15.75 7.49999H8.25C8.05109 7.49999 7.86032 7.42097 7.71967 7.28032C7.57902 7.13966 7.5 6.9489 7.5 6.74999V5.99999C7.50002 5.48896 7.58721 4.98169 7.75781 4.49999H5.25V20.25H18.75V4.49999Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

ClipboardTextIcon.propTypes = {
    sx: PropTypes.any,
};
