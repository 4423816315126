import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
    selectItems,
    selectOrderTotalValue,
    selectSubtotalValue,
    selectTotalQuantity,
    useCart,
} from '../../../cart/useCart';
import ProductPicker from '../../../components/ProductPicker/ProductPicker';
import BrandClearOrderDialog from './BrandClearOrderDialog';
import { mapProductsToCartItems } from './BrandCreateOrder';
import BrandProductCard from './BrandProductCard';
import KeyboardPlaceholder from '../../../components/KeyboardPlaceholder/KeyboardPlaceholder';

export default function BrandCreateOrderMobile(props) {
    const { state, addItem, removeItem, clearCart } = useCart();
    const navigate = useNavigate();
    const [showClearModal, setShowClearModal] = useState(false);

    const items = selectItems(state);
    const quantity = selectTotalQuantity(state);
    const subtotal = selectSubtotalValue(state);

    const handleAddProduct = (product) => {
        const item = {
            ...product,
            productVariants: product.productVariants.map((variant) => ({
                ...variant,
                quantity: 0,
            })),
        };
        // TODO should we handle this outside of cart context?
        item.expanded = true;
        addItem(item);
    };

    const handleRemoveProduct = (product) => {
        removeItem(product.id);
    };

    const handleClear = () => {
        setShowClearModal(true);
    };

    const handleClearCart = () => {
        clearCart();
        setShowClearModal(false);
    };

    const handleSubmit = () => {
        const subTotal = selectSubtotalValue(state);
        const total = selectOrderTotalValue(state);
        const order = { ...state, subTotal, total, items: mapProductsToCartItems(state.items) };
        const id = Date.now().toString();
        localStorage.setItem(`self-order-cart-${id}`, JSON.stringify(order));
        navigate(`/brand/new-order/${id}`);
        clearCart();
    };

    const hasItems = items.length > 0;
    const hasQuantity = selectTotalQuantity(state) > 0;
    return (
        <Box>
            <Stack gap={1} flexDirection="row" flexWrap="wrap" sx={{ mb: 2 }}>
                <ProductPicker
                    sx={{ maxWidth: '50%', flex: '1 0 auto' }}
                    value={items}
                    placeholder={t('BrandCreateOrder.shortSearchPlaceholderLabel')}
                    onAddProduct={handleAddProduct}
                    onRemoveProduct={handleRemoveProduct}
                />
                <Stack gap={1} flexDirection="row" flex="1 0 auto" justifyContent="flex-end">
                    <Button size="small" disabled={!hasItems} color="error" variant="contained" onClick={handleClear}>
                        {t('BrandCreateOrder.clearLabel')}
                    </Button>
                    <Button size="small" disabled={!hasQuantity} variant="contained" onClick={handleSubmit}>
                        {t('BrandCreateOrder.submitLabel')}
                    </Button>
                </Stack>
            </Stack>
            <Box sx={{ mb: 2 }}>
                <Stack flexDirection="row">
                    <Box sx={{ flex: '1 0 auto' }} />
                    <Box sx={{ minWidth: 100 }}>
                        <Stack gap={2} direction="row" justifyContent="flex-end">
                            <Box>
                                <Typography textAlign="right" variant="body2">
                                    {t('BrandCreateOrder.totalQtyLabel')}
                                </Typography>
                            </Box>
                            <Box sx={{ minWidth: 112 }}>
                                <Typography variant="body2" align="right">
                                    <NumericFormat
                                        displayType="text"
                                        value={quantity}
                                        thousandSeparator
                                        decimalScale={2}
                                    />
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider sx={{ my: 0.5 }} />
                        <Stack gap={2} direction="row" justifyContent="flex-end">
                            <Box>
                                <Typography align="right" variant="body2" sx={{ fontWeight: 'bold' }}>
                                    {t('BrandCreateOrder.subtotalValueLabel')}
                                </Typography>
                            </Box>
                            <Box sx={{ minWidth: 112 }}>
                                <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>
                                    <NumericFormat
                                        prefix="$"
                                        displayType="text"
                                        value={subtotal}
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box>
                {!hasItems ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: { xs: 4, sm: 8, md: 24 },
                        }}>
                        <Typography variant="body2" textAlign="center">
                            {t('BrandCreateOrder.emptyResultsLabel')}
                        </Typography>
                    </Box>
                ) : (
                    <Stack gap={2}>
                        {items.map((product) => (
                            <BrandProductCard
                                key={product.id}
                                product={product}
                                onDelete={(e, product) => handleRemoveProduct(product)}
                            />
                        ))}
                        <KeyboardPlaceholder />
                    </Stack>
                )}
            </Box>
            <BrandClearOrderDialog
                open={showClearModal}
                onClose={() => setShowClearModal(false)}
                onConfirm={handleClearCart}
            />
        </Box>
    );
}
