import React from 'react';
import BrandProduct from '../BrandProduct';
import * as api from '../../../../api.js';

export async function loader({ signal }) {
    try {
        const allCategory = await api.getCategories('top');
        const pricingGroups = await api.getPricingGroups({ signal });
        return {
            allCategory,
            products: [],
            pricingGroups: pricingGroups.data,
        };
    } catch (error) {
        return { error };
    }
}

export default function BrandCreateProducts() {
    return <BrandProduct isCreateProduct={true} />;
}
