import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import ProductIcon from '../ProductIcon.jsx';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import * as React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';

export default function ShopifyImportProductCard({ product }) {
    const { t } = useTranslation();
    const fontSize = '14px';

    const fields = ['description', 'mpn', 'msrp', 'active', 'specs'];
    const initialMissingFields = fields.filter(
        (field) =>
            product[field] === undefined ||
            product[field] === null ||
            (typeof product[field] === 'string' && product[field].trim() === '') ||
            (Array.isArray(product[field]) && product[field].length === 0)
    );
    const [missingFields] = React.useState(initialMissingFields);

    const extraImages = product.imageLinks ? product.imageLinks.slice(1) : [];
    const imageWidth = 151;

    const gap = 4; // 4px gap between images
    const adjustedWidth = (imageWidth - 2 * gap) / 4;

    return (
        <Card sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <ProductIcon
                    src={product.imageLinks?.[0]}
                    sx={{ width: `${imageWidth}px`, height: `${imageWidth}px`, pl: '12px' }}
                />
                <Stack direction="row" gap={`${gap}px`} sx={{ pl: '12px', pb: '12px', flexWrap: 'wrap' }}>
                    {extraImages.map((image, index) => (
                        <ProductIcon
                            key={index}
                            src={image}
                            sx={{
                                width: `${adjustedWidth}px`,
                                height: `${adjustedWidth}px`,
                            }}
                        />
                    ))}
                </Stack>
            </Stack>
            <CardContent sx={{ pt: 1 }}>
                <Stack spacing={1} sx={{ width: '100%', height: '100%' }}>
                    <Typography
                        variant="body1"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            fontWeight: 'bold',
                        }}
                        align="left">
                        {product.title}
                    </Typography>
                    <Stack>
                        <Stack direction="row" spacing={0.5}>
                            <Typography align="left" sx={{ fontSize, fontWeight: 'bold' }}>
                                Wholesale:
                            </Typography>
                            <Typography align="left" sx={{ fontSize }}>
                                <NumericFormat
                                    prefix="$"
                                    displayType="text"
                                    value={product.wholesale || 0}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator=","
                                />
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.5}>
                            <Typography align="left" sx={{ fontSize, fontWeight: 'bold' }}>
                                {t('ConnectedBrandProducts.msrpLabel')}:
                            </Typography>
                            <Typography align="left" sx={{ fontSize }}>
                                <NumericFormat
                                    prefix="$"
                                    displayType="text"
                                    value={product.msrp || 0}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator=","
                                />
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {`Variants: ${product.productVariants?.length}`}
                    </Typography>
                    {!_.isEmpty(missingFields) ? (
                        <Box>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                Missing Fields from Shopify
                            </Typography>
                            <Typography sx={{ color: 'red', fontSize: '12px' }}>{missingFields.join(', ')}</Typography>
                        </Box>
                    ) : null}
                </Stack>
            </CardContent>
        </Card>
    );
}

ShopifyImportProductCard.propTypes = {
    product: PropTypes.object,
};
