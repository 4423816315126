import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from '@mui/material';

export default function CaretCircleDownIcon(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0.25C8.07163 0.25 6.18657 0.821828 4.58319 1.89317C2.97981 2.96451 1.73013 4.48726 0.992175 6.26884C0.25422 8.05042 0.0611377 10.0108 0.437344 11.9021C0.81355 13.7934 1.74215 15.5307 3.10571 16.8943C4.46927 18.2579 6.20655 19.1865 8.09787 19.5627C9.98918 19.9389 11.9496 19.7458 13.7312 19.0078C15.5127 18.2699 17.0355 17.0202 18.1068 15.4168C19.1782 13.8134 19.75 11.9284 19.75 10C19.7473 7.41498 18.7192 4.93661 16.8913 3.10872C15.0634 1.28084 12.585 0.25273 10 0.25ZM10 18.25C8.3683 18.25 6.77325 17.7661 5.41655 16.8596C4.05984 15.9531 3.00242 14.6646 2.37799 13.1571C1.75357 11.6496 1.59019 9.99085 1.90852 8.3905C2.22685 6.79016 3.01259 5.32015 4.16637 4.16637C5.32015 3.01259 6.79016 2.22685 8.39051 1.90852C9.99085 1.59019 11.6496 1.75357 13.1571 2.37799C14.6646 3.00242 15.9531 4.05984 16.8596 5.41655C17.7661 6.77325 18.25 8.3683 18.25 10C18.2475 12.1873 17.3775 14.2843 15.8309 15.8309C14.2843 17.3775 12.1873 18.2475 10 18.25ZM14.2806 7.96937C14.3504 8.03903 14.4057 8.12175 14.4434 8.21279C14.4812 8.30384 14.5006 8.40144 14.5006 8.5C14.5006 8.59856 14.4812 8.69616 14.4434 8.7872C14.4057 8.87825 14.3504 8.96097 14.2806 9.03063L10.5306 12.7806C10.461 12.8504 10.3783 12.9057 10.2872 12.9434C10.1962 12.9812 10.0986 13.0006 10 13.0006C9.90144 13.0006 9.80384 12.9812 9.71279 12.9434C9.62175 12.9057 9.53903 12.8504 9.46938 12.7806L5.71937 9.03063C5.57864 8.88989 5.49958 8.69902 5.49958 8.5C5.49958 8.30098 5.57864 8.11011 5.71937 7.96937C5.86011 7.82864 6.05098 7.74958 6.25 7.74958C6.44902 7.74958 6.63989 7.82864 6.78063 7.96937L10 11.1897L13.2194 7.96937C13.289 7.89964 13.3717 7.84432 13.4628 7.80658C13.5538 7.76884 13.6514 7.74941 13.75 7.74941C13.8486 7.74941 13.9462 7.76884 14.0372 7.80658C14.1283 7.84432 14.211 7.89964 14.2806 7.96937Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

CaretCircleDownIcon.propTypes = {
    sx: PropTypes.any,
};
