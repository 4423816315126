import { useNavigation, useRouteError, useRouteLoaderData } from 'react-router-dom';

/**
 * A hook to access the connected businesses for a given route.
 * @param {string} routeId
 * @returns
 */
export function useBusinesses(routeId) {
    const error = useRouteError();
    const { businesses = [] } = useRouteLoaderData(routeId) || {};
    const navigation = useNavigation();
    // TODO how do we handle infinite scroll?
    return [
        businesses,
        {
            error,
            isLoading: navigation.state === 'loading',
            isSubmitting: navigation.state === 'submitting',
        },
    ];
}
