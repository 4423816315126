import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';

export default function PricingGroupSelect(props) {
    const { groups, onChange, selectProps, defaultValue = '' } = props;
    const emptyLabel = 'Select Pricing Group';
    const [value, setValue] = React.useState(defaultValue || '');

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        if (onChange) {
            const selectedGroup = groups.find((group) => group.id === newValue) || {};
            onChange(selectedGroup);
        }
    };

    return (
        <Select value={value} onChange={handleChange} sx={{ width: '100%', height: 40 }} displayEmpty {...selectProps}>
            <MenuItem value="">
                <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                    {emptyLabel}
                </Typography>
            </MenuItem>
            {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                    <Typography variant="textSm" sx={{ fontWeight: 500, pt: 0.3 }}>
                        {group.name}
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    );
}

PricingGroupSelect.propTypes = {
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
