import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

export default function PackageIcon(props) {
    return (
        <Box component="svg" sx={props?.sx} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.97 6.20155L12.72 1.68749C12.4996 1.56569 12.2518 1.5018 12 1.5018C11.7482 1.5018 11.5004 1.56569 11.28 1.68749L3.03 6.20342C2.7944 6.33233 2.59772 6.52214 2.46052 6.75301C2.32331 6.98388 2.25061 7.24736 2.25 7.51592V16.4822C2.25061 16.7507 2.32331 17.0142 2.46052 17.2451C2.59772 17.476 2.7944 17.6658 3.03 17.7947L11.28 22.3106C11.5004 22.4324 11.7482 22.4963 12 22.4963C12.2518 22.4963 12.4996 22.4324 12.72 22.3106L20.97 17.7947C21.2056 17.6658 21.4023 17.476 21.5395 17.2451C21.6767 17.0142 21.7494 16.7507 21.75 16.4822V7.51686C21.7499 7.24782 21.6774 6.98376 21.5402 6.75234C21.403 6.52093 21.206 6.33069 20.97 6.20155ZM12 2.99999L19.5319 7.12499L16.7409 8.65311L9.20813 4.52811L12 2.99999ZM12 11.25L4.46812 7.12499L7.64625 5.38499L15.1781 9.50999L12 11.25ZM3.75 8.43749L11.25 12.5419V20.5847L3.75 16.4831V8.43749ZM20.25 16.4794L12.75 20.5847V12.5456L15.75 10.904V14.25C15.75 14.4489 15.829 14.6397 15.9697 14.7803C16.1103 14.921 16.3011 15 16.5 15C16.6989 15 16.8897 14.921 17.0303 14.7803C17.171 14.6397 17.25 14.4489 17.25 14.25V10.0828L20.25 8.43749V16.4784V16.4794Z"
                fill="currentColor"
            />
        </Box>
    );
}

PackageIcon.propTypes = {
    sx: PropTypes.any,
};
