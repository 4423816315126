import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';

export default function CaretDownIcon(props) {
    return (
        <SvgIcon component="svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.9999 13.5863L6.70687 8.29325C6.31587 7.90225 5.68288 7.90225 5.29288 8.29325C4.90288 8.68425 4.90188 9.31725 5.29288 9.70725L11.2929 15.7072C11.6839 16.0982 12.3169 16.0982 12.7069 15.7072L18.7069 9.70725C19.0979 9.31625 19.0979 8.68325 18.7069 8.29325C18.3159 7.90325 17.6829 7.90225 17.2929 8.29325L11.9999 13.5863Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

CaretDownIcon.propTypes = {
    sx: PropTypes.any,
};
