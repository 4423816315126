import React from 'react';
import _ from 'lodash';
import Box from '@mui/material/Box';
import ProductIcon from '../../../../../../../components/ProductIcon.jsx';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

export default function EditProductVariantSelect(props) {
    const { selected, options, onChange } = props;

    function handleClick(item) {
        const optionsClone = _.cloneDeep(options);
        const i = _.findIndex(options, item);

        optionsClone.map((item) => {
            item.isSelected = false;
            return item;
        });
        optionsClone[i].isSelected = true;
        onChange(i);
    }

    const view = options.map((variant, i) => {
        const tabColor = variant.id === selected?.id ? 'primary.main' : '';
        const bckColor = variant.id === selected?.id ? 'common.lightGrey' : '';

        let selectedStyle;

        // set border radius correctly
        const index = i + 1;
        if (index === 1) {
            selectedStyle = '5px 0 0 0 ';
        }
        if (index > 2) {
            selectedStyle = '0 0 0 0 ';
        }
        if (index === options.length) {
            selectedStyle = '0 0 0 5px ';
        }
        if (options.length === 1) {
            selectedStyle = '5px 0 0 5px ';
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '50px',
                    backgroundColor: bckColor,
                }}
                key={variant.id}>
                <Box sx={{ backgroundColor: tabColor, borderRadius: selectedStyle, width: '18px' }}></Box>
                <Button
                    onClick={() => handleClick(variant)}
                    sx={{ width: '100%', justifyContent: 'flex-start', padding: 0 }}>
                    <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <ProductIcon
                            sx={{ width: '40px', marginRight: '10px' }}
                            src={variant.imageLinks[0]}></ProductIcon>
                        <Typography sx={{ textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {variant.variantTags.map((attr) => attr.value).join(' / ')}
                        </Typography>
                    </Stack>
                </Button>
            </Box>
        );
    });

    return view;
}

EditProductVariantSelect.propTypes = {
    selected: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            isSelected: PropTypes.bool,
            imageLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
            variantTags: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                })
            ).isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
};
