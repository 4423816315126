import React from 'react';
import PropTypes from 'prop-types';
import { format, isToday, isTomorrow, differenceInDays, endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Alert, LightGray } from '../../theme';

const DueDate = ({ date, icon, prefix = '', suffix = '', sx }) => {
    const { t } = useTranslation();
    const dueDate = endOfDay(new Date(date));
    const today = endOfDay(new Date());
    let pastDue = false;
    let displayText;
    if (isToday(dueDate)) {
        displayText = t('DueDate.today');
    } else if (isTomorrow(dueDate)) {
        displayText = t('DueDate.tomorrow');
    } else if (dueDate > today) {
        displayText = t('DueDate.dueOn', { date: format(dueDate, 'MMM d, yyyy') });
    } else {
        pastDue = true;
        const daysPastDue = differenceInDays(today, dueDate);
        displayText = t('DueDate.pastDue', { count: daysPastDue });
    }

    const classNames = ['due-date'];
    if (pastDue) {
        classNames.push('past-due');
    }
    return (
        <Stack direction="row" alignItems="center" gap={1} sx={{ color: pastDue ? Alert : LightGray }}>
            {icon}
            <Typography
                className={classNames.join(' ')}
                sx={{ fontSize: '14px', fontWeight: '500', color: pastDue ? Alert : LightGray, ...sx }}>
                {prefix} {displayText} {suffix}
            </Typography>
        </Stack>
    );
};

DueDate.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    icon: PropTypes.node,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    sx: PropTypes.object,
};

export default DueDate;
