import { getPrefix } from './getPrefix';
import amplifyconfiguration from './amplifyconfiguration';

const env = import.meta.env.MODE;

/**
 * get value of the environment variable for given enum
 * @param {number} enumVal
 * @returns {string}
 */
export function get(enumVal) {
    const vars = [
        import.meta.env[`${getPrefix(env)}THATCH_API_URL`],
        import.meta.env[`${getPrefix(env)}MATERIAL_UI_LICENCE_KEY`],
        import.meta.env[`${getPrefix(env)}SENTRY_STAGE`],
        import.meta.env[`${getPrefix(env)}SENTRY_DSN`],
        import.meta.env[`${getPrefix(env)}LAUNCH_DARKLY_CLIENT_ID`],
        amplifyconfiguration[`${getPrefix(env)}`],
        import.meta.env[`${getPrefix(env)}FULLSTORY_ORG_ID`],
    ];
    return vars[enumVal];
}

/**
 * Enums
 */
export const ENVIRONMENT_VARIABLES_ENUMS = {
    THATCH_API_URL: 0,
    MATERIAL_UI_LICENCE_KEY: 1,
    SENTRY_STAGE: 2,
    SENTRY_DSN: 3,
    LAUNCH_DARKLY_CLIENT_ID: 4,
    AMPLIFY_CONFIG: 5,
    FULLSTORY_ORG_ID: 6,
};
