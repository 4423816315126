import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WholeshopLogo from '../icons/WholeshopLogo.jsx';

export default function PoweredByThatch(props) {
    const { t } = useTranslation();
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
            <Typography sx={{ fontSize: '14px', color: 'common.Teal', fontWeight: 500, lineHeight: 'normal' }}>
                {t('PoweredByThatch.poweredByLabel')}
            </Typography>
            <WholeshopLogo sx={{ width: 115 }} />
        </Stack>
    );
}
