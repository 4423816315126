import { redirect } from 'react-router-dom';
import * as authProvider from '../../providers/auth';
import * as businessProvider from '../../providers/businessProvider';
import * as userProvider from '../../providers/userProvider';

export async function action() {
    await authProvider.signOut();
    businessProvider.clearCache();
    userProvider.clearCache();
    return redirect('/login');
}
