import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { states } from '../StateSelect/StateSelect.jsx';
import PropTypes from 'prop-types';

export default function AddressStateSelect(props) {
    const { label, onChange, selectProps, defaultValue = '' } = props;
    const [value, setValue] = React.useState(defaultValue);
    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="state-select-label" shrink>
                    {label}
                </InputLabel>
                <Select
                    label="State"
                    placeholder="State"
                    variant="outlined"
                    labelId="state-select-label"
                    id="state-select"
                    displayEmpty
                    value={value}
                    onChange={handleChange}
                    {...selectProps}
                    renderValue={
                        value !== ''
                            ? undefined
                            : () => <Typography style={{ color: 'grey' }}>{'Select State'}</Typography>
                    }>
                    <MenuItem value="" disabled>
                        <Typography variant="body2" style={{ color: 'grey' }}>
                            Select State
                        </Typography>
                    </MenuItem>
                    {states.map((i) => (
                        <MenuItem key={i.code} value={i.code}>
                            {i.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

AddressStateSelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};
