import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { Grid, TextField, Typography, InputAdornment, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { formatPercentageValue } from '../../PricingGroupsList/pricingGroupUtils.js';

export const PricingGroupNumericFormat = forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.floatValue,
                    },
                });
            }}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            prefix="$"
        />
    );
});

PricingGroupNumericFormat.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default function ItemPricingGroupRow({ pricingGroup, override, onChange, itemPrice }) {
    const discount = override?.discountPercentage ?? pricingGroup.discountPercentage;
    const initialPrice = itemPrice * (1 - discount / 100);
    const [newPrice, setNewPrice] = useState(initialPrice);
    const isUserChange = useRef(false);
    const { t } = useTranslation();
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const pricingGroupPrice = itemPrice * (1 - pricingGroup.discountPercentage / 100);

    const discountPercentage = () => {
        if (override) {
            return override.discountPercentage;
        }
        return '';
    };

    const handlePercentageChange = (value) => {
        const floatValue = value.floatValue;
        const newDiscountPercentage = isNaN(floatValue) || floatValue === '' ? '' : floatValue;
        const newPrice =
            newDiscountPercentage === '' ? pricingGroupPrice : itemPrice * (1 - newDiscountPercentage / 100);
        setNewPrice(newPrice);
        onChange({ discountPercentage: newDiscountPercentage });
    };

    const handlePriceChange = (price) => {
        if (isUserChange.current) {
            const parsedPrice = parseFloat(price);
            setNewPrice(parsedPrice);

            if (!isNaN(parsedPrice) && parsedPrice !== '') {
                const originalPrice = itemPrice;
                const discountPercentage = ((originalPrice - parsedPrice) / originalPrice) * 100;

                onChange({ discountPercentage });
            }
        }
        isUserChange.current = false;
    };

    useEffect(() => {
        setNewPrice(initialPrice);
    }, [itemPrice]);

    const handleKeyDown = () => {
        isUserChange.current = true;
    };

    return (
        <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center">
            <Grid item xs={12} sm={6}>
                {isXs && (
                    <Typography variant="label" sx={{ fontSize: '14px', fontWeight: 800, textTransform: 'uppercase' }}>
                        {t('ProductPricingGroup.titleLabel')}
                    </Typography>
                )}
                <TextField
                    size="small"
                    disabled
                    value={pricingGroup.name}
                    fullWidth
                    sx={{ height: 40, fontSize: 14, fontWeight: 500 }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                {isXs && (
                    <Typography variant="label" sx={{ fontSize: '12px', fontWeight: 800, textTransform: 'uppercase' }}>
                        {t('ProductPricingGroup.percentOffLabel')}
                    </Typography>
                )}
                <NumericFormat
                    value={discountPercentage()}
                    decimalScale={2}
                    thousandSeparator
                    customInput={TextField}
                    autoComplete="off"
                    onValueChange={(values) => handlePercentageChange(values)}
                    size="small"
                    fullWidth
                    sx={{
                        height: 40,
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography sx={{ textDecoration: override ? 'line-through' : 'none' }}>
                                    {formatPercentageValue(pricingGroup.discountPercentage)}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                    suffix="%"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                {isXs && (
                    <Typography variant="label" sx={{ fontSize: '12px', fontWeight: 800, textTransform: 'uppercase' }}>
                        {t('ProductPricingGroup.priceLabel')}
                    </Typography>
                )}
                <TextField
                    value={newPrice.toFixed(2)} // Round for display only
                    onChange={(event) => handlePriceChange(event.target.value)}
                    onKeyDown={handleKeyDown}
                    InputProps={{
                        inputComponent: PricingGroupNumericFormat,
                    }}
                    size="small"
                    fullWidth
                    sx={{ height: 40, fontSize: 14, fontWeight: 500 }}
                />
            </Grid>
        </Grid>
    );
}

ItemPricingGroupRow.propTypes = {
    itemPrice: PropTypes.number.isRequired,
    override: PropTypes.object,
    hasMultipleOverrides: PropTypes.bool,
    pricingGroup: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
