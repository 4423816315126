import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import BasicModal from '../../../../components/BasicModal/BasicModal.jsx';

export default function MissingFieldsModal({ open, onClose, missingFields }) {
    const buttonStyle = {
        width: '100%',
        textTransform: 'none',
        color: 'white',
    };

    const buttonsArray = [
        <Button
            key="1"
            onClick={onClose}
            variant="contained"
            sx={{
                ...buttonStyle,
            }}>
            Okay
        </Button>,
    ];

    const content = (
        <List sx={{ flex: 1, width: '100%', height: '100%', paddingTop: 0 }}>
            {Object.keys(missingFields).map((field, index) => (
                <ListItem
                    key={index}
                    sx={{
                        paddingLeft: 1,
                        paddingBottom: 0,
                        paddingTop: 0,
                        display: 'list-item',
                        color: 'red',
                    }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>&#8226; {_.capitalize(field)}</Typography>
                </ListItem>
            ))}
        </List>
    );

    return (
        <BasicModal
            open={open}
            title={'Required Fields'}
            message={'Please fill in the missing required fields.'}
            content={content}
            buttons={buttonsArray}
        />
    );
}

MissingFieldsModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    missingFields: PropTypes.object,
};
