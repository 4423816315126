import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const BrandLayoutContext = createContext();

/**
 * @typedef useBrandLayoutInterface
 * @property {boolean} isDrawerOpen
 * @property {function} toggleDrawer
 */

/**
 *
 * @returns {useBrandLayoutInterface}
 */
export const useBrandLayout = () => useContext(BrandLayoutContext);

export const BrandLayoutProvider = ({ children }) => {
    const [isDrawerOpen, setDrawerOpen] = useState(true);

    const toggleDrawer = (open) => {
        setDrawerOpen(open);
    };

    return <BrandLayoutContext.Provider value={{ isDrawerOpen, toggleDrawer }}>{children}</BrandLayoutContext.Provider>;
};

BrandLayoutProvider.propTypes = {
    children: PropTypes.node,
};
