import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import { BusinessTypes } from '../../constants/BusinessTypes.jsx';
import { Midnight, White } from '../../theme.js';

export default function BusinessSelect(props) {
    const { label, businesses, onChange, selectProps, type, defaultValue = '' } = props;
    const { t } = useTranslation();
    const emptyLabel = type === BusinessTypes.RETAIL ? t('BusinessSelect.dealerLabel') : t('BusinessSelect.brandLabel');
    const [value, setValue] = React.useState(_.isEmpty(defaultValue) ? emptyLabel : defaultValue);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        if (onChange) {
            const businessId = newValue === emptyLabel ? '' : newValue;
            onChange(businessId);
        }
    };

    const backgroundColor = value === emptyLabel ? 'transparent' : Midnight;
    const fontColor = value === emptyLabel ? Midnight : White;

    return (
        <Box sx={{ maxWidth: 160 }}>
            <FormControl fullWidth>
                <InputLabel id="brand-select-label" sx={{ color: Midnight }}>
                    {label}
                </InputLabel>
                <Select
                    label={label}
                    labelId="brand-select-label"
                    id="brand-select"
                    value={value}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        <Typography
                            variant="textTiny"
                            sx={{
                                color: selected === emptyLabel ? Midnight : White,
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                pt: 0.5,
                            }}>
                            {selected === emptyLabel
                                ? emptyLabel
                                : businesses.find((business) => business.businessId === selected)?.name}
                        </Typography>
                    )}
                    sx={{
                        backgroundColor,
                        '.MuiSelect-icon': {
                            color: fontColor,
                        },
                        transition: 'background-color 0.3s ease, color 0.3s ease',
                    }}
                    {...selectProps}>
                    <MenuItem value={emptyLabel}>
                        <Typography
                            variant="textTiny"
                            sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5, color: Midnight }}>
                            {emptyLabel}
                        </Typography>
                    </MenuItem>
                    {businesses.map((i) => (
                        <MenuItem key={i.businessId} value={i.businessId}>
                            <Typography
                                variant="textTiny"
                                sx={{ textTransform: 'uppercase', fontWeight: 'bold', pt: 0.5, color: Midnight }}>
                                {i.name}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

BusinessSelect.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    businesses: PropTypes.array,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};
