import React from 'react';
import { ProductVariantTable } from './ProductVariantTable/ProductVariantTable.jsx';
import { ProductVariantTagOptions } from './ProductVariantTagOptions/ProductVariantTagOptions.jsx';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';

const ProductVariants = ({ product, onPVOptionsChange, onChange, setFieldsValidity }) => {
    function handleOnChange(options) {
        onPVOptionsChange(options);
    }

    return (
        <>
            <Stack spacing={4}>
                <ProductVariantTagOptions
                    setFieldsValidity={setFieldsValidity}
                    product={product}
                    onChange={handleOnChange}
                />
                <ProductVariantTable product={product} onChange={onChange} />
            </Stack>
        </>
    );
};

export default ProductVariants;

ProductVariants.propTypes = {
    onChange: PropTypes.func.isRequired,
    onPVOptionsChange: PropTypes.func.isRequired,
    setFieldsValidity: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};
