import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import MapPinIcon from '../../icons/MapPinIcon.jsx';
import Typography from '@mui/material/Typography';
import DealerAddressCell from '../DealerAddressCell/DealerAddressCell.jsx';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

export default function DealerAddresses({ dealer }) {
    const [showMore, setShowMore] = useState(false);
    const initialAddressLimit = 3;
    const { t } = useTranslation();
    const handleShowMoreToggle = () => {
        setShowMore((prev) => !prev);
    };

    const displayedAddresses = showMore ? dealer.addresses : dealer.addresses?.slice(0, initialAddressLimit);

    return (
        <Card sx={{ width: '100%' }}>
            <CardContent sx={{ padding: '24px' }}>
                <Stack gap={2}>
                    <Stack gap={1} direction="row">
                        <MapPinIcon sx={{ width: 24, height: 24 }} />
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('Dealers.addressesLabel')}
                        </Typography>
                    </Stack>
                    <Stack gap={2}>
                        {displayedAddresses?.map((address, index) => (
                            <Stack gap={2} key={index}>
                                <DealerAddressCell address={address} />
                                {dealer.addresses.length > initialAddressLimit ? <Divider /> : null}
                            </Stack>
                        ))}
                        {dealer.addresses?.length > initialAddressLimit && (
                            <Box
                                sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ width: 140, height: 32, fontSize: '12px', my: 1 }}
                                    onClick={handleShowMoreToggle}>
                                    {showMore ? t('Dealers.showLess') : t('Dealers.showMore')}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

DealerAddresses.propTypes = {
    dealer: PropTypes.object.isRequired,
};
