import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../../api';

const UploadButtonStyles = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'common.borderColor',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
};
export default function Media({ businessId, productId, images, handleImagesChange }) {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [imageMenuIndex, setImageMenuIndex] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleFileChange = async (event) => {
        setIsUploading(true);
        const files = event.target.files;
        for (const file of files) {
            try {
                const response = await api.uploadImage(file);
                handleImagesChange([...images, response.url]);
            } catch (error) {
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);
            }
        }
        setIsUploading(false);
    };

    const removeImage = (index) => {
        handleImagesChange(images.filter((_, i) => i !== index));
    };

    const showImageActions = (index) => {
        const isInArray = imageMenuIndex.includes(index);
        if (!isInArray) {
            setImageMenuIndex([...imageMenuIndex, index]);
        }
    };

    const hideImageActions = (index) => {
        const isInArray = imageMenuIndex.includes(index);
        if (isInArray) {
            setImageMenuIndex(imageMenuIndex.filter((i) => i !== index));
        }
    };

    const makeDefaultImage = (index) => {
        const image = images[index];
        const update = [image, ...images.filter((image, i) => i !== index)];
        handleImagesChange(update);
        hideImageActions(index);
    };
    return (
        <>
            <Typography sx={{ mb: 1 }} color="common.blueTypography">
                {t('Media.description')}
            </Typography>
            <Box>
                <Grid container spacing={2}>
                    {images.map((image, index) => (
                        <Grid item key={`${image}-${index}`}>
                            <ClickAwayListener onClickAway={() => hideImageActions(index)}>
                                <Box
                                    data-testid="image-container"
                                    role="button"
                                    tabIndex="0"
                                    sx={{ position: 'relative' }}
                                    onMouseEnter={() => showImageActions(index)}
                                    onMouseLeave={() => hideImageActions(index)}>
                                    <img
                                        src={image}
                                        alt={`Uploaded image ${index}`}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                    <Box
                                        data-testid="media-image-action-overlay"
                                        sx={{
                                            display: imageMenuIndex.includes(index) ? 'flex' : 'none',
                                            flexDirection: 'column',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            bgcolor: 'rgba(0, 0, 0, 0.1)',
                                            zIndex: 10,
                                        }}>
                                        <Button
                                            sx={{ flex: '1 0 auto', fontSize: '0.6rem' }}
                                            variant="contained"
                                            size="small"
                                            onClick={() => makeDefaultImage(index)}>
                                            {t('Media.makeDefaultButtonLabel')}
                                        </Button>
                                        <Button
                                            role="button"
                                            aria-label={`Delete image ${index}`}
                                            sx={{ flex: '1 0 auto', fontSize: '0.6rem' }}
                                            size="small"
                                            color="error"
                                            variant="contained"
                                            onClick={() => removeImage(index)}>
                                            {t('Media.removeButtonLabel')}
                                        </Button>
                                    </Box>
                                    <Box
                                        data-testid="default-label"
                                        sx={{
                                            display: index === 0 ? 'flex' : 'none',
                                            position: 'absolute',
                                            bgcolor: 'common.buttonPrimary',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            justifyContent: 'center',
                                            zIndex: 5,
                                        }}>
                                        <Typography
                                            sx={{
                                                py: 0.25,
                                                color: 'common.white',
                                            }}
                                            variant="caption">
                                            {t('Media.defaultLabel')}
                                        </Typography>
                                    </Box>
                                </Box>
                            </ClickAwayListener>
                        </Grid>
                    ))}
                    {isUploading ? (
                        <Grid item>
                            <Box
                                sx={{
                                    ...UploadButtonStyles,
                                }}>
                                <CircularProgress />
                            </Box>
                        </Grid>
                    ) : null}
                    <Grid item>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="upload-media-input"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                        />
                        {isUploading ? null : (
                            <label htmlFor="upload-media-input">
                                <Box
                                    tabIndex="0"
                                    role="button"
                                    component="span"
                                    sx={{
                                        ...UploadButtonStyles,
                                        color: 'common.blueTypography',
                                        flexDirection: 'column',
                                        cursor: 'pointer',
                                        ':hover': {
                                            bgcolor: 'rgba(31, 101, 159, 0.04)', // from mui button
                                        },
                                    }}
                                    id="add-media-button">
                                    <AddCircleOutlineIcon color="inherit" fontSize="large" />
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: 'bold', pt: 1 }}
                                        component="span"
                                        color="inherit">
                                        {t('Media.addMediaButtonLabel')}
                                    </Typography>
                                </Box>
                            </label>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

Media.propTypes = {
    handleImagesChange: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
    businessId: PropTypes.string,
    productId: PropTypes.string,
};
