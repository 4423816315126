import React from 'react';
import PropTypes from 'prop-types';
import ProductIcon from '../ProductIcon.jsx';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.jsx';
import Chip from '@mui/material/Chip';
import theme from '../../theme.js';
export default function ShopifyProductCard(props) {
    const { product, onShouldSyncChange, shouldSync } = props;
    const imageSrc = product?.image?.src;
    const extraImages = product.images ? product.images.slice(1) : [];
    const imageWidth = 151;

    const gap = 4; // 4px gap between images
    const adjustedWidth = (imageWidth - 2 * gap) / 4;

    return (
        <Box sx={{ paddingBottom: 1 }}>
            <Card sx={{ display: 'flex' }}>
                <Stack sx={{ display: 'flex', flexWrap: 'wrap', pr: '20px' }}>
                    <ProductIcon
                        src={imageSrc}
                        sx={{ width: `${imageWidth}px`, height: `${imageWidth}px`, pl: '12px' }}
                    />
                    <Stack direction="row" gap={`${gap}px`} sx={{ pl: '12px', pb: '12px', flexWrap: 'wrap' }}>
                        {extraImages.map((image, index) => (
                            <ProductIcon
                                key={index}
                                src={image.src}
                                sx={{
                                    width: `${adjustedWidth}px`,
                                    height: `${adjustedWidth}px`,
                                }}
                            />
                        ))}
                    </Stack>
                </Stack>
                <CardContent sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: {
                                xs: 'wrap',
                                sm: 'unset',
                            },
                            width: '100%',
                            justifyContent: 'space-between',
                            gap: 1,
                        }}>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item xs={12} sm={6}>
                                <Stack alignItems="flex-start">
                                    <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                        <Typography variant="h6">
                                            <b>{product.title}</b>
                                        </Typography>
                                        {product.hasBeenImported ? (
                                            <Chip
                                                label="Imported"
                                                variant="filled"
                                                sx={{
                                                    color: 'white',
                                                    backgroundColor: theme.palette.shopify.primary,
                                                    height: 20,
                                                    fontSize: '13px',
                                                    fontWeight: 'bold',
                                                    borderRadius: '10px',
                                                }}
                                            />
                                        ) : null}
                                    </Stack>

                                    <Typography sx={{ fontSize: '14px' }}>
                                        <b>Variants:</b> {product.variants.length}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        <b>Images:</b> {product.images?.length}
                                    </Typography>
                                    <Typography sx={{ fontSize: '14px' }}>
                                        <b>Status:</b> {product.status}
                                    </Typography>
                                    <Box style={{ margin: '16px 0' }} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} container justifyContent="flex-end" alignItems="center">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        flexWrap: 'wrap',
                                    }}>
                                    <ToggleSwitch isChecked={shouldSync} onChange={onShouldSyncChange} label="Sync" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

ShopifyProductCard.propTypes = {
    product: PropTypes.object,
    onShouldSyncChange: PropTypes.func,
    shouldSync: PropTypes.bool,
};
