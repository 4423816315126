import React, { useEffect, useState } from 'react';
import { Alert, Grid, Snackbar, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import { useActionData, useLoaderData, useNavigation, useSubmit } from 'react-router-dom';
import { pricingGroupHeaderStyle } from '../ProductPricingGroupList/ProductPricingGroupList.jsx';
import ItemPricingGroupRow from '../ProductPricingGroupList/ItemPricingGroupRow/ItemPricingGroupRow.jsx';
import { hasMadeChanges, mapOverridesForSubmit } from '../PricingGroupsList/pricingGroupUtils.js';
import { useError } from '../Error/ErrorProvider.jsx';

export default function VariantPricingGroupList({ price, variantIndex, setHasMadePricingGroupChanges }) {
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { pricingGroups, product } = useLoaderData();
    const variant = product.productVariants[variantIndex];
    const { t } = useTranslation();
    const submit = useSubmit();
    const navigation = useNavigation();
    const intentName = 'saveVariantGroups';
    const [deletes, setDeletes] = useState([]);
    const initialOverrides = variant.variantPricingGroups.map((group) => ({
        ...group,
        discountPercentage: Number(group.discountPercentage),
    }));
    const [overrides, setOverrides] = useState(initialOverrides);
    const isLoading = navigation.state === 'submitting' || navigation.state === 'loading';
    const isSaving = isLoading && navigation.json?.intent === intentName;
    const { handleError } = useError();
    const actionData = useActionData();
    const [showSuccess, setShowSuccess] = useState(false);

    const handleChange = (pricingGroupId, discountPercentage) => {
        let updatedOverrides = [...overrides];
        let updatedDeletes = [...deletes];

        const overriddenGroup = pricingGroups.find((group) => group.id === pricingGroupId);
        const existingOverride = overrides.find((override) => override.pricingGroupId === pricingGroupId);
        const initialOverride = initialOverrides.find((override) => override.pricingGroupId === pricingGroupId);
        const matchOriginalDiscount = Number(discountPercentage) === Number(overriddenGroup.discountPercentage);

        // Define the new override structure only once
        const newOverride = {
            pricingGroupId,
            discountPercentage,
            variantId: variant.id,
        };

        // If there's an existing override, handle removal or update
        if (existingOverride) {
            if (discountPercentage === '') {
                // Remove the existing override and add it to the delete list if needed
                updatedOverrides = updatedOverrides.filter((override) => override.pricingGroupId !== pricingGroupId);
                updatedDeletes = [
                    ...updatedDeletes.filter((deleteItem) => deleteItem.pricingGroupId !== pricingGroupId),
                    ...Object.values(initialOverrides)
                        .flat()
                        .filter((group) => group.pricingGroupId === pricingGroupId),
                ];
            } else if (discountPercentage === 0) {
                // Handle the case where the discount is explicitly set to 0
                updatedOverrides = updatedOverrides.map((override) =>
                    override.pricingGroupId === pricingGroupId ? { ...override, discountPercentage: 0 } : override
                );
                updatedDeletes = updatedDeletes.filter((deleteItem) => deleteItem.pricingGroupId !== pricingGroupId);
            } else {
                // Update the existing override with the new discount percentage
                updatedOverrides = updatedOverrides.map((override) =>
                    override.pricingGroupId === pricingGroupId ? { ...override, discountPercentage } : override
                );
                updatedDeletes = updatedDeletes.filter((deleteItem) => deleteItem.pricingGroupId !== pricingGroupId);
            }
        } else if (initialOverride) {
            // If no existing override but there is an initial override
            if (matchOriginalDiscount) {
                updatedOverrides.push(initialOverride);
            } else {
                updatedOverrides.push(newOverride);
            }
            updatedDeletes = updatedDeletes.filter((deleteItem) => deleteItem.pricingGroupId !== pricingGroupId);
        } else {
            // If no override exists, add a new one
            updatedOverrides.push(newOverride);
            updatedDeletes = updatedDeletes.filter((deleteItem) => deleteItem.pricingGroupId !== pricingGroupId);
        }

        setDeletes(updatedDeletes);
        setOverrides(updatedOverrides);
    };

    const discardChanges = () => {
        setOverrides(initialOverrides);
    };

    const handleSave = () => {
        submit(
            {
                intent: intentName,
                updates: mapOverridesForSubmit({ overrides, initialOverrides }),
                deletes: deletes.map((del) => ({ id: del.id, variantId: del.variantId })),
                productId: variant.productId,
                variantId: variant.id,
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    useEffect(() => {
        setOverrides(initialOverrides);
    }, [variantIndex]);

    useEffect(() => {
        if (actionData && navigation.state === 'loading') {
            if (actionData.error) {
                handleError(actionData.error);
            } else {
                setDeletes([]);
                setShowSuccess(true);
            }
        }
    }, [actionData]);

    useEffect(() => {
        setHasMadePricingGroupChanges(hasMadeChanges(overrides, initialOverrides));
    }, [overrides, setHasMadePricingGroupChanges, initialOverrides]);

    return (
        <>
            {!isXs && (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="label" sx={{ ...pricingGroupHeaderStyle, textAlign: 'left' }}>
                            {t('VariantPricingGroupList.labels.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="label" sx={{ ...pricingGroupHeaderStyle, textAlign: 'left' }}>
                            {t('VariantPricingGroupList.labels.percentOff')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="label" sx={{ ...pricingGroupHeaderStyle, textAlign: 'left' }}>
                            {t('VariantPricingGroupList.labels.price')}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {pricingGroups.map((group, index) => (
                <ItemPricingGroupRow
                    pricingGroup={group}
                    override={overrides.find((override) => override.pricingGroupId === group.id)}
                    itemPrice={Number(price)}
                    key={index}
                    onChange={(event) => handleChange(group.id, event.discountPercentage)}
                />
            ))}
            {hasMadeChanges(overrides, initialOverrides) ? (
                <Stack gap={2} direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button onClick={discardChanges} variant="outlined" color="secondary" sx={{ height: 40 }}>
                        {t('VariantPricingGroupList.actions.discard')}
                    </Button>
                    <LoadingButton
                        isLoading={isSaving}
                        onClick={handleSave}
                        variant="contained"
                        sx={{ height: 40, width: 100 }}>
                        {t('VariantPricingGroupList.actions.save')}
                    </LoadingButton>
                </Stack>
            ) : null}
            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {t('VariantPricingGroupList.messages.updateSuccess')}
                </Alert>
            </Snackbar>
        </>
    );
}

VariantPricingGroupList.propTypes = {
    price: PropTypes.number,
    variantIndex: PropTypes.number,
    setHasMadePricingGroupChanges: PropTypes.func,
};
