import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import * as api from '../../../api';
import {
    selectItems,
    selectOrderTotalValue,
    selectSubtotalValue,
    selectTotalQuantity,
    useCart,
} from '../../../cart/useCart';
import ProductPicker from '../../../components/ProductPicker/ProductPicker';
import BrandClearOrderDialog from './BrandClearOrderDialog';
import ManageOrderDataGrid from './ManageOrderDataGrid/ManageOrderDataGrid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BrandCreateOrderMobile from './BrandCreateOrder.mobile';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageHeader from '../../../components/PageHeader/PageHeader';

export function mapProductsToCartItems(products) {
    return products.reduce(
        (acc, product) => [
            ...acc,
            ...product.productVariants
                .filter((item) => item.quantity)
                .map(({ imageLinks = [], wholesale, ...rest }) => ({
                    ...rest,
                    price: wholesale,
                    // fallback to parent images
                    images: [...imageLinks, ...product.imageLinks],
                })),
        ],
        []
    );
}

export function loader({ request }) {
    const url = new URL(request.url);
    const query = {};
    const text = url.searchParams.get('q');
    if (text) {
        query.searchString = text;
        query.active = true;
    }
    return api.getProducts({ query, signal: request.signal });
}

export default function BrandCreateOrder(props) {
    const { state, addItem, removeItem, clearCart } = useCart();
    const navigate = useNavigate();
    const [showClearModal, setShowClearModal] = useState(false);
    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    const items = selectItems(state);
    const quantity = selectTotalQuantity(state);
    const subtotal = selectSubtotalValue(state);
    const total = selectOrderTotalValue(state);

    const handleAddProduct = (product) => {
        const item = {
            ...product,
            productVariants: product.productVariants.map((variant) => ({
                ...variant,
                quantity: 0,
            })),
        };
        // TODO should we handle this outside of cart context?
        item.expanded = true;
        addItem(item);
    };

    const handleRemoveProduct = (product) => {
        removeItem(product.id);
    };

    const handleClear = () => {
        setShowClearModal(true);
    };

    const handleClearCart = () => {
        clearCart();
        setShowClearModal(false);
    };

    const handleSubmit = () => {
        const subTotal = selectSubtotalValue(state);
        const total = selectOrderTotalValue(state);
        const order = { ...state, subTotal, total, items: mapProductsToCartItems(state.items) };
        const id = Date.now().toString();
        localStorage.setItem(`self-order-cart-${id}`, JSON.stringify(order));
        navigate(`/brand/new-order/${id}`);
        clearCart();
    };

    const hasItems = items.length > 0;
    const hasQuantity = selectTotalQuantity(state) > 0;

    if (!isSmBreakpoint) {
        return <BrandCreateOrderMobile />;
    }

    const flags = useFlags();

    return (
        <Box data-testid="BrandCreateOrder">
            {flags.isWholeshopEnabled ? <PageHeader title={t('BrandCreateOrder.title')} sx={{ mb: 3 }} /> : null}
            <Box component="header">
                <Stack
                    gap={3}
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row-reverse' },
                        justifyContent: 'space-between',
                        minWidth: 0,
                        alignItems: { sm: 'flex-end' },
                        flexWrap: 'wrap',
                    }}>
                    <Box>
                        <Stack gap={2} direction="row" justifyContent="space-between">
                            <Box sx={{ minWidth: { sm: 140 } }}>
                                <Typography textAlign="right" variant="body1">
                                    {t('BrandCreateOrder.totalQtyLabel')}
                                </Typography>
                            </Box>
                            <Box sx={{ flex: '1 0 auto', minWidth: 104 }}>
                                <Typography variant="body1" align="right">
                                    <NumericFormat
                                        displayType="text"
                                        value={quantity}
                                        thousandSeparator
                                        decimalScale={2}
                                    />
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack gap={2} direction="row" justifyContent="space-between">
                            <Box sx={{ minWidth: { sm: 140 } }}>
                                <Typography align="right" variant="body1">
                                    {t('BrandCreateOrder.subtotalValueLabel')}
                                </Typography>
                            </Box>
                            <Box sx={{ flex: '1 0 auto', minWidth: 104 }}>
                                <Typography variant="body1" align="right">
                                    <NumericFormat
                                        prefix="$"
                                        displayType="text"
                                        value={subtotal}
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider sx={{ my: 1 }} />
                        <Stack gap={2} direction="row" justifyContent="space-between">
                            <Box sx={{ minWidth: { sm: 140 } }}>
                                <Typography textAlign="right" variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {t('BrandCreateOrder.orderTotalValueLabel')}
                                </Typography>
                            </Box>
                            <Box sx={{ flex: '1 0 auto', minWidth: 104 }}>
                                <Typography align="right" variant="body1" sx={{ fontWeight: 'bold' }}>
                                    <NumericFormat
                                        prefix="$"
                                        displayType="text"
                                        value={total}
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{ flex: '1 0 auto' }}>
                        <ProductPicker
                            sx={{ display: 'inline-block', minWidth: '100%' }}
                            value={items}
                            SearchBarProps={{ sx: { maxWidth: { sm: 306 } } }}
                            placeholder={t('BrandCreateOrder.searchPlaceholderLabel')}
                            onAddProduct={handleAddProduct}
                            onRemoveProduct={handleRemoveProduct}
                        />
                    </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
            </Box>
            <Box component="section">
                <Stack gap={2} direction="row" justifyContent="space-between" flexWrap="wrap-reverse">
                    <Box sx={{ my: 1 }}>
                        {hasItems ? (
                            <Typography>
                                {items.length} {t('BrandCreateOrder.productsTitleLabel')}
                            </Typography>
                        ) : null}
                    </Box>
                    <Stack sx={{ pb: 2 }} direction="row" gap={2} justifyContent="flex-end">
                        <Button
                            size="small"
                            disabled={!hasItems}
                            color="error"
                            variant="contained"
                            onClick={handleClear}>
                            {t('BrandCreateOrder.clearLabel')}
                        </Button>
                        <Button size="small" disabled={!hasQuantity} variant="contained" onClick={handleSubmit}>
                            {t('BrandCreateOrder.submitLabel')}
                        </Button>
                    </Stack>
                </Stack>
                {!hasItems ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: { xs: 4, sm: 8, md: 24 },
                        }}>
                        <Typography textAlign="center">{t('BrandCreateOrder.emptyResultsLabel')}</Typography>
                    </Box>
                ) : (
                    <ManageOrderDataGrid
                        sx={{
                            height: 'calc(100vh - 500px)',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                    />
                )}
            </Box>
            <BrandClearOrderDialog
                open={showClearModal}
                onClose={() => setShowClearModal(false)}
                onConfirm={handleClearCart}
            />
        </Box>
    );
}
