import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useLoaderData, useNavigate } from 'react-router-dom';
import * as api from '../../api';
import { getStoredCartContents } from '../../cart/CartContext';
import { useCart } from '../../cart/useCart';
import Crumbs from '../../components/Crumbs/Crumbs.jsx';
import ProductIcon from '../../components/ProductIcon.jsx';
import ProductQuantityTextField from '../../components/ProductQuantityTextField/ProductQuantityTextField.jsx';
import SearchBar from '../../components/SearchBar/SearchBar.jsx';
import OrderSummaryCard from './OrderSummaryCard';
import { filterProducts } from './cartUtil.js';
import ClearCartDialog from './ClearCartDialog.jsx';

export async function loader({ request, params }) {
    const { id } = params;
    const cart = getStoredCartContents(`cart-${id}`);
    const businessIds = cart?.items?.map((i) => i.businessId);

    const [businesses, products] = await Promise.all([
        cart?.business && api.getBusinesses({ signal: request.signal }),
        businessIds.length > 0 &&
            api.getProducts({ query: { businessId: [...new Set(businessIds)], limit: 100 }, signal: request.signal }),
    ]);

    return {
        business: [...(businesses || [])].filter((b) => `${b.id}` === `${cart.business}`).pop(),
        products: products?.rows,
        variants: (products?.rows || []).map((i) => i.productVariants).reduce((acc, curr) => [...acc, ...curr], []),
    };
}

/**
 * @deprecated
 */
export default function Cart(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [showClearModal, setShowClearModal] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [selectedProduct, setSelectedProduct] = React.useState();
    const data = useLoaderData();
    const { business, products: parentProducts = [], variants = [] } = data || {};
    const { state, state: { items = [] } = {}, clearCart, removeItem, updateItem } = useCart();

    const products = items.map((p) => {
        const v = variants.find((v) => v.id === p.id);
        const parentProduct = parentProducts.find((i) => i.id === v?.productId);
        const images = v && v.imageLinks?.length ? v.imageLinks : parentProduct?.imageLinks;
        return {
            ...v,
            ...p,
            images,
        };
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemoveClick = (id) => {
        setOpen(true);
        setSelectedProduct(id);
    };

    const removeProduct = (id) => {
        removeItem(id);
        handleClose();
    };

    const handleContinueClick = () => {
        navigate('./checkout');
    };

    const handleClearCart = () => {
        clearCart();
        setShowClearModal(false);
    };

    const filteredProducts = filterProducts(products, search);

    return (
        <>
            <Container maxWidth="lg" sx={{ py: 1 }}>
                <Crumbs />
            </Container>
            <Container maxWidth="xl">
                <Box sx={{ mx: { lg: 9 }, mb: 10 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row-reverse' },
                            gap: { xs: 1, sm: 3, md: 6, lg: 18 },
                        }}>
                        <Box>
                            <OrderSummaryCard
                                business={state?.business && business}
                                products={products}
                                OrderSummaryCardActions={
                                    <Stack gap={1}>
                                        <Button
                                            disabled={!business || !items?.length}
                                            fullWidth
                                            variant="contained"
                                            size="small"
                                            onClick={handleContinueClick}>
                                            {t('OrderSummaryCard.buttons.continue')}
                                        </Button>
                                        <Button
                                            disabled={!business || !items?.length}
                                            fullWidth
                                            variant="contained"
                                            size="small"
                                            color="secondary"
                                            onClick={() => setShowClearModal(true)}>
                                            {t('OrderSummaryCard.buttons.clearCart')}
                                        </Button>
                                    </Stack>
                                }
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', minWidth: 0 }}>
                            <Box>
                                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <b>{t('Cart.title')}</b>
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Divider />
                                </Box>
                            </Box>
                            <Box>
                                <SearchBar
                                    placeholder={t('Cart.searchMyCartLabel')}
                                    sx={{ maxWidth: 240, mb: 2 }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <Divider />
                            </Box>
                            {filteredProducts.map((product, i) => (
                                <Card key={product.id + `${i}`} sx={{ display: 'flex' }}>
                                    <ProductIcon src={product.images?.[0]} sx={{ pl: '12px', width: '151px' }} />
                                    <CardContent sx={{ width: '100%' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexWrap: {
                                                    xs: 'wrap',
                                                    sm: 'unset',
                                                },
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                            }}>
                                            <Box>
                                                <Typography component="div" variant="h5">
                                                    <b>{product.title}</b>
                                                </Typography>
                                                {product?.variantTags?.map((t) => (
                                                    <Typography key={t.name}>{t.value}</Typography>
                                                ))}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 1,
                                                        flexWrap: 'wrap',
                                                    }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="text.secondary"
                                                        component="div">
                                                        {t('Cart.skuLabel')} {product.sku} <br />
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="text.secondary"
                                                        component="div">
                                                        {t('Cart.mpnLabel')} {product.mpn}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: { xs: 'row', sm: 'column' },
                                                    justifyContent: 'space-between',
                                                    alignItems: { xs: 'center', sm: 'unset' },
                                                    width: { xs: '100%', sm: 'unset' },
                                                    flexWrap: { xs: 'wrap', sm: 'unset' },
                                                }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                    }}>
                                                    <IconButton
                                                        sx={{ color: 'common.removeColor' }}
                                                        onClick={() => handleRemoveClick(product.id)}>
                                                        <DeleteOutline />
                                                    </IconButton>
                                                    <ProductQuantityTextField
                                                        quantity={product.quantity}
                                                        onChange={(newValue) =>
                                                            updateItem({ id: product.id, quantity: newValue })
                                                        }
                                                        sx={{ width: '110px' }}
                                                    />
                                                </Box>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Typography variant="h6">
                                                        <b>
                                                            <NumericFormat
                                                                prefix="$"
                                                                displayType="text"
                                                                value={product?.wholesale}
                                                                thousandSeparator
                                                                decimalScale={2}
                                                                fixedDecimalScale
                                                            />
                                                        </b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            ))}
                            {!filteredProducts.length && (
                                <Box>
                                    <Typography>{t('Cart.emptyLabel')}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{t('Cart.removeProductDialog.title')}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={() => removeProduct(selectedProduct)} autoFocus>
                            {t('Cart.removeProductDialog.buttonLabel')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ClearCartDialog
                    open={showClearModal}
                    onClose={() => setShowClearModal(false)}
                    onConfirm={handleClearCart}
                />
            </Container>
        </>
    );
}

Cart.propTypes = {
    businessId: PropTypes.string,
};
