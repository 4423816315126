import React from 'react';
import TruckIcon from '../../icons/TruckIcon.jsx';
import Typography from '@mui/material/Typography';
import { formatDate } from '../../routes/retail/orders/OrderDetailsHeader.jsx';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export default function ShippedOnLabel({ date }) {
    const { t } = useTranslation();
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <TruckIcon
                sx={{
                    color: 'common.LightGray',
                    width: '24px',
                    height: '24px',
                }}
            />
            <Typography
                sx={{
                    whiteSpace: 'nowrap',
                    color: 'common.LightGray',
                    fontSize: '14px',
                    fontWeight: 500,
                }}>
                {t('OrderDetailsHeader.shippedOnLabel')} {formatDate(date)}
            </Typography>
        </Stack>
    );
}

ShippedOnLabel.propTypes = {
    date: PropTypes.string.isRequired,
};
