import * as api from '../api';

const cache = {
    business: null,
};

/* eslint no-return-await: off */
export async function getBusiness(options = {}) {
    const { signal } = options;
    if (!cache.business) {
        cache.business = api.getDefaultBusiness({ signal });
    }
    return await cache.business;
}

export function clearCache() {
    cache.business = null;
}

export const _getCache = () => {
    return cache;
};
