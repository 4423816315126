import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import shopifyIcon from '../../assets/shopify-logo.svg';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch.jsx';
import Button from '@mui/material/Button';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';

export default function ShopsSelectModal(props) {
    const { open, onClose, shops = [], onContinue, isLoading } = props;
    const [shopsSelection, setShopsSelection] = useState(() => {
        return shops?.map((shop) => ({
            name: shop.shop,
            isSelected: true,
        }));
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <DialogContent>
                <Stack gap={1}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Import Products</Typography>
                    <Divider />
                    <Stack direction="row" gap={1} sx={{ alignItems: 'top' }}>
                        <Box>
                            <img src={shopifyIcon} alt="My Icon" style={{ width: '50px', height: '50px' }} />
                        </Box>
                        <Stack gap={1.5}>
                            <Stack>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Shopify</Typography>
                                <Typography sx={{ fontSize: '14px' }}>Select stores to import products from</Typography>
                            </Stack>
                            <Stack>
                                <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>Connected Stores</Typography>
                                {shopsSelection.map((shop, index) => (
                                    <ToggleSwitch
                                        key={index}
                                        isChecked={shop.isSelected}
                                        onChange={() => {
                                            const newShopsSelection = shopsSelection.map((s, i) =>
                                                i === index ? { ...s, isSelected: !s.isSelected } : s
                                            );
                                            setShopsSelection(newShopsSelection);
                                        }}
                                        label={shop.name}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Button sx={{ color: 'black', fontWeight: 'bold' }} onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            loadingColor="white"
                            isLoading={isLoading}
                            onClick={() =>
                                onContinue(shopsSelection.filter((shop) => shop.isSelected).map((shop) => shop.name))
                            }
                            variant="contained"
                            color="primary">
                            Continue
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

ShopsSelectModal.propTypes = {
    open: PropTypes.bool,
    onContinue: PropTypes.func,
    onClose: PropTypes.func,
    shops: PropTypes.array,
    isLoading: PropTypes.bool,
};
