import {
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { Form, useRouteLoaderData } from 'react-router-dom';
import { selectSubtotalValue, selectTotalQuantity, useCart } from '../../../../cart/useCart';
import BulkVariantPicker from '../../../../components/BulkVariantPicker/BulkVariantPicker.jsx';
import CustomImageGallery from '../../../../components/CustomImageGallery/CustomImageGallery.jsx';
import ArrowsOutIcon from '../../../../icons/ArrowsOutIcon.jsx';
import { Midnight, Midnight70, Olive, Tide, White } from '../../../../theme.js';
import CircleXIcon from '../../../../icons/CircleXIcon.jsx';

const CURRENCY_SYMBOL = '$';
const GALLERY_WIDTH = 545;
const PLACEHOLDER_WIDTH = 465;

export default function ProductDetailsForm(props) {
    const { t } = useTranslation();

    const { state, clearCart } = useCart();
    const data = useRouteLoaderData('retail-product-details');
    const { business = {}, product = {} } = data || {};
    const isRouteBusinessInCart = state?.business ? `${state?.business}` === `${business?.id}` : true;
    const [isAddingToCart, setIsAddingToCart] = React.useState(false);
    const [selectedVariant, setSelectedVariant] = React.useState(product?.productVariants[0]);
    const [showExpandView, setShowExpandView] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const images = selectedVariant?.imageLinks?.length ? selectedVariant?.imageLinks : product?.imageLinks;

    const handleSubmit = (event) => {
        event.preventDefault();
        if (props.onSubmit) {
            props.onSubmit(event, state);
        }
        setIsAddingToCart(true);
        setTimeout(() => {
            setIsAddingToCart(false);
            clearCart();
            setShowExpandView(false);
        }, 1000);
    };

    const handleExpandView = () => {
        setShowExpandView(true);
    };

    const formWidth = 364;

    const FormFooter = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Typography variant="textSm" sx={{ fontWeight: 500, color: Tide }}>
                        {t('ProductDetailsForm.itemsSelectedLabel', { count: selectTotalQuantity(state) })}
                    </Typography>
                    <Typography variant="textSm" sx={{ fontWeight: 700, color: Tide }}>
                        <NumericFormat
                            value={selectSubtotalValue(state)}
                            displayType={'text'}
                            thousandSeparator
                            prefix={CURRENCY_SYMBOL}
                            decimalScale={2}
                            fixedDecimalScale
                            valueIsNumericString
                        />
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, maxWidth: 200 }}>
                    <Button
                        disabled={!isRouteBusinessInCart || isAddingToCart || !selectTotalQuantity(state)}
                        type="submit"
                        variant="contained"
                        fullWidth>
                        {isAddingToCart ? t('ProductDetails.addingToCartLabel') : t('ProductDetails.addToCartLabel')}
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <input type="hidden" name="intent" value="bulk-variant-picker" />
                <Stack sx={{ mb: 4 }} direction="row" flexWrap="wrap" gap={4}>
                    <Box
                        sx={{
                            flex: '1 0 auto',
                            maxWidth: { sm: images?.length ? GALLERY_WIDTH : PLACEHOLDER_WIDTH },
                            width: '100%',
                            height: '100%',
                        }}>
                        <CustomImageGallery
                            images={images.map((src) => ({
                                original: src,
                                thumbnail: src,
                            }))}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            gap: 3,
                            flex: { xs: '1 0 auto' },
                            maxWidth: { xs: '100%', md: formWidth },
                        }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Card sx={{ pt: 1 }}>
                                <BulkVariantPicker
                                    product={product}
                                    sx={{
                                        maxHeight: { lg: 350 },
                                        overflowY: 'scroll',
                                        width: {
                                            xs: 'calc(100vw - 48px)',
                                            sm: 'unset',
                                        },
                                    }}
                                    onRowFocus={(event, row) => setSelectedVariant(row)}
                                />
                                <Box
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(event) => {
                                        if (event.key === ' ') {
                                            handleExpandView();
                                        }
                                    }}
                                    sx={{
                                        bgcolor: Olive,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 1,
                                        p: 1,
                                        '&:focus': {
                                            boxShadow: '1px 1px 10px 0px rgba(0, 51, 73, 0.45)',
                                        },
                                        '&:hover': {
                                            bgcolor: Midnight70,
                                        },
                                    }}
                                    onClick={handleExpandView}>
                                    <ArrowsOutIcon sx={{ width: 24, color: White }} />
                                    <Typography
                                        variant="textXs"
                                        sx={{
                                            color: White,
                                            textDecoration: 'underline',
                                            fontWeight: 600,
                                        }}>
                                        {t('ProductDetailsForm.expandThisViewLabel')}
                                    </Typography>
                                </Box>
                            </Card>
                            <FormFooter />
                        </Box>
                    </Box>
                </Stack>
            </Form>
            <Dialog
                open={showExpandView}
                onClose={() => setShowExpandView(false)}
                maxWidth="sm"
                fullWidth
                fullScreen={fullScreen}
                aria-labelledby="bulk-picker-dialog-title"
                PaperProps={{ component: Form, onSubmit: handleSubmit }}>
                <DialogTitle id="bulk-picker-dialog-title">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('ProductDetailsForm.bulkSelectDialogTitle')}
                        </Typography>
                        <IconButton onClick={() => setShowExpandView(false)}>
                            <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ px: { xs: 0, sm: 3 } }}>
                    <BulkVariantPicker product={product} onRowFocus={(event, row) => setSelectedVariant(row)} />
                </DialogContent>
                <DialogActions sx={{ p: 3, boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.08)' }}>
                    <Box sx={{ width: '100%' }}>
                        <FormFooter />
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}

ProductDetailsForm.propTypes = {
    onSubmit: PropTypes.func,
};
