import { OrderDiscountTypes } from '../../../constants/OrderDiscountTypes.jsx';

export function calculatePricing(order, updatedInfo = {}) {
    const {
        discountAmount = order.discountAmount,
        discountType = order.discountType,
        shippingCost = order.shippingCost,
    } = updatedInfo;

    // Calculate subtotal
    const subTotal = order.orderItems.reduce((acc, curr) => acc + Number(curr.quantity) * Number(curr.price), 0);

    // Calculate discount
    let discountTotal = 0;
    if (Number(discountAmount) > 0 && discountType) {
        discountTotal =
            discountType === OrderDiscountTypes.PERCENT
                ? Math.round(subTotal * Number(discountAmount) * 100) / 10000
                : Math.round(Number(discountAmount) * 100) / 100;
    }

    const shipping = shippingCost !== undefined ? Math.round(Number(shippingCost) * 100) / 100 : 0;

    const total = Math.round((subTotal - discountTotal + shipping) * 100) / 100;

    return {
        subTotal: Math.round(subTotal * 100) / 100,
        discountTotal,
        shipping,
        total,
    };
}
