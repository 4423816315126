import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Fog, SoftBG, Teal } from '../../theme';
import ImagesIcon from '../../icons/ImagesIcon';

export default function ProductImagePlaceholder(props) {
    const {
        Icon = () => <ImagesIcon sx={{ width: 48, height: 48, color: Teal }} {...props?.IconProps} />,
        ImageBackgroundProps,
    } = props;
    return (
        <Box
            className="image-placeholder"
            sx={{
                borderRadius: 4,
                bgcolor: SoftBG,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                aspectRatio: '1/1',
            }}
            {...props}>
            <Box
                className="image-background"
                sx={{
                    bgcolor: Fog,
                    borderRadius: 250,
                    width: 115,
                    height: 115,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                {...ImageBackgroundProps}>
                <Icon />
            </Box>
        </Box>
    );
}

ProductImagePlaceholder.propTypes = {
    Icon: PropTypes.elementType,
    IconProps: PropTypes.object,
    ImageBackgroundProps: PropTypes.object,
};
