import React from 'react';
import PropTypes from 'prop-types';
import { selectItemById, useCart } from '../../../../cart/useCart';
import OutlinedInput from '@mui/material/OutlinedInput';
import { cloneDeep } from 'lodash';

export default function ProductVariantQuantityInput(props) {
    const { state, updateItem } = useCart();
    const { renderCellParams: params } = props;
    const { row: { productId, id: variantId } = {} } = params;
    const item = selectItemById(state, productId);

    const handleOnChange = (event) => {
        const quantity = Number(event.target.value);
        const update = cloneDeep(item);
        const found = update.productVariants.find((variant) => variant.id === variantId);
        if (!found) {
            throw new Error('The product variants are in an unexpected state.');
        }
        found.quantity = quantity;
        updateItem(update);
    };

    return (
        <OutlinedInput
            data-testid="product-variant-quantity-input"
            inputProps={{ min: 0 }}
            type="number"
            sx={{ width: 56 }}
            value={params.value}
            size="micro"
            onChange={handleOnChange}
        />
    );
}

ProductVariantQuantityInput.propTypes = {
    renderCellParams: PropTypes.any.isRequired,
};
