import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductImagePlaceholder from '../ProductImagePlaceholder/ProductImagePlaceholder';
import {
    formattedPriceString,
    getPriceRange,
} from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';

const ProductMedia = (props) => {
    const { image, ...rest } = props;
    const [imgSource, setImgSource] = React.useState(image);
    const handleImageError = () => {
        setImgSource(null);
    };
    return imgSource ? (
        <CardMedia component="img" image={imgSource} onError={handleImageError} {...rest} />
    ) : (
        <ProductImagePlaceholder />
    );
};
ProductMedia.propTypes = {
    image: PropTypes.string,
    sx: PropTypes.any,
};

const widthXs = 182;
const widthMd = 220;

export default function ProductCard({ product, brandId, onClick }) {
    const { t } = useTranslation();
    return (
        <Stack direction="column" gap={2}>
            <Card
                sx={{
                    borderRadius: '16px',
                    height: { xs: widthXs, md: widthMd },
                    width: { xs: widthXs, md: widthMd },
                    /* Super Soft */
                    boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.08)',
                }}>
                <CardActionArea onClick={onClick}>
                    <ProductMedia image={product.imageLinks[0]} sx={{ aspectRatio: '1/1' }} />
                </CardActionArea>
            </Card>
            <Stack
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    gap: '6px',
                }}
                onClick={onClick}>
                <Typography
                    sx={{
                        color: 'common.LightGray',
                        fontSize: '10px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                    }}>
                    {t('ProductCard.optionsLabel', { count: product?.productVariants?.length ?? 1 })}
                </Typography>
                <Stack direction="column" gap="6px">
                    <Typography
                        title={product.title}
                        variant="body1"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: {
                                xs: widthXs,
                                md: widthMd,
                            },
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                        align="left">
                        {product.title}
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 0 }}>
                        <Typography align="left" sx={{ color: 'common.Tide', fontWeight: 600, lineHeight: 'normal' }}>
                            {formattedPriceString(getPriceRange(product).min)}
                        </Typography>
                        <Typography sx={{ color: 'common.Teal', fontSize: 12, fontWeight: 600, lineHeight: 'normal' }}>
                            {t('ProductCard.msrpLabel')}{' '}
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={product.msrp || 0}
                                decimalScale={2}
                                fixedDecimalScale
                                thousandSeparator
                            />
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

ProductCard.propTypes = {
    onClick: PropTypes.func,
    product: PropTypes.object,
    brandId: PropTypes.string,
};
