import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Midnight } from '../../theme';

export default function PageHeader({ actions, title, ...rest }) {
    return (
        <Box display="flex" justifyContent="flex-start" alignItems="center" gap={4} {...rest}>
            <Typography variant="displayMd" sx={{ color: Midnight }}>
                {title}
            </Typography>
            {actions && <Box>{actions}</Box>}
        </Box>
    );
}

PageHeader.propTypes = {
    actions: PropTypes.node,
    title: PropTypes.string,
};
