import { Avatar, Box, Button, Divider, List, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useSignOut } from '../../hooks/useSignOut';
import CaretDownIcon from '../../icons/CaretDownIcon';
import GearIcon from '../../icons/GearIcon';
import PackageIcon from '../../icons/PackageIcon';
import SignOutIcon from '../../icons/SignOutIcon';
import UserCircleIcon from '../../icons/UserCircleIcon';
import { White } from '../../theme';
import { BusinessTypes } from '../../constants/BusinessTypes';

export default function AccountMenu(props) {
    const { t } = useTranslation();
    const signOut = useSignOut();
    const root = useRouteLoaderData('root');
    const navigate = useNavigate();
    const { business } = root || {};
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const baseUrl = business?.type === BusinessTypes.BRAND ? '/brand' : '/retail';
    return (
        <>
            <Button
                aria-describedby={id}
                variant="text"
                onClick={handleClick}
                sx={{
                    backgroundColor: White,
                    borderRadius: 25,
                    display: 'inline-flex',
                    gap: 1,
                    px: '14px',
                    py: '12px',
                    boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                }}>
                <Avatar src={business?.imageLinks?.[0]} sx={{ height: 24, width: 24 }} />
                <Typography
                    component="span"
                    sx={{
                        color: 'common.Tide',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: { xs: 98, sm: 'unset' },
                        whiteSpace: 'nowrap',
                    }}>
                    {business?.name}
                </Typography>
                <CaretDownIcon sx={{ color: 'common.Teal', height: 24, width: 24 }} />
            </Button>
            <Menu
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        mt: 2,
                        width: 205,
                        borderRadius: 4,
                    },
                }}>
                <List
                    component="nav"
                    aria-label="account-menu"
                    sx={{
                        '& .MuiMenuItem-root': { fontSize: '14px', fontWeight: 600 },
                        '& .MuiListItemIcon-root': { color: 'common.Teal' },
                    }}>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate(`${baseUrl}/user-profile`);
                        }}>
                        <ListItemIcon>
                            <UserCircleIcon sx={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        {t('AccountMenu.profileLabel')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate(`${baseUrl}/orders`);
                        }}>
                        <ListItemIcon>
                            <PackageIcon sx={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        {t('AccountMenu.allOrdersLabel')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate(`${baseUrl}/settings/company-profile`);
                        }}>
                        <ListItemIcon>
                            <GearIcon sx={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        {t('AccountMenu.settingsLabel')}
                    </MenuItem>
                    <Box sx={{ px: 2, py: 1 }}>
                        <Divider />
                    </Box>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            signOut();
                        }}>
                        <ListItemIcon>
                            <SignOutIcon sx={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        {t('AccountMenu.logOutLabel')}
                    </MenuItem>
                </List>
            </Menu>
        </>
    );
}
