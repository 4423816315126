import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Amplify } from 'aws-amplify';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import * as api from './api.js';
import ErrorProvider from './components/Error/ErrorProvider.jsx';
import './i18n.js';
import './index.css';
import './assets/fonts/SalisburyBold.ttf';
import { ENVIRONMENT_VARIABLES_ENUMS as env, get } from './providers/config/config.js';
import theme from './theme.js';
import { init as initFullStory } from '@fullstory/browser';

initFullStory({ orgId: get(env.FULLSTORY_ORG_ID), debug: process.env.NODE_ENV === 'local-dev' });

Amplify.configure(get(env.AMPLIFY_CONFIG));
(async () => {
    const frontendSetup = await api.frontEndSetup();

    let LDProvider = null; // Declare LDProvider outside the try block
    try {
        const ldConfig = {
            clientSideID: get(env.LAUNCH_DARKLY_CLIENT_ID),
            context: {
                kind: 'user',
                key: 'user-key-123abc',
                anonymous: true,
            },
            options: { hash: frontendSetup.data.featureFlagHash.secureHash },
        };
        LDProvider = await asyncWithLDProvider(ldConfig);
    } catch (error) {
        console.error('Failed to initialize LaunchDarkly', error);
    }
    if (LDProvider) {
        // Check if LDProvider is initialized before rendering
        ReactDOM.createRoot(document.getElementById('root')).render(
            <LDProvider>
                <ErrorProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <App />
                    </ThemeProvider>
                </ErrorProvider>
            </LDProvider>
        );
    } else {
        console.error('Failed to load LaunchDarkly provider');
        // Optionally, render a different component or error message
    }
})();
