import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    useActionData,
    useLoaderData,
    useNavigate,
    useNavigation,
    useRouteLoaderData,
    useSubmit,
} from 'react-router-dom';
import * as api from '../../api';
import { getStoredCartContents } from '../../cart/CartContext';
import { selectItems, selectProducts, useCart } from '../../cart/useCart';
import EditShippingAddressDialog from '../../components/EditShippingAddressDialog/EditShippingAddressDialog';
import LoadingButton from '../../components/LoadingButton/LoadingButton';
import { BusinessTypes } from '../../constants/BusinessTypes';
import { OrderStatuses } from '../../constants/OrderStatuses';
import CaretCircleDownIcon from '../../icons/CaretCircleDownIcon';
import CircleXIcon from '../../icons/CircleXIcon';
import ClipboardTextIcon from '../../icons/ClipboardTextIcon';
import MapPinIcon from '../../icons/MapPinIcon';
import { Midnight, Teal, White } from '../../theme';
import RemoveOrderItemDialog from '../brand/Orders/RemoveOrderItemDialog';
import ColumnOverlayLayout from '../retail/orders/ColumnOverlayLayout';
import OrderSummaryActions from '../retail/orders/OrderSummaryActions';
import OrderSummaryDetails from '../retail/orders/OrderSummaryDetails';
import CartHeader from './CartHeader';
import CartProductSummary from './CartProductSummary';
import CartSummaryHeader from './CartSummaryHeader';
import { mapCartStateToOrder } from './cartUtil';

export async function loader({ request, params }) {
    const { id } = params;
    const url = new URL(request.url);
    const orderId = url.searchParams.get('orderId');
    const nonce = url.searchParams.get('nonce');
    const cartAction = url.searchParams.get('cartAction');

    const cart = getStoredCartContents(`cart-${id}`);
    const businessIds = cart?.items?.map((i) => i.businessId);

    let order = null;
    if (orderId) {
        order = await api.reorder(orderId, { signal: request.signal });
    }

    const [businesses, products] = await Promise.all([
        id && api.getBusinesses({ signal: request.signal }),
        // TODO come up with better solution for getting products this will break eventually (limit 100)
        businessIds?.length > 0 &&
            api.getProducts({ query: { businessId: [...new Set(businessIds)], limit: 100 }, signal: request.signal }),
    ]);

    const businessConnectionSettings = await api.getConnectionSettings({
        connectedBusinessId: id,
        signal: request.signal,
    });

    return {
        business: [...(businesses || [])].filter((b) => `${b.id}` === `${id}`).pop(),
        order,
        products: products?.rows,
        variants: (products?.rows || []).map((i) => i.productVariants).reduce((acc, curr) => [...acc, ...curr], []),
        cartAction,
        nonce,
        businessConnectionSettings,
    };
}

export const calculateSubtotal = (products) => {
    return products?.reduce((acc, curr) => acc + curr.wholesale * curr.quantity, 0) || 0;
};

export async function action({ request, params }) {
    const payload = await request.json();
    const result = await api.createOrder(payload);
    const orderId = result.id;
    return { orderId };
}

export default function CartCheckout() {
    const { t } = useTranslation();
    const submit = useSubmit();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const actionData = useActionData();
    const targetProductVariant = React.useRef(null);
    const [showRemoveItemDialog, setShowRemoveItemDialog] = React.useState(false);
    const [showEditShippingAddressDialog, setShowEditShippingAddressDialog] = React.useState(false);
    const [unavailableProducts, setUnavailableProducts] = React.useState([]);
    // TODO new design doesn't show search, but leaving logic in case we want to add it back
    // const [search, setSearch] = React.useState('');
    const isLoading = navigation.state === 'loading';
    const isSubmitting = navigation.state === 'submitting';
    const [detailsExpanded, setDetailsExpanded] = React.useState(false);

    const root = useRouteLoaderData('root');
    const data = useLoaderData();
    const { business, businessConnectionSettings, products: parentProducts = [], variants = [] } = data || {};
    const { state, state: { businessId } = {}, addPreviousOrder, clearCart, removeItem, updateAddress } = useCart();
    const items = selectItems(state, { hasQuantity: true });
    const products = items.map((p) => {
        const v = variants.find((v) => v.id === p.id);
        const parentProduct = parentProducts.find((i) => i.id === v?.productId);
        const images = v && v.imageLinks?.length ? v.imageLinks : parentProduct?.imageLinks;
        return {
            ...v,
            ...p,
            images,
        };
    });

    let paymentTermData = businessConnectionSettings?.paymentTerm;
    if (!paymentTermData) {
        paymentTermData = {
            id: businessConnectionSettings?.paymentTermId || 0,
        };
    }
    // TODO new design doesn't show search, but leaving logic in case we want to add it back
    // const filteredProducts = filterProducts(products, search);

    const order = mapCartStateToOrder(state);
    const productIds = selectProducts(state)?.map((p) => p.id) || [];

    /**
     * isEmpty verifies if the cart has not items or has no quantity
     */
    const isEmpty = !products?.length;

    React.useEffect(() => {
        if (!state?.address) {
            // TODO find primary address, for now selecting first
            const data = {
                ...root?.defaultAddresses?.[0],
                label: `${root?.business?.name}${root?.defaultAddresses?.[0].name ? ' | ' + root?.defaultAddresses?.[0].name : ''}`,
            };
            updateAddress(data);
        }
    }, [state]);

    // Update cart state from previous order
    React.useEffect(() => {
        if (data?.order && state?.nonce === data?.nonce) {
            return;
        }
        if (data?.order) {
            const items = data?.order?.orderItems
                .filter((item) => item.productVariant?.active)
                .map((item) => ({
                    wholesale: item.wholesale,
                    // override with productVariant fields
                    id: item.productVariant.id,
                    title: item.productVariant.title,
                    description: item.productVariant.description,
                    msrp: item.productVariant.msrp,
                    retailPrice: item.productVariant.retailPrice,
                    active: item.productVariant.active,
                    businessId: item.productVariant.businessId,
                    specs: item.productVariant.specs,
                    categories: item.productVariant.categories,
                    images: [
                        ...(item.productVariant?.imageLinks ?? []),
                        ...(item.productVariant.product?.imageLinks ?? []),
                    ].filter(Boolean),
                    imageLinks: item.productVariant.imageLinks,
                    mpn: item.productVariant.mpn,
                    upc: item.productVariant.upc,
                    sku: item.productVariant.sku,
                    barcode: item.productVariant.barcode,
                    thirdPartyId: item.productVariant.thirdPartyId,
                    year: item.productVariant.year,
                    minimumAdvertisePrice: item.productVariant.minimumAdvertisePrice,
                    variantTags: item.productVariant.variantTags,
                    isDefault: item.productVariant.isDefault,
                    createdAt: item.productVariant.createdAt,
                    updatedAt: item.productVariant.updatedAt,
                    productId: item.productVariant.productId,
                    variantTagOptions: item.productVariant.variantTagOptions,
                    quantity: Number(item.quantity),
                }));
            const order = {
                addresses: data.order.addresses,
                businessId: data?.order?.businessId,
                id: data.order.id,
                items: items ?? [],
            };
            const products = data.order.orderItems
                .filter((item) => item.productVariant.active)
                .map((item) => item.productVariant.product);
            // ensure products are unique by id
            const uniqueProducts = products.filter((p, index, self) => index === self.findIndex((t) => t.id === p.id));
            addPreviousOrder(order, data?.nonce, data?.cartAction, uniqueProducts);
            const inactiveOrderItems = data.order.orderItems.filter((item) => !item.productVariant.active);
            if (inactiveOrderItems.length) {
                setUnavailableProducts(inactiveOrderItems);
            }
            navigate({ search: '' }, { replace: true });
        }
    }, [data, state]);

    React.useEffect(() => {
        if (actionData?.orderId) {
            clearCart();
            navigate(`/retail/orders/${actionData?.orderId}?alert=orderCreated`);
        }
    }, [actionData]);

    const handleOnAddressChange = (event, address) => {
        updateAddress(address);
    };
    const handleOnRemoveClick = (e, id) => {
        setShowRemoveItemDialog(true);
        targetProductVariant.current = id;
    };
    const handleDeleteProduct = () => {
        removeItem(targetProductVariant.current);
        setShowRemoveItemDialog(false);
        targetProductVariant.current = null;
    };
    const handleCloseRemoveItemDialog = () => {
        setShowRemoveItemDialog(false);
    };

    const handleSubmit = () => {
        const { address } = state;
        const userId = root?.user?.user?.id;
        const retailBusinessId = root.business.type === BusinessTypes.RETAIL ? root.business.businessId : undefined;
        const payload = {
            businessId,
            createdByBusinessId: retailBusinessId,
            createdByUser: userId,
            subTotal: calculateSubtotal(products),
            retailBusinessId,
            orderItems: products.map((i) => ({
                quantity: parseInt(i.quantity),
                price: parseFloat(i.wholesale),
                description: i.description,
                title: i.title,
                productVariantId: i.id,
            })),
            addresses: [address].map((a) => ({
                label: a.label,
                name: a.name,
                street1: a.street1,
                street2: a.street2,
                street3: a.street3,
                city: a.city,
                state: a.state,
                postalCode: a.postalCode,
                countryCode: a.countryCode,
                emails: a.emails,
                phones: a.phones,
            })),
            status: OrderStatuses.IN_REVIEW,
        };
        // TODO add form validation
        submit(payload, { method: 'post', encType: 'application/json' });
    };

    return (
        <Box
            // TODO create DRY layout styles @RetailOrderDetails
            sx={{ px: { xs: 3, sm: 7 }, pt: { xs: 3, sm: 6 }, pb: { xs: detailsExpanded ? '500px' : '194px', md: 8 } }}>
            <CartHeader business={business} />
            <Stack direction="row" sx={{ my: 4 }} gap={5}>
                <Stack
                    direction="column"
                    gap={3}
                    sx={{
                        flex: '1 1 auto',
                        maxWidth: 755,
                    }}>
                    <Card sx={{ width: '100%' }}>
                        <CardHeader
                            sx={{ pt: 3, px: 3 }}
                            action={
                                state?.address ? (
                                    <Button
                                        size="small"
                                        variant="text"
                                        color="teal"
                                        onClick={() => setShowEditShippingAddressDialog(true)}>
                                        <Box component="span" sx={{ textDecoration: 'underline' }}>
                                            {t('CartCheckout.editAddressLabel')}
                                        </Box>
                                    </Button>
                                ) : null
                            }
                            title={
                                <Typography
                                    variant="textLg"
                                    sx={{ fontWeight: 700, display: 'flex', alignItems: 'center', pr: 3 }}>
                                    <MapPinIcon sx={{ color: Teal, width: 24, height: 24, mr: 1 }} />
                                    {t('CartCheckout.shippingAddressLabel')}
                                </Typography>
                            }
                        />

                        <CardContent sx={{ pt: 0, px: 4 }}>
                            {state?.address ? (
                                <Stack direction="column" gap={1}>
                                    <Typography variant="textSm" sx={{ fontWeight: 700 }}>
                                        {state?.address?.label}
                                    </Typography>
                                    <Typography variant="textSm" sx={{ fontWeight: 500, lineHeight: '140%' }}>
                                        {state?.address?.street1} <br />
                                        {state?.address?.street2 ? (
                                            <>
                                                {state?.address?.street2} <br />
                                            </>
                                        ) : null}
                                        {state?.address?.city}, {state?.address?.state} {state?.address?.postalCode}{' '}
                                        <br />
                                        {state?.address?.countryCode}
                                    </Typography>
                                </Stack>
                            ) : (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setShowEditShippingAddressDialog(true)}>
                                    {t('CartCheckout.chooseAddressLabel')}
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                    <Card sx={{ pb: 3 }}>
                        <CardHeader
                            sx={{ display: { xs: 'none', sm: 'flex' }, px: '30px', pb: 0, pt: 3 }}
                            title={
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        fontWeight: 700,
                                    }}
                                    variant="textLg">
                                    <ClipboardTextIcon />
                                    {t('CartCheckout.itemsLabel')}
                                </Typography>
                            }
                        />
                        <CardContent sx={{ px: 4 }}>
                            {productIds?.map((id, index) => (
                                <React.Fragment key={id}>
                                    {index !== 0 ? <Divider sx={{ my: '30px' }} /> : null}
                                    <CartProductSummary productId={id} onRemoveItem={handleOnRemoveClick} />
                                </React.Fragment>
                            ))}
                            {!productIds?.length ? (
                                <Box>
                                    <Typography>{t('CartCheckout.emptyLabel')}</Typography>
                                </Box>
                            ) : null}
                        </CardContent>
                    </Card>
                </Stack>
                <ColumnOverlayLayout>
                    <Card>
                        <CartSummaryHeader
                            action={
                                <IconButton
                                    sx={{ display: { md: 'none' } }}
                                    onClick={() => setDetailsExpanded(!detailsExpanded)}>
                                    <CaretCircleDownIcon sx={{ color: Midnight, width: 24, height: 24 }} />
                                </IconButton>
                            }
                            businessName={business?.name}
                        />
                        <CardContent sx={{ px: 3 }}>
                            <OrderSummaryDetails
                                order={order}
                                expanded={detailsExpanded}
                                paymentTermData={paymentTermData}
                            />
                            <OrderSummaryActions expanded={detailsExpanded}>
                                <Stack gap={1}>
                                    <LoadingButton
                                        isLoading={isSubmitting || isLoading}
                                        disabled={!state?.address || isEmpty || isSubmitting || isLoading}
                                        fullWidth
                                        color="primary"
                                        variant="contained"
                                        loadingColor={White}
                                        onClick={handleSubmit}>
                                        {t('CartCheckout.sendOrderLabel')}
                                    </LoadingButton>
                                </Stack>
                            </OrderSummaryActions>
                        </CardContent>
                    </Card>
                </ColumnOverlayLayout>
            </Stack>
            <RemoveOrderItemDialog
                open={showRemoveItemDialog}
                onClose={handleCloseRemoveItemDialog}
                onConfirm={handleDeleteProduct}
            />
            <EditShippingAddressDialog
                address={state?.address}
                open={showEditShippingAddressDialog}
                onClose={() => setShowEditShippingAddressDialog(false)}
                onChange={handleOnAddressChange}
            />
            <Dialog open={!!unavailableProducts.length} onClose={() => setUnavailableProducts([])}>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('CartCheckout.unavailableProductsLabel', { count: unavailableProducts.length })}
                        </Typography>
                        <IconButton onClick={() => setUnavailableProducts([])}>
                            <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>{t('CartCheckout.unavailableProductsMessage')}</Typography>
                    <Typography component="ul" sx={{ mt: 1, fontWeight: 500, paddingInlineStart: 2.5 }}>
                        {unavailableProducts.map((p) => (
                            <Typography component="li" key={p.id} variant="textSm">
                                <Typography component="span" variant="textSm" sx={{ fontWeight: 700 }}>
                                    {p?.productVariant?.product?.title} |{' '}
                                </Typography>
                                <Typography component="span" variant="textSm" sx={{ fontWeight: 500 }}>
                                    {p?.productVariant?.variantTags?.length
                                        ? p?.productVariant?.variantTags?.map((v) => v.value)?.join(', ')
                                        : p?.productVariant?.title}
                                </Typography>
                            </Typography>
                        ))}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => setUnavailableProducts([])}>
                        {t('CartCheckout.proceedLabel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
