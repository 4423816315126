import { Checkbox, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export function EditProductVariantOptions(props) {
    const { onChange, selected } = props;

    function handleVariantTagChange(i) {
        return (value) => {
            const v = value.target.value;
            const selectedTags = _.clone(selected.variantTags);
            selectedTags[i].value = v;
            onChange({ name: 'variantTags', value: selectedTags });
        };
    }

    function handleActiveCheckBox(changed) {
        return (event) => {
            const checked = event.target.checked;
            onChange({ name: 'active', value: checked });
        };
    }

    function handleMakeDefault(changed) {
        return (event) => {
            const checked = event.target.checked;
            onChange({ name: 'isDefault', value: checked });
        };
    }

    const options = selected?.variantTags || [];

    const Fields = options.map((option, i) => {
        return (
            <Grid key={option.name} item xs={12} sm={6}>
                <TextField
                    // pass down testing utils to MUI
                    inputProps={{ 'data-testid': 'variant-option-' + i }}
                    disabled={true}
                    error={!option.value}
                    label={option.name}
                    name="title"
                    fullWidth
                    value={option.value || ''}
                    onChange={handleVariantTagChange(i)}
                    required
                />
            </Grid>
        );
    });

    return (
        <>
            {Fields}
            <Grid item xs={12} sm={12}>
                <Box>
                    <Checkbox
                        disabled={!selected?.active}
                        inputProps={{ 'data-testid': 'variant-default' }}
                        checked={selected?.isDefault || false}
                        onClick={handleMakeDefault(selected)}
                    />
                    <span>Default</span>
                </Box>
                <Box>
                    <Checkbox
                        inputProps={{ 'data-testid': 'variant-active' }}
                        checked={selected?.active || false}
                        onClick={handleActiveCheckBox(selected)}
                    />
                    <span>Active</span>
                </Box>
            </Grid>
        </>
    );
}

EditProductVariantOptions.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.object,
};
