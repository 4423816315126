import { useAuthenticator } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import * as React from 'react';
import { redirect } from 'react-router-dom';
import * as authProvider from '../../providers/auth';
import { signIn } from 'aws-amplify/auth';
import SignIn from './SignIn.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const username = formData.get('email');
        const password = formData.get('password');
        const response = await signIn({ username, password });
        if (response.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
            localStorage.setItem('username', username);
            return redirect('/signUp/confirm');
        } else if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            return redirect('/login/newPassword');
        }
        return response;
    } catch (error) {
        return {
            error,
        };
    }
}
/**
 *
 * @param {import('react-router-dom').LoaderFunctionArgs} param
 * @returns
 */
export async function loader({ request }) {
    const isAuthenticated = await authProvider.authenticate();
    const url = new URL(request.url);
    const redirectUrl = url.searchParams.get('from');
    if (isAuthenticated) {
        return redirect(redirectUrl || '/');
    }
    return {
        redirectUrl,
    };
}

export default function Login() {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    return <Box>{authStatus !== 'authenticated' && <SignIn />}</Box>;
}
