import { Box, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Outlet, useMatches, useRouteLoaderData } from 'react-router-dom';
import AccountMenu from '../../components/AccountMenu/AccountMenu';
import { BusinessTypes } from '../../constants/BusinessTypes';
import ListIcon from '../../icons/ListIcon';
import BrandDrawer from './BrandDrawer';
import { Tide } from '../../theme';

const drawerWidth = 263;
const drawerlessRoutes = ['BrandCreateOrderDetails', 'Settings'];

export default function BrandLayout() {
    const data = useRouteLoaderData('root');
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const isMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
    const matches = useMatches();
    const isDrawerlessRoute = matches.some(({ id }) => {
        return drawerlessRoutes.includes(id);
    });

    const business = data?.business ?? {};
    const isOnboardingComplete =
        business?.type !== BusinessTypes.BRAND || business?.settings?.paymentProviderSettings?.onboardingComplete;

    return (
        <Box
            className="BrandLayout"
            data-testid="brand-layout"
            sx={{
                px: { xs: 3, md: 0 },
                pr: { md: '42px' },
                pb: { xs: 8, md: 12 },
                pt: { xs: '28px', md: '47px' },
            }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                position="relative"
                sx={{ mb: { xs: 3, md: 'unset' } }}>
                <Box>
                    <IconButton
                        onClick={() => setOpen(!open)}
                        sx={{ display: isDrawerlessRoute || isMdBreakpoint ? 'none' : undefined, p: 0 }}>
                        <ListIcon sx={{ height: 32, width: 32, color: Tide }} />
                    </IconButton>
                </Box>
                <Box sx={isMdBreakpoint ? { position: 'absolute', top: 0, right: 0, zIndex: 100 } : {}}>
                    <AccountMenu />
                </Box>
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                }}>
                {isDrawerlessRoute ? null : (
                    <Box>
                        <BrandDrawer
                            drawerWidth={drawerWidth}
                            open={open}
                            setOpen={setOpen}
                            hasAlert={!isOnboardingComplete}
                        />
                    </Box>
                )}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'background.default',
                        pl: { md: 5 },
                        width: !isDrawerlessRoute ? { xs: '100%', md: `calc(100% - ${drawerWidth}px)` } : '100%',
                    }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}
