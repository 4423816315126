import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';

export default function BrandProductEditButtons({
    isNewProduct,
    productChanged = false,
    onSave,
    onRemove,
    isSubmitting = false,
}) {
    const { t } = useTranslation();
    const btnText = isNewProduct
        ? t('BrandProductEditButtons.createProductLabel')
        : t('BrandProductEditButtons.saveChangesLabel');
    return (
        <Stack direction="row" spacing={1} sx={{ paddingBottom: 2, alignItems: 'flex-end' }}>
            <LoadingButton
                size="small"
                isLoading={isSubmitting}
                variant="contained"
                color="primary"
                sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
                onClick={onSave}>
                {btnText}
            </LoadingButton>
            {!isNewProduct && _.isFunction(onRemove) ? (
                <Button
                    size="small"
                    data-testid="archiveBtn"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    variant="contained"
                    color="destructive"
                    onClick={onRemove}>
                    Archive Product
                </Button>
            ) : null}

            {productChanged && isNewProduct ? (
                <Stack direction="row" sx={{ alignItems: 'flex-end' }}>
                    <WarningAmberIcon sx={{ color: 'red' }} />
                    <Typography sx={{ color: 'red' }}>Unsaved Changes</Typography>
                </Stack>
            ) : null}
        </Stack>
    );
}

BrandProductEditButtons.propTypes = {
    isNewProduct: PropTypes.bool.isRequired,
    productChanged: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onSave: PropTypes.func,
    onRemove: PropTypes.func,
};
