import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData } from 'react-router-dom';
import * as api from '../../../api.js';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import { useSettingsContext } from '../SettingsContext.jsx';
import HandshakeIcon from '../../../icons/HandshakeIcon.jsx';
import { Teal } from '../../../theme.js';
import Crumbs from '../../../components/Crumbs/Crumbs.jsx';
import _ from 'lodash';
import InviteBusiness from '../../../components/InviteBusiness/InviteBusiness.jsx';

export async function action({ request }) {
    const formData = await request.formData();
    const businessName = formData.get('businessName');
    const businessId = formData.get('businessId');
    const emails = JSON.parse(formData.get('emails'));

    try {
        const uniqueEmails = Array.from(new Set(emails.map((email) => email.toLowerCase())));

        const results = await Promise.all(
            uniqueEmails.map(async (email) => {
                await api.invite(businessId, email, businessName);
                return { email };
            })
        );

        return { results };
    } catch (error) {
        return { error };
    }
}

export default function Invite() {
    const rootData = useRouteLoaderData('root');
    const context = useSettingsContext();
    const business = context?.business || rootData?.business;
    const { t } = useTranslation();
    const type = business?.type === BusinessTypes.RETAIL ? 'Brand' : 'Dealer';

    return (
        <>
            <Stack gap={3} sx={{ maxWidth: 600 }}>
                <Crumbs />
                <Stack gap={3}>
                    <Stack gap={1}>
                        <Stack direction="row" gap={2}>
                            <HandshakeIcon sx={{ width: 32, height: 32, color: Teal }} />
                            <Typography variant="displaySm" fontWeight={700}>
                                {t('Invite.title', { type })}
                            </Typography>
                        </Stack>
                        <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                            {t('Invite.prompt', { type: _.toLower(`${type}s`) })}
                        </Typography>
                    </Stack>
                    <InviteBusiness />
                </Stack>
            </Stack>
        </>
    );
}
