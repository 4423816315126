import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import fullsizePng from '../assets/wholeshop-logo--icon-white.webp';

export default function WholeshopLogoIconWhite(props) {
    const { sx, ...rest } = props;
    return <Box component="img" src={fullsizePng} alt="Wholeshop logo" sx={sx} {...rest} />;
}

WholeshopLogoIconWhite.propTypes = {
    sx: PropTypes.any,
};
