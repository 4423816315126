import Box from '@mui/material/Box';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import * as authProvider from '../../providers/auth.js';
import * as businessProvider from '../../providers/businessProvider.js';
import * as userProvider from '../../providers/userProvider.js';
import Stack from '@mui/material/Stack';
import { redirect, useFetcher } from 'react-router-dom';
import WholeshopLogoIcon from '../../icons/WholeshopLogoIcon.jsx';
import { useTranslation } from 'react-i18next';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import TextField from '@mui/material/TextField';
import { Alert, LightGray, Midnight, Teal } from '../../theme.js';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField.jsx';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import Button from '@mui/material/Button';
import SignInTextBG from '../../components/SignInTextBG/SignInTextBG.jsx';
import List from '@mui/material/List';
import { validatePasswords } from '../../helpers/HelperFunctions.jsx';
import isEmpty from 'lodash/isEmpty';
import * as api from '../../api.js';
import { signIn } from 'aws-amplify/auth';
import { BusinessTypes } from '../../constants/BusinessTypes.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const password = formData.get('password');
        const firstName = formData.get('firstName');
        const lastName = formData.get('lastName');
        const url = new URL(request.url);
        const signature = url.searchParams.get('signature');
        const inviteId = url.searchParams.get('id');
        const businessType = url.searchParams.get('business-type');
        const body = {
            firstName,
            lastName,
            signature,
            inviteId,
            password,
            businessType,
        };
        const response = await api.linkNewBusiness({ body });
        const username = response.usernameToInvite;
        await signIn({ username, password });
        try {
            await api.postSignUp({ signal: request.signal });
            if (businessType === BusinessTypes.BRAND) {
                return redirect('/business/setup/stripe');
            }
        } catch (error) {
            // TODO handle server error
        }
        return redirect('/');
    } catch (error) {
        return { error };
    }
}

export default function InviteSignup() {
    const fetcher = useFetcher();
    const isLoading = fetcher.state === 'submitting' || fetcher.state === 'loading';
    const { t } = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);

    const submitButtonEnabled = () => {
        return (
            !isEmpty(firstName) &&
            !isEmpty(lastName) &&
            !isEmpty(password) &&
            !isEmpty(confirmPassword) &&
            isEmpty(passwordErrors)
        );
    };

    useEffect(() => {
        const clearCacheAndSignOut = async () => {
            await authProvider.signOut();
            businessProvider.clearCache();
            userProvider.clearCache();
        };
        clearCacheAndSignOut();
    }, []);

    // This useEffect now properly gets triggered when the password or confirmPassword changes
    useEffect(() => {
        setPasswordErrors(validatePasswords({ password, confirmPassword }));
    }, [password, confirmPassword]);

    return (
        <Stack direction="row" sx={{ height: '100vh', width: '100%' }}>
            <Stack
                sx={{
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 10,
                    mx: 5,
                }}>
                <fetcher.Form method="POST" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Stack gap={5} sx={{ alignItems: 'flex-start', flexGrow: 1 }}>
                        <WholeshopLogoIcon />
                        <Stack gap={3}>
                            <Stack>
                                <Typography variant="displayMd">{t('InviteSignUp.title')}</Typography>
                                <Typography sx={{ color: LightGray }}>{t('InviteSignUp.subtitle')}</Typography>
                            </Stack>
                            <FormInputLabel text={t('InviteSignUp.firstNameLabel')}>
                                <TextField
                                    fullWidth
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder={t('InviteSignUp.firstNamePlaceholder')}
                                />
                            </FormInputLabel>
                            <FormInputLabel text={t('InviteSignUp.lastNameLabel')}>
                                <TextField
                                    fullWidth
                                    name="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder={t('InviteSignUp.lastNamePlaceholder')}
                                />
                            </FormInputLabel>
                            <FormInputLabel text={t('InviteSignUp.passwordLabel')}>
                                <PasswordTextField
                                    fullWidth
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={t('InviteSignUp.passwordPlaceholder')}
                                    autoComplete="current-password"
                                />
                            </FormInputLabel>
                            <FormInputLabel text={t('InviteSignUp.confirmPasswordLabel')}>
                                <PasswordTextField
                                    fullWidth
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder={t('InviteSignUp.confirmPasswordPlaceholder')}
                                    autoComplete="current-password"
                                />
                            </FormInputLabel>
                            {passwordErrors.length > 0 ? (
                                <List>
                                    {passwordErrors.map((error, index) => (
                                        <Typography
                                            sx={{
                                                color: Alert,
                                            }}
                                            key={index}>
                                            {error}
                                        </Typography>
                                    ))}
                                </List>
                            ) : null}
                            {fetcher.data?.error && fetcher.state !== 'submitting' ? (
                                <Typography
                                    sx={{
                                        color: Alert,
                                        maxWidth: 300,
                                    }}>
                                    {t('InviteSignUp.errorMessage')} {fetcher.data?.error.message}
                                </Typography>
                            ) : null}
                            <Stack gap={1}>
                                <LoadingButton
                                    isLoading={isLoading}
                                    disabled={!submitButtonEnabled()}
                                    loadingColor="white"
                                    type="submit"
                                    variant="contained">
                                    {t('InviteSignUp.title')}
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            mt: 'auto',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 2,
                            paddingBottom: '20px',
                        }}>
                        <Typography sx={{ color: Teal }}>{t('InviteSignUp.signInPrompt')}</Typography>
                        <Button
                            href={'/login'}
                            variant="underlined"
                            sx={{
                                color: Midnight,
                                padding: '4px 4px',
                                minWidth: 'auto',
                                fontSize: '16px',
                            }}>
                            {t('InviteSignUp.signInButton')}
                        </Button>
                    </Stack>
                </fetcher.Form>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    display: {
                        xs: 'none',
                        sm: 'block',
                    },
                }}>
                <SignInTextBG text={t('InviteSignUp.welcomeLabel')} />
            </Box>
        </Stack>
    );
}
