import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import BusinessLogo from '../BusinessLogo.jsx';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export default function ShipToCard({ dealerInfo, onClick }) {
    const address = dealerInfo?.address;
    const dealer = dealerInfo?.dealer;

    return (
        <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Shipping address</Typography>
            <Card
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '8px',
                }}>
                <CardActionArea sx={{ padding: '16px' }} onClick={onClick}>
                    {dealer ? (
                        <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 1, flexWrap: 'wrap' }}>
                            <Stack gap={2} direction={{ xs: 'column', sm: 'row' }} sx={{ alignItems: 'flex-start' }}>
                                <Box
                                    sx={{
                                        width: '80px',
                                        height: '80px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        border: '1px solid black',
                                        alignSelf: { xs: 'center', sm: 'center' },
                                    }}>
                                    <BusinessLogo business={dealer} />
                                </Box>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{dealer.name}</Typography>
                            </Stack>
                            <Box sx={{ display: 'flex', paddingX: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <Stack gap={2} direction="row">
                                    <Chip
                                        label={address.label}
                                        color="primary"
                                        variant="filled"
                                        sx={{
                                            height: 24,
                                            fontSize: '0.75rem',
                                            borderRadius: '12px',
                                        }}
                                    />
                                    <Stack>
                                        <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                            {address.name}
                                        </Typography>
                                        <Typography sx={{ fontSize: '15px', whiteSpace: 'pre-line' }}>
                                            {`${address.street1}\n${address.city}, ${address.state}, ${address.postalCode}\n${address.countryCode}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    ) : (
                        <Typography>Select Address</Typography>
                    )}
                </CardActionArea>
            </Card>
        </Box>
    );
}

ShipToCard.propTypes = {
    dealerInfo: PropTypes.object,
    onClick: PropTypes.func,
};
