import React from 'react';
import { Switch, FormControlLabel, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

export default function ToggleSwitch({ isChecked, onChange, label, type = 'switch' }) {
    const handleChange = (event) => {
        onChange(event.target.checked);
    };

    return (
        <FormControlLabel
            control={
                type === 'check' ? (
                    <Checkbox
                        checked={isChecked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                ) : (
                    <Switch
                        checked={isChecked}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                )
            }
            label={label}
        />
    );
}

ToggleSwitch.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    type: PropTypes.string,
};
