import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LightGray, Teal } from '../../theme.js';
import PropTypes from 'prop-types';

export default function SettingsCrumbs({ routeName }) {
    return (
        <Typography variant="textTiny" sx={{ fontWeight: 800, textTransform: 'uppercase', letterSpacing: 2 }}>
            <Box component="span" sx={{ color: LightGray }}>
                Settings
            </Box>{' '}
            <Box component="span" sx={{ color: Teal }}>
                / {routeName}
            </Box>
        </Typography>
    );
}

SettingsCrumbs.propTypes = {
    routeName: PropTypes.string,
};
