import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import LoadingButton from '../../../../components/LoadingButton/LoadingButton.jsx';
import BasicModal from '../../../../components/BasicModal/BasicModal.jsx';

export default function ArchiveProductModal({ open, onClose, onArchive, isArchiving }) {
    const buttonsArray = [
        <Button
            key="1"
            onClick={onClose}
            sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            variant="contained">
            Cancel
        </Button>,
        <LoadingButton
            isLoading={isArchiving}
            key="2"
            onClick={onArchive}
            sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            variant="contained"
            color="destructive">
            Archive
        </LoadingButton>,
    ];

    const content = (
        <Typography sx={{ paddingLeft: 1, fontSize: '12px' }}>
            This product and all of its variants will no longer be available for purchase and will not be visible to
            anyone outside of your business.
        </Typography>
    );

    return (
        <BasicModal
            open={open}
            title={'Archive Product'}
            message={'Archiving this product will archive all variants.'}
            content={content}
            buttons={buttonsArray}
        />
    );
}

ArchiveProductModal.propTypes = {
    open: PropTypes.bool,
    onArchive: PropTypes.func,
    onClose: PropTypes.func,
    isArchiving: PropTypes.bool,
};
