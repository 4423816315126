import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import * as React from 'react';
import PropTypes from 'prop-types';

export default function CoverPhoto({ image }) {
    return (
        <Box
            sx={{
                display: {
                    sm: 'flex',
                    aspectRatio: '190/53',
                    maxWidth: 950,
                    minWidth: { sm: 400 },
                },
            }}>
            <Card sx={{ width: '100%', height: '100%' }}>
                <CardMedia
                    component="img"
                    image={image}
                    sx={{
                        objectFit: 'cover',
                    }}
                />
            </Card>
        </Box>
    );
}

CoverPhoto.propTypes = {
    image: PropTypes.string,
};
