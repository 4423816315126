import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as api from '../../api';
import * as businessProvider from '../../providers/businessProvider';
import CaretDownIcon from '../../icons/CaretDownIcon';
import { Teal } from '../../theme';
import { Typography } from '@mui/material';
import AddAddressDialog from '../AddAddressDialog/AddAddressDialog.jsx';
import { useError } from '../Error/ErrorProvider.jsx';

const Item = ({ children, ...props }) => (
    <MenuItem
        sx={{ maxWidth: 248, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block' }}
        {...props}>
        {children}
    </MenuItem>
);
Item.propTypes = {
    children: PropTypes.node,
};

export default function AddressSelect(props) {
    const { label, name, onChange, selectProps, defaultValue = '', placeholder } = props;
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { business, defaultAddresses } = useRouteLoaderData('root') || {};

    const addresses = defaultAddresses?.map((a, i) => ({ ...a, _id: i }));
    const index = addresses?.findIndex((i) => i._id === defaultValue?._id);
    const [value, setValue] = React.useState(index >= 0 ? index : '');
    const { handleError } = useError();
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const { value } = event.target;
        if (value === 'add') {
            setOpen(true);
            return;
        }
        setValue(value);

        if (onChange) {
            onChange(
                event,
                addresses.find((i) => i._id === value)
            );
        }
    };

    const getBusinessAddressLabel = (address) => {
        const { name, label, street1, street2, street3, city, state, postalCode, countryCode } = address;
        return `${name || ''} ${label || ''}${name || label ? ' - ' : ''}${street1} ${street2}${street3 && ' ' + street3 + ' '}${city} ${state} ${postalCode} ${countryCode}`;
    };

    const handleCloseForm = () => {
        setOpen(false);
    };
    const handleSaveAddress = async (address) => {
        setIsLoading(true);
        const formattedAddress = {
            name: address.name,
            label: address.label,
            street1: address.street1,
            street2: address.street2,
            street3: '',
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            countryCode: address.countryCode ?? 'US',
            emails: [
                {
                    default: address.email,
                },
            ],
            phones: [
                {
                    primary: address.phoneNumber,
                    secondary: address.secondaryPhoneNumber,
                },
            ],
        };
        const update = {
            ...business,
            addresses: [...business.addresses, formattedAddress],
        };

        try {
            await api.updateBusiness(business.id, update, {});
            businessProvider.clearCache();
            navigate('.', { replace: true });
            setOpen(false);
            setTimeout(() => {
                setIsLoading(false);
                const _id = business?.addresses?.length;
                setValue(_id);
                // add local id as a place holder
                formattedAddress._id = _id;
                onChange({ target: update.addresses.length - 1 }, formattedAddress);
            }, 2000);
        } catch (error) {
            setIsLoading(false);
            handleError(error);
        }
    };

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
                <InputLabel id="address-select-label">{label}</InputLabel>
                <Select
                    labelId="address-select-label"
                    id="address-select"
                    value={value}
                    onChange={handleChange}
                    label={label}
                    name={name}
                    displayEmpty
                    IconComponent={(props) => <CaretDownIcon {...props} sx={{ width: 24, height: 24, color: Teal }} />}
                    fullWidth
                    {...selectProps}>
                    <Item value="" disabled>
                        {placeholder}
                    </Item>
                    {addresses?.map((address) => (
                        <Item key={address._id} value={address._id}>
                            {getBusinessAddressLabel(address)}
                        </Item>
                    ))}
                    <Item value="add">
                        <Typography
                            sx={{ color: Teal, fontWeight: 700, textDecoration: 'underline', lineHeight: '18px' }}>
                            {t('AddressSelect.addLabel')}
                        </Typography>
                    </Item>
                </Select>
            </FormControl>
            <AddAddressDialog
                onClose={handleCloseForm}
                open={open}
                onSubmitAddress={handleSaveAddress}
                isLoading={isLoading}
            />
        </Box>
    );
}

AddressSelect.propTypes = {
    children: PropTypes.node,
    defaultValue: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    selectProps: PropTypes.any,
};
