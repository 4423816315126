import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CircleXIcon from '../../icons/CircleXIcon.jsx';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import { Tide } from '../../theme.js';
import { useTranslation } from 'react-i18next';

export default function PricingGroupEditDialog({ onDiscardAllOverrides, onKeepOverrides, onDismiss }) {
    const [selectedOption, setSelectedOption] = useState('option1');
    const { t } = useTranslation();

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDiscardOverrides = () => {
        onDiscardAllOverrides();
    };

    const handleKeepOverrides = () => {
        onKeepOverrides();
    };

    const dismiss = () => {
        onDismiss();
    };

    const radioStyle = {
        color: Tide,
        '&.Mui-checked': { color: Tide },
    };

    const applyChanges = () => {
        if (selectedOption === 'option1') {
            handleKeepOverrides();
        } else if (selectedOption === 'option2') {
            handleDiscardOverrides();
        }
    };

    return (
        <Card>
            <CardContent sx={{ padding: '24px' }}>
                <Stack gap={2}>
                    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('PricingGroupEditDialog.applyChanges')}
                        </Typography>
                        <IconButton onClick={dismiss} sx={{ padding: 0 }}>
                            <CircleXIcon />
                        </IconButton>
                    </Stack>
                    <FormControl component="fieldset">
                        <RadioGroup value={selectedOption} onChange={handleChange}>
                            <FormControlLabel
                                value="option1"
                                control={<Radio sx={radioStyle} />}
                                label={
                                    <Typography variant="textSm" sx={{ color: Tide, fontWeight: 500 }}>
                                        {t('PricingGroupEditDialog.option1')}
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                value="option2"
                                control={<Radio sx={radioStyle} />}
                                label={
                                    <Typography variant="textSm" sx={{ color: Tide, fontWeight: 500 }}>
                                        {t('PricingGroupEditDialog.option2')}
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                        <Typography variant="textSm" sx={{ my: 2, fontWeight: 700 }}>
                            {t('PricingGroupEditDialog.note')}
                        </Typography>
                    </FormControl>
                    <Stack gap={2} direction="row">
                        <Button
                            onClick={dismiss}
                            variant="outlined"
                            color="secondary"
                            sx={{ maxWidth: 465, width: '100%' }}>
                            {t('PricingGroupEditDialog.cancel')}
                        </Button>
                        <Button onClick={applyChanges} variant="contained" sx={{ maxWidth: 465, width: '100%' }}>
                            {t('PricingGroupEditDialog.applyChanges')}
                        </Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

PricingGroupEditDialog.propTypes = {
    onDiscardAllOverrides: PropTypes.func,
    onKeepOverrides: PropTypes.func,
    onDismiss: PropTypes.func,
};
