import * as api from '../api';
import { FullStory } from '@fullstory/browser';

const cache = {
    user: null,
};

/* eslint no-return-await: off */
export async function getUser(options = {}) {
    const { signal } = options;
    if (!cache.user) {
        cache.user = api.getUser({ signal });
    }
    const result = await cache.user;
    try {
        if (result?.user?.id) {
            FullStory('setIdentity', {
                uid: `${result?.user?.id}`,
                properties: {
                    email: result?.user?.username,
                    businessId: result?.user?.businessId,
                },
            });
        }
    } catch (error) {
        console.error('Failed to set identity for FullStory', error);
    }
    return result;
}

export function getUserById({ id, signal }) {
    return api.getUserById({ id, signal });
}

export function clearCache() {
    cache.user = null;
}

export const _getCache = () => {
    return cache;
};
