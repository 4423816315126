import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Midnight, Tide } from '../../theme.js';
import MapPinIcon from '../../icons/MapPinIcon.jsx';
import PhoneIcon from '../../icons/PhoneIcon.jsx';
import EnvelopeIcon from '../../icons/EnvelopeIcon.jsx';

export default function DealerAddressCell({ address }) {
    return (
        <Stack gap={1}>
            <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                <Typography variant="textSm" sx={{ fontWeight: 700, color: Midnight }}>
                    {address?.name ? `${address.name} - ` : ''} {address?.label}
                </Typography>
            </Stack>
            <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
                <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                    <MapPinIcon sx={{ width: 15, height: 15 }} />
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        {address.street1} {address.city}, {address.state} {address.postalCode}
                    </Typography>
                </Stack>
                <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                    <PhoneIcon sx={{ width: 15, height: 15 }} />
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        {address.phones?.[0].primary}
                    </Typography>
                </Stack>
            </Stack>
            <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
                <Stack gap={1} direction="row" sx={{ alignItems: 'center' }}>
                    <EnvelopeIcon sx={{ width: 15, height: 15 }} />
                    <Typography variant="textSm" sx={{ color: Tide }}>
                        {address.emails?.[0].default}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

DealerAddressCell.propTypes = {
    address: PropTypes.object.isRequired,
};
