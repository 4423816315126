import * as React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import RenderError from '../../components/Error/RenderError/RenderError.jsx';
import { useSignOut } from '../../hooks/useSignOut.js';
import { useTranslation } from 'react-i18next';

export default function RootBoundary() {
    const error = useRouteError();
    const signOut = useSignOut();
    const { t } = useTranslation();

    if (isRouteErrorResponse(error)) {
        let message = '';

        if (error.status === 400) {
            message = t('Error.malformedRequest');
        }

        if (error.status === 404) {
            message = t('Error.notFound');
        }

        if (error.status === 401) {
            message = t('Error.notAuthorized');
            signOut();
        }

        if (error.status === 503) {
            message = t('Error.apiDown');
        }

        if (error.status === 418) {
            message = '🫖';
        }

        return <RenderError message={message} error={error} />;
    }

    return <RenderError message={t('Error.somethingElseWrong')} error={error} />;
}
