import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountMenu from '../../../components/AccountMenu/AccountMenu';
import BusinessLogo from '../../../components/BusinessLogo';
import OrderStatusChip from '../../../components/OrderStatusChip/OrderStatusChip.jsx';
import PaymentDueDate from '../../../components/PaymentDueDate/PaymentDueDate.jsx';
import { OrderStatuses } from '../../../constants/OrderStatuses';
import ArrowCircleLeftIcon from '../../../icons/ArrowCircleLeftIcon.jsx';
import CalendarCheckIcon from '../../../icons/CalendarCheckIcon';
import CalendarXIcon from '../../../icons/CalendarXIcon.jsx';
import CreditCardIcon from '../../../icons/CreditCardIcon.jsx';
import { LightGray, Midnight } from '../../../theme.js';
import { PaymentStatuses } from '../../../constants/PaymentStatuses.jsx';

export const formatDate = (dateString) => {
    if (!dateString) {
        return '';
    }
    return format(new Date(dateString), 'MMM dd, yyyy');
};

export default function OrderDetailsHeader(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const paidOn = props.order?.payments?.find((p) => p.status === PaymentStatuses.COMPLETE)?.updatedAt;

    const cancelledOn =
        props?.order?.cancelledOn ??
        (props?.order?.status === OrderStatuses.CANCELLED ? props?.order?.updatedAt : null);
    const labelStyle = {
        whiteSpace: 'noWrap',
        color: LightGray,
        fontWeight: 500,
    };

    const backToOrders = () => {
        navigate('/retail/orders');
    };

    return (
        <Stack sx={{ alignItems: 'left' }}>
            <Stack
                direction={{ xs: 'column-reverse', sm: 'row' }}
                sx={{
                    justifyContent: 'space-between',
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    width: '100%',
                }}>
                <Box sx={{ height: 55, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    {!props.hideBackButton && (
                        <Button onClick={backToOrders} sx={{ color: Midnight, padding: 0 }}>
                            <Stack gap={1.5} direction="row" sx={{ alignItems: 'center' }}>
                                <ArrowCircleLeftIcon sx={{ width: 24, height: 24 }} />
                                <Typography
                                    noWrap
                                    variant="textMd"
                                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {t('OrderDetailsHeader.back')}
                                </Typography>
                            </Stack>
                        </Button>
                    )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: { xs: '100%', sm: 'auto' } }}>
                    <Box>
                        <AccountMenu />
                    </Box>
                </Box>
            </Stack>

            <Stack gap={3} sx={{ flexDirection: 'row', flexWrap: { xs: 'wrap-reverse', md: 'nowrap' } }}>
                <Stack direction="row" gap={3}>
                    <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                        <BusinessLogo
                            business={props?.business}
                            sx={{ height: 80, width: 80 }}
                            CardMediaProps={{
                                sx: {
                                    borderRadius: 4,
                                    boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                                    objectFit: 'cover',
                                },
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            variant="displayMd"
                            sx={{
                                lineHeight: 'normal',
                            }}>
                            {t('OrderDetailsHeader.title')}
                            {props?.order?.orderNumber > 0 ? props?.order?.orderNumber : props?.order?.id}
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={'8px 16px'} flexWrap="wrap">
                            <OrderStatusChip order={props?.order} />
                            <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap">
                                <CalendarCheckIcon sx={{ color: 'common.LightGray', width: '24px', height: '24px' }} />
                                <Typography
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        color: 'common.LightGray',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}>
                                    {t('OrderDetailsHeader.createdOnLabel')} {formatDate(props?.order?.createdAt)}
                                </Typography>
                            </Stack>
                            {paidOn ? (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <CreditCardIcon sx={{ color: 'common.LightGray', width: '24px', height: '24px' }} />
                                    <Typography
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            color: 'common.LightGray',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                        }}>
                                        {t('OrderDetailsHeader.paidOnLabel')} {formatDate(paidOn)}
                                    </Typography>
                                </Stack>
                            ) : null}
                            {cancelledOn ? (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <CalendarXIcon sx={{ color: 'common.LightGray', width: '18px', height: '20px' }} />
                                    <Typography variant="textSm" sx={labelStyle}>
                                        {t('OrderDetailsHeader.cancelledOnLabel')} {formatDate(cancelledOn)}
                                    </Typography>
                                </Stack>
                            ) : null}
                            <PaymentDueDate order={props?.order} />
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
}

OrderDetailsHeader.propTypes = {
    business: PropTypes.any.isRequired,
    order: PropTypes.any.isRequired,
    hideBackButton: PropTypes.bool,
};
