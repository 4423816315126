import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export default function KeyboardPlaceholder(props) {
    const { height = 320 } = props;

    const [placeholderHeight, setPlaceholderHeight] = useState(height);

    useEffect(() => {
        // Check availability of the VirtualKeyboard API
        if ('virtualKeyboard' in navigator) {
            const listener = (event) => {
                setPlaceholderHeight(event.target.boundingRect.height);
            };
            navigator.virtualKeyboard.addEventListener('geometrychange', listener);
            return () => {
                navigator.virtualKeyboard.removeEventListener('geometrychange', listener);
            };
        }
    }, []);

    return <Box height={placeholderHeight} {...props} />;
}

KeyboardPlaceholder.propTypes = {
    height: PropTypes.number,
};
