import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import CaretCircleLeftIcon from '../../icons/CaretCircleLeftIcon.jsx';
import CaretCircleRightIcon from '../../icons/CaretCircleRightIcon.jsx';
import { Midnight, White } from '../../theme.js';
import ProductImagePlaceholder from '../ProductImagePlaceholder/ProductImagePlaceholder.jsx';
import CircleXIcon from '../../icons/CircleXIcon.jsx';

export default function CustomImageGallery({ images }) {
    const theme = useTheme();
    const galleryRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const handleScreenChange = (isFullScreen) => {
        setIsFullScreen(isFullScreen);
    };

    const imagesEmpty = images === null || images === undefined || images.length === 0;
    return (
        <Box
            sx={{
                '& .image-gallery-image': {
                    aspectRatio: '1 / 1',
                    objectFit: 'contain',
                    cursor: 'pointer',
                },
                '& .image-gallery:fullscreen::backdrop, .image-gallery-content.fullscreen': {
                    backgroundColor: White,
                },
                '& .image-gallery-slides': {
                    borderRadius: 4,
                },
                '& .image-gallery-slide-wrapper': {
                    borderRadius: 4,
                    /* Super Soft */
                    boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.08)',
                },
                '& .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left': {
                    width: 70,
                },
                '& .image-gallery-slide-wrapper.image-gallery-thumbnails-left': {
                    width: 'calc(100% - 80px)',
                },
                '& .image-gallery-thumbnails-container': {
                    width: 65,
                },
                '& .image-gallery-thumbnail': {
                    borderRadius: 4,
                    width: 65,
                    height: 65,
                },
                '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus': {
                    border: `3px solid ${theme.palette.common.Midnight}`,
                },
                '& .image-gallery-thumbnail-image': {
                    aspectRatio: '1 / 1',
                    objectFit: 'cover',
                    borderRadius: '13px',
                },
            }}>
            {imagesEmpty ? (
                <ProductImagePlaceholder />
            ) : (
                <ImageGallery
                    ref={galleryRef}
                    onClick={() => {
                        galleryRef.current.toggleFullScreen();
                    }}
                    items={images.map((src) => ({
                        original: src.original,
                        thumbnail: src.thumbnail,
                    }))}
                    renderRightNav={(onClick, disabled) => (
                        <IconButton
                            sx={{ position: 'absolute', right: 16, bottom: 8, zIndex: 100 }}
                            onClick={onClick}
                            disabled={disabled}>
                            <CaretCircleRightIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                        </IconButton>
                    )}
                    renderLeftNav={(onClick, disabled) => (
                        <IconButton
                            sx={{ position: 'absolute', right: 64, bottom: 8, zIndex: 100 }}
                            onClick={onClick}
                            disabled={disabled}>
                            <CaretCircleLeftIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                        </IconButton>
                    )}
                    renderCustomControls={() =>
                        isFullScreen ? (
                            <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 100 }}>
                                <IconButton
                                    onClick={() => {
                                        galleryRef.current.toggleFullScreen();
                                    }}>
                                    <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                                </IconButton>
                            </Box>
                        ) : null
                    }
                    onScreenChange={handleScreenChange}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    thumbnailPosition={isSmBreakpoint ? 'left' : 'bottom'}
                />
            )}
        </Box>
    );
}

CustomImageGallery.propTypes = {
    images: PropTypes.array,
};
