import { Avatar, CardHeader, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '../../icons/ShoppingCartIcon';

export default function CartSummaryHeader(props) {
    const { t } = useTranslation();
    return (
        <CardHeader
            sx={{
                px: 3,
                py: 4,
                pb: '14px',
                '& .MuiCardHeader-content': { display: 'flex', flexDirection: 'column', gap: '6px' },
                ...(props?.sx || {}),
            }}
            action={props.action}
            avatar={
                <Avatar sx={{ bgcolor: 'common.SoftBG', width: 48, height: 48 }} variant="round">
                    <ShoppingCartIcon sx={{ color: 'common.Tide', width: 24, height: 24 }} />
                </Avatar>
            }
            title={<Typography variant="label">{t('CartSummaryHeader.title')}</Typography>}
            subheader={
                <Typography sx={{ lineHeight: 'normal' }} variant="displayXs">
                    {props?.businessName}
                </Typography>
            }></CardHeader>
    );
}
CartSummaryHeader.propTypes = {
    action: PropTypes.node,
    businessName: PropTypes.string,
    sx: PropTypes.object,
};
