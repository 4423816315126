export default {
    VITE_: {
        aws_project_region: 'us-east-1',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_lbh4XUtlr',
        aws_user_pools_web_client_id: '76r71f6lvgch2m7mf90fuoiq0p',
        oauth: {
            domain: 'thatch-dev.auth.us-east-1.amazoncognito.com',
        },
        aws_cognito_username_attributes: ['EMAIL'],
        aws_cognito_social_providers: [],
        aws_cognito_signup_attributes: ['EMAIL'],
        aws_cognito_mfa_configuration: 'OFF',
        aws_cognito_mfa_types: [],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [
                'REQUIRES_LOWERCASE',
                'REQUIRES_UPPERCASE',
                'REQUIRES_NUMBERS',
                'REQUIRES_SYMBOLS',
            ],
        },
        aws_cognito_verification_mechanisms: ['EMAIL'],
        aws_content_delivery_bucket: 'thatchui-20240214172200-hostingbucket-dev',
        aws_content_delivery_bucket_region: 'us-east-1',
        aws_content_delivery_url: 'https://dvva9huj0rzq5.cloudfront.net',
    },
    DEV_: {
        aws_project_region: 'us-east-1',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_lbh4XUtlr',
        aws_user_pools_web_client_id: '76r71f6lvgch2m7mf90fuoiq0p',
        oauth: {
            domain: 'thatch-dev.auth.us-east-1.amazoncognito.com',
        },
        aws_cognito_username_attributes: ['EMAIL'],
        aws_cognito_social_providers: [],
        aws_cognito_signup_attributes: ['EMAIL'],
        aws_cognito_mfa_configuration: 'OFF',
        aws_cognito_mfa_types: [],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [
                'REQUIRES_LOWERCASE',
                'REQUIRES_UPPERCASE',
                'REQUIRES_NUMBERS',
                'REQUIRES_SYMBOLS',
            ],
        },
        aws_cognito_verification_mechanisms: ['EMAIL'],
        aws_content_delivery_bucket: 'thatchui-20240214172200-hostingbucket-dev',
        aws_content_delivery_bucket_region: 'us-east-1',
        aws_content_delivery_url: 'https://dvva9huj0rzq5.cloudfront.net',
    },
    STAGING_: {
        aws_project_region: 'us-east-1',
        aws_cognito_region: 'us-east-1',
        aws_user_pools_id: 'us-east-1_ziV2gMq2W',
        aws_user_pools_web_client_id: '7cq9t4odfc3l7ouimg3s8ovu8c',
        oauth: {},
        aws_cognito_username_attributes: ['EMAIL'],
        aws_cognito_social_providers: [],
        aws_cognito_signup_attributes: ['EMAIL'],
        aws_cognito_mfa_configuration: 'OFF',
        aws_cognito_mfa_types: [],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [
                'REQUIRES_LOWERCASE',
                'REQUIRES_UPPERCASE',
                'REQUIRES_NUMBERS',
                'REQUIRES_SYMBOLS',
            ],
        },
        aws_cognito_verification_mechanisms: ['EMAIL'],
        aws_content_delivery_bucket: 'thatchui-20240214172200-hostingbucket-staging',
        aws_content_delivery_bucket_region: 'us-east-1',
        aws_content_delivery_url: 'https://d3vkn2nlrut8tx.cloudfront.net',
    },
    PROD_: {
        aws_project_region: 'us-west-2',
        aws_cognito_region: 'us-west-2',
        aws_user_pools_id: 'us-west-2_V9g4rx29L',
        aws_user_pools_web_client_id: '4rcl4cru8c8r47rsav6rg8fcn9',
        oauth: {},
        aws_cognito_username_attributes: ['EMAIL'],
        aws_cognito_social_providers: [],
        aws_cognito_signup_attributes: ['EMAIL'],
        aws_cognito_mfa_configuration: 'OFF',
        aws_cognito_mfa_types: [],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [
                'REQUIRES_LOWERCASE',
                'REQUIRES_UPPERCASE',
                'REQUIRES_NUMBERS',
                'REQUIRES_SYMBOLS',
            ],
        },
        aws_cognito_verification_mechanisms: ['EMAIL'],
        aws_content_delivery_bucket: 'thatchui-20240411223707-hostingbucket-prod',
        aws_content_delivery_bucket_region: 'us-west-2',
        aws_content_delivery_url: 'https://d1jlsmfewlq1g4.cloudfront.net',
    },
};
