import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import UserTable from '../../../components/UserTable/UserTable.jsx';
import PropTypes from 'prop-types';

export default function CompanyUsers({ users, isLoadingUsers, handleDeleteUser }) {
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                width: '100%',
            }}>
            <Stack spacing={2} justifyContent="center">
                <UserTable users={users} onDeleteUser={handleDeleteUser} isLoading={isLoadingUsers} />
            </Stack>
        </Box>
    );
}

CompanyUsers.propTypes = {
    users: PropTypes.array,
    isLoadingUsers: PropTypes.bool,
    handleDeleteUser: PropTypes.func,
};
