import React from 'react';
import { Grid, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { getPercentageRangeString, getWholesaleRangeString } from '../PricingGroupsList/pricingGroupUtils.js';

export default function VaryingProductPricingGroupRow({ product, pricingGroup }) {
    const { t } = useTranslation();
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const navigateToVariantEdit = () => {
        navigate(`/brand/products/edit-variants/${product.id}`);
    };

    const editButton = () => {
        return (
            <Button onClick={navigateToVariantEdit} sx={{ padding: 0, minWidth: 0 }}>
                <Typography variant="label">Edit</Typography>
            </Button>
        );
    };

    return (
        <Grid container spacing={{ xs: 1, sm: 2 }} alignItems="center">
            <Grid item xs={12} sm={6}>
                {isXs && (
                    <Typography variant="label" sx={{ fontSize: '14px', fontWeight: 800, textTransform: 'uppercase' }}>
                        {t('ProductPricingGroup.titleLabel')}
                    </Typography>
                )}
                <TextField
                    size="small"
                    disabled
                    value={pricingGroup.name}
                    fullWidth
                    sx={{ height: 40, fontSize: 14, fontWeight: 500 }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                {isXs && (
                    <Typography variant="label" sx={{ fontSize: '12px', fontWeight: 800, textTransform: 'uppercase' }}>
                        {t('ProductPricingGroup.percentOffLabel')}
                    </Typography>
                )}
                <TextField
                    disabled
                    value={getPercentageRangeString(product, [pricingGroup])}
                    size="small"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                                    {editButton()}
                                </Stack>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={6} sm={3}>
                {isXs && (
                    <Typography variant="label" sx={{ fontSize: '12px', fontWeight: 800, textTransform: 'uppercase' }}>
                        {t('ProductPricingGroup.priceLabel')}
                    </Typography>
                )}
                <TextField
                    disabled
                    value={getWholesaleRangeString(product, [pricingGroup])}
                    size="small"
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{editButton()}</InputAdornment>,
                    }}
                />
            </Grid>
        </Grid>
    );
}

VaryingProductPricingGroupRow.propTypes = {
    product: PropTypes.object.isRequired,
    pricingGroup: PropTypes.object.isRequired,
};
