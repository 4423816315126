import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Midnight } from '../../theme';
import ProductIcon from '../ProductIcon';

export default function ProductImageCell(props) {
    const { src, alt, label, sx = {} } = props;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: 1,
                ...sx,
            }}>
            <ProductIcon
                sx={{
                    width: 45,
                    height: 45,
                    borderRadius: 2,
                    boxShadow: '1px 1px 10px 0px rgba(0, 0, 0, 0.08)',
                    overflow: 'hidden',
                }}
                src={src}
                alt={alt}
            />
            <Typography variant="textXs" sx={{ fontWeight: 500, color: Midnight }}>
                {label}
            </Typography>
        </Box>
    );
}

ProductImageCell.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    label: PropTypes.string.isRequired,
    sx: PropTypes.object,
};
