import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../api.js';
import { AddressInputType } from '../../constants/AddressInputType.jsx';
import { getAuthToken } from '../../providers/auth.js';
import AddAddressForm from '../AddressInput/AddAddressForm.jsx';
import { useError } from '../Error/ErrorProvider.jsx';
import SearchBar from '../SearchBar/SearchBar.jsx';
import DealerListItem from './DealerListItem.jsx';

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function SelectDealerDrawer(props) {
    const { open, handleClose, dealerInfo, onDealerSelect, businessConnections } = props;
    const { t } = useTranslation();
    const [dealers, setDealers] = useState(businessConnections);
    const [selectedDealer, setSelectedDealer] = useState(dealerInfo?.dealer);
    const [, setSelectedAddress] = useState(dealerInfo?.address);
    const [createNewDealerOpen, setCreateNewDealerOpen] = useState(false);
    const [isCreatingNewDealer, setIsCreatingNewDealer] = useState(false);
    const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);

    const { handleError } = useError();

    const handleDealerSelect = (dealer) => {
        setSelectedDealer(dealer.id);
        const primaryAddress = dealer.addresses.find((address) => address.label === 'Primary') || dealer.addresses[0];
        handleAddressSelect(dealer)(primaryAddress);
    };
    const handleAddressSelect = (dealer) => (address) => {
        setSelectedAddress(address);
        if (onDealerSelect) {
            onDealerSelect({ dealer, address });
        }
    };

    const onDidCreateNewDealer = async (dealer) => {
        // Force token refresh to assure all business links in token
        await getAuthToken(true);
        setCreateNewDealerOpen(false);
        handleDealerSelect(dealer);
        onDealerSelect({ dealer, address: dealer.addresses[0] });
    };
    const onCreateNewDealer = async (newDealerData) => {
        setIsCreatingNewDealer(true);
        try {
            const user = {
                username: newDealerData.email.toLowerCase(),
                firstName: newDealerData.firstName,
                lastName: newDealerData.lastName,
            };
            const business = {
                type: 'Retail',
                name: newDealerData.name,
                addresses: [
                    {
                        name: newDealerData.name,
                        label: 'Primary',
                        street1: newDealerData.street1,
                        street2: newDealerData.street2,
                        street3: '',
                        city: newDealerData.city,
                        state: newDealerData.state,
                        postalCode: newDealerData.postalCode,
                        countryCode: newDealerData.countryCode,
                        phones: [
                            {
                                primary: newDealerData.phoneNumber.replace(/\s/g, ''),
                            },
                        ],
                        emails: [
                            {
                                default: newDealerData.email,
                            },
                        ],
                    },
                ],
            };
            const createdDealer = await api.createRetailer({ user, business }, {});
            await onDidCreateNewDealer(createdDealer);
        } catch (error) {
            handleError(error);
        } finally {
            setIsCreatingNewDealer(false);
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        if (_.isEmpty(query)) {
            return setDealers(businessConnections);
        } else {
            const filteredDealers = businessConnections.filter((dealer) => {
                return dealer.name.includes(query);
            });
            setDealers(filteredDealers);
        }
    };

    useEffect(() => {
        if (open) {
            setDealers(businessConnections);
        }
    }, [open]);

    return (
        <>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                onOpen={() => {}}
                PaperProps={{
                    sx: { pt: 1, height: 'calc(100vh - 56px)', borderTopRightRadius: 4, borderTopLeftRadius: 4 },
                }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}>
                {!createNewDealerOpen ? (
                    <Stack gap={1} flexDirection={'column'}>
                        <Stack sx={{ px: 1 }}>
                            <Stack direction="row" gap={1} sx={{ mb: 2 }} justifyContent="space-between">
                                <Box sx={{ flex: isSearchBarFocused ? '1 0 auto' : undefined }}>
                                    <SearchBar
                                        fullWidth
                                        onFocus={() => setIsSearchBarFocused(true)}
                                        onBlur={() => setIsSearchBarFocused(false)}
                                        placeholder={t('SelectDealer.searchLabel')}
                                        onChange={handleSearch}
                                    />
                                </Box>
                                {!isSearchBarFocused ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={() => setCreateNewDealerOpen(true)}>
                                        {t('SelectDealer.addDealerLabel')}
                                    </Button>
                                ) : null}
                                <Button color="primary" variant="contained" size="small" onClick={handleClose}>
                                    {t('SelectDealer.doneLabel')}
                                </Button>
                            </Stack>
                            <Box sx={{ flexGrow: 1 }}>
                                <List
                                    sx={{
                                        height: 365,
                                        overflowY: 'auto',
                                        '::-webkit-scrollbar': {
                                            width: '8px',
                                        },
                                        '::-webkit-scrollbar-thumb': {
                                            background: '#1DD75BFF',
                                            borderRadius: '4px',
                                        },
                                        '::-webkit-scrollbar-thumb:hover': {
                                            background: '#1AA64CFF',
                                        },
                                    }}>
                                    {dealers?.map((dealer) => (
                                        <DealerListItem
                                            key={dealer.id}
                                            dealerInfo={
                                                dealer.id === dealerInfo?.dealer?.id
                                                    ? dealerInfo
                                                    : { dealer, address: null }
                                            }
                                            isSelected={selectedDealer === dealer.id}
                                            onAddressSelect={handleAddressSelect(dealer)}
                                            onClick={() => handleDealerSelect(dealer)}
                                        />
                                    ))}
                                    {(!dealers || !dealers.length) && (
                                        <Box sx={{ mx: 5, my: 4, textAlign: 'center' }}>
                                            <Typography variant="subtitle1">
                                                {t('Connections.emptyStateLabel')}
                                            </Typography>
                                        </Box>
                                    )}
                                </List>
                            </Box>
                        </Stack>
                    </Stack>
                ) : (
                    <Box sx={{ px: 1 }}>
                        <AddAddressForm
                            type={AddressInputType.DEALER}
                            isCheckout={true}
                            isLoading={isCreatingNewDealer}
                            onCancel={() => setCreateNewDealerOpen(false)}
                            onConfirm={onCreateNewDealer}
                        />
                    </Box>
                )}
            </SwipeableDrawer>
        </>
    );
}

SelectDealerDrawer.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    dealerInfo: PropTypes.object,
    addressIndex: PropTypes.number,
    onDealerSelect: PropTypes.func,
    businessConnections: PropTypes.array,
};
