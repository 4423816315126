import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

/**
 *
 * @param {*} productVariants
 * @param {*} selectedOptions
 * @returns
 */
export function applyFilters(productVariants, selectedOptions) {
    return productVariants.filter((pV = {}) => {
        if (!selectedOptions) {
            return true;
        }
        const { variantTags = [] } = pV;
        const facets = Object.keys(selectedOptions);
        if (facets.length > variantTags.length) {
            return false;
        }
        return !variantTags
            .filter((tag) => facets.includes(tag.name))
            .map((tag) => selectedOptions[tag.name] === tag.value)
            .includes(false);
    });
}

export function reduceToTagOptionSets(productVariants) {
    return productVariants?.reduce(
        (a, c) => ({
            ...c.variantTags.reduce((acc, curr) => {
                const name = curr.name;
                const set = acc[name] || new Set();
                return {
                    ...acc,
                    [name]: set.add(curr.value),
                };
            }, a),
        }),
        {}
    );
}

const PREVENT_BAD_OPTION_SELECTION_BY_CLEARING_SUBSEQUENT_OPTIONS = false;
export function useProduct() {
    /**
     * shape of selectedOptions example:
     * ```
     * { "Color": "Red", "Size": "XL", etc.. }
     * ```
     */
    const [selectedOptions, _setSelectedOptions] = useState({});

    /**
     * Clears subsequent selected options if previous option changes
     * This was originally added to prevent the UI from getting into a bad state when selecting variant options
     */
    const setSelectedOptions = (options) => {
        let update = options;

        if (PREVENT_BAD_OPTION_SELECTION_BY_CLEARING_SUBSEQUENT_OPTIONS) {
            // find the index of the first changed key and clear the subsequent items
            const prevSelectedKeys = Object.keys(selectedOptions);
            const index = prevSelectedKeys.findIndex((key) => options[key] !== selectedOptions[key]);

            if (index > -1) {
                update = Object.keys(options)
                    .slice(0, index + 1)
                    .reduce((acc, key) => ({ ...acc, [key]: options[key] }), {});
            }
        }
        _setSelectedOptions(update);
    };

    const data = useLoaderData();
    const { product, product: { productVariants = [] } = {} } = data || {};
    const defaultVariant = productVariants.find(({ isDefault }) => isDefault) || productVariants[0];

    useEffect(() => {
        if (defaultVariant) {
            const options = defaultVariant?.variantTags.reduce(
                (acc, { name, value }) => ({
                    ...acc,
                    [name]: value,
                }),
                {}
            );
            setSelectedOptions(options);
        }
    }, [defaultVariant]);

    /** A map of the sets of all variant tags by name */
    const productVariantTagOptionSets = reduceToTagOptionSets(productVariants);

    /**
     * `variantOptions` is calculated result of which options to display based on the selected options
     */
    const variantOptions = Object.keys(productVariantTagOptionSets).map((key) => {
        const selections = Object.keys(selectedOptions);
        let options = selectedOptions;
        if (selections.includes(key)) {
            // apply the proceding selections to current key options
            options = selections
                .slice(0, selections.indexOf(key))
                .reduce((acc, curr) => ({ ...acc, [curr]: selectedOptions[curr] }), {});
        }
        const filteredProductVariants = applyFilters(productVariants, options);
        const set = reduceToTagOptionSets(filteredProductVariants)[key] ?? [];

        return {
            name: key,
            values: Array.from(set).map((value) => ({
                name: key,
                value,
            })),
        };
    });

    // The set of product variants that match the selected options
    const filteredProductVariants = applyFilters(productVariants, selectedOptions);
    // Is there only 1 filtered product?
    const matchingProduct = filteredProductVariants.length === 1 ? filteredProductVariants[0] : null;
    // Are all required options selected? (assuming that all options must be selected)
    const hasSelectedAllVariantOptions =
        Object.keys(productVariantTagOptionSets || {}).length === Object.keys(selectedOptions || {}).length;

    // Whether the product can be added to the cart
    const isOrderable =
        !productVariants.length || !!(productVariants.length && matchingProduct && hasSelectedAllVariantOptions);

    const images =
        matchingProduct && matchingProduct.imageLinks?.length ? matchingProduct.imageLinks : product?.imageLinks;
    return {
        isOrderable,
        // this is an abstraction, intended to be the "displayed product"
        product: matchingProduct || product,
        images,
        selectedOptions,
        setSelectedOptions,
        variantOptions,
    };
}
