import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './routes/router';
import AppLoading from './routes/AppLoading';
import { LicenseInfo } from '@mui/x-license-pro';
import { get, ENVIRONMENT_VARIABLES_ENUMS as env } from './providers/config/config';
import * as Sentry from '@sentry/react';

if (get(env.SENTRY_STAGE) !== 'local') {
    Sentry.init({
        dsn: get(env.SENTRY_DSN),
        environment: get(env.SENTRY_STAGE),
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        tracingOrigins: ['https://app-dev.vmibythatch.com', 'https://devapi.vmibythatch.com', 'localhost'],
        tracePropagationTargets: [
            'localhost',
            // 'https://devapi.vmibythatch.com/core' // Temporarily removing this until in thatch-api Access-Control-Allow-Headers supports "baggage" and "sentry-trace"
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

export default function App() {
    LicenseInfo.setLicenseKey(get(env.MATERIAL_UI_LICENCE_KEY));
    return <RouterProvider router={router} fallbackElement={<AppLoading />} />;
}
