/**
 * Enums representing the UserProfileForm input types
 */
export const UserProfileInputTypes = {
    FIRSTNAME: 'firstName',
    LASTNAME: 'lastName',
    POSITION: 'position',
    EMAIL: 'email',
    PHONE: 'phone',
    SECONDARYPHONE: 'secondaryPhone',
};
