import { createTheme } from '@mui/material/styles';
import defaultTheme from './theme';

export default createTheme(defaultTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 700,
                    fontSize: '16px',
                    fontStyle: 'normal',
                    padding: '13px 24px',
                    letterSpacing: '0.75px',
                    lineHeight: '24px',
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: { variant: 'contained', color: 'primary' },
                    style: {
                        backgroundColor: defaultTheme.palette.common.Midnight,
                        border: `1px solid ${defaultTheme.palette.common.Midnight}`,
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        color: defaultTheme.palette.common.White,
                        '&:hover': {
                            backgroundColor: defaultTheme.palette.common.Midnight70,
                            border: `1px solid ${defaultTheme.palette.common.Midnight70}`,
                        },
                        '&:focus, &:active': {
                            /* Midnight Shadow  */
                            boxShadow: '1px 1px 10px 0px rgba(0, 51, 73, 0.45)',
                        },
                        '&:disabled': {
                            backgroundColor: defaultTheme.palette.common.Midnight20,
                            border: `1px solid ${defaultTheme.palette.common.Midnight20}`,
                            color: defaultTheme.palette.common.White,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'destructive' },
                    style: {
                        backgroundColor: defaultTheme.palette.common.Alert,
                        border: `1px solid ${defaultTheme.palette.common.Alert}`,
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        color: defaultTheme.palette.common.White,
                        '&:hover': {
                            backgroundColor: defaultTheme.palette.common.Alert70,
                            border: `1px solid ${defaultTheme.palette.common.Alert70}`,
                            color: defaultTheme.palette.common.White,
                            /* Alert Shadow */
                            boxShadow: '1px 1px 10px 0px rgba(214, 46, 79, 0.30)',
                        },
                        '&:focus, &:active': {
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EFABB9',
                        },
                        '&:disabled': {
                            backgroundColor: defaultTheme.palette.common.Alert40,
                            border: `1px solid ${defaultTheme.palette.common.Alert40}`,
                            color: defaultTheme.palette.common.White,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'white' },
                    style: {
                        backgroundColor: defaultTheme.palette.common.White,
                        border: `1px solid ${defaultTheme.palette.common.White}`,
                        boxShadow: 'unset',
                        color: defaultTheme.palette.common.Midnight,
                        '&:hover': {
                            backgroundColor: defaultTheme.palette.common.Midnight2,
                            border: `1px solid ${defaultTheme.palette.common.Midnight2}`,
                            color: defaultTheme.palette.common.Midnight70,
                        },
                        '&:focus, &:active': {
                            backgroundColor: defaultTheme.palette.common.White,
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #B2C2C8',
                        },
                        '&:disabled': {
                            opacity: '0.3',
                            backgroundColor: defaultTheme.palette.common.Midnight2,
                            border: `1px solid ${defaultTheme.palette.common.Midnight2}`,
                            color: defaultTheme.palette.common.Midnight50,
                        },
                    },
                },
                {
                    props: { variant: 'outlined', color: 'secondary' },
                    style: {
                        backgroundColor: defaultTheme.palette.common.White,
                        border: `2px solid ${defaultTheme.palette.common.Midnight}`,
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        color: defaultTheme.palette.common.Midnight,
                        '&:hover': {
                            backgroundColor: defaultTheme.palette.common.White,
                            border: `2px solid ${defaultTheme.palette.common.Midnight70}`,
                            color: defaultTheme.palette.common.Midnight70,
                            /* Midnight Shadow  */
                            boxShadow: '1px 1px 10px 0px rgba(0, 51, 73, 0.45)',
                        },
                        '&:focus, &:active': {
                            backgroundColor: defaultTheme.palette.common.White,
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #E5EBED',
                        },
                        '&:disabled': {
                            backgroundColor: 'unset',
                            border: `2px solid ${defaultTheme.palette.common.Midnight20}`,
                            color: defaultTheme.palette.common.Midnight20,
                        },
                    },
                },
                {
                    props: { variant: 'outlined', color: 'destructive' },
                    style: {
                        backgroundColor: defaultTheme.palette.common.White,
                        border: `2px solid ${defaultTheme.palette.common.Alert}`,
                        /* Shadow/xs */
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                        color: defaultTheme.palette.common.Alert,
                        '&:hover': {
                            border: `2px solid ${defaultTheme.palette.common.Alert70}`,
                            color: defaultTheme.palette.common.Alert70,
                            /* Alert Shadow */
                            boxShadow: '1px 1px 10px 0px rgba(214, 46, 79, 0.30)',
                        },
                        '&:focus, &:active': {
                            backgroundColor: defaultTheme.palette.common.White,
                            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #EFABB9',
                        },
                        '&:disabled': {
                            backgroundColor: 'unset',
                            border: `2px solid ${defaultTheme.palette.common.Alert40}`,
                            color: defaultTheme.palette.common.Alert40,
                        },
                    },
                },
                {
                    props: { variant: 'outlined', color: 'white' },
                    style: {
                        backgroundColor: 'unset',
                        border: `2px solid ${defaultTheme.palette.common.White}`,
                        color: defaultTheme.palette.common.White,
                        '&:hover': {
                            border: `2px solid ${defaultTheme.palette.common.Midnight4}`,
                            color: defaultTheme.palette.common.Midnight4,
                        },
                        '&:focus, &:active': {
                            backgroundColor: defaultTheme.palette.common.Midnight,
                            boxShadow:
                                '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(229, 235, 237, 0.60)',
                        },
                        '&:disabled': {
                            opacity: '0.2',
                            backgroundColor: 'unset',
                            border: `2px solid ${defaultTheme.palette.common.Midnight2}`,
                            color: defaultTheme.palette.common.Midnight4,
                        },
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        fontSize: '14px',
                        letterSpacing: '0.5px',
                        lineHeight: '20px',
                        padding: '10px 24px',
                    },
                },
                {
                    /**
                     * @alias small
                     */
                    props: { size: 'sm' },
                    style: {
                        fontSize: '14px',
                        letterSpacing: '0.5px',
                        lineHeight: '20px',
                        padding: '10px 24px',
                    },
                },
                {
                    props: { size: 'xs' },
                    style: {
                        fontSize: '12px',
                        letterSpacing: '0.5px',
                        lineHeight: '16px',
                        padding: '8px 16px',
                        '& .MuiSvgIcon-root': {
                            fontSize: '16px',
                        },
                    },
                },
            ],
        },
        MuiInputBase: {
            styleOverrides: {
                multiline: {
                    '&.MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: '16px',
                        },
                    },
                },
            },
        },
    },
});
