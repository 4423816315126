import React from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import ProductIcon from '../ProductIcon.jsx';
import Typography from '@mui/material/Typography';

export default function ShopifyInventoryMismatch({ inventoryData }) {
    const { mismatch, orderItem } = inventoryData;
    return (
        <Stack direction="row" gap={2}>
            <ProductIcon src={orderItem.productVariant.imageLinks?.[0]} sx={{ width: '50px', height: '50px' }} />
            <Stack sx={{ alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Order Qty</Typography>
                <Typography>{orderItem.quantity}</Typography>
            </Stack>
            <Stack sx={{ alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Shopify Qty</Typography>
                <Typography>{orderItem.quantity - mismatch}</Typography>
            </Stack>
        </Stack>
    );
}

ShopifyInventoryMismatch.propTypes = {
    inventoryData: PropTypes.object,
};
