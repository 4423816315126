import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function PencilSimpleIcon(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="PencilSimple">
                <path
                    id="Vector"
                    d="M21.3103 6.87866L17.1216 2.68897C16.9823 2.54965 16.8169 2.43913 16.6349 2.36373C16.4529 2.28832 16.2578 2.24951 16.0608 2.24951C15.8638 2.24951 15.6687 2.28832 15.4867 2.36373C15.3047 2.43913 15.1393 2.54965 15 2.68897L3.43969 14.2502C3.2998 14.389 3.18889 14.5542 3.11341 14.7362C3.03792 14.9183 2.99938 15.1135 3.00001 15.3105V19.5002C3.00001 19.898 3.15804 20.2796 3.43935 20.5609C3.72065 20.8422 4.10218 21.0002 4.50001 21.0002H8.6897C8.88675 21.0009 9.08197 20.9623 9.26399 20.8868C9.44602 20.8113 9.61122 20.7004 9.75001 20.5605L21.3103 9.00022C21.4496 8.86093 21.5602 8.69556 21.6356 8.51355C21.711 8.33153 21.7498 8.13645 21.7498 7.93944C21.7498 7.74243 21.711 7.54735 21.6356 7.36534C21.5602 7.18333 21.4496 7.01795 21.3103 6.87866ZM8.6897 19.5002H4.50001V15.3105L12.75 7.06053L16.9397 11.2502L8.6897 19.5002ZM18 10.189L13.8103 6.00022L16.0603 3.75022L20.25 7.93897L18 10.189Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}

PencilSimpleIcon.propTypes = {
    sx: PropTypes.any,
};
