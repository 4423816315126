import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useNavigation, useRouteLoaderData, useSubmit } from 'react-router-dom';
import OrderNote from '../../../components/OrderNote/OrderNote';
import OrderNoteInput from '../../../components/OrderNoteInput/OrderNoteInput';
import LoadingButton from '../../../components/LoadingButton/LoadingButton.jsx';

export default function OrderCommentsCard(props) {
    const { order } = props;
    const submit = useSubmit();
    const { t } = useTranslation();
    const root = useRouteLoaderData('root') || {};
    const { thatchUser } = root || {};
    const [newOrderNote, setNewOrderNote] = React.useState('');
    const navigation = useNavigation();
    const isLoading = navigation.state === 'submitting' || navigation.state === 'loading';
    const isCreatingNote = isLoading && navigation.json?.intent === 'createNote';

    const handleInputNoteOnChange = (e) => {
        setNewOrderNote(e.target.value);
    };

    const handleInputNoteOnSubmit = (e) => {
        e.preventDefault();
        if (!newOrderNote || !newOrderNote.trim().length) {
            return;
        }
        const formData = {
            intent: 'createNote',
            businessId: order?.businessId,
            content: newOrderNote,
            createdByBusinessId: thatchUser?.businessId,
            createdByUser: thatchUser?.id,
            orderId: order?.id,
        };
        submit(formData, { method: 'post', encType: 'application/json' });
        setNewOrderNote('');
    };

    // map in business names
    const notes = order?.orderNotes?.map((n) => {
        return { ...n, createdByBusinessName: root?.businessesByBusinessId?.[n.createdByBusinessId]?.name };
    });

    return (
        <Card sx={props.sx}>
            <CardHeader
                sx={{ px: 3, pt: 3, '& .MuiCardHeader-content': { display: 'flex' } }}
                title={
                    <Typography variant="label" sx={{ fontSize: 12, fontWeight: 800 }}>
                        {t('RetailOrderDetails.commentsLabel')}
                    </Typography>
                }
            />
            <CardContent sx={{ pt: 0, px: 3 }}>
                <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
                    {notes?.map((note, index) => (
                        <OrderNote component="li" key={index} note={note} />
                    ))}
                </Box>
                <Form onSubmit={handleInputNoteOnSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 2 }}>
                        <OrderNoteInput
                            value={newOrderNote}
                            onChange={handleInputNoteOnChange}
                            fullWidth
                            multiline
                            placeholder={t('OrderNote.placeholder')}
                        />
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Box>
                                <LoadingButton
                                    isLoading={isCreatingNote}
                                    size="xs"
                                    variant="outlined"
                                    color="secondary"
                                    type="submit">
                                    {t('OrderNote.submitButtonLabel')}
                                </LoadingButton>
                            </Box>
                            <Box>
                                <Button size="xs" onClick={() => setNewOrderNote('')}>
                                    {t('OrderNote.clearButtonLabel')}
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Form>
            </CardContent>
        </Card>
    );
}

OrderCommentsCard.propTypes = {
    order: PropTypes.any,
    sx: PropTypes.any,
};
