import { Box, Chip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCart } from '../../cart/useCart';
import { Fog, Midnight, Alert as Red, sanSarifFont, SoftBG, Tide } from '../../theme';
import QuantityInput from '../QuantityInput/QuantityInput';
import GroupedDataGrid from './GroupedDataGrid';
import ProductImageCell from './ProductImageCell';
import { useGroupedProducts } from './useGroupedProducts';
import { NumericFormat } from 'react-number-format';
import { getPriceRange } from '../../routes/brand/Products/ProductVariants/ProductVariantTable/productUtil.js';

const CURRENCY_SYMBOL = '$';

export default function BulkVariantPicker(props) {
    const { product, sx = {}, onRowFocus } = props;
    const { t } = useTranslation();
    const { state: cartState, addItem, updateItem } = useCart();
    const { groupedProducts, variantOptions } = useGroupedProducts(product);

    const handleQuantityChange = (variant) => (event) => {
        const existing = cartState.items.findIndex((item) => item.id === variant.id);
        if (existing > -1) {
            updateItem({ ...variant, quantity: Number(event.target.value) });
        } else {
            addItem({ ...variant, quantity: Number(event.target.value) });
        }
    };

    const optionsWithColor = [];
    const optionsWithSize = [];
    const others = [];
    variantOptions.forEach((option) => {
        if (option.label.toLowerCase().includes('color')) {
            optionsWithColor.push(option);
        } else if (option.label.toLowerCase().includes('size')) {
            optionsWithSize.push(option);
        } else {
            others.push(option);
        }
    });

    const wholesaleRange = () => getPriceRange(props?.product);
    const shouldHideVariantPrices = wholesaleRange().min === wholesaleRange().max;

    const priceChip = (wholesale) => {
        return (
            <Chip
                sx={{
                    fontSize: 10,
                    fontWeight: 600,
                    py: 0.5,
                    bgcolor: Fog,
                    border: `1px solid ${Tide}`,
                    height: 'unset',
                    '& .MuiChip-label': {
                        lineHeight: 'normal',
                        padding: '0 8px',
                    },
                }}
                label={
                    <NumericFormat
                        value={wholesale}
                        thousandSeparator
                        displayType="text"
                        prefix={CURRENCY_SYMBOL}
                        decimalScale={2}
                        fixedDecimalScale
                        valueIsNumericString
                    />
                }
            />
        );
    };

    const columns = [
        {
            field: 'image',
            headerName: optionsWithColor?.[0] ? optionsWithColor?.[0]?.label : '',
            renderCell: ({ group, row }) => {
                const { data, index } = row;
                const variant = data;
                return index < 1 ? (
                    <ProductImageCell src={variant?.images?.[0]} alt={variant.label} label={group?.data?.label} />
                ) : (
                    <Box className="placeholder" />
                );
            },
            rowHeader: true,
        },
        ...optionsWithSize.map((option) => ({
            field: option.label,
            headerName: option.label,
            // TODO render initial row with wholesale price chip
            renderCell: ({ row: { data, index } = {} }) => {
                const label = data.variantTags.find((tag) => tag.name === option.label)?.value;
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
                        <Typography variant="textXs" sx={{ fontWeight: 500 }}>
                            {label}
                        </Typography>
                    </Box>
                );
            },
        })),
        ...others.map((option) => ({
            field: option.label,
            headerName: option.label,
        })),
        {
            field: 'Price',
            headerName: '',
            renderCell: ({ row: { data } = {} }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        {shouldHideVariantPrices ? null : priceChip(data.wholesale)}
                    </Box>
                );
            },
        },
        {
            field: 'quantity',
            headerName: t('BulkVariantPicker.quantityLabel'),
            isStickyColumn: true,
            renderCell: ({ row: { data } = {} }) => {
                const existing = cartState.items.findIndex((item) => item.id === data.id);
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <QuantityInput
                            sx={{
                                color: Midnight,
                                fontFamily: sanSarifFont,
                                lineHeight: 'unset',
                                width: 32,
                                '& input': {
                                    fontSize: 10,
                                    fontWeight: 700,
                                    padding: '6px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 32,
                                    minHeight: 28,
                                    backgroundColor: SoftBG,
                                },
                                '& .QuantityInput-inputBorder': {
                                    border: `1px solid ${Fog}`,
                                    borderRadius: 1,
                                    overflow: 'hidden',
                                    '&.error': {
                                        borderColor: Red,
                                    },
                                },
                            }}
                            clearZeroValueOnFocus
                            value={existing > -1 ? cartState.items[existing].quantity : 0}
                            hideButtons
                            min={0}
                            max={999}
                            onChange={handleQuantityChange(data)}
                            onFocus={(e) => onRowFocus(e, data)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <Box className="BulkVariantPicker" sx={{ ...sx }}>
            <GroupedDataGrid columns={columns} rows={groupedProducts} />
        </Box>
    );
}

BulkVariantPicker.propTypes = {
    onRowFocus: PropTypes.func,
    product: PropTypes.object.isRequired,
    sx: PropTypes.object,
};
