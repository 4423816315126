import { Box, Collapse, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import PoweredByThatch from '../../../components/PoweredByThatch';

export default function OrderSummaryActions(props) {
    const { children, collapseChildren = true, expanded, ...rest } = props;
    const theme = useTheme();
    const isMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box {...rest}>
            {!collapseChildren ? <Box sx={{ py: '30px' }}>{children}</Box> : null}
            <Collapse in={isMdBreakpoint || expanded}>
                {collapseChildren ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: '30px' }}>{children}</Box>
                ) : null}
                <PoweredByThatch />
            </Collapse>
        </Box>
    );
}

OrderSummaryActions.propTypes = {
    children: PropTypes.node,
    collapseChildren: PropTypes.node,
    expanded: PropTypes.bool,
    sx: PropTypes.object,
};
