import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export default function EditCardWrapper({
    action,
    actionText = '',
    title,
    children,
    sx = { padding: 2, marginBottom: 2 },
}) {
    return (
        <Stack gap={1}>
            <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                    {title}
                </Typography>
                {action ? (
                    <Button
                        size={'small'}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        id="show-archive"
                        component="span"
                        onClick={action}>
                        {actionText}
                    </Button>
                ) : (
                    ''
                )}
            </Stack>
            <Paper sx={sx}>{children}</Paper>
        </Stack>
    );
}

EditCardWrapper.propTypes = {
    action: PropTypes.func,
    actionText: PropTypes.string,
    title: PropTypes.string,
    sx: PropTypes.object,
    children: PropTypes.node,
};
