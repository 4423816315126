import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Avatar, Typography, Button } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import React, { useState } from 'react';
import CategoryIcon from '@mui/icons-material/Category';
import { BoxShadow, Teal, Tide } from '../../../../theme.js';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Stack from '@mui/material/Stack';

export function OrderItems(props) {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const orderItems = props?.order?.orderItems || [];
    const products = _(orderItems)
        .groupBy((item) => item.productVariant.product.id)
        .map((variants, productId) => ({
            product: variants[0].productVariant.product,
            productVariants: variants.map((item) => item.productVariant),
        }))
        .value();
    const visibleProducts = showAll ? products : products.slice(0, 3);
    const remainingItemCount = orderItems.length - 3;
    const buttonStyle = {
        textDecoration: 'underline',
        color: Tide,
        textUnderlineOffset: '3px',
    };

    const handleToggleShowAll = () => {
        setShowAll(!showAll);
    };

    return (
        <Box>
            {visibleProducts.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Avatar
                            sx={{ boxShadow: BoxShadow, marginRight: '10px', width: 64, height: 64 }}
                            src={item?.product?.imageLinks?.[0] ?? ''}
                            alt={item?.product?.title}
                            variant="rounded">
                            <CategoryIcon />
                        </Avatar>
                        <Stack>
                            <Typography variant="textSm" sx={{ fontWeight: '500' }}>
                                {item?.product?.title}
                            </Typography>
                            <Typography variant="textXs" sx={{ fontWeight: '400', color: Teal }}>
                                {t('OrderItems.variantsLabel', { count: item?.productVariants?.length })}
                            </Typography>
                        </Stack>
                    </Box>
                    <Typography sx={{ fontWeight: '500' }}>
                        <NumericFormat
                            prefix="$"
                            displayType="text"
                            value={item.product.wholesale}
                            decimalScale={2}
                            fixedDecimalScale
                            thousandSeparator=","
                        />
                    </Typography>
                </Box>
            ))}
            {remainingItemCount > 0 && !showAll && (
                <Button onClick={handleToggleShowAll} sx={{ padding: 0 }}>
                    <Typography variant="textSm" sx={buttonStyle}>
                        {t('OrderItems.moreButton', { number: remainingItemCount })}
                    </Typography>
                </Button>
            )}
            {showAll && (
                <Button onClick={handleToggleShowAll} sx={{ padding: 0 }}>
                    <Typography variant="textSm" sx={buttonStyle}>
                        {t('OrderItems.showLessButton')}
                    </Typography>
                </Button>
            )}
        </Box>
    );
}

OrderItems.propTypes = {
    business: PropTypes.any,
    order: PropTypes.shape({
        orderItems: PropTypes.arrayOf(
            PropTypes.shape({
                productVariant: PropTypes.shape({
                    imageLinks: PropTypes.arrayOf(PropTypes.string),
                    title: PropTypes.string,
                }),
                price: PropTypes.string,
            })
        ),
    }),
};
