import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { useMediaQuery, useTheme } from '@mui/material';
import { Midnight } from '../../theme.js';

function ThatchDrawer({ open, onClose, children, drawerWidth }) {
    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Drawer
            variant={isSmBreakpoint ? 'permanent' : 'temporary'}
            open={isSmBreakpoint || open}
            onClose={onClose}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    borderRadius: '16px',
                    backgroundColor: Midnight,
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    position: 'fixed',
                    left: 0,
                    top: 'auto',
                    zIndex: 1,
                },
            }}
            anchor="left">
            {children}
        </Drawer>
    );
}

ThatchDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    drawerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ThatchDrawer;
