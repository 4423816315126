import React from 'react';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Fog, Midnight, Teal, Tide } from '../../theme.js';
import BusinessLogo from '../BusinessLogo.jsx';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '../../icons/CheckCircleIcon.jsx';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import BuildingsIcon from '../../icons/BuildingsIcon.jsx';
import PhoneIcon from '../../icons/PhoneIcon.jsx';
import EnvelopeIcon from '../../icons/EnvelopeIcon.jsx';
import { useTranslation } from 'react-i18next';

export default function DealerDetailsCard({ dealer }) {
    const { t } = useTranslation();
    return (
        <Card sx={{ width: '100%' }}>
            <CardContent sx={{ padding: '24px' }}>
                <Stack gap={2}>
                    <Stack gap={1} direction="row">
                        <CheckCircleIcon />
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('Dealers.detailsLabel')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                        <Box
                            sx={{
                                overflow: 'hidden',
                                borderRadius: '8px',
                                border: '1px solid',
                                borderColor: Fog,
                            }}>
                            <BusinessLogo business={dealer} sx={{ width: 48, height: 48 }} />
                        </Box>
                        <Stack>
                            <Typography variant="textMd" sx={{ color: Midnight }}>
                                {dealer.name}
                            </Typography>
                            <Typography variant="textMd" sx={{ color: Teal }}>
                                {dealer.addresses?.[0].city}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack gap={3} direction={{ xs: 'column', sm: 'row' }}>
                        {/* Company Address */}
                        <Stack>
                            <FormInputLabel text={t('Dealers.companyAddressLabel')}>
                                <Stack gap={1}>
                                    <Stack gap={1} direction="row">
                                        <BuildingsIcon sx={{ width: 17, height: 17, color: Teal }} />
                                        <Typography variant="textSm" sx={{ color: Tide }}>
                                            {t('Dealers.headquartersLabel')}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography variant="textSm" sx={{ color: Midnight }}>
                                            {dealer.addresses?.[0].street1} <br />
                                            {`${dealer.addresses?.[0].city}, `}
                                            {`${dealer.addresses?.[0].state} `}
                                            {dealer.addresses?.[0].postalCode}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </FormInputLabel>
                        </Stack>
                        {/* Company Contact */}
                        <Stack>
                            <FormInputLabel text={t('Dealers.companyContactLabel')}>
                                <Stack gap={1}>
                                    <Stack gap={1} direction="row">
                                        <PhoneIcon sx={{ width: 17, height: 17, color: Teal }} />
                                        <Typography variant="textSm" sx={{ color: Tide }}>
                                            {dealer.addresses?.[0].phones?.[0].primary}
                                        </Typography>
                                    </Stack>
                                    <Stack gap={1} direction="row">
                                        <EnvelopeIcon sx={{ width: 17, height: 17, color: Teal }} />
                                        <Typography variant="textSm" sx={{ color: Tide }}>
                                            {dealer.addresses?.[0].emails?.[0].default}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </FormInputLabel>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

DealerDetailsCard.propTypes = {
    dealer: PropTypes.object.isRequired,
};
