import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { sortedCountries } from '../../constants/Countries.jsx';

export default function AddressCountrySelect(props) {
    const { label, onChange, selectProps, defaultValue = '', sx } = props;
    const [value, setValue] = React.useState(defaultValue);
    const countries = sortedCountries();
    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <FormControl>
            <InputLabel id="country-select-label" shrink>
                {label}
            </InputLabel>
            <Select
                label="Country"
                placeholder="Country"
                variant="outlined"
                labelId="country-select-label"
                id="country-select"
                displayEmpty
                value={value}
                onChange={handleChange}
                sx={sx}
                {...selectProps}
                renderValue={
                    value !== ''
                        ? undefined
                        : () => <Typography style={{ color: 'grey' }}>{'Select Country'}</Typography>
                }>
                <MenuItem value="" disabled>
                    <Typography variant="body2" style={{ color: 'grey' }}>
                        Select Country
                    </Typography>
                </MenuItem>
                {countries.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                        {i.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

AddressCountrySelect.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
    sx: PropTypes.object,
};
