import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useActionData,
    useLoaderData,
    useNavigation,
    useRevalidator,
    useRouteLoaderData,
    useSubmit,
} from 'react-router-dom';
import * as api from '../../../../api';
import * as businessProvider from '../../../../providers/businessProvider';
import LoadingButton from '../../../../components/LoadingButton/LoadingButton.jsx';
import { OrderStatuses } from '../../../../constants/OrderStatuses.jsx';
import { PaymentStatuses } from '../../../../constants/PaymentStatuses.jsx';
import OrderSummaryCard from '../../../cart/OrderSummaryCard.jsx';
import TrackingNumbers from './TrackingNumbers';
import ShopifyInventoryMismatch from '../../../../components/ShopifyInventoryMismatch/ShopifyInventoryMismatch.jsx';
import { useError } from '../../../../components/Error/ErrorProvider.jsx';
import OrderCommentsCard from '../../../retail/orders/OrderCommentsCard.jsx';
import BrandOrderDetailsHeader from '../../../../components/BrandOrderDetailsHeader/BrandOrderDetailsHeader.jsx';
import CheckCircleIcon from '../../../../icons/CheckCircleIcon.jsx';
import theme, { BoxShadow, Fog, Midnight, Teal, White } from '../../../../theme.js';
import BusinessLogo from '../../../../components/BusinessLogo.jsx';
import FormInputLabel from '../../../../components/FormInputLabel/FormInputLabel.jsx';
import ClipboardTextIcon from '../../../../icons/ClipboardTextIcon.jsx';
import OrderProductSummary from '../../../retail/orders/OrderProductSummary.jsx';
import {
    groupOrderItemsByProduct,
    groupProductsByProductId,
    selectItemsByProductId,
    selectProductById,
    selectTotalQuantityOfProductId,
} from '../../../retail/orders/orderUtil.js';
import SaveChangesFooter from '../../../../components/SaveChangesFooter/SaveChangesFooter.jsx';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import FinancialSettings from '../../../../components/FinancialSettings/FinancialSettings.jsx';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CircleXIcon from '../../../../icons/CircleXIcon.jsx';
import DialogActions from '@mui/material/DialogActions';
import { calculatePricing } from '../orderUtils.js';
import isEmpty from 'lodash/isEmpty';

export async function loader({ request, params }) {
    const { signal } = request;
    const url = new URL(request.url);
    const success = url.searchParams.get('success');
    const hasSuccessFlag = success === 'true';

    let order;
    if (hasSuccessFlag) {
        const retryAttempts = 8;
        order = await api.getOrderAfterPayment(params.orderId, retryAttempts, { signal });
    } else {
        order = await api.getOrderById(params.orderId, { signal });
    }

    const mostRecentPayment = order?.payments?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
    const paymentStatus = mostRecentPayment?.status || PaymentStatuses.OPEN;
    const defaultBusiness = await businessProvider.getBusiness();
    const businesses = defaultBusiness?.connectedBusiness || [];
    const retailBusinessData = [...(businesses || [])]?.filter((b) => b.businessId === order.retailBusinessId).pop();
    return {
        retailBusinessData,
        order,
        hasSuccessFlag,
        paymentStatus,
        businessesById: [defaultBusiness, ...(businesses || [])]?.reduce((acc, curr) => {
            return {
                ...acc,
                [curr.businessId]: curr,
            };
        }, {}),
        mostRecentPayment,
    };
}

export async function action({ request, params }) {
    const body = await request.json();
    const { intent, orderId, payload, businessId, content, createdByBusinessId, createdByUser } = body;
    try {
        if (intent === 'updateOrder') {
            const response = await api.updateOrder(orderId, payload);
            return { intent, order: response };
        }
        if (intent === 'takePayment') {
            const response = await api.payForOrder(params.orderId);
            window.location = response?.url;
        }
        if (intent === 'markedAsPaid') {
            await api.payForOrder(params.orderId, {}, { markedAsPaid: 'true' });
        }
        if (intent === 'createNote') {
            const note = {
                businessId,
                content,
                createdByBusinessId,
                createdByUser,
                orderId,
            };
            const response = await api.createOrderNote(note, { signal: request.signal });
            return { intent, note: response };
        }
        if (intent === 'updateStatus') {
            const response = await api.updateOrderStatus(orderId, payload);
            return { intent, order: response };
        }
    } catch (error) {
        return { error };
    }
    return null;
}

export const mapOrderToUpdatePayload = (order) => {
    const {
        businessId,
        discountAmount,
        discountType,
        id,
        paymentTermId,
        retailBusinessId,
        shippingCost,
        status,
        subTotal,
        total,
        trackingNumbers,
    } = order;
    const orderItems = order.orderItems.map((item) => ({
        id: item.id,
        price: item.price,
        productVariantId: item.productVariantId,
        quantity: item.quantity,
    }));
    return {
        businessId,
        discountAmount,
        discountType,
        id,
        orderItems,
        paymentTermId,
        retailBusinessId,
        shippingCost,
        status,
        subTotal,
        total,
        trackingNumbers,
    };
};

export default function BrandOrderDetails() {
    const submit = useSubmit();
    const { business } = useRouteLoaderData('root') || {};
    const { revalidate } = useRevalidator();
    const actionData = useActionData();
    const navigation = useNavigation();
    const { handleError } = useError();
    const { order: initialData = {}, retailBusinessData, paymentStatus } = useLoaderData() || {};
    const [initialOrder, setInitialOrder] = useState(initialData);
    const [order, setOrder] = useState(initialOrder);
    const orderItems = order?.orderItems?.map((i) => ({ ...i, quantity: parseInt(i.quantity) })) ?? [];
    const productMap = groupOrderItemsByProduct(order);
    const productIds = Object.keys(productMap) || [];
    const productsByProductId = groupProductsByProductId(order);

    const [openDecline, setOpenDecline] = React.useState(false);
    const isPaymentComplete = paymentStatus === PaymentStatuses.COMPLETE;
    const isPaymentPending = paymentStatus === PaymentStatuses.PENDING;
    const isPaymentFailed = paymentStatus === PaymentStatuses.FAILED;
    const isBusinessPayoutsEnabled = business?.settings?.paymentProviderSettings?.payoutsEnabled;
    const [showDeleteFail, setShowDeleteFail] = useState(false);
    const handleOpenDecline = () => setOpenDecline(true);
    const handleCloseDecline = () => setOpenDecline(false);
    const [showCancelOrderDialogue, setShowCancelOrderDialogue] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('info');

    const { t } = useTranslation();
    const [inventoryWarningData, setInventoryWarningData] = useState(null);
    const shippingAddress = order.addresses?.[0];
    const isSavingChanges =
        (navigation.state === 'submitting' || navigation.state === 'loading') &&
        navigation.json?.intent === 'updateOrder';
    const isXsBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
    const [isFinancialSettingsOpen, setIsFinancialSettingsOpen] = useState(false);

    // Loading Variables
    const isLoading = navigation.state === 'submitting' || navigation.state === 'loading';
    const isCancelling = isLoading && navigation.json?.orderStatus === OrderStatuses.CANCELLED;
    const isDeclining = isLoading && navigation.json?.orderStatus === OrderStatuses.DECLINED;
    const isApproving = isLoading && navigation.json?.orderStatus === OrderStatuses.APPROVED;
    const isMarkingShipped = isLoading && navigation.json?.orderStatus === OrderStatuses.SHIPPED;
    const isTakingPayment = isLoading && navigation.json?.intent === 'takePayment';
    const isMarkingPaid = isLoading && navigation.json?.intent === 'markedAsPaid';
    const hasMadeChanges = () => {
        return JSON.stringify(initialOrder) !== JSON.stringify(order);
    };

    // Function to handle closing the snackbar
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Function to show the snackbar
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const isEditable = order.status === OrderStatuses.IN_REVIEW;

    // Handlers for new actions
    const handleUpdateTrackingNumbers = (trackingNumbers) => {
        setOrder({
            ...order,
            trackingNumbers,
        });
    };

    const handleDecline = () => {
        const reason = document.getElementById('decline-reason').value;
        updateOrderStatus('Declined', reason)
            .then(() => {
                showSnackbar('Order declined successfully', 'success');
                return reason;
            })
            .catch((error) => {
                console.error('Error declining order:', error);
                showSnackbar('Error declining order', 'error');
            });
    };

    const handleCancelOrder = async () => {
        await updateOrderStatus('Cancelled');
    };

    const handleMarkAsShipped = async () => {
        await updateOrderStatus('Shipped');
    };

    const handleTakePayment = () => {
        submit({ intent: 'takePayment' }, { method: 'post', encType: 'application/json' });
    };

    const handleMarkAsPaid = async () => {
        await submit({ intent: 'markedAsPaid' }, { method: 'post', encType: 'application/json' });
        revalidate();
    };

    // Post Action Handlers
    const didUpdateStatus = (updatedOrder) => {
        const orderStatus = updatedOrder.status;
        const updatedData = {
            status: orderStatus,
            shippedOn: updatedOrder.shippedOn,
            cancelledOn: updatedOrder.cancelledOn,
            approvalNote: updatedOrder.approvalNote,
            declineReason: updatedOrder.declineReason,
            updatedAt: actionData.order.data.updatedAt,
        };

        showSnackbar(`Order ${orderStatus.toLowerCase()} successfully`, 'success');
        if (orderStatus === OrderStatuses.DECLINED) {
            handleCloseDecline();
        }
        if (orderStatus === OrderStatuses.CANCELLED) {
            setShowCancelOrderDialogue(false);
        }
        setOrder({
            ...order,
            ...updatedData,
        });
        setInitialOrder({
            ...initialOrder,
            ...updatedData,
        });
    };
    const didUpdateOrder = (updatedOrder) => {
        setInitialOrder(updatedOrder);
        setOrder(updatedOrder);
    };
    const didCreateNote = (note) => {
        const updatedOrder = {
            ...order,
            orderNotes: [
                ...(order.orderNotes || []),
                {
                    ...note,
                },
            ],
        };
        setOrder(updatedOrder);
        setInitialOrder(updatedOrder);
    };
    const handleActionDataChange = (actionData) => {
        if (actionData?.error) {
            handleError(actionData?.error);
        } else if (actionData?.intent) {
            const intent = actionData.intent;
            if (intent === 'updateOrder') {
                didUpdateOrder(actionData.order.data);
            } else if (intent === 'createNote') {
                didCreateNote(actionData.note);
            } else if (intent === 'updateStatus') {
                didUpdateStatus(actionData.order.data);
            }
        }
    };

    useEffect(() => {
        if (isPaymentPending) {
            revalidate();
        }
    }, [paymentStatus]);

    useEffect(() => {
        handleActionDataChange(actionData);
    }, [actionData]);

    const updateOrderStatus = async (updatedStatus, note = '') => {
        // Construct the payload
        const payload = {
            businessId: order.businessId,
            status: updatedStatus,
        };
        // Add either approvalNote or declineReason based on the status
        if (updatedStatus === OrderStatuses.APPROVED) {
            if (note) {
                payload.approvalNote = note;
            }
            const shopifyVariantIds = order.orderItems.map((orderItem) => {
                return orderItem.productVariant.thirdPartyId;
            });
            if (!isEmpty(shopifyVariantIds)) {
                const inventoryMismatches = await checkInventoryMismatch(order.orderItems);
                if (!isEmpty(inventoryMismatches)) {
                    return [
                        setInventoryWarningData({
                            orderData: {
                                orderId: order.id,
                                payload,
                                note,
                                status: updatedStatus,
                            },
                            inventories: inventoryMismatches,
                        }),
                    ];
                }
            }
        } else if (updatedStatus === OrderStatuses.DECLINED && note) {
            payload.declineReason = note;
        }
        await sendUpdatedOrderStatus({ status: updatedStatus, payload });
    };

    const sendUpdatedOrderStatus = ({ status, payload }) => {
        submit(
            {
                intent: 'updateStatus',
                payload,
                orderId: order.id,
                orderStatus: status,
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    const handleCloseInventoryWarning = () => {
        setInventoryWarningData(null);
    };

    const proceedWithInventoryMismatch = async () => {
        const { orderData } = inventoryWarningData;
        setInventoryWarningData(null);
        await sendUpdatedOrderStatus({
            orderId: orderData.orderId,
            payload: orderData.payload,
            status: orderData.status,
            note: orderData.note,
        });
    };

    async function checkInventoryMismatch(orderItems) {
        const shopifyVariantIds = orderItems.map((orderItem) => orderItem.productVariant.thirdPartyId);
        const linkedShops = business?.shopifySessions;
        if (isEmpty(shopifyVariantIds) || isEmpty(linkedShops)) {
            return [];
        }

        const availableInventory = await api.fetchAvailableShopifyInventory({ shopifyVariantIds }, {});
        if (!isEmpty(availableInventory)) {
            return orderItems
                .map((orderItem) => {
                    const inventory = availableInventory.find(
                        (inv) => inv.variantId.toString() === orderItem.productVariant.thirdPartyId.toString()
                    );
                    if (inventory && orderItem.quantity > inventory.available) {
                        return {
                            orderItem,
                            mismatch: orderItem.quantity - inventory.available,
                        };
                    }
                    return null;
                })
                .filter((item) => item !== null);
        }
        return [];
    }

    const handleApprove = () => {
        updateOrderStatus('Approved')
            .then(() => {
                return showSnackbar('Order approved successfully', 'success');
            })
            .catch(() => {
                showSnackbar('Error approving order', 'error');
            });
    };

    const handleItemUpdate = (update) => {
        setOrder((prevOrder) => {
            // Update the item
            const updatedOrderItems = prevOrder.orderItems.map((item) =>
                item.id === update.id
                    ? {
                          ...item,
                          ...(update.quantity !== undefined ? { quantity: update.quantity } : {}),
                          ...(update.price !== undefined ? { price: update.price } : {}),
                      }
                    : item
            );

            // Calculate new pricing after item update
            const pricing = calculatePricing({ ...prevOrder, orderItems: updatedOrderItems });

            return {
                ...prevOrder,
                orderItems: updatedOrderItems,
                ...pricing,
            };
        });
    };

    const handleDiscardChanges = () => {
        setOrder(initialOrder);
    };

    const handleSaveChanges = async () => {
        const payload = mapOrderToUpdatePayload(order);
        await saveOrder(payload);
    };
    const saveOrder = (payload) => {
        submit(
            {
                intent: 'updateOrder',
                payload,
                orderId: order.id,
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    const handleDeleteProduct = (itemId) => {
        const filteredItems = order.orderItems.filter((item) => item.id !== itemId);
        if (filteredItems.length < 1) {
            setShowDeleteFail(true);
        } else {
            setOrder((prevOrder) => {
                if (filteredItems.length < 1) {
                    setShowDeleteFail(true);
                } else {
                    const updatedOrder = { ...prevOrder, orderItems: filteredItems };
                    const pricing = calculatePricing(updatedOrder);

                    return {
                        ...updatedOrder,
                        ...pricing,
                    };
                }
            });
        }
    };

    const handleFinancialSettingsChange = (updatedInfo) => {
        setOrder((prevOrder) => {
            const pricing = calculatePricing(prevOrder, updatedInfo);
            return {
                ...prevOrder,
                ...updatedInfo,
                ...pricing,
            };
        });
    };

    const financialSettings = () => {
        return <FinancialSettings order={order} onChange={handleFinancialSettingsChange} />;
    };

    return (
        <>
            <Stack gap={3} sx={{ mb: { xs: 60, sm: 0 } }}>
                <BrandOrderDetailsHeader order={order ?? {}} />
                {isPaymentPending ? (
                    <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                            {t('BrandOrderDetails.paymentPendingLabel')}
                        </Typography>
                        <CircularProgress size={15} />
                    </Stack>
                ) : null}
                {isPaymentFailed ? (
                    <Typography
                        variant="h7"
                        gutterBottom
                        sx={{ fontWeight: 'bold', textAlign: 'left', color: 'common.red' }}>
                        {t('BrandOrderDetails.paymentFailedLabel')}
                        {!order?.payments?.[0]?.updatedAt
                            ? null
                            : format(new Date(order?.payments?.[0]?.updatedAt), 'MM/dd/yyyy')}
                    </Typography>
                ) : null}
                <Stack gap={3} direction={{ xs: 'column', sm: 'row' }} sx={{ alignItems: 'top' }}>
                    <Stack gap={3} sx={{ width: '100%' }}>
                        <Card>
                            <CardContent sx={{ padding: '24px' }}>
                                <Stack gap={3} sx={{ width: '100%' }}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            fontWeight: 700,
                                        }}
                                        variant="textLg">
                                        <CheckCircleIcon />
                                        {t('BrandOrderDetails.detailsLabel')}
                                    </Typography>
                                    <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                                        <Box
                                            sx={{
                                                overflow: 'hidden',
                                                borderRadius: '8px',
                                                border: '1px solid',
                                                borderColor: Fog,
                                            }}>
                                            <BusinessLogo
                                                business={retailBusinessData}
                                                sx={{ width: 48, height: 48 }}
                                            />
                                        </Box>
                                        <Stack>
                                            <Typography variant="textMd" sx={{ color: Midnight }}>
                                                {retailBusinessData?.name}
                                            </Typography>
                                            <Typography variant="textMd" sx={{ color: Teal }}>
                                                {retailBusinessData?.addresses?.[0]?.city}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        gap={3}
                                        direction="row"
                                        sx={{ alignItems: 'top', width: { xs: '100%', sm: '50%' } }}>
                                        <FormInputLabel text={'Order Type'} sx={{ fontSize: 10 }}>
                                            <Typography variant="textSm">{order.type}</Typography>
                                        </FormInputLabel>
                                        <FormInputLabel text={'Ship To'} sx={{ fontSize: 10 }}>
                                            <Stack>
                                                <Typography variant="textSm">{shippingAddress?.street1}</Typography>
                                                <Typography variant="textSm">
                                                    {shippingAddress?.city}, {shippingAddress?.state}{' '}
                                                    {shippingAddress?.postalCode}
                                                </Typography>
                                            </Stack>
                                        </FormInputLabel>
                                    </Stack>
                                    <TrackingNumbers
                                        shippedOnDate={order.shippedOn}
                                        initialTrackingNumbers={order.trackingNumbers}
                                        onTrackingNumbersUpdate={handleUpdateTrackingNumbers}
                                    />
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent sx={{ padding: '24px' }}>
                                <Stack gap={2}>
                                    <Stack
                                        gap={1}
                                        direction={{ xs: 'column', sm: 'row' }}
                                        sx={{
                                            alignItems: { xs: 'flex-start', sm: 'center' },
                                            justifyContent: 'space-between',
                                        }}>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                fontWeight: 700,
                                            }}
                                            variant="textLg">
                                            <ClipboardTextIcon />
                                            {t('BrandOrderDetails.itemsLabel')}
                                        </Typography>
                                    </Stack>
                                    {productIds?.map((id, index) => (
                                        <React.Fragment key={id}>
                                            <OrderProductSummary
                                                productTitle={selectProductById(productsByProductId, id)?.title}
                                                quantity={selectTotalQuantityOfProductId(productMap, id)}
                                                items={selectItemsByProductId(productMap, id)}
                                                editable={isEditable}
                                                onUpdate={handleItemUpdate}
                                                onDelete={handleDeleteProduct}
                                            />
                                            {index !== productIds.length - 1 ? <Divider sx={{ my: '30px' }} /> : null}
                                        </React.Fragment>
                                    ))}
                                    {!orderItems.length && (
                                        <Box>
                                            <Typography>{t('RetailOrderDetails.emptyLabel')}</Typography>
                                        </Box>
                                    )}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack gap={3}>
                        <Box
                            sx={{
                                position: { xs: 'fixed', sm: 'static' },
                                bottom: { xs: 0, sm: 'auto' },
                                left: 0,
                                right: 0,
                                zIndex: 1000,
                                width: { xs: '100%', sm: 'auto' },
                                padding: { xs: 2, sm: 'inherit' },
                            }}>
                            <OrderSummaryCard
                                isCollapsible={isXsBreakpoint}
                                order={order}
                                business={retailBusinessData}
                                variant="BRAND_REVIEW"
                                OrderSummaryCardActions={
                                    <Stack direction="column" spacing={2} justifyContent="center">
                                        {order.status === OrderStatuses.SHIPPED ? (
                                            <>
                                                {!isPaymentComplete ? (
                                                    <LoadingButton
                                                        isLoading={isTakingPayment}
                                                        disabled={!isBusinessPayoutsEnabled}
                                                        fullWidth
                                                        variant="contained"
                                                        name="intent"
                                                        value="payNow"
                                                        onClick={handleTakePayment}>
                                                        {t('OrderSummaryCard.buttons.takePaymentLabel')}
                                                    </LoadingButton>
                                                ) : null}
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => setShowCancelOrderDialogue(true)}>
                                                    {t('OrderSummaryCard.buttons.cancelOrderLabel')}
                                                </Button>
                                            </>
                                        ) : order.status === OrderStatuses.APPROVED ? (
                                            <>
                                                <LoadingButton
                                                    isLoading={isMarkingShipped}
                                                    variant="contained"
                                                    onClick={handleMarkAsShipped}>
                                                    {t('OrderSummaryCard.buttons.markAsShippedLabel')}
                                                </LoadingButton>
                                                {!isPaymentComplete ? (
                                                    <>
                                                        <LoadingButton
                                                            isLoading={isTakingPayment}
                                                            color="secondary"
                                                            disabled={!isBusinessPayoutsEnabled}
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            name="intent"
                                                            value="payNow"
                                                            onClick={handleTakePayment}>
                                                            {t('OrderSummaryCard.buttons.takePaymentLabel')}
                                                        </LoadingButton>
                                                        <LoadingButton
                                                            isLoading={isMarkingPaid}
                                                            color="secondary"
                                                            variant="outlined"
                                                            size="small"
                                                            disabled={isPaymentComplete}
                                                            onClick={handleMarkAsPaid}>
                                                            {t('OrderSummaryCard.buttons.markAsPaidLabel')}
                                                        </LoadingButton>
                                                    </>
                                                ) : null}
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={() => setShowCancelOrderDialogue(true)}>
                                                    {t('OrderSummaryCard.buttons.cancelOrderLabel')}
                                                </Button>
                                            </>
                                        ) : order.status === OrderStatuses.IN_REVIEW ? (
                                            <>
                                                {isXsBreakpoint ? (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => setIsFinancialSettingsOpen(true)}>
                                                        Financial Settings
                                                    </Button>
                                                ) : null}
                                                <Button
                                                    color="secondary"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleOpenDecline}>
                                                    {t('OrderSummaryCard.buttons.declineOrderLabel')}
                                                </Button>
                                                <LoadingButton
                                                    color="secondary"
                                                    variant="outlined"
                                                    size="small"
                                                    isLoading={isApproving}
                                                    onClick={handleApprove}>
                                                    {t('OrderSummaryCard.buttons.approveSendInvoiceLabel')}
                                                </LoadingButton>
                                            </>
                                        ) : null}
                                    </Stack>
                                }
                            />
                        </Box>
                        {/* Financial Settings */}
                        {isXsBreakpoint ? (
                            <Dialog
                                open={isFinancialSettingsOpen}
                                onClose={() => setIsFinancialSettingsOpen(false)}
                                PaperProps={{
                                    sx: {
                                        position: 'fixed',
                                        bottom: 0,
                                        margin: 0,
                                        width: '100%',
                                        maxWidth: '100%',
                                    },
                                }}>
                                {financialSettings()}
                            </Dialog>
                        ) : (
                            financialSettings()
                        )}
                        {/* Notes */}
                        <OrderCommentsCard
                            order={order}
                            sx={{
                                width: { sm: 326 },
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4, mt: 8 }}>
                {/* Decline Modal */}
                <Dialog fullWidth open={openDecline} onClose={handleCloseDecline}>
                    <DialogTitle>
                        <Typography variant="displayXs">{t('BrandOrderDetails.dialogs.declineOrderTitle')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Stack gap={1}>
                            <TextField
                                margin="normal"
                                id="decline-reason"
                                fullWidth
                                placeholder={t('BrandOrderDetails.dialogs.declinePlaceholder')}
                            />
                            <Stack gap={1} direction="row" sx={{ alignItems: 'center', alignSelf: 'flex-end' }}>
                                <Button variant="outlined" color="secondary" onClick={handleCloseDecline}>
                                    {t('BrandOrderDetails.dialogs.cancelButton')}
                                </Button>
                                <LoadingButton
                                    isLoading={isDeclining}
                                    onClick={handleDecline}
                                    variant="contained"
                                    sx={{ width: 120 }}>
                                    {t('BrandOrderDetails.dialogs.declineButton')}
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
                <Dialog open={inventoryWarningData !== null}>
                    <Box sx={{ width: 400, bgColor: White, boxShadow: BoxShadow, p: 4 }}>
                        <Stack gap={1}>
                            <Typography>
                                <strong>Warning:</strong> You do not have enough inventory in Shopify to fulfill this
                                order. Proceed anyway?
                            </Typography>
                            {inventoryWarningData?.inventories?.map((inventory, index) => (
                                <ShopifyInventoryMismatch key={index} inventoryData={inventory} />
                            ))}
                            <Stack direction="row" sx={{ mt: 2, justifyContent: 'space-between' }}>
                                <Button onClick={handleCloseInventoryWarning}>Cancel</Button>
                                <Button onClick={proceedWithInventoryMismatch} variant="contained">
                                    Proceed
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Dialog>
                {/* Cancel Order */}
                <Dialog
                    open={showCancelOrderDialogue}
                    onClose={() => setShowCancelOrderDialogue(false)}
                    maxWidth="xs"
                    fullWidth>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                                {t('CancelOrderDialog.title')}
                            </Typography>
                            <IconButton onClick={() => setShowCancelOrderDialogue(false)}>
                                <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                            {t('CancelOrderDialog.message', { orderNumber: order.orderNumber })}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="sm"
                            variant="outlined"
                            color="secondary"
                            onClick={() => setShowCancelOrderDialogue(false)}
                            autoFocus>
                            {t('CancelOrderDialog.cancel')}
                        </Button>
                        <LoadingButton
                            isLoading={isCancelling}
                            size="sm"
                            variant="contained"
                            color="primary"
                            onClick={handleCancelOrder}
                            sx={{ width: 150 }}>
                            {t('CancelOrderDialog.confirm')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                {hasMadeChanges() ? (
                    <SaveChangesFooter
                        isLoading={isSavingChanges}
                        onSave={handleSaveChanges}
                        onDiscard={handleDiscardChanges}
                    />
                ) : null}
            </Box>
            <Dialog open={showDeleteFail} onClose={() => setShowDeleteFail(false)}>
                <Typography variant="displayXs" sx={{ px: '24px', pt: '24px' }}>
                    {t('BrandOrderDetails.dialogs.deleteFailTitle')}
                </Typography>
                <DialogContent>
                    <Stack gap={2}>
                        <Typography>{t('BrandOrderDetails.dialogs.deleteFailMessage')}</Typography>
                        <Button
                            variant="contained"
                            onClick={() => setShowDeleteFail(false)}
                            sx={{ alignSelf: 'flex-end', width: '50px' }}>
                            {t('BrandOrderDetails.dialogs.okButton')}
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}
