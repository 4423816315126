import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useLoaderData, useNavigation } from 'react-router-dom';
import * as api from '../../../api';
import * as businessProvider from '../../../providers/businessProvider';
import Crumbs from '../../../components/Crumbs/Crumbs.jsx';
import { Teal } from '../../../theme.js';
import CreditCardIcon from '../../../icons/CreditCardIcon.jsx';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

export async function loader({ request }) {
    const business = await businessProvider.getBusiness(request);
    return { business };
}

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const intent = formData.get('intent');
        if (intent === 'login') {
            const result = await api.getStripeLoginLink();
            if (result && result.url) {
                window.open(result.url, '_blank');
            }
            return { login: { success: true } };
        } else if (intent === 'refresh') {
            businessProvider.clearCache();
            return await api.getStripeLoginLink();
        } else if (intent === 'revoke') {
            const result = await api.getStripeLoginLink();
            if (result && result.url) {
                // build settings url for account removal
                const urlParts = result.url.split('/');
                const accountId = urlParts[4];
                const newUrl = `https://connect.stripe.com/app/express#${accountId}/settings`;
                window.open(newUrl, '_blank');
            }
            return { login: { success: true } };
        }
    } catch (error) {
        return { error };
    }
}

export default function Payment() {
    const data = useLoaderData();
    const { business = {} } = data || {};
    const navigation = useNavigation();
    const isLoading = navigation.state === 'loading';
    const isSubmitting = navigation.state === 'submitting';
    const { t } = useTranslation();
    const isConnected =
        business?.settings?.paymentProviderSettings?.payoutsEnabled ||
        business?.settings?.paymentProviderSettings?.connectedBankAccount ||
        business?.settings?.paymentProviderSettings?.onboardingComplete;

    const disabled = isLoading || isSubmitting;
    return (
        <Stack gap={3} sx={{ maxWidth: '600px' }}>
            <Crumbs />
            <Stack gap={1}>
                <Stack direction="row" gap={2}>
                    <CreditCardIcon sx={{ width: 32, height: 32, color: Teal }} />
                    <Typography variant="displaySm" fontWeight={700}>
                        {t('Payment.title')}
                    </Typography>
                </Stack>
                <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                    {t('Payment.prompt')}
                </Typography>
            </Stack>
            <Card>
                <CardContent>
                    <Stack gap={1.5}>
                        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                            <CreditCardIcon sx={{ height: 32, width: 32, color: Teal }} />
                            <Typography variant="textLg" sx={{ fontWeight: 'bold' }}>
                                {t('Payment.subTitle')}
                            </Typography>
                            {isConnected ? (
                                <Form method="put">
                                    <input hidden readOnly name="intent" value="refresh" />
                                    <IconButton type="submit" sx={{ color: Teal }} data-testid="refresh-button">
                                        <RefreshIcon />
                                    </IconButton>
                                </Form>
                            ) : null}
                        </Stack>
                        <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                            {t('Payment.stripePrompt')}
                        </Typography>
                        {isConnected ? (
                            <Stack>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    !!business?.settings?.paymentProviderSettings?.onboardingComplete
                                                }
                                            />
                                        }
                                        label={t('Payment.onboardingCompleteLabel')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!business?.settings?.paymentProviderSettings?.payoutsEnabled}
                                            />
                                        }
                                        label={t('Payment.payoutsEnabledLabel')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    !!business?.settings?.paymentProviderSettings?.connectedBankAccount
                                                }
                                            />
                                        }
                                        label={t('Payment.connectedBankAccountLabel')}
                                    />
                                </FormGroup>
                            </Stack>
                        ) : null}
                        {!isConnected ? (
                            <Form method="put">
                                <input hidden readOnly name="intent" value="login" />
                                <Button disabled={disabled} variant="contained" color="primary" type="submit">
                                    {t('Payment.connectToStripeLabel')}
                                </Button>
                            </Form>
                        ) : (
                            <Stack direction="row" gap={1}>
                                <Form method="put">
                                    <input hidden readOnly name="intent" value="login" />
                                    <Button disabled={disabled} variant="contained" color="primary" type="submit">
                                        {t('Payment.loginToStripeLabel')}
                                    </Button>
                                </Form>
                                <Form method="put">
                                    <input hidden readOnly name="intent" value="revoke" />
                                    <Button disabled={disabled} variant="outlined" color="secondary" type="submit">
                                        {t('Payment.revokeButtonLabel')}
                                    </Button>
                                </Form>
                            </Stack>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}
