import PropTypes from 'prop-types';
import React from 'react';
import { SvgIcon } from '@mui/material';

export default function ShoppingBagOpenIcon(props) {
    return (
        <SvgIcon component="svg" sx={props?.sx} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM20.25 5.25V6.75H3.75V5.25H20.25ZM20.25 18.75H3.75V8.25H20.25V18.75ZM16.5 10.5C16.5 11.6935 16.0259 12.8381 15.182 13.682C14.3381 14.5259 13.1935 15 12 15C10.8065 15 9.66193 14.5259 8.81802 13.682C7.97411 12.8381 7.5 11.6935 7.5 10.5C7.5 10.3011 7.57902 10.1103 7.71967 9.96967C7.86032 9.82902 8.05109 9.75 8.25 9.75C8.44891 9.75 8.63968 9.82902 8.78033 9.96967C8.92098 10.1103 9 10.3011 9 10.5C9 11.2956 9.31607 12.0587 9.87868 12.6213C10.4413 13.1839 11.2044 13.5 12 13.5C12.7956 13.5 13.5587 13.1839 14.1213 12.6213C14.6839 12.0587 15 11.2956 15 10.5C15 10.3011 15.079 10.1103 15.2197 9.96967C15.3603 9.82902 15.5511 9.75 15.75 9.75C15.9489 9.75 16.1397 9.82902 16.2803 9.96967C16.421 10.1103 16.5 10.3011 16.5 10.5Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

ShoppingBagOpenIcon.propTypes = {
    sx: PropTypes.any,
};
