import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PropTypes from 'prop-types';
import SettingsGearIconDropdown from '../../SettingsGearIconDropdown/SettingsGearIconDropdown.jsx';

export default function IconButtonsSection({ type }) {
    if (!type) {
        console.error('Type is required when using IconButtonsSection component');
        return null;
    }
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const handleIconButtonClick = (iconType) => () => {
        const path = type.toLowerCase();
        switch (iconType) {
            case 'shopping-cart':
                navigate('/' + path + '/cart');
                break;
            case 'connections':
                navigate('/' + path + '/connections');
                break;
            case 'messages':
                navigate(path + '/messages');
                break;
            case 'reports':
                navigate(path + '/reports');
                break;
            case 'orders':
                navigate('/' + path + '/orders');
                break;
            case 'settings':
                handleSettingsGearIconClick();
                break;
            default:
                console.error('Invalid icon type');
                break;
        }
    };

    const handleSettingsGearIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
    };

    const handleCloseSettings = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Connections" arrow>
                <IconButton color="inherit" onClick={handleIconButtonClick('connections')}>
                    <ConnectWithoutContactIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Messages" arrow>
                <span>
                    <IconButton disabled color="inherit" onClick={handleIconButtonClick('messages')}>
                        <MessageIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Reports" arrow>
                <span>
                    <IconButton disabled color="inherit" onClick={handleIconButtonClick('reports')}>
                        <AssessmentIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Orders" arrow>
                <IconButton color="inherit" onClick={handleIconButtonClick('orders')}>
                    <LocalShippingIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Settings" arrow>
                <IconButton aria-describedby={id} color="inherit" onClick={handleSettingsGearIconClick}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <SettingsGearIconDropdown
                type={type}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseSettings}
            />
        </Box>
    );
}

IconButtonsSection.propTypes = {
    type: PropTypes.string,
};
