import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import * as api from '../../api';
import { BusinessTypes } from '../../constants/BusinessTypes';
import Box from '@mui/material/Box';
/**
 * @param {import("react-router-dom").LoaderFunctionArgs}
 */
export async function loader({ request }) {
    try {
        const { signal } = request;
        const business = await api.getDefaultBusiness({ signal });
        const result = await api.getStripeOnboardingUrl(business.businessId, { signal });
        if (!result?.onboardingComplete && business.type === BusinessTypes.BRAND) {
            window.location = result?.onboardingUrl;
            return null;
        }
        return redirect('/');
    } catch (error) {
        return { error };
    }
}

export default function BusinessSetupStrip() {
    const data = useLoaderData();
    if (data?.error) {
        // TODO notify user of error
    }
    return (
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </Box>
    );
}
