import React from 'react';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';
import { useSignOut } from '../../../hooks/useSignOut';
import { zIndexes } from '../../../constants/zIndexes.jsx';

function BrandSettingsGearIconDropdown({ id, open, anchorEl, onClose }) {
    const navigate = useNavigate();
    const signOut = useSignOut();

    const handleIconButtonClick = (iconType) => () => {
        onClose();
        switch (iconType) {
            case 'user-profile':
                navigate('/brand/user-profile');
                break;
            case 'settings':
                navigate('/brand/settings/company-profile');
                break;
            case 'sign-out':
                signOut();
                break;
            default:
                console.error('Invalid icon type');
                break;
        }
    };

    const handleClickAway = (event) => {
        onClose();
    };

    return (
        <Popper
            sx={{ zIndex: zIndexes.popper }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            placement="bottom-start">
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                mt: 1.5,
                                borderRadius: 2,
                                boxShadow: 3,
                                minWidth: 275,
                                minHeight: 275,
                            }}>
                            <List component="nav" aria-label="settings navigation">
                                <ListItem button onClick={handleIconButtonClick('user-profile')}>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="User Profile" sx={{ color: 'black', fontWeight: 'bold' }} />
                                </ListItem>
                                <Divider />
                                <ListItem button onClick={handleIconButtonClick('settings')}>
                                    <ListItemIcon>
                                        <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Settings" sx={{ color: 'black', fontWeight: 'bold' }} />
                                </ListItem>
                                <Divider />
                                <ListItem button onClick={handleIconButtonClick('sign-out')}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Sign Out" sx={{ color: 'black', fontWeight: 'bold' }} />
                                </ListItem>
                            </List>
                        </Paper>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    );
}

BrandSettingsGearIconDropdown.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    open: PropTypes.bool,
    anchorEl: PropTypes.oneOfType([PropTypes.instanceOf(Element)]),
    onClose: PropTypes.func,
};

export default BrandSettingsGearIconDropdown;
