import { Autocomplete, FormControl } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const tagOptions = ['Size', 'Color', 'Material', 'Style', 'Pack'];

export function TagOptionName(props) {
    const [localValue, setLocalValue] = useState(props.value);

    function handleChange(e) {
        const v = e.target.value;
        setLocalValue(v);
    }

    function handleSelectChange(e, selectValue) {
        setLocalValue(selectValue);
    }

    // on blur used to reduce rerender lag
    // timeout used to prevent onclick from getting swallowed by onblur React bug
    function handleBlur() {
        setTimeout(props.onChange(localValue), 100);
    }

    const helperText = props.isInvalid ? 'Duplicate Options Not Allowed' : null;

    return (
        <FormControl fullWidth>
            <Autocomplete
                freeSolo
                options={tagOptions}
                onInputChange={handleSelectChange}
                value={localValue}
                id="option"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            onChange={handleChange}
                            helperText={helperText}
                            onBlur={handleBlur}
                            error={props.isInvalid}
                            margin="normal"
                            id="optionInput"
                            label="Option"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name={'tag-option-name'}
                        />
                    );
                }}
            />
        </FormControl>
    );
}

TagOptionName.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    isInvalid: PropTypes.bool,
};
