import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';

export default function CaretCircleUpIcon(props) {
    return (
        <SvgIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.95538 19.75C11.8837 19.75 13.7688 19.1782 15.3722 18.1068C16.9756 17.0355 18.2253 15.5127 18.9632 13.7312C19.7012 11.9496 19.8942 9.98918 19.518 8.09787C19.1418 6.20655 18.2132 4.46927 16.8497 3.10571C15.4861 1.74215 13.7488 0.813549 11.8575 0.437343C9.9662 0.0611373 8.0058 0.25422 6.22422 0.992175C4.44264 1.73013 2.91989 2.97981 1.84855 4.58319C0.777208 6.18657 0.205381 8.07163 0.20538 10C0.20811 12.585 1.23622 15.0634 3.0641 16.8913C4.89199 18.7192 7.37036 19.7473 9.95538 19.75ZM9.95538 1.75C11.5871 1.75 13.1821 2.23385 14.5388 3.14037C15.8955 4.0469 16.953 5.33537 17.5774 6.84286C18.2018 8.35035 18.3652 10.0092 18.0469 11.6095C17.7285 13.2098 16.9428 14.6798 15.789 15.8336C14.6352 16.9874 13.1652 17.7731 11.5649 18.0915C9.96453 18.4098 8.30573 18.2464 6.79824 17.622C5.29075 16.9976 4.00228 15.9402 3.09576 14.5835C2.18923 13.2267 1.70538 11.6317 1.70538 10C1.70786 7.81272 2.57785 5.71575 4.12449 4.16911C5.67113 2.62247 7.76811 1.75248 9.95538 1.75ZM5.67475 12.0306C5.60502 11.961 5.5497 11.8783 5.51196 11.7872C5.47422 11.6962 5.45479 11.5986 5.45479 11.5C5.45479 11.4014 5.47422 11.3038 5.51196 11.2128C5.5497 11.1217 5.60502 11.039 5.67475 10.9694L9.42475 7.21937C9.49441 7.14964 9.57713 7.09432 9.66817 7.05658C9.75922 7.01884 9.85682 6.99941 9.95538 6.99941C10.0539 6.99941 10.1515 7.01884 10.2426 7.05658C10.3336 7.09432 10.4164 7.14964 10.486 7.21937L14.236 10.9694C14.3767 11.1101 14.4558 11.301 14.4558 11.5C14.4558 11.699 14.3767 11.8899 14.236 12.0306C14.0953 12.1714 13.9044 12.2504 13.7054 12.2504C13.5064 12.2504 13.3155 12.1714 13.1748 12.0306L9.95538 8.81031L6.736 12.0306C6.66635 12.1004 6.58363 12.1557 6.49258 12.1934C6.40154 12.2312 6.30394 12.2506 6.20538 12.2506C6.10682 12.2506 6.00922 12.2312 5.91817 12.1934C5.82713 12.1557 5.74441 12.1004 5.67475 12.0306Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

CaretCircleUpIcon.propTypes = {
    sx: PropTypes.any,
};
