import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import BasicModal from '../../../../components/BasicModal/BasicModal.jsx';

export default function AbandonProductModal({ open, onClose, onLeave }) {
    const buttonsArray = [
        <Button key="1" variant="outlined" color="secondary" onClick={onClose}>
            Cancel
        </Button>,
        <Button key="2" variant="contained" onClick={onLeave}>
            Abandon Product
        </Button>,
    ];
    return (
        <BasicModal
            open={open}
            title={'Abandon Product'}
            message={'All product info will be lost when leaving this page.'}
            buttons={buttonsArray}
        />
    );
}

AbandonProductModal.propTypes = {
    open: PropTypes.bool,
    onLeave: PropTypes.func,
    onClose: PropTypes.func,
};
