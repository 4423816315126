import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import TagIcon from '../../icons/TagIcon.jsx';
import { NumericFormat } from 'react-number-format';
import { LightGray } from '../../theme.js';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';

export default function MinimumOrder({ value }) {
    const { t } = useTranslation();
    return (
        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
            <TagIcon sx={{ width: 16, height: 16, color: LightGray }} />
            <Typography
                variant="textSm"
                sx={{ display: 'flex', alignItems: 'center', color: LightGray, fontWeight: 500 }}>
                {t('MinimumOrder.title')}
            </Typography>
            <Typography
                variant="textSm"
                sx={{ display: 'flex', alignItems: 'center', color: LightGray, fontWeight: 500 }}>
                <NumericFormat prefix="$" displayType="text" value={value || 0} decimalScale={0} thousandSeparator />
            </Typography>
        </Stack>
    );
}

MinimumOrder.propTypes = {
    value: PropTypes.string,
};
