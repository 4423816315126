import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function BrandClearOrderDialog(props) {
    const { open, onClose, onConfirm } = props;
    const { t } = useTranslation();

    const handleConfirm = (e) => {
        if (onConfirm) {
            onConfirm(e);
        }
    };
    const handleCancel = (e) => {
        if (onClose) {
            onClose(e);
        }
    };

    return (
        <Dialog open={open} onClose={handleCancel} data-testid="brand-clear-order-dialog">
            <DialogTitle>{t('BrandClearOrderDialog.title')}</DialogTitle>
            <Divider />
            <DialogContent sx={{ width: { sm: 372 } }}>
                <Typography variant="body1">{t('BrandClearOrderDialog.details')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button color="info" onClick={handleCancel}>
                    {t('BrandClearOrderDialog.cancelLabel')}
                </Button>
                <Button variant="contained" color="error" onClick={handleConfirm}>
                    {t('BrandClearOrderDialog.clearLabel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

BrandClearOrderDialog.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    open: PropTypes.bool,
};
