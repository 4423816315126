import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

export default function EnvelopeIcon(props) {
    return (
        <Box component="svg" sx={props?.sx} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Vector"
                d="M14 3H2C1.86739 3 1.74021 3.05268 1.64645 3.14645C1.55268 3.24021 1.5 3.36739 1.5 3.5V12C1.5 12.2652 1.60536 12.5196 1.79289 12.7071C1.98043 12.8946 2.23478 13 2.5 13H13.5C13.7652 13 14.0196 12.8946 14.2071 12.7071C14.3946 12.5196 14.5 12.2652 14.5 12V3.5C14.5 3.36739 14.4473 3.24021 14.3536 3.14645C14.2598 3.05268 14.1326 3 14 3ZM8 8.32187L3.28562 4H12.7144L8 8.32187ZM6.16937 8L2.5 11.3631V4.63688L6.16937 8ZM6.90938 8.67813L7.65938 9.36875C7.75162 9.45343 7.87228 9.50041 7.9975 9.50041C8.12272 9.50041 8.24338 9.45343 8.33562 9.36875L9.08562 8.67813L12.7106 12H3.28562L6.90938 8.67813ZM9.83062 8L13.5 4.63625V11.3638L9.83062 8Z"
                fill="currentColor"
            />
        </Box>
    );
}

EnvelopeIcon.propTypes = {
    sx: PropTypes.any,
};
