import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Alert } from '../../theme.js';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

export default function ItemEditMenu({ open, anchorEl, onClose, onEdit, onDelete, anchorOrigin, PaperProps }) {
    const { t } = useTranslation();
    return (
        <Popover open={open} anchorEl={anchorEl} onClose={onClose} anchorOrigin={anchorOrigin} PaperProps={PaperProps}>
            <Box sx={{ py: 1 }}>
                <MenuItem onClick={onEdit}>
                    <Typography variant="textSm">{t('EditMenu.editAction')}</Typography>
                </MenuItem>
                <MenuItem onClick={onDelete}>
                    <Typography variant="textSm" sx={{ color: Alert }}>
                        {t('EditMenu.deleteAction')}
                    </Typography>
                </MenuItem>
            </Box>
        </Popover>
    );
}

ItemEditMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    anchorOrigin: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'bottom', 'center']),
        horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    }),
    PaperProps: PropTypes.object,
};

ItemEditMenu.defaultProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    PaperProps: {
        sx: {
            mt: 2,
            borderRadius: 4,
        },
    },
};
