import { OrderDiscountTypes } from '../../constants/OrderDiscountTypes';

export function filterProducts(products, search) {
    if (!search) {
        return products;
    }
    return products.filter((p) => {
        const { mpn = '', title = '', upc = '', variantTags = [] } = p;
        const q = search.toLowerCase();
        const variantOptionValues = variantTags.map(({ value }) => value.toLowerCase());
        return (
            title.toLowerCase().includes(q) ||
            `${mpn}`.toLowerCase().includes(q) ||
            `${upc}`.toLowerCase().includes(q) ||
            variantOptionValues.some((value) => value.includes(q))
        );
    });
}

/**
 *
 * @param {object} order
 * @param {string} order.discountAmount
 * @param {string} order.discountType
 * @param {number} order.subTotal
 */
export function calculateOrderDiscountTotal(order) {
    const { discountAmount, discountType, subTotal } = order;
    let total = 0;
    if (discountAmount && discountType) {
        total =
            discountType === OrderDiscountTypes.PERCENT
                ? Number((subTotal * Number(discountAmount)) / 100)
                : Number(discountAmount);
    }
    return total;
}

export function mapCartStateToOrder(state = {}) {
    const { discountAmount, discountType, items = [], shippingCost, terms } = state;
    const orderItems = items.map((item) => {
        const { businessId, id: productVariantId, quantity, wholesale: price } = item;
        return {
            id: 'TODO',
            businessId,
            createdAt: new Date(),
            updatedAt: new Date(),
            orderId: 'TODO',
            productVariantId,
            productVariant: {
                product: {},
            },
            quantity,
            price,
        };
    });
    const subTotal = orderItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const discountTotal = calculateOrderDiscountTotal({
        discountAmount: state.discountAmount,
        discountType: state.discountType,
        subTotal,
    });
    const total = subTotal + (Number(shippingCost) ?? 0) - Number(discountTotal);
    return {
        discountAmount,
        discountType,
        orderItems,
        shippingCost,
        subTotal,
        terms,
        total,
    };
}
