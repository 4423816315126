import countryList from 'react-select-country-list';
export const countries = countryList().getData();

// Places United States in first index for convenience
export function sortedCountries() {
    const usIndex = countries.findIndex((item) => item.label === 'United States');
    if (usIndex !== -1) {
        const usEntry = countries.splice(usIndex, 1)[0];
        countries.unshift(usEntry);
    }
    return countries;
}
