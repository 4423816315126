import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as api from '../../../api';
import CircleXIcon from '../../../icons/CircleXIcon';
import { Midnight } from '../../../theme';

export default function CancelOrderDialog(props) {
    const { businessId, onClose, onSuccess, open, orderId, orderNumber } = props;
    const { t } = useTranslation();

    const handleConfirm = async () => {
        const payload = {
            businessId: businessId?.toString(),
            status: 'Cancelled',
        };
        try {
            const result = await api.updateOrderStatus(orderId, payload, {});
            handleClose();
            if (onSuccess) {
                onSuccess(result);
            }
        } catch (error) {
            // TODO
            handleClose();
        }
    };
    const handleClose = (e) => {
        if (onClose) {
            onClose(e);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                        {t('CancelOrderDialog.title')}
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                    {t('CancelOrderDialog.message', { orderNumber })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button size="sm" variant="outlined" color="secondary" onClick={handleClose} autoFocus>
                    {t('CancelOrderDialog.cancel')}
                </Button>
                <Button size="sm" variant="contained" color="primary" onClick={handleConfirm}>
                    {t('CancelOrderDialog.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

CancelOrderDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    businessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    orderNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
