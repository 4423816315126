import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Teal } from '../../../theme.js';
import EditableThatchAvatar from '../../ThatchAvatar/EditableThatchAvatar.jsx';
import ThatchAvatar from '../../ThatchAvatar/ThatchAvatar.jsx';
import CardContent from '@mui/material/CardContent';
import { useLoaderData } from 'react-router-dom';

export default function CompanyProfileHeader(props) {
    const { business = {} } = useLoaderData();
    const { isEditable = false, children, onImageChange } = props;
    const businessTypeDisplayName = business?.type === BusinessTypes.RETAIL ? 'Retailer' : 'Brand';

    return (
        <Card>
            <CardContent sx={{ padding: '24px' }}>
                <Stack gap={2}>
                    <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                        {isEditable ? (
                            <EditableThatchAvatar
                                variant="business"
                                src={business?.imageLinks?.[0]}
                                onImageChange={onImageChange}
                            />
                        ) : (
                            <ThatchAvatar variant="business" src={business?.imageLinks?.[0]} />
                        )}
                        <Stack>
                            <Typography variant="displayXs">{business.name}</Typography>
                            <Typography variant="textMd" sx={{ color: Teal }}>
                                {businessTypeDisplayName}
                            </Typography>
                        </Stack>
                    </Stack>
                    {children}
                </Stack>
            </CardContent>
        </Card>
    );
}

CompanyProfileHeader.propTypes = {
    onImageChange: PropTypes.func,
    isEditable: PropTypes.bool,
    children: PropTypes.node,
};
