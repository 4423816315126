import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';

export default function ProductQuantityTextField(props) {
    const { label, quantity: initialQuantity, onChange, min = 1, max = 100000, ...rest } = props;
    const [quantity, setQuantity] = useState(initialQuantity);

    const handleChange = (event) => {
        const newValue = event.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(newValue)) {
            if (newValue === '' || (newValue >= min && !isNaN(newValue))) {
                setQuantity(newValue);
                if (newValue !== '') {
                    onChange(Number(newValue));
                }
            } else {
                setQuantity(newValue);
            }
        }
    };

    const handleBlur = () => {
        if (quantity === '') {
            onChange(min);
            setQuantity(min);
        }
    };

    return (
        <FormControl variant="outlined" size="medium">
            <TextField
                label={label}
                name="quantity"
                value={quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                    inputProps: {
                        min,
                        max,
                        step: 1,
                    },
                    sx: {
                        '& input': {
                            px: 2,
                            py: 1.5,
                            textAlign: 'center',
                            fontWeight: 700,
                        },
                    },
                }}
                variant="outlined"
                size="small"
                {...rest}
            />
        </FormControl>
    );
}

ProductQuantityTextField.propTypes = {
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    sx: PropTypes.object,
};
