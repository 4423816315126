import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Paper from '@mui/material/Paper';
import { TagOptionName } from './TagOptionName.jsx';
import { TagOptionValues } from './TagOptionValues.jsx';
import PropTypes from 'prop-types';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { checkRequiredFieldValues, requiredFields } from '../ProductVariantTable/productUtil.js';
import { cloneDeep, filter, findIndex, get, identity, isEmpty, keys, pickBy, uniqueId, without } from 'lodash';

export function ProductVariantTagOptions(props) {
    const productVariantTagOptions = get(props, 'product.productVariantTagOptions') || [];
    const product = get(props, 'product') || {};
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    function handleAdd() {
        const v = checkRequiredFieldValues(product, requiredFields);
        const hasErrors = keys(pickBy(v, identity));
        if (!isEmpty(hasErrors)) {
            props.setFieldsValidity(v);
            setSnackbarOpen(true);

            return;
        }

        const newOption = {
            active: true,
            id: uniqueId('_new'),
            name: '',
            values: [],
            _isInvalid: false,
        };
        const pvCopy = cloneDeep(productVariantTagOptions);
        pvCopy.push(newOption);
        props.onChange(pvCopy);
    }

    function handleValueChange(tagOption) {
        return (values) => {
            const optionCopy = cloneDeep(tagOption);
            const pvCopy = cloneDeep(productVariantTagOptions);
            const iToUpdate = findIndex(pvCopy, { id: tagOption.id });
            optionCopy.values = values;
            pvCopy[iToUpdate].values = values;
            props.onChange(pvCopy);
        };
    }

    function handleNameChange(tagOption) {
        return (name) => {
            const optionCopy = cloneDeep(tagOption);
            const pvCopy = cloneDeep(productVariantTagOptions);
            const iToUpdate = findIndex(pvCopy, { id: tagOption.id });

            optionCopy.name = name;
            pvCopy[iToUpdate].name = name;
            const countByName = filter(pvCopy, { name: optionCopy.name });
            if (pvCopy.length > 1) {
                if (countByName.length > 1) {
                    pvCopy[iToUpdate]._isInvalid = true;
                } else {
                    pvCopy[iToUpdate]._isInvalid = false;
                }
            }
            props.onChange(pvCopy);
        };
    }

    function handleDelete(tagOption) {
        return () => {
            const newChips = without(productVariantTagOptions, tagOption);
            props.onChange(newChips);
        };
    }

    const disableAddBtn = filter(productVariantTagOptions, { _isInvalid: true }).length > 0;

    return (
        <>
            <Paper sx={{ padding: 2, marginBottom: 2 }}>
                <Button
                    disabled={disableAddBtn}
                    id="add-option"
                    variant="contained"
                    component="span"
                    onClick={handleAdd}
                    startIcon={<AddCircleOutlineIcon />}>
                    Add Option
                </Button>

                {productVariantTagOptions.map((tagOption, index) => {
                    return (
                        <Box key={index + tagOption.id}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <TagOptionName
                                        id="option-name-change"
                                        isInvalid={tagOption._isInvalid}
                                        value={tagOption.name}
                                        onChange={handleNameChange(tagOption)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TagOptionValues
                                        values={tagOption.values}
                                        onChange={handleValueChange(tagOption)}
                                    />
                                </Grid>
                                <Button
                                    id="delete-option"
                                    sx={{ cursor: 'pointer', marginLeft: '10px', marginTop: '20px' }}
                                    onClick={handleDelete(tagOption)}>
                                    <RemoveCircleIcon />
                                </Button>
                            </Grid>
                        </Box>
                    );
                })}
            </Paper>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={'error'} sx={{ width: '100%' }}>
                    {'Fill out missing required fields before adding options'}
                </Alert>
            </Snackbar>
        </>
    );
}

ProductVariantTagOptions.propTypes = {
    onChange: PropTypes.func.isRequired,
    product: PropTypes.object,
    setFieldsValidity: PropTypes.func.isRequired,
};
