import DeleteIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGridPro } from '@mui/x-data-grid-pro';
import * as React from 'react';
import PropTypes from 'prop-types';
import ProductIcon from '../../../components/ProductIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductVariantQuantityInput from './ManageOrderDataGrid/ProductVariantQuantityInput';

const ROW_HEIGHT = 26;

export default function BrandProductCard(props) {
    const { onDelete, product } = props;
    const { t } = useTranslation();
    const handleDeleteClick = (e) => {
        if (onDelete) {
            onDelete(e, product);
        }
    };
    const columns = [
        {
            field: 'mpn',
            headerClassName: 'thatch-table-header--blue',
            headerName: t('BrandProductCard.mpnLabel'),
            resizable: false,
        },
        {
            field: 'variantTags',
            headerClassName: 'thatch-table-header--blue',
            headerName: t('BrandProductCard.variantOptionsLabel'),
            flex: 1,
            resizable: false,
            valueFormatter: (params) => {
                return params?.value?.map((tag) => tag.value).join(', ');
            },
        },
        {
            field: 'quantity',
            headerClassName: 'thatch-table-header--blue',
            headerName: t('BrandProductCard.quantityLabel'),
            resizable: false,
            sortable: false,
            width: 66,
            renderCell: (params) => <ProductVariantQuantityInput renderCellParams={params} />,
        },
    ];

    const rows = product?.productVariants?.filter((variant) => variant.active);

    return (
        <Card elevation={4} data-testid="BrandProductCard" sx={{ position: 'relative' }}>
            <CardContent>
                <IconButton color="error" sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleDeleteClick}>
                    <DeleteIcon />
                </IconButton>
                <Stack direction="row" gap={1}>
                    <ProductIcon src={product.imageLinks?.[0]} sx={{ width: '66px' }} />
                    <Stack justifyContent="space-between" flex="1 0 auto">
                        <Typography variant="body2" sx={{ fontWeight: 'bold', pr: 3 }}>
                            {product.title}
                        </Typography>
                        <Stack direction="row" gap={2} justifyContent="space-between">
                            <Box>
                                <Typography variant="body2">{t('BrandProductCard.wholesaleLabel')}</Typography>
                                <Typography variant="body2">
                                    <NumericFormat
                                        displayType={'text'}
                                        prefix="$"
                                        value={product.wholesale}
                                        thousandSeparator
                                        decimalScale={2}
                                        valueIsNumericString
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">{t('BrandProductCard.msrpLabel')}</Typography>
                                <Typography variant="body2">
                                    <NumericFormat
                                        displayType={'text'}
                                        prefix="$"
                                        value={product.msrp}
                                        thousandSeparator
                                        decimalScale={2}
                                        valueIsNumericString
                                        fixedDecimalScale
                                    />
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2">{t('BrandProductCard.variantsLabel')}</Typography>
                                <Typography variant="body2">{product.productVariants?.length}</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
                <Box>
                    <DataGridPro
                        sx={{ border: 'none', pt: 1, pb: 3 }}
                        columnHeaderHeight={ROW_HEIGHT}
                        rowHeight={ROW_HEIGHT}
                        columns={columns}
                        rows={rows}
                        hideFooter
                        disableColumnMenu
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow--blue' : 'oddRow'
                        }
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

BrandProductCard.propTypes = {
    onDelete: PropTypes.func,
    product: PropTypes.any,
};
