import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useFetcher, useLoaderData } from 'react-router-dom';
import ProductIcon from '../ProductIcon';
import SearchBar from '../SearchBar/SearchBar';

const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function ProductPickerMobile(props) {
    const data = useLoaderData();
    const fetcher = useFetcher();
    const searchInputRef = useRef();
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(data?.rows || []);
    const { t } = useTranslation();

    const isSelected = (id) => {
        const selectedProducts = props?.value || [];
        return !!selectedProducts.find((p) => id === p.id);
    };

    useEffect(() => {
        if (fetcher.data && fetcher.state === 'idle') {
            setOptions(fetcher?.data?.rows);
        }
    }, [fetcher]);

    const queryProducts = useMemo(
        () =>
            debounce((value) => {
                const queryString = new URLSearchParams();
                queryString.set('q', value);
                const query = `?${queryString.toString()}`;
                fetcher.load(query);
            }, 400),
        []
    );

    useEffect(() => {
        if (fetcher.state === 'idle') {
            queryProducts(searchValue);
        }
    }, [searchValue, queryProducts]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const handleFocus = (e) => {
        setOpen(true);
        e.target.blur();
        setTimeout(() => {
            searchInputRef.current.focus();
        }, 400);
    };

    const handleAddButtonClick = (product) => () => {
        if (!isSelected(product.id)) {
            if (props?.onAddProduct) {
                props.onAddProduct(product);
            }
        } else {
            if (props?.onRemoveProduct) {
                props.onRemoveProduct(product);
            }
        }
    };

    const handleOnClose = () => {
        setOpen(false);
        setSearchValue('');
    };

    return (
        <Box data-testid="product-picker" sx={props.sx}>
            <SearchBar
                fullWidth
                {...props?.SearchBarProps}
                placeholder={props.placeholder}
                onFocus={handleFocus}
                value={searchValue}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={handleOnClose}
                onOpen={() => {}}
                PaperProps={{
                    sx: { pt: 1, height: 'calc(100vh - 56px)', borderTopRightRadius: 4, borderTopLeftRadius: 4 },
                }}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}>
                <Stack gap={1} flexDirection={'column'}>
                    <Box sx={{ display: 'flex', p: 1, width: '100%', justifyContent: 'center' }}>
                        <Box sx={{ height: 6, width: 30, bgcolor: 'common.borderColor', borderRadius: 3 }} />
                    </Box>
                    <Stack direction="row" gap={2} px={1}>
                        <SearchBar
                            fullWidth
                            inputRef={searchInputRef}
                            {...props?.SearchBarProps}
                            placeholder={props.placeholder}
                            onChange={handleSearchInputChange}
                            value={searchValue}
                        />
                        <Button variant="contained" size="small" color="primary" onClick={(e) => handleOnClose()}>
                            {t('ProductPicker.doneButtonLabel')}
                        </Button>
                    </Stack>
                    <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
                        <Stack>
                            {options.map((o) => (
                                <Card
                                    key={o.id}
                                    elevation={0}
                                    sx={{
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: 'common.borderColor',
                                        bgcolor: isSelected(o.id) ? 'common.selectOptionSuccess' : undefined,
                                    }}>
                                    <CardActionArea
                                        component="div"
                                        onClick={handleAddButtonClick(o)}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Badge badgeContent={o.productVariants?.length} color="secondary">
                                            <ProductIcon sx={{ width: 64 }} src={o.imageLinks?.[0]} alt={o.title} />
                                        </Badge>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                {o.title}
                                            </Typography>
                                            <Stack direction="row" gap={11}>
                                                <Box>
                                                    <Typography variant="body2">
                                                        {t('BrandProductCard.wholesaleLabel')}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <NumericFormat
                                                            displayType={'text'}
                                                            prefix="$"
                                                            value={o.wholesale}
                                                            thousandSeparator
                                                            decimalScale={2}
                                                            valueIsNumericString
                                                            fixedDecimalScale
                                                        />
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2">
                                                        {t('BrandProductCard.msrpLabel')}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        <NumericFormat
                                                            displayType={'text'}
                                                            prefix="$"
                                                            value={o.msrp}
                                                            thousandSeparator
                                                            decimalScale={2}
                                                            valueIsNumericString
                                                            fixedDecimalScale
                                                        />
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                            <Box height={300} />
                        </Stack>
                    </Box>
                    <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {searchValue ? `Showing "${searchValue}"` : 'Showing All Products'}
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {options.length} products -{' '}
                            {options.reduce((acc, curr) => acc + curr.productVariants.length, 0)} variants
                        </Typography>
                    </Box>
                </Stack>
            </SwipeableDrawer>
        </Box>
    );
}

ProductPickerMobile.propTypes = {
    sx: Box?.propTypes?.sx,
    onAddProduct: PropTypes.func,
    onRemoveProduct: PropTypes.func,
    placeholder: PropTypes.string,
    SearchBarProps: PropTypes.any,
    value: PropTypes.any,
};
