import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    AccountMenu: {
                        profileLabel: 'Profile',
                        allOrdersLabel: 'All Orders',
                        settingsLabel: 'Settings',
                        logOutLabel: 'Log Out',
                    },
                    AddressSelect: {
                        addLabel: 'Add New Address',
                    },
                    BulkVariantPicker: {
                        colorLabel: 'Color',
                        quantityLabel: 'Qty',
                    },
                    BusinessSetup: {
                        title: 'Company Info',
                        description: 'Tell us about your organization so we can help you connect and grow your company',
                        businessNameLabel: 'Business Name',
                        businessTypeLabel: 'Business Type',
                        businessAddressLabel: 'Business Address',
                        cityLabel: 'City',
                        cityPlaceholder: 'Enter City',
                        state: 'State',
                        statePlaceholder: 'Enter State',
                        zipLabel: 'Zip',
                        zipPlaceholder: 'Enter Zip',
                        businessEmailLabel: 'Business Email',
                        phoneLabel: 'Phone',
                        termsOfUseText: 'By signing up, I agree with the Terms of Use & Privacy Policy.',
                        continueButtonText: 'Continue',
                    },
                    BusinessSetupSuccess: {
                        welcomeMessage: 'Welcome to Wholeshop!',
                        congratulationsMessage: 'Congratulations, your account has been successfully created.',
                        enjoyMessage:
                            'Enjoy a seamless partnership between retailers and brands and start building community and connection.',
                        getStartedButton: "Let's Get Started",
                    },
                    BusinessTypeSelect: {
                        brandOptionLabel: 'Brand / Vendor',
                        retailerOptionLabel: 'Retailer',
                    },
                    BusinessForm: {
                        organizationNameInputLabel: 'Business Name',
                        organizationTypeInputLabel: 'Business Type',
                        addressLine1: 'Street',
                        city: 'City',
                        state: 'State',
                        zip: 'Zip',
                        phone: 'Phone',
                        agreement: 'By signing up, I agree with the ',
                        termsOfUse: 'Terms of Use',
                        privacyPolicy: 'Privacy Policy',
                        submitButtonLabel: 'Continue',
                        email: 'Business Email',
                    },
                    CancelOrderDialog: {
                        title: 'Cancel Order',
                        message:
                            'Are you sure you want to cancel order #{{orderNumber}}? This will permanently cancel your order.',
                        cancel: 'Not Now',
                        confirm: 'Cancel Order',
                    },
                    Dealers: {
                        title: 'Dealers',
                        inviteButton: 'Invite Dealer',
                        backButton: 'Back To Dealers',
                        dealerInfo: 'Dealer Info',
                        emailLabel: 'Email',
                        lastOrderLabel: 'Last Order',
                        ordersLabel: 'Orders',
                        lastOrderAmountLabel: 'Last Order $',
                        detailsLabel: 'Details',
                        companyAddressLabel: 'Company Address',
                        companyContactLabel: 'Company Contact',
                        headquartersLabel: 'Headquarters',
                        addressesLabel: 'Addresses',
                        showMore: 'Show More',
                        showLess: 'Show Less',
                        activityLabel: 'Activity',
                        amountSpentLabel: 'Amount Spent',
                        totalOrdersLabel: 'Total Orders',
                        createNewOrderButton: 'Create New Order',
                        viewAllOrdersButton: 'View All Orders',
                        statusLabel: 'Status',
                        customerTypelabel: 'Customer Type',
                        noteLabel: 'Note',
                        pricingGroupLabel: 'Pricing Group',
                        paymentTermsLabel: 'Payment Terms',
                        emptyDealersTitle: 'No dealers are associated with your account yet.',
                        emptyDealersSubtitle:
                            'Dealers will appear here after they have accepted your invite and create an account with Wholeshop.',
                    },
                    DealerSettings: {
                        title: 'Dealer Settings',
                        subTitle:
                            'Adjust, add or modify your customer types, pricing groups and payment terms for your dealers.',
                        paymentTermsLabel: 'Payment Terms',
                        paymentTermsDescription:
                            'Manage your global payment terms. This can be changed in order details.',
                        termsLabel: 'Terms',
                        pricingGroupsLabel: 'Pricing Groups',
                        pricingGroupsDescription:
                            'Pricing groups allow you to assign dealer-specific price levels for your products.',
                        customerPaymentTermsLabel: 'Customer Payment Terms',
                    },
                    DueDate: {
                        today: 'Due today',
                        tomorrow: 'Due tomorrow',
                        dueOn: 'Due on {{date}}',
                        pastDue_one: 'Past Due {{count}} Day',
                        pastDue_other: 'Past Due {{count}} Days',
                    },
                    EditShippingAddressDialog: {
                        title: 'Edit Shipping Address',
                        cancelButton: 'Cancel',
                        submitButton: 'Save Changes',
                        selectNewAddressLabel: 'Select New Address',
                        addressLabelLabel: 'Address label',
                        addressLabel: 'Address',
                        cityLabel: 'City',
                        stateLabel: 'State',
                        zipLabel: 'Zip',
                        emailLabel: 'Email',
                        phoneLabel: 'Phone',
                        secondaryPhoneLabel: 'Secondary phone',
                    },
                    RemoveOrderItemDialog: {
                        title: 'Remove Product',
                        description: 'Are you sure you want to remove this product from the order?',
                        cancelBtnLabel: 'Cancel',
                        confirmBtnLabel: 'Remove',
                    },
                    /** @deprecated */
                    Cart: {
                        title: 'Cart',
                        searchMyCartLabel: 'Search my cart',
                        emptyLabel: 'No items in cart',
                        noMatchingResultsLabel: 'No results match your search criteria',
                        mpnLabel: 'MPN:',
                        skuLabel: 'SKU:',
                        removeProductDialog: {
                            title: 'Are you sure you want to remove this product?',
                            buttonLabel: 'Remove',
                        },
                    },
                    CartCheckout: {
                        shippingAddressLabel: 'Shipping Address',
                        editAddressLabel: 'Edit',
                        sendOrderLabel: 'Send Order',
                        clearCartLabel: 'Clear Cart',
                        emptyLabel: 'No items in cart',
                        chooseAddressLabel: 'Choose Address',
                        itemsLabel: 'Items',
                        unavailableProductsLabel_one: '{{count}} item unavailable',
                        unavailableProductsLabel_other: '{{count}} items unavailable',
                        unavailableProductsMessage: 'Sorry some items in this order are unavailable.',
                        proceedLabel: 'Proceed',
                    },
                    CartSummaryHeader: {
                        title: 'Shopping Cart',
                    },
                    ClearCartDialog: {
                        title: 'Clear Cart',
                        details: 'Are you sure you want to clear the cart? All items will be removed.',
                        cancelLabel: 'Cancel',
                        clearLabel: 'Clear',
                    },
                    CartHeader: {
                        title: 'Shopping Cart',
                    },
                    EditLineItemsDialog: {
                        title: 'Manage Order Details',
                        shippingLabel: 'Shipping',
                        shippingSubtitle: 'Apply shipping cost to order',
                        discountLabel: 'Discount',
                        discountSubtitle: 'Apply master discount to entire order',
                        termsLabel: 'Terms',
                        termsSubtitle: 'Apply terms to the order',
                        buttons: {
                            primaryLabel: 'Apply',
                            secondaryLabel: 'Cancel',
                            resetLabel: 'Reset',
                        },
                    },
                    Integrations: {
                        titleLabel: 'Integrations',
                        promptLabel:
                            'Connect Wholeshop to your external software for inventory, product importing/exporting, and more.',
                        shopifyPrompt: 'Connect Shopify to allow inventory pushes.',
                        connectedStoresLabel: 'Connected Stores',
                        connectButton: 'Connect',
                        cancelButton: 'Cancel',
                        disconnectButton: 'Disconnect',
                        noShopsLabel: 'No linked shops',
                        shopNameLabel: 'Shop Name',
                        connectShopTitleLabel: 'Connect Shop',
                        enterNamePlaceholder: 'Enter Name Placeholder',
                        warningLabel: 'Warning',
                        warningDescription:
                            'By disconnecting from your Shopify store, your products from Shopify will remain, but they will no longer stay in sync with the corresponding products in Shopify.',
                        connectedDialogTitle: 'Connected',
                        connectedDialogPendingTitle: 'Connecting...',
                        connectedDialogDescription: 'Shopify is now connected to {{shopName}}.',
                        connectedDialogClose: 'Close',
                    },
                    InviteSignUp: {
                        title: 'Create Account',
                        subtitle: 'Accept your invite by signing up below.',
                        firstNameLabel: 'First Name',
                        lastNameLabel: 'Last Name',
                        passwordLabel: 'Password',
                        confirmPasswordLabel: 'Confirm Password',
                        firstNamePlaceholder: 'Enter first name',
                        lastNamePlaceholder: 'Enter last name',
                        passwordPlaceholder: 'Enter password',
                        confirmPasswordPlaceholder: 'Confirm password',
                        welcomeLabel: 'Fundamentally changing how retail commerce happens',
                        signInPrompt: 'Already have an account?',
                        signInButton: 'Log in',
                        errorMessage: 'Account creation failed, please try again',
                    },
                    OrderAgainButton: {
                        label: 'Order Again',
                    },
                    OrderAgainDialog: {
                        title: 'Order Again',
                        orderLabel: 'Order #',
                        description:
                            'You already have items in your cart. Would you like to replace the current items in your cart with these or add these to your existing cart?',
                        replaceLabel: 'Replace Items',
                        addLabel: 'Add to Existing',
                    },
                    OrderItemCard: {
                        mpnLabel: 'MPN:',
                        upcLabel: 'UPC:',
                        skuLabel: 'SKU:',
                        qtyLabel: 'QTY:',
                    },
                    OrderSummaryCard: {
                        title: 'Order Summary',
                        totalQuantityLabel: 'Total Qty',
                        shippingLabel: 'Shipping',
                        subtotalLabel: 'Subtotal',
                        termsLabel: 'Terms',
                        orderTotalLabel: 'Order Total',
                        statusLabel: 'Status:',
                        addShippingLabel: 'Add Shipping',
                        addDiscountLabel: 'Add Discount',
                        discountLabel: 'Discount',
                        dueNowLabel: 'Due Now',
                        payOnScanLabel: 'Pay on Scan',
                        consignmentLabel: 'Consignment',
                        buttons: {
                            manageDetailsLabel: 'Manage Order Details',
                            declineOrderLabel: 'Decline Order',
                            sendInvoiceLabel: 'Send Invoice',
                            approveSendInvoiceLabel: 'Approve / Send Invoice',
                            cancelOrderLabel: 'Cancel Order',
                            viewAllOrdersLabel: 'View all orders',
                            clearCart: 'Clear cart',
                            continue: 'Continue',
                            sendOrder: 'Send order',
                            viewInvoiceLabel: 'View Invoice',
                            payNowLabel: 'Pay Now',
                            takePaymentLabel: 'Take Payment',
                            markAsShippedLabel: 'Mark as Shipped',
                            markAsPaidLabel: 'Mark as Paid',
                        },
                        alert: {
                            payoutsNotEnabled: 'Vendor is not able to accept payments at this time.',
                            completeStripeToCompletePayment: 'Complete Stripe onboarding to receive payments',
                        },
                        missingBusiness: 'There was an error displaying your order. Please reload.',
                        poweredBy: 'Powered by',
                    },
                    OrderSummaryDetails: {
                        totalQuantityLabel: 'Total Quantity',
                        subtotalLabel: 'Subtotal',
                        shippingLabel: 'Shipping',
                        discountLabel: 'Discount',
                        termsLabel: 'Terms',
                        orderTotalLabel: 'Order Total',
                    },
                    OrderStatusChip: {
                        paymentDueLabel: 'Payment Due',
                    },
                    OrderStatusSelect: {
                        emptyLabel: 'All Statuses',
                    },
                    BusinessSelect: {
                        brandLabel: 'All Brands',
                        dealerLabel: 'All Dealers',
                    },
                    OrderSortSelect: {
                        newestFirst: 'Newest First',
                        oldestFirst: 'Oldest First',
                        totalMost: 'Order Total ∧',
                        totalLeast: 'Order Total ∨',
                    },
                    PaymentDueDate: {
                        paymentLabel: 'Payment',
                    },
                    PaymentTerms: {
                        defaultPaymentTermsLabel: 'Default Payment Terms',
                        deleteDialogTitle: 'Delete Payment Term',
                        deleteDialogContent: 'Are you sure you want to delete this payment term?',
                        deleteDialogCancel: 'Cancel',
                        deleteDialogDelete: 'Delete',
                        paymentTermsLabel: 'Payment Terms',
                        paymentTermsDescription:
                            'Manage your global payment terms. This can be changed in order details.',
                        termsLabel: 'Terms',
                        customPaymentTermsLabel: 'Custom Payment Terms',
                        saveButton: 'Save',
                        addAnotherOption: 'Add Another Option',
                        NET_DAYS: 'Net Days',
                        EARLY_PAYMENT_DISCOUNT: 'Early Payment Discount',
                        CUSTOM: 'Custom',
                        selectTermTypeLabel: 'Select Term Type',
                        discountLabel: '% Discount',
                        discountDaysLabel: 'Days to Discount',
                        netDaysLabel: 'Net Days',
                        newCustomPaymentTermLabel: 'New Custom Payment Term',
                    },
                    PaymentTermsSelect: {
                        customPaymentTermsLabel: 'Custom Terms',
                        defaultPaymentTermsLabel: 'Default Terms',
                    },
                    ProductPricingGroup: {
                        titleLabel: 'Pricing Group',
                        percentOffLabel: '% Off MSRP',
                        priceLabel: 'Price',
                    },
                    ProductDetails: {
                        mpnLabel: 'MPN:',
                        skuLabel: 'SKU:',
                        descriptionLabel: 'Product Description',
                        wholesaleLabel: 'Wholesale',
                        msrpLabel: 'MSRP',
                        quantityLabel: 'Quantity',
                        addToCartLabel: 'Add to Cart',
                        addingToCartLabel: 'Adding to cart...',
                        specsLabel: 'Specs',
                        addedToCartLabel: 'Added to cart!',
                        cartBusinessIdMismatch:
                            'You currently have products from another business in your cart, complete existing order or clear cart to order this product.',
                    },
                    ProductDetailsForm: {
                        itemsSelectedLabel_one: '{{count}} item selected',
                        itemsSelectedLabel_other: '{{count}} items selected',
                        expandThisViewLabel: 'Expand this view',
                        bulkSelectDialogTitle: 'Bulk Select',
                    },
                    ProductPicker: {
                        addButtonLabel: 'Add',
                        removeButtonLabel: 'Remove',
                        doneButtonLabel: 'Done',
                        mpnLabel: 'MPN',
                        productNameLabel: 'Product Name',
                        wholesaleLabel: 'Wholesale',
                    },
                    BrandOrders: {
                        title: 'Orders',
                        newOrderButtonLabel: 'New Order', // deprecated
                        addAnOrderButtonLabel: 'Add an Order',
                    },
                    BrandOrderDetails: {
                        paidLabel: 'PAID',
                        paymentFailedLabel: 'Payment FAILED',
                        noteLabel: 'Note',
                        paymentPendingLabel: 'Payment Pending',
                        detailsLabel: 'Details',
                        itemsLabel: 'Items',
                        dialogs: {
                            deleteFailTitle: 'Unable to remove product',
                            deleteFailMessage: 'An order must have at least one product',
                            okButton: 'Ok',
                            declineOrderTitle: 'Decline Order',
                            declinePlaceholder: 'Decline Reason',
                            cancelButton: 'Cancel',
                            declineButton: 'Decline',
                        },
                    },
                    FinancialSettings: {
                        title: 'Financial Settings',
                        shippingLabel: 'Shipping',
                        shippingMessage: 'Apply shipping cost to order',
                        discountLabel: 'Discount',
                        discountMessage: 'Apply master discount to entire order',
                        addButton: 'Add',
                        updateButton: 'Update',
                        termsLabel: 'Payment terms',
                    },
                    TrackingNumbers: {
                        title: 'Tracking Number(s)',
                        addButton: 'Add',
                    },
                    QuickActionButton: {
                        inviteBrand: 'Invite a Brand',
                        inviteDealer: 'Invite a Dealer',
                        createProduct: 'Create a Product',
                        addAnOrder: 'Add an Order',
                    },
                    RetailOrderDetails: {
                        title: 'Order #',
                        emptyLabel: 'No items',
                        detailsLabel: 'Details',
                        dateLabel: 'Order Submitted:',
                        itemsLabel: 'Items',
                        paidLabel: 'PAID',
                        paymentFailedLabel: 'Payment FAILED',
                        quantityLabel: 'Qty',
                        skuLabel: 'SKU',
                        mpnLabel: 'MPN',
                        shipToLabel: 'Ship to',
                        statusLabel: 'Status:',
                        trackingLabel: 'Tracking:',
                        commentsLabel: 'Comments',
                        SuccessDialog: {
                            title: 'Success!',
                            orderLabel: 'Order #',
                            description: 'Thank you for your payment!',
                            buttonLabel: 'Ok',
                        },
                        Alert: {
                            orderCreated: 'Success!',
                            orderError: 'There was a problem processing your order. Please try again.',
                            paymentPending: 'Payment Pending',
                        },
                    },
                    OrderDetailsHeader: {
                        back: 'Back To Orders',
                        title: 'Order #',
                        orderNumberLabel: 'Order #',
                        placedOnLabel: 'Placed on',
                        paidOnLabel: 'Paid on',
                        createdOnLabel: 'Created on',
                        shippedOnLabel: 'Shipped on',
                        cancelledOnLabel: 'Cancelled on',
                    },
                    RetailOrderListItem: {
                        shipToLabel: 'Ship to',
                        totalLabel: 'Total',
                        orderNumberLabel: 'Order #',
                        viewDetailsLabel: 'View Order Details',
                        invoiceLabel: 'Invoice',
                        statusLabel: 'Status',
                        dateCreated: 'Order Date',
                        messageLabel: 'Message',
                        trackLabel: 'Track',
                    },
                    RetailOrders: {
                        title: 'All Orders',
                        searchPlaceholder: 'Search all orders',
                        totalOrdersLabel: 'Total Orders',
                        statusLabel: 'Status',
                    },
                    Settings: {
                        backToHomeButton: 'Back to Home',
                        manageAddressesLabel: 'Manage Addresses',
                        companyProfileLabel: 'Company Profile',
                        inviteLabel: 'Invite a {{businessType}}',
                        integrationsLabel: 'Integrations',
                        dealerLabel: 'Dealer',
                        brandLabel: 'Brand',
                        paymentLabel: 'Payment',
                        dealerSettings: 'Dealer Settings',
                    },
                    StickyHeader: {
                        joinButton: 'Join Today',
                        loginButton: 'Log In',
                        aboutButton: 'About',
                        newsButton: 'News',
                        homeButton: 'Home',
                    },
                    ConnectedBrandProducts: {
                        productsLabel_one: '{{count}} product',
                        productsLabel_other: '{{count}} products',
                        emptyProductsLabel: 'No products',
                        searchPlaceholder: 'Search my products',
                        msrpLabel: 'MSRP',
                        wholesaleLabel: 'Wholesale',
                        variantsLabel: 'Variants',
                        viewItemLabel: 'View item',
                        establishedLabel: 'Established:',
                        headquartersLabel: 'Headquarters:',
                        categoryLabel: 'Category',
                        priceLabel: 'Price',
                        aboutBrandLabel: 'About The Brand',
                        buttons: {
                            messagesLabel: 'Messages',
                            ordersLabel: 'Orders',
                            reportsLabel: 'Reports',
                            allFiltersLabel: 'All Filters',
                        },
                    },
                    Connections: {
                        title: 'Connections',
                        breadcrumbLabel: 'My Brands',
                        Connection: { locationLabel: 'Location' },
                        emptyStateLabel: 'No connections were found.',
                        pleaseClickLabel: 'Please click',
                        hereLabel: 'here',
                        toConnectLabel: 'to add new connections.',
                    },
                    UserProfile: {
                        title: 'My Profile',
                        firstNameInputLabel: 'First Name',
                        firstNameInputPlaceholder: 'Enter First Name',
                        titleInputLabel: 'Title',
                        titleInputPlaceholder: 'Enter Title',
                        lastNameInputLabel: 'Last Name',
                        lastNameInputPlaceholder: 'Enter Last Name',
                        emailInputLabel: 'Email',
                        emailInputPlaceholder: 'Enter Email',
                        phoneInputLabel: 'Phone',
                        secondaryPhoneInputLabel: 'Secondary Phone',
                        saveButton: 'Save Changes',
                        successMessage: 'Info Updated!',
                    },
                    Invite: {
                        emailInputLabel: 'Email',
                        title: 'Invite a {{type}}',
                        prompt: 'Seamlessly partner with your favorite {{type}} by inviting them to join Wholeshop!',
                        inviteSent: 'Invite Sent',
                        sendInviteLabel: 'Send an Invite',
                        sendInviteButtonLabel: 'Send Invite',
                        emailPlaceholder: 'Enter Email',
                        addButtonLabel: 'Add another',
                        errorMessage: 'There was an error sending your invite please try again.',
                    },
                    InviteAConnectionDialog: { title: 'Invite a {{type}}', brandLabel: 'Brand', dealerLabel: 'Dealer' },
                    CompanyProfile: {
                        bioInputLabel: 'Bio',
                        businessNameInputLabel: 'Business Name',
                        cityInputLabel: 'City',
                        stateInputLabel: 'State',
                        zipInputLabel: 'Zip',
                        businessTypeInputLabel: 'Business Type',
                        establishedInputLabel: 'Year Established',
                        bioPlaceholder: 'Tell us about your company (max 1000 characters)',
                        addressInputLabel: 'Address',
                        organizationEmailInputLabel: 'Categories Email',
                        phoneInputLabel: 'Phone',
                        secondaryPhoneInputLabel: 'Secondary Phone',
                        coverPhotoInputLabel: 'Add Cover Photo',
                        locationsInputLabel: 'Locations',
                        minOrderInputLabel: 'Order Minimum',
                        minOrderInputPlaceholder: 'Enter Value',
                        okButton: 'Ok',
                        characterLimitExceeded: 'Character limit succeeded (1000)',
                    },
                    MinimumOrder: {
                        title: 'Min. Order:',
                    },
                    CoverPhoto: {
                        promptLabel: 'Choose a file or drag and drop images',
                        recommendationLabel: 'Recommended size 950x265px',
                    },
                    AddAddressInput: {
                        titleLabel: 'Add New Address',
                        editTitleLabel: 'Edit Address',
                        addressInputLabel: 'Address',
                        addressInputPlaceholder: 'Enter Address',
                        cityInputLabel: 'City',
                        addressLabelInputLabel: 'Address Label',
                        addressLabelInputPlaceholder: 'Enter Label',
                        emailInputPlaceholder: 'Enter Email',
                        cityInputPlaceholder: 'Enter City',
                        phoneInputLabel: 'Phone',
                        phoneInputPlaceholder: 'Enter Number',
                        secondaryPhoneInputLabel: 'Secondary Phone',
                        secondaryPhoneInputPlaceholder: 'Enter Number',
                        emailInputLabel: 'Email',
                        streetInputLabel: 'About Us',
                        buildingTypeInputLabel: 'Address',
                        stateInputLabel: 'State',
                        zipCodeInputLabel: 'Zip',
                        zipCodeInputPlaceholder: 'Enter Zip',
                        countryInputLabel: 'Locations',
                        checkBoxPrompt: 'Mark as Primary Address',
                        submitNewAddressPrompt: 'Add Address',
                        submitExistingAddressPrompt: 'Save Address',
                        primaryLabel: 'Primary',
                    },
                    AddUserInput: {
                        addUserTitle: 'Add a user',
                        firstNameInputLabel: 'First Name',
                        firstNameInputPlaceholder: 'Enter First Name',
                        lastNameInputLabel: 'Last Name',
                        lastNameInputPlaceholder: 'Enter Last Name',
                        emailInputLabel: 'Email',
                        emailInputPlaceholder: 'Enter Email',
                        addNewUserButtonLabel: 'Add New User',
                    },
                    Payment: {
                        title: 'Payment Details',
                        prompt: 'Manage your payment details for your brand account.',
                        subTitle: 'Connect Payment Method',
                        stripePrompt: 'Connect to stripe to start receiving payments from your dealers.',
                        connectToStripeLabel: 'Connect to Stripe',
                        loginToStripeLabel: 'Login to Stripe',
                        revokeButtonLabel: 'Revoke Stripe Access',
                        refresh: 'Refresh stripe',
                        onboardingCompleteLabel: 'Setup complete',
                        payoutsEnabledLabel: 'Payouts enabled',
                        connectedBankAccountLabel: 'Bank account connected',
                    },
                    Brand: {
                        ProductForm: {
                            Pricing: {
                                msrpLabel: 'MSRP',
                                wholesaleLabel: 'Wholesale',
                                retailDefaultPriceLabel: 'Retail Default',
                                mapPricingLabel: 'MAP Pricing',
                            },
                        },
                    },
                    BrandClearOrderDialog: {
                        title: 'Clear Order?',
                        details: 'All products in order will be removed.',
                        cancelLabel: 'Cancel',
                        clearLabel: 'Clear',
                    },
                    BrandDrawer: {
                        dashboardLabel: 'Dashboard',
                        ordersLabel: 'Orders',
                        productsLabel: 'Products',
                        dealersLabel: 'Dealers',
                    },
                    BrandCreateOrder: {
                        title: 'New Order',
                        searchPlaceholderLabel: 'Search and add products',
                        shortSearchPlaceholderLabel: 'Add products',
                        totalQtyLabel: 'Total Qty',
                        subtotalValueLabel: 'Subtotal Value',
                        orderTotalValueLabel: 'Order Total Value',
                        emptyResultsLabel: 'Add Products by searching above',
                        clearLabel: 'Clear',
                        submitLabel: 'Submit',
                        productsTitleLabel: 'Product(s) added to pending order',
                    },
                    Edit: {
                        changeLabel: 'Change',
                        deleteLabel: 'Delete',
                    },
                    Error: {
                        apiDown: 'Looks like our API is down',
                        malformedRequest: 'Hey Developer, there is something wrong with your request',
                        notAuthorized: "You aren't authorized to see this",
                        notFound: "Hmmm.. we can't find the resource you've requested.",
                        somethingWrong:
                            'A network error occurred, please try again. If the problem persists please contact Wholeshop.',
                        somethingElseWrong: 'Oops an error occurred.',
                    },
                    Root: {
                        onboardingIncomplete: 'Complete stripe onboarding in order to receive payments',
                        paymentLinkLabel: 'Manage payment',
                        homeLabel: 'Home',
                    },
                    AppBar: {
                        cartLabel: 'Cart',
                        connectionsLabel: 'Connections',
                        productsLabel: 'Products',
                        ordersLabel: 'Orders',
                        userProfileLabel: 'User Profile',
                        settingsLabel: 'Settings',
                        signOutLabel: 'Sign Out',
                        myBrandsLabel: 'My Brands',
                        allOrdersLabel: 'All Orders',
                    },
                    BusinessAppBar: {
                        orders: 'Orders',
                        products: 'Products',
                    },
                    PriceSlider: {
                        goLabel: 'Go',
                    },
                    ProductCard: {
                        optionsLabel_one: '{{count}} option',
                        optionsLabel_other: '{{count}} options',
                        msrpLabel: 'MSRP',
                    },
                    ProductHeader: {
                        msrpLabel: 'MSRP',
                    },
                    BrandProductCard: {
                        mpnLabel: 'MPN',
                        skuLabel: 'SKU',
                        msrpLabel: 'MSRP',
                        upcLabel: 'UPC',
                        thirdPartyIdLabel: 'Third Party ID',
                        quantityLabel: 'Qty',
                        variantOptionsLabel: 'Variant Option(s)',
                        variantsLabel: 'Variants',
                        wholesaleLabel: 'Wholesale',
                    },
                    BrandProductEditButtons: {
                        createProductLabel: 'Create Product',
                        saveChangesLabel: 'Save Changes',
                    },
                    BrandProductTable: {
                        title: 'Products',
                        createProductBtn: 'Create Product',
                        showArchived: 'Show Archived',
                        hideArchived: 'Hide Archived',
                        syncWithShopify: 'Sync with Shopify',
                    },
                    SelectDealer: {
                        searchLabel: 'Search',
                        addDealerLabel: 'Add Dealer',
                        doneLabel: 'Done',
                        addressesLabel: 'Addresses',
                    },
                    AboutPage: {
                        about: 'About Wholeshop',
                        aboutDetail:
                            'Wholeshop is the brainchild of our founder, Dan Fairbanks. Dan’s experience in both retail and software development makes him uniquely able to identify and solve the foundational issues that persist in retail.',
                        article:
                            'I was sitting in the back office of my running store that I\'ve worked hard to build for the past 3 years, staring at the computer screen thinking "how am I going to pay these invoices?” I opened my stores to spread the joy of running, not sit on last season’s inventory and growing debt. Then my manager walked in and told me there’s a customer asking if we carry the latest New Balance model. Dang it: I forgot about futures orders. Nearly all small retailers know this sinking feeling in their gut. I want to carry everything, but I can’t take any more inventory risk. How do I keep up? How am I going to pay for new inventory? I was overwhelmed and discouraged.<br/><br/>' +
                            'I tried exploring new payment terms, opening new distribution channels, finding new brands that had a hit product. But because I was drowning in the day-to-day operations of my store I could never get these new programs off the ground. I ended up doing what many other small retailers do: I discounted the products to get them out of my inventory.<br/><br/>' +
                            'Eventually I realized that running is a hobby for me, but helping people get their products into the hands of the right customers is my passion. So I closed my stores and began the work of solving these problems for all small, speciality retailers.<br/><br/>' +
                            'I knew there had to be a better way.',
                        articleSubTitle: 'founder',
                        articleTitle: "Dan's Story",
                        subArticleTitle: 'Research & Discovery',
                        subArticle:
                            'This led Dan on a journey of research and discovery. He talked to thousands of retailers and hundreds of brands—big brands and small brands alike. He spoke with industry experts, supply chain experts at Walmart, companies across the country.<br/><br/>' +
                            'Dan hired a crack team with massive experience building SaaS solutions. This team has invested years researching, testing, and interviewing users to develop the best, focused solution for B2B wholesale.<br/>' +
                            'Together, we have taken everything that didn’t work and threw it out. We make sure to save everything that did work. We finally put it all together into a process that fundamentally changes how retail commerce happens, a process that’s better for brands and better for retailers. Amazingly: everyone makes more money than before.<br/><br/>' +
                            '<strong>After these years of research & development, the result is Wholeshop.</strong>',
                        cardsTitle: 'At Wholeshop, we believe that...',
                        cards: [
                            {
                                cardTitle: 'Brand and Partners',
                                cardDescription:
                                    'Brands and partners should compliment each other, not struggle against each other.',
                            },
                            {
                                cardTitle: 'Insights',
                                cardDescription:
                                    'Brands should be able to have the same insight in brick and mortar sales as retailers do, or as D2C brands have in e-commerce sales.',
                            },
                            {
                                cardTitle: 'Community',
                                cardDescription:
                                    'Retailers are better at building community, connection, and relationships than brands.',
                            },
                            {
                                cardTitle: 'Understanding Users',
                                cardDescription:
                                    'Brands can be even better at understanding user needs when they know who their users are.',
                            },
                        ],
                    },
                    LandingPage: {
                        section1Title:
                            '<strong>Wholeshop</strong> is cloud-based wholesale collaboration for brands and retailers',
                        section1Description:
                            'Work more efficiently to place, receive, and process wholesale orders. Free for retailers, profitable for brands.',
                        getDemoButton: 'Get a demo now',
                        howItWorksTitle: 'How <strong>Wholeshop</strong> Works',
                        howItWorksDescription:
                            '<strong>Wholeshop</strong> is dedicated to improve efficiency for the entire wholesale process. Our platform gives brands and retailers a one-to-one strong relationship. Brands can establish MOQs and discounts, and dealers can use the order-chat feature to ask questions or make special requests.',
                        learnMoreButton: 'Learn more',
                        growBusinessTitle: 'Grow Your Business with <strong>Wholeshop</strong>',
                        growBusinessDescription1:
                            'Brands will be able to find new retailers, and retailers will expand their offerings with new high-quality brands. <strong>Wholeshop</strong> helps build B2B networks.',
                        growBusinessDescription2:
                            'Designed from the ground up to work for everything from small regional wholesale businesses up through multinational billion-dollar enterprises, <strong>Wholeshop</strong> is built to scale. ',
                        getStartedTitle: 'Wholeshop Helps Brands Get Started Fast',
                        getStartedDescription1:
                            'Integrate with your existing Shopify store and import your products in a snap. Not on Shopify? No problem: <strong>Wholeshop</strong> is a stand-alone solution that works with your existing processes and software.',
                        getStartedDescription2:
                            'Companies who use <strong>Wholeshop</strong> can have their B2B online presence ready in as little as 24 hours.',
                        completeSolutionTitle: 'The Wholeshop Platform Is A Complete Solution',
                        card1Title: 'Exception order management',
                        card1Description:
                            'Dealers can place orders online and vendors can handle and process the orders in one central location. Apply discounts by location, quality, or other criteria, or just give a one-off discount just because. With <strong>Wholeshop</strong>, the WORK part of order management is pleasant and fun.',
                        card2Title: 'Convenient vendor—dealer chat',
                        card2Description:
                            'Save yourself from searching through your backlog of emails to find questions asked or what was agreed upon. With <strong>Wholeshop</strong> everyone is working with complete transparency so you can get more done, more quickly, with greater customer satisfaction.',
                        card3Title: 'Integrated payment processing',
                        card3Description:
                            'With <strong>Wholeshop</strong> everything happens in one spot: fewer things to manage, less mess. Brands can simplify accounts receivable; retailers improve workflow',
                        card4Title: 'Simplify product management with Shopify integration.',
                        card4Description:
                            'If you are selling your products in a D2C Shopify storefront, <strong>Wholeshop</strong> can import your whole catalog (or just a selection) to get product details in a single step, and then to keep them in sync.',
                        getStartedButton: 'Get Started Now',
                        testimonial:
                            '"Wholeshop is great. It has everything we needed and nothing we don’t. Wholeshop helps us work more efficiently."',
                        testimonialName: '— LUISA H., CYCLING BRAND B2B Sales MANAGER',
                    },
                    Media: {
                        addMediaButtonLabel: 'Add Media',
                        defaultLabel: 'Default',
                        description: 'Images, Videos, PDFs, Pricing, etc.',
                        makeDefaultButtonLabel: 'Make Default',
                        removeButtonLabel: 'Remove',
                    },
                    NewsPage: {
                        news: 'News',
                        title: 'Wholeshop News',
                        subTitle:
                            'Welcome to the Wholeshop newsroom, your go-to destination for the latest news, stories, and announcements from Wholeshop.',
                        articleTitle:
                            'Wholeshop Unveils New Brand Identity: Formerly Thatch, Now a Revolutionary Wholesale Collaboration Platform for Brands and Retailers',
                        articleDate: 'Oct 1, 2024',
                        article:
                            'Today, Wholeshop announces the official rebranding of its company from Thatch, marking a bold new step forward in its mission to streamline wholesale operations for brands and retailers. Wholeshop provides a powerful, efficient, and intuitive platform designed to simplify wholesale order management, benefitting both suppliers and retail buyers. With Wholeshop, brands and retailers can collaborate more seamlessly than ever before, driving efficiency and profitability across the entire retail supply chain.<br/><br/>' +
                            'After nearly two years of market research and software development, Wholeshop’s 1.0 release is live and ready to transform wholesale workflows. Designed for ease of use and operational clarity, Wholeshop offers a centralized solution for brands and retailers to place, receive, and process wholesale orders. The platform is free for retailers, while offering significant cost-saving benefits for brands.<br/>' +
                            '<strong>A Streamlined Experience for Brands with Wholeshop</strong><br/><br/>' +
                            'Wholeshop equips brands with a secure, unified space to manage and display their wholesale products. Key features include real-time access to wholesale pricing, automated discounting, and the ability to process orders faster than ever before. Brands benefit from fewer errors, happier dealers, and quicker turnarounds, with automated tools encouraging sign-ups, first orders, and timely reorders. <br/><br/>' +
                            '“We’ve built a platform that helps brands take their wholesale operations to the next level. With Wholeshop, brands can manage everything from product listings to pricing and order fulfillment in one place, resulting in smoother transactions and stronger retailer relationships,” said Dan Fairbanks, CEO of Wholeshop.<br/>' +
                            '<strong>Wholeshop Provides Enhanced Wholesale Buying for Retailers</strong><br/><br/>' +
                            'For retailers, Wholeshop simplifies working with multiple brands by bringing them all together in one central location. Retailers can easily interact with their suppliers to access products and deals tailored to their shop’s specific needs. With Wholeshop’s user-friendly interface, retailers can track the status of past, current, and future orders—all from one URL.<br/><br/>' +
                            '“Wholeshop gives retailers unprecedented control over their wholesale partnerships. They can work directly with top-tier brands, enjoy personalized offers, and track all their orders in one place, giving them a clear view of their wholesale operations,” Fairbanks continued.<br/>' +
                            '<strong>What’s Next for Wholeshop?</strong><br/><br/>' +
                            'Wholeshop integrates seamlessly with Shopify and other e-commerce systems, while also functioning as a powerful standalone B2B portal. Whether or not brands or retailers are using a particular e-commerce platform, Wholeshop allows them to experience wholesale efficiency and control like never before.<br/><br/>' +
                            '“Our rebranding to Wholeshop isn’t just about a name change—it’s about setting a new standard for wholesale collaboration. We’re excited to launch a platform that not only helps our users improve efficiency but also maximizes profitability in their businesses,” said Fairbanks.',
                        aboutWholeShopTitle: 'About Wholeshop',
                        aboutWholeShop:
                            'Wholeshop is a next-generation wholesale collaboration platform designed for brands and retailers. With its robust, easy-to-use tools, Wholeshop enables brands to manage their wholesale product offerings, pricing, and orders in one secure location. Retailers benefit from working directly with top brands, gaining access to customized products and streamlined order management. Wholeshop helps businesses increase efficiency and profitability, driving success across the retail economy.<br/><br/>' +
                            'To learn more about Wholeshop or request a demo, visit <link1>whole.shop</link1> or contact us at <link2>hello@whole.shop</link2>.',
                    },
                    OrderNote: {
                        inputLabel: 'Note',
                        placeholder: 'Add new comment',
                        emptyNoteLabel: '(empty)',
                        submitButtonLabel: 'Post',
                        clearButtonLabel: 'Clear',
                    },
                    SubmitOrderDialog: {
                        title: 'Submit Order',
                        orderLabel: 'Order',
                        inputLabel: 'Note',
                        button: {
                            addNoteLabel: 'Add Note',
                            continueLabel: 'Send without Note',
                        },
                    },
                    SideCart: {
                        subtotalLabel: 'Subtotal',
                        itemsLabel: 'items',
                        reviewOrderButtonLabel: 'Review Order',
                        poweredByLabel: 'Powered by',
                    },
                    PoweredByThatch: {
                        poweredByLabel: 'Powered by',
                    },
                    SignIn: {
                        signInLabel: 'Sign In',
                        signInPrompt: 'Welcome back! Please enter your details.',
                        emailLabel: 'Email',
                        passwordLabel: 'Password',
                        forgotPasswordButton: 'Forgot Password',
                        signUpButton: 'Sign Up',
                        haveAccountLabel: `Don't have an account?`,
                        welcomeLabel:
                            'Cloud-based<br/>wholesaling and<br/>collaboration<br/>for brands and<br/>retailers',
                    },
                    SignUp: {
                        createAccountLabel: 'Create Account',
                        signUpPrompt: 'Sign up to collaborate simple and seamlessly.',
                        userEmailLabel: 'User Email',
                        passwordLabel: 'Password',
                        confirmPasswordLabel: 'Confirm Password',
                        signInButton: 'Sign In',
                        haveAccountLabel: `Already have an account?`,
                        welcomeLabel: 'Fundamentally<br/>changing how<br/>retail commerce<br/>happens.',
                    },
                    ForgotPassword: {
                        forgotPasswordLabel: 'Forgot Password',
                        forgotPasswordPrompt: 'Enter your email for instructions on how to reset it.',
                        emailLabel: 'Email',
                        backToSignInButton: 'Back To Sign In',
                        resetPasswordLabel: 'Reset Password',
                        emailPlaceholder: 'Enter email address',
                    },
                    ConfirmPassword: {
                        verificationLabel: 'Verification',
                        verificationPrompt:
                            'Enter the code we sent to your email address and enter your new password below.',
                        codeLabel: 'Code',
                        enterCodePlaceholder: 'Enter code',
                        newPasswordLabel: 'New Password',
                        newPasswordPlaceholder: 'Create password',
                        confirmNewPassword: 'Confirm New Password',
                        confirmPasswordPlaceholder: 'Confirm password',
                        resendCodeButton: 'Resend Code',
                        resetPasswordButton: 'Reset Password',
                    },
                    CreateNewPassword: {
                        title: 'Create New Password',
                        newPasswordPrompt: 'Generate your own new password',
                        newPasswordLabel: 'New Password',
                        newPasswordPlaceholder: 'Create Password',
                        confirmNewPassword: 'Confirm New Password',
                        confirmPasswordPlaceholder: 'Confirm Password',
                        updatePasswordButton: 'Submit',
                        backToSignInButton: 'Back to Sign In',
                        errorTitle: 'Error',
                        errorMessage:
                            'An unknown error occurred when changing your password. To try again, please return to the sign in page and re enter your email and temporary password.',
                    },
                    ConfirmSignUp: {
                        verifyLabel: 'Verify Email',
                        verificationPrompt:
                            'Your code is on the way. To log in, enter the code we emailed to {{email}}. It may take a minute to arrive.',
                        confirmationCodeLabel: 'Confirmation Code',
                        enterCodePlaceholder: 'Enter confirmation code',
                        resendEmailButton: 'Resend Email',
                        confirmButton: 'Confirm',
                    },
                    OrderItems: {
                        showLessButton: 'Show Less',
                        moreButton: '+{{number}} more',
                        variantsLabel: '{{count}} Variant',
                        variantsLabel_other: '{{count}} Variants',
                    },
                    ManageAddresses: {
                        titleLabel: 'Manage Shipping Addresses',
                        subTitleLabel: 'Manage Addresses',
                        promptLabel: 'We ship products to the addresses associated with this store front.',
                        addAddressButtonLabel: 'Add Address',
                    },
                    EditMenu: {
                        deleteAction: 'Delete',
                        editAction: 'Edit',
                    },
                    ThatchHeader: {
                        title: 'Thatch is now Wholeshop!',
                    },
                    PricingGroupEditDialog: {
                        applyChanges: 'Apply Changes',
                        option1:
                            'Apply to products and variants that used the previous set % off MSRP. Individual product or variant price changes will not be affected.',
                        option2:
                            'Apply to all products and variants overriding any individual product or variant price changes.',
                        note: 'Note: This will apply to all pricing groups that have been changed.',
                        cancel: 'Cancel',
                    },
                    PricingGroupList: {
                        labels: {
                            pricingGroup: 'Pricing Group',
                            discountPercentage: '% Off MSRP',
                        },
                        actions: {
                            addOption: 'Add Another Option',
                            discard: 'Discard',
                            save: 'Save',
                        },
                        messages: {
                            updateSuccess: 'Update Successful',
                        },
                    },
                    VariantPricingGroupList: {
                        labels: {
                            title: 'Pricing Group',
                            percentOff: '% Off MSRP',
                            price: 'Price',
                        },
                        actions: {
                            discard: 'Discard Changes',
                            save: 'Save',
                        },
                        messages: {
                            updateSuccess: 'Pricing Groups Updated',
                        },
                    },
                },
            },
        },
    });

export default i18n;
