import { Box, Button, Divider, List, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Fog, LightGray, White } from '../../theme';
import CaretDownIcon from '../../icons/CaretDownIcon';

export default function ButtonWithMenu(props) {
    const { options = [], onChange, value, defaultValue = '', placeholder = '' } = props;
    const isControlled = value !== undefined;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedOption, setSelectedOption] = React.useState(defaultValue);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleMenuItemClick = (option) => {
        return () => {
            handleClose();
            if (!isControlled) {
                setSelectedOption(option);
            } else {
                onChange?.(option.value);
            }
        };
    };

    const optionLabel = isControlled ? options.find((option) => option.value === value)?.label : selectedOption?.label;

    return (
        <>
            <Button
                aria-describedby={id}
                variant="text"
                onClick={handleClick}
                sx={{
                    backgroundColor: White,
                    borderRadius: 25,
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    gap: 1,
                    px: '14px',
                    py: '8px',
                    border: `1px solid ${Fog}`,
                    minWidth: { sm: 216 },
                }}>
                <Typography
                    component="span"
                    variant="textSm"
                    sx={{
                        color: LightGray,
                        fontWeight: 500,
                        lineHeight: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>
                    {optionLabel || placeholder}
                </Typography>
                <CaretDownIcon sx={{ color: 'common.Teal', height: 24, width: 24 }} />
            </Button>
            <Menu
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        mt: 2,
                        width: 205,
                        borderRadius: 4,
                    },
                }}>
                <List
                    aria-label="menu"
                    sx={{
                        '& .MuiMenuItem-root': { fontSize: '14px', fontWeight: 600 },
                        '& .MuiListItemIcon-root': { color: 'common.Teal' },
                    }}>
                    {options.map((option) => (
                        <React.Fragment key={option.value}>
                            <MenuItem onClick={handleMenuItemClick(option)}>
                                {option?.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
                                {option?.label}
                            </MenuItem>
                            {option.divider ? (
                                <Box sx={{ px: 2, py: 1 }}>
                                    <Divider />
                                </Box>
                            ) : null}
                        </React.Fragment>
                    ))}
                </List>
            </Menu>
        </>
    );
}

ButtonWithMenu.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
};
