import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Alert, Box, Snackbar, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import _ from 'lodash';
import { confirmSignUp, resendSignUpCode, signIn } from 'aws-amplify/auth';
import { Form, redirect, useFetcher, useNavigate } from 'react-router-dom';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import TextField from '@mui/material/TextField';
import { usePassword } from '../login/PasswordProvider.jsx';
import { maskEmail } from '../../helpers/HelperFunctions.jsx';
import { useTranslation } from 'react-i18next';
import UserCircleCheckIcon from '../../icons/UserCircleCheckIcon.jsx';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const intent = formData.get('intent');
        const confirmationCode = formData.get('confirmation');
        const password = formData.get('password');
        const username = formData.get('username');

        if (intent === 'submitCode') {
            await confirmSignUp({ confirmationCode, username });
            if (!_.isEmpty(password)) {
                await signIn({ username, password });
            }
            localStorage.removeItem('username');
            return redirect('/');
        }
        if (intent === 'resendCode') {
            await resendSignUpCode({ username });
            return { status: 'code resent' };
        }
        return null;
    } catch (error) {
        return {
            error,
        };
    }
}

export default function ConfirmSignUp() {
    const theme = useTheme();
    const [confirmationCode, setConfirmationCode] = useState('');
    const username = localStorage.getItem('username') ?? '';
    const navigate = useNavigate();
    const { password = '' } = usePassword();
    const fetcher = useFetcher();
    const [currentIntent, setCurrentIntent] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setSnackbarOpen(fetcher.data?.status === 'code resent');
    }, [fetcher.data, fetcher.state]);

    useEffect(() => {
        // if password was cleared from the context (I.E. from browser reload) return to sign in to retrieve it again
        if (_.isEmpty(password)) {
            navigate('/');
        }
    }, []);

    const resendCode = () => {
        setCurrentIntent('resendCode');
        fetcher.submit(
            {
                intent: 'resendCode',
                username,
            },
            { method: 'post' }
        );
    };

    const submitCode = () => {
        setCurrentIntent('submitCode');
        fetcher.submit(
            {
                intent: 'submitCode',
                username,
                confirmation: confirmationCode,
                password,
            },
            { method: 'post' }
        );
    };

    const confirmEnabled = () => {
        return !_.isEmpty(confirmationCode);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
            }}>
            <Form method="POST">
                <input hidden name="password" value={password} readOnly />
                <input hidden name="username" value={username} readOnly />
                <Stack gap={10} sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack
                        sx={{
                            alignItems: 'flex-start',
                            width: {
                                xs: '90%',
                                sm: '400px',
                            },
                            mt: 15,
                        }}>
                        <UserCircleCheckIcon sx={{ color: 'common.Midnight', width: 48, height: 48 }} />
                        <Stack gap={2}>
                            <Typography variant="displayMd">{t('ConfirmSignUp.verifyLabel')}</Typography>
                            <Typography sx={{ color: theme.palette.common.LightGray }}>
                                {t('ConfirmSignUp.verificationPrompt', { email: maskEmail(username) })}
                            </Typography>
                            <Stack gap={3}>
                                <FormInputLabel text={t('ConfirmSignUp.confirmationCodeLabel')}>
                                    <TextField
                                        fullWidth
                                        placeholder={t('ConfirmSignUp.enterCodePlaceholder')}
                                        name="confirmation"
                                        value={confirmationCode}
                                        onChange={(event) => setConfirmationCode(event.target.value)}
                                    />
                                </FormInputLabel>
                                <Stack
                                    gap={1}
                                    direction="row"
                                    sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <LoadingButton
                                        isLoading={
                                            currentIntent === 'resendCode' &&
                                            (fetcher.state === 'submitting' || fetcher.state === 'loading')
                                        }
                                        onClick={resendCode}
                                        variant="outlined"
                                        color="secondary"
                                        sx={{ flex: 1 }}>
                                        {t('ConfirmSignUp.resendEmailButton')}
                                    </LoadingButton>
                                    <LoadingButton
                                        disabled={!confirmEnabled()}
                                        isLoading={
                                            currentIntent === 'submitCode' &&
                                            (fetcher.state === 'submitting' || fetcher.state === 'loading')
                                        }
                                        variant="contained"
                                        color="primary"
                                        onClick={submitCode}
                                        sx={{ flex: 1 }}>
                                        {t('ConfirmSignUp.confirmButton')}
                                    </LoadingButton>
                                </Stack>
                                {fetcher.data?.error ? (
                                    <Typography
                                        sx={{
                                            color: 'common.Alert',
                                        }}>{`Error: ${fetcher.data.error.message}`}</Typography>
                                ) : null}
                            </Stack>
                        </Stack>
                    </Stack>
                    <WholeshopLogo sx={{ height: '25.7px', width: '150px', mb: 5 }} />
                </Stack>
            </Form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    New verification code sent!
                </Alert>
            </Snackbar>
        </Box>
    );
}
