import Typography from '@mui/material/Typography';
import * as React from 'react';
import AppLayout from '../components/AppLayout.jsx';
import Box from '@mui/material/Box';

function PrivacyPolicy() {
    return (
        <AppLayout>
            <Typography variant="h4" align="center" gutterBottom>
                Privacy Policy
            </Typography>
            <Box sx={{ marginLeft: 2 }}>
                <Typography variant="h6" gutterBottom>
                    1. Introduction
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        Welcome to Thatch!
                    </Typography>
                    <Typography variant="body1" paragraph>
                        This Privacy Policy was written to help you better understand how we collect, use, and store
                        your information. Since technology and privacy laws are always changing, we may occasionally
                        update this policy. If a significant change is made, we will be sure to post a notice in your
                        account admin. If you continue to use Thatch after these changes are posted, you agree to the
                        revised policy.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        By signing up for any of the products or services offered by Thatch (together, the “Services”),
                        or dealing with a merchant using Thatch’s Services, you are agreeing to the terms of this
                        Privacy Policy and, as applicable, the Thatch Terms of Service. This Privacy Policy is a legally
                        binding agreement between you (and your client, employer, or another entity if you are acting on
                        their behalf) as the user of the Services (referred to in this Privacy Policy as “you” or
                        “your”) and Thatch Corporation If we add any new features or tools to our Services, they will
                        also be subject to this policy.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        When we use the term “Personal Information” in this policy, it means any information related to
                        an identifiable individual, but does not include the name, title, business address, or telephone
                        number of an employee of an organization.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We will keep your Personal Information accurate, complete, and up to date with the information
                        that you provide to us. If you request access to your Personal Information, we will inform you
                        of the existence, use, and disclosure of your Personal Information as allowed by law, and
                        provide you access to that information. We will always ask for your consent before using your
                        Personal Information for a purpose other than those described in this Privacy Policy.
                    </Typography>
                </Box>
                <Typography variant="h6" gutterBottom>
                    2. Information from merchants
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        What information do we collect from merchants and why?
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            ● We collect your name, company name, address, email address, phone number(s), and credit
                            card details.
                        </Typography>
                        <Box sx={{ marginLeft: 2 }}>
                            <Typography variant="body1" paragraph>
                                ○ We need this information to provide you with our Services; for example, to confirm
                                your identity, contact you, and invoice you.
                            </Typography>
                        </Box>
                        <Typography variant="body1" paragraph>
                            ● We also collect data about how and when you access your account, including information
                            about the device and browser you use, your network connection, and your IP address.
                        </Typography>
                        <Box sx={{ marginLeft: 2 }}>
                            <Typography variant="body1" paragraph>
                                ○ We need this information to give you access to and improve our Services.
                            </Typography>
                        </Box>
                        <Typography variant="body1" paragraph>
                            ● Upon completing the Thatch Plan Sign-up / Merchant Setup, we will create a Stripe
                            submerchant account on your behalf. During that process we collect personal and business
                            information including your business name, DBA, business address, business type, EIN, date of
                            birth, Social Security Number, phone number, and email.
                        </Typography>
                        <Box sx={{ marginLeft: 2 }}>
                            <Typography variant="body1" paragraph>
                                ○ We need this information to create a Stripe submerchant account for you and to provide
                                you with Stripe services. Upon creating a Stripe submerchant account, you agree to
                                Stripe’s submerchant terms and conditions.
                            </Typography>
                        </Box>
                        <Typography variant="body1" paragraph>
                            ● We collect Personal Information about your customers that you share with us or that
                            customers provide while shopping or during checkout.
                        </Typography>
                        <Box sx={{ marginLeft: 2 }}>
                            <Typography variant="body1" paragraph>
                                ○ We use this information to provide you with our Services and so that you can process
                                orders and better serve your customers.
                            </Typography>
                        </Box>
                        <Typography variant="body1" paragraph>
                            ● We will also use Personal Information in other cases where you have given us your express
                            permission.
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        When do we collect this information?
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            ● We collect Personal Information when you sign up for our Services, when you access our
                            Services, or otherwise provide us with the information.
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                    3. Information from our merchants’ customers
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        What information do we collect and why?
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            ● We collect our merchants’ customers’ name, email, shipping and billing address, payment
                            details, company name, phone number, IP address, and device data.
                        </Typography>
                        <Box sx={{ marginLeft: 2 }}>
                            <Typography variant="body1" paragraph>
                                ○ We need this information to provide merchants with our Services, including supporting
                                and processing orders, authentication, and processing payments. We also use this
                                information to improve our Services.
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="body1" paragraph>
                        When do we collect this information?
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            ● Information is collected when a merchant’s customer uses or accesses our Services, such as
                            when a customer visits our website, places an order, or signs up for an account.
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                    4. Information from Thatch website visitors and support users
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        What information do we collect and why?
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            ● From Thatch website visitors, we collect information about the device and browser you use,
                            your network connection, and your IP address. We may also receive Personal Information when
                            you purchase tickets or make other requests to Thatch via any of our websites. We use this
                            information to service your account, enhance our Services, and answer any questions you may
                            have.
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        When do we collect this information?
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            ● We collect this information when you visit Thatch-hosted webpages or engage with us either
                            by email, web form, instant message, phone, or post content on our websites (including
                            forums and blogs). We also collect any additional information that you might provide to us.
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                    5. What do we do with your Personal Information when you terminate your relationship with us?
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        ● We will continue to store archived copies of your Personal Information for legitimate business
                        purposes and to comply with the law.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ● We will continue to store anonymous or anonymized information, such as website visits, without
                        identifiers, in order to improve our Services.
                    </Typography>
                </Box>
                <Typography variant="h6" gutterBottom>
                    6. What we don’t do with your Personal Information
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        ● We do not and will never share, disclose, sell, rent, or otherwise provide Personal
                        Information to other companies for the marketing of their own products or services.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ● We do not use the Personal Information we collect from you or your customers to contact or
                        market to your customers or directly compete with you. However, Thatch may contact or market to
                        your customers if we obtain their information from another source, such as from the customers
                        themselves.
                    </Typography>
                </Box>
                <Typography variant="h6" gutterBottom>
                    7. How do we keep your Personal Information secure?
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        ● We follow industry standards on information security management to safeguard sensitive
                        information, such as financial information, intellectual property, employee details, and any
                        other Personal Information entrusted to us. Our information security systems apply to people,
                        processes, and information technology systems on a risk-management basis.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        ● No method of transmission over the Internet, or method of electronic storage, is 100% secure.
                        Therefore, we cannot guarantee the absolute security of your Personal Information.
                    </Typography>
                </Box>
                <Typography variant="h6" gutterBottom>
                    8. Control and access to your Personal Information
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="body1" paragraph>
                        You retain all rights to your Personal Information and can access it any time. In addition,
                        Thatch takes reasonable steps to allow you to correct, amend, delete, or limit the use of your
                        Personal Information. You can update many types of Personal Information, such as payment or
                        contact information, directly within your account settings. If you are unable to change your
                        Personal Information within your account settings, please contact us to make the required
                        changes. It’s important to remember that if you delete or limit the use of your Personal
                        Information, the Services may not function properly. If you have any questions about your
                        Personal Information or this policy, please contact us at: support@thatch.tech.
                    </Typography>
                </Box>
            </Box>
        </AppLayout>
    );
}

export default PrivacyPolicy;
