import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import ThatchAvatar from './ThatchAvatar/ThatchAvatar.jsx';
import Typography from '@mui/material/Typography';
export default function UserCell({ user }) {
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                display: 'flex',
            }}>
            <ThatchAvatar
                src={user.profileImageUrl}
                alt={user.firstName + ' ' + user.lastName}
                sx={{
                    width: 50,
                    height: 50,
                    border: 'none',
                    flexShrink: 0,
                    marginRight: 1,
                }}
            />
            <Stack
                direction="row"
                sx={{
                    flexGrow: 1,
                    alignItems: 'center',
                    minWidth: 0,
                }}>
                <Stack
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        minWidth: 0,
                        flexGrow: 1,
                    }}>
                    <Typography
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                        {user.username}
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
}

UserCell.propTypes = {
    user: PropTypes.any,
};
