import React from 'react';
import { NumericFormat } from 'react-number-format';
import Chip from '@mui/material/Chip';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import toUpper from 'lodash/toUpper';
import trim from 'lodash/trim';
import words from 'lodash/words';

export const isValidEmail = (email) => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
};

// DataGridPro Helpers
export const createCurrencyColumn = ({ field, headerName, width = 150, align, headerAlign, flex = 0 }) => {
    return {
        field,
        headerName,
        width,
        align,
        headerAlign,
        flex,
        renderCell: (params) => {
            const value = isEmpty(params.value) ? 0 : params.value;
            return (
                <NumericFormat
                    value={value}
                    displayType={'text'}
                    thousandSeparator
                    decimalScale={2}
                    valueIsNumericString
                    prefix="$"
                    fixedDecimalScale={true}
                />
            );
        },
    };
};

export const creatChipColumn = ({ field, headerName, width = 150 }) => {
    return {
        field,
        headerName,
        width,
        renderCell: (params) => {
            if (startsWith(params.row.id, '_new') && !params.row.active) {
                return <Chip label="Archiving" color="error" size="small" />;
            } else if (params.row.isDefault) {
                return <Chip label="Default" color="primary" size="small" />;
            } else if (startsWith(params.row.id, '_new')) {
                return <Chip label="New" color="success" size="small" />;
            }
        },
    };
};

export function maskEmail(email) {
    const [localPart, domain] = email.split('@');
    if (!localPart || !domain) {
        return email;
    }

    const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 1);
    return `${maskedLocalPart}@${domain}`;
}

export function validatePasswords({ password, confirmPassword }) {
    const errors = [];
    if (password === '') {
        return errors;
    }
    if (/\s/.test(password)) {
        errors.push('Password must not contain spaces.');
    }
    if (password.length < 8) {
        errors.push('Password must have at least 8 characters.');
    }
    if (!/[a-z]/.test(password)) {
        errors.push('Password must have lower case letters.');
    }
    if (!/[A-Z]/.test(password)) {
        errors.push('Password must have upper case letters.');
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        errors.push('Password must have special characters.');
    }
    if (password !== confirmPassword) {
        errors.push('Passwords must match');
    }
    return errors;
}

export function renderCurrencyCell(params) {
    const value = isEmpty(params) ? 0 : params.value;
    return (
        <NumericFormat
            value={value}
            displayType={'text'}
            thousandSeparator
            decimalScale={2}
            valueIsNumericString
            prefix="$"
            fixedDecimalScale={true}
        />
    );
}

/**
 * Extract string from HTML
 * @param {String} html the html string from which to extract
 * @param {Boolean} prettyPrint determines if returning string be formatted neatly
 * @returns {String} the raw string extracted from HTML
 */
export function extractTextFromHTML(html, prettyPrint = true) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    let text = '';
    function recurseThroughNode(node) {
        Array.from(node.childNodes).forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE) {
                text += child.textContent.replace(/\s+/g, ' ').trim() + ' ';
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                recurseThroughNode(child);
            }
        });
    }
    if (prettyPrint) {
        recurseThroughNode(doc.body);
        return text.trim();
    } else {
        return doc.body.textContent || '';
    }
}

/**
 * Extracts the initials from a given string of words.
 *
 * This function removes any leading or trailing whitespace from the input string
 * and returns the initials of the first two words. If the string contains only
 * one word, it returns the initial of that word. Returns an empty string if no
 * valid words are present.
 *
 * @param {string} input - The input string from which to extract initials.
 * @returns {string|null} The initials of the first two words or the initial of the single word.
 */
export function getInitials(input) {
    if (isEmpty(input)) {
        return null;
    }
    const initials = words(trim(input))
        .slice(0, 2)
        .map((word) => toUpper(head(word)));
    return initials.join('');
}
