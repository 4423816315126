import * as React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput';
import FormInputLabel from '../../../../components/FormInputLabel/FormInputLabel.jsx';
import Stack from '@mui/material/Stack';

const Pricing = ({ product, onChange, fieldsValidity, handleValidity }) => {
    const { t } = useTranslation();
    return (
        <Stack gap={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputLabel text={t('Brand.ProductForm.Pricing.msrpLabel')}>
                        <CurrencyInput
                            error={fieldsValidity?.msrp}
                            onBlur={handleValidity}
                            name="msrp"
                            fullWidth
                            value={product?.msrp}
                            onChange={onChange}
                            size="small"
                            required
                        />
                    </FormInputLabel>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputLabel text={t('Brand.ProductForm.Pricing.retailDefaultPriceLabel')}>
                        <CurrencyInput
                            name="retailPrice"
                            fullWidth
                            value={product?.retailPrice}
                            onChange={onChange}
                            size="small"
                        />
                    </FormInputLabel>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormInputLabel text={t('Brand.ProductForm.Pricing.mapPricingLabel')}>
                        <CurrencyInput
                            name="minimumAdvertisePrice"
                            fullWidth
                            value={product?.minimumAdvertisePrice}
                            onChange={onChange}
                            size="small"
                        />
                    </FormInputLabel>
                </Grid>
            </Grid>
        </Stack>
    );
};

Pricing.propTypes = {
    product: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    handleValidity: PropTypes.func.isRequired,
    fieldsValidity: PropTypes.object,
};

export default Pricing;
