import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import BusinessLogo from '../../../../components/BusinessLogo';
import BusinessPrimaryAddress from '../../../../components/BusinessPrimaryAddress/BusinessPrimaryAddress';
import AccountMenu from '../../../../components/AccountMenu/AccountMenu';
import MinimumOrder from '../../../../components/MinimumOrder/MinimumOrder.jsx';

export default function BusinessHeader(props) {
    return (
        <Stack gap={3} sx={{ flexDirection: 'row', flexWrap: { xs: 'wrap-reverse', md: 'nowrap' } }}>
            <Stack direction="row" gap={3}>
                <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                    <BusinessLogo
                        business={props?.business}
                        sx={{ height: 80, width: 80 }}
                        CardMediaProps={{
                            sx: {
                                borderRadius: 4,
                                boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                                objectFit: 'cover',
                            },
                        }}
                    />
                </Box>
                <Box>
                    <Typography
                        variant="displayMd"
                        sx={{
                            lineHeight: 'normal',
                        }}>
                        {props?.business?.name}
                    </Typography>
                    <Stack direction="row" gap={1}>
                        <MinimumOrder value={props?.business.minOrderValue} />
                        <BusinessPrimaryAddress business={props?.business} />
                    </Stack>
                </Box>
            </Stack>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                <Box>
                    <AccountMenu />
                </Box>
            </Box>
        </Stack>
    );
}

BusinessHeader.propTypes = {
    business: PropTypes.any.isRequired,
};
