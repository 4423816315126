import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductIcon from '../../../components/ProductIcon';
import QuantityInput from '../../../components/QuantityInput/QuantityInput';

export default function BrandProductVariantCard(props) {
    const { onChange, onRemove, variant } = props;
    const { t } = useTranslation();
    const handleDeleteClick = (e) => {
        if (onRemove) {
            onRemove(e, variant.id);
        }
    };
    const handleQuantityChange = (e, value) => {
        if (onChange) {
            onChange({ id: variant?.id, quantity: value });
        }
    };
    const handlePriceChange = (values) => {
        const { value } = values;
        if (onChange) {
            onChange({ id: variant?.id, price: value });
        }
    };
    const variantOptionValues = variant?.variantTags?.map(({ value }) => value);

    return (
        <Card elevation={4} data-testid="BrandProductCard" sx={{ position: 'relative' }}>
            <CardContent>
                <IconButton color="error" sx={{ position: 'absolute', right: 0, top: 0 }} onClick={handleDeleteClick}>
                    <DeleteIcon />
                </IconButton>
                <Stack direction="row" gap={1} sx={{ mb: 1 }}>
                    <ProductIcon src={variant?.images?.[0]} sx={{ minWidth: 66, width: 66 }} />
                    <Stack justifyContent="flex-start" flex="1 1 auto">
                        <Typography variant="body2" sx={{ fontWeight: 'bold', pr: 3 }}>
                            {variant?.title}
                        </Typography>
                        <Typography variant="body2">{variantOptionValues.join(',')}</Typography>
                        <Stack direction="row" flexWrap="wrap">
                            {variant.mpn ? (
                                <Box sx={{ display: 'inline-flex', mr: 3 }}>
                                    <Typography variant="caption">
                                        {t('BrandProductCard.mpnLabel')}: {variant.mpn}
                                    </Typography>
                                </Box>
                            ) : null}
                            {variant.sku ? (
                                <Box sx={{ display: 'inline-flex' }}>
                                    <Typography variant="caption">
                                        {t('BrandProductCard.skuLabel')}: {variant.sku}
                                    </Typography>
                                </Box>
                            ) : null}
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" gap={1}>
                    <Box>
                        <QuantityInput value={variant.quantity} onChange={handleQuantityChange} />
                    </Box>
                    <Box>
                        <NumericFormat
                            prefix="$"
                            displayType="input"
                            style={{
                                textAlign: 'center',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                fontWeight: 'bold',
                                fontSize: '1.25rem',
                                maxWidth: 120,
                            }}
                            onValueChange={handlePriceChange}
                            value={variant?.price ?? variant?.wholesale}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            valueIsNumericString
                        />
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
}

BrandProductVariantCard.propTypes = {
    quantity: PropTypes.number,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    variant: PropTypes.any,
};
