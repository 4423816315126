import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
export const PasswordContext = createContext();

export const PasswordProvider = ({ children }) => {
    const [password, setPassword] = useState('');
    return <PasswordContext.Provider value={{ password, setPassword }}>{children}</PasswordContext.Provider>;
};

export function usePassword() {
    return useContext(PasswordContext);
}

PasswordProvider.propTypes = {
    children: PropTypes.element,
};
