import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { useRouteLoaderData } from 'react-router-dom';

export default function CategorySelectableList(props) {
    const data = useRouteLoaderData('business-products');
    const { defaultValue, value, onChange } = props;
    const { categories: { subCategories: categories = [] } = {} } = data || {};

    return (
        <FormControl component="fieldset" data-testid="category-selectable-list">
            <RadioGroup onChange={onChange} defaultValue={defaultValue} value={value}>
                {categories.map((category) => (
                    <FormControlLabel
                        value={category?.id}
                        key={category?.name}
                        control={<Radio />}
                        label={category?.name}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

CategorySelectableList.propTypes = {
    categories: PropTypes.array,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number,
    value: PropTypes.number,
};
