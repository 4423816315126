import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import PropTypes from 'prop-types';

export const AddVariantTagDialog = ({ open, onAdd, onCancel }) => {
    const [localValue, setLocalValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    function handleChange(e) {
        const v = e.target.value;
        setLocalValue(v);
    }

    function handleAdd() {
        onAdd(localValue);
        setIsOpen(false);
    }

    function handleClose() {
        setIsOpen(false);
        onCancel();
    }

    useEffect(() => {
        if (open) {
            setIsOpen(true);
        } else {
            setLocalValue('');
        }
    }, [open]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Add a Variant Value</DialogTitle>
            <DialogContent>
                <Input autoFocus value={localValue} onChange={handleChange} placeholder="Enter value" fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdd}>Add</Button>
            </DialogActions>
        </Dialog>
    );
};

AddVariantTagDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
