import Box from '@mui/material/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore.js';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function BackButton() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <Button
            color="primary"
            onClick={handleBackClick}
            sx={{
                width: '50px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}>
            <Box display="flex" gap="1px" alignItems="center">
                <NavigateBeforeIcon />
                <Typography variant="body1">Back</Typography>
            </Box>
        </Button>
    );
}
