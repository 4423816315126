import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSignOut } from '../../../hooks/useSignOut';
import AppLayout from '../../AppLayout';
import { CartProvider } from '../../../cart/CartContext';

export default function RenderError(props) {
    const { error, message } = props;
    const navigate = useNavigate();
    const signOut = useSignOut();

    function handleGoBack() {
        navigate(-1);
    }

    function handleLogout() {
        signOut();
    }

    return (
        <CartProvider>
            <AppLayout
                containerProps={{
                    component: 'main',
                    maxWidth: 'xs',
                }}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <ErrorOutlineIcon color="error" sx={{ fontSize: 60 }} />
                    <Typography component="h1" variant="h5" align="center" gutterBottom>
                        {message}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center">
                        There was a problem processing the request. Please try again.
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center" mt={1}>
                        This issue has been reported.
                    </Typography>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={handleGoBack}>
                            Return to last page
                        </Button>
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={handleLogout}>
                            Log Out
                        </Button>
                    </Box>
                </Box>
                <Snackbar open>
                    <Alert sx={{ width: '100%' }} severity="error">
                        Error: {error?.data || error?.stack}
                    </Alert>
                </Snackbar>
            </AppLayout>
        </CartProvider>
    );
}

RenderError.propTypes = {
    error: PropTypes.object,
    message: PropTypes.string,
};
