import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import theme from '../../theme.js';
import PropTypes from 'prop-types';
import { SettingsProfileType } from '../../constants/SettingsProfileType.jsx';
import StoreIcon from '@mui/icons-material/Store';
import PersonIcon from '@mui/icons-material/Person';
export default function SettingsAvatar(props) {
    const { src, type, size = 120, ...rest } = props;
    const round = type === SettingsProfileType.USER;
    const avatarStyle = {
        borderRadius: round ? size / 2 : null,
        width: size,
        height: size,
        objectFit: 'cover',
    };
    const [imgSource, setImgSource] = useState(src);
    const handleImageError = () => {
        setImgSource(null);
    };
    const fallback = () => {
        switch (type) {
            case SettingsProfileType.BUSINESS:
                return <StoreIcon style={{ fontSize: size * 0.75, color: 'gray' }} />;
            case SettingsProfileType.USER:
                return <PersonIcon style={{ fontSize: size * 0.75, color: 'gray' }} />;
        }
    };

    useEffect(() => {
        setImgSource(src);
    }, [src]);

    return (
        <Box>
            {imgSource ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: round ? size / 2 : null,
                        width: size,
                        height: size,
                    }}>
                    <img src={imgSource} alt="avatar" style={avatarStyle} {...rest} onError={handleImageError} />
                </Box>
            ) : (
                <Box
                    {...rest}
                    sx={{
                        backgroundColor: theme.palette.textField.background,
                        borderRadius: round ? size / 2 : null,
                        width: size,
                        height: size,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    {fallback()}
                </Box>
            )}
        </Box>
    );
}

SettingsAvatar.propTypes = {
    size: PropTypes.number,
    src: PropTypes.string,
    type: PropTypes.string,
};
