import { MuiTelInput } from 'mui-tel-input';
import * as React from 'react';
import PropTypes from 'prop-types';

function PhoneInput(props) {
    const { label, name, onChange, defaultValue = '', ...rest } = props;
    const [phone, setPhone] = React.useState(defaultValue);

    const handleChange = (newPhone) => {
        setPhone(newPhone);
        if (onChange) {
            onChange(newPhone);
        }
    };
    return (
        <MuiTelInput
            value={phone}
            onChange={handleChange}
            name={name}
            label={label}
            defaultCountry="US"
            onlyCountries={['US']}
            inputProps={{
                style: {
                    paddingLeft: '0px', // Override theme padding here
                },
            }}
            {...rest}
        />
    );
}

PhoneInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
};

export default PhoneInput;
