import * as auth from 'aws-amplify/auth';
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';

const MAX_ATTEMPTS = 2;
let token;
export async function getAuthToken(forceRefresh = false) {
    if (token && !forceRefresh && !isTokenExpired(token)) {
        // If token exists and is not expired, use it
        return token;
    } else {
        // If token is empty or expired, force refresh
        forceRefresh = true;
    }
    const results = await auth.fetchAuthSession({ forceRefresh });
    token = results?.tokens?.idToken?.toString();
    return token;
}

export async function authenticate() {
    // TODO a better pub/sub style
    // polls for auth session
    let token = await getAuthToken();
    let i = 0;
    while (!token) {
        token = await getAuthToken();
        i++;
        if (i >= MAX_ATTEMPTS) {
            break;
        }
    }
    return !!token;
}

export async function signOut() {
    await auth.signOut();
    _clearToken();
}

/**
 * This method is meant to be a helper for the react router loader methods.
 * A loader is fired immediately on page load, but the requests to thatch-api need to wait for authentication to be set first.
 * This method helps manage waiting for that to complete in a unified way across loaders
 * @param {*} callback the loader function
 * @returns
 */
export const awaitAuth = (callback) => async (loaderOptions) => {
    const isAuthenticated = await authenticate();
    if (isAuthenticated) {
        return callback(loaderOptions);
    }
};

/**
 * For
 */
export const _clearToken = () => {
    localStorage.removeItem('authToken');
    token = undefined;
};

// Function to check if the token is expired
export const isTokenExpired = (token) => {
    const expirationTime = getExpirationTime(token);

    if (_.isNull(expirationTime)) {
        return true;
    }

    const currentTime = _.floor(Date.now() / 1000);
    return currentTime >= expirationTime;
};

// Function to get expiration time from JWT
const getExpirationTime = (token) => {
    try {
        const decoded = jwtDecode(token?.toString() ?? '');
        return _.get(decoded, 'exp', null);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};
