import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../cart/useCart.js';
import CircleXIcon from '../../../icons/CircleXIcon.jsx';
import { Midnight } from '../../../theme.js';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function OrderAgainButton(props) {
    const { connectionId, orderId, color = 'secondary', fullWidth = true, variant = 'outlined', ...rest } = props;
    const { state: cart } = useCart();
    const navigate = useNavigate();
    const [showReorderDialog, setShowReorderDialog] = React.useState(false);
    const { t } = useTranslation();
    const flags = useFlags();

    const handleOrderAgain = () => {
        const items = cart?.items;
        if (items?.length) {
            setShowReorderDialog(true);
        } else {
            navigateToCartCheckout();
        }
    };

    const navigateToCartCheckout = (action) => {
        const nonce = Math.random().toString(36).substring(2, 15);
        navigate(
            `/retail/connections/${connectionId}/cart/checkout?orderId=${orderId}&nonce=${nonce}${action ? `&cartAction=${action}` : ''}`
        );
    };
    if (!flags.orderAgain) {
        return null;
    }
    return (
        <>
            <Button onClick={handleOrderAgain} color={color} variant={variant} fullWidth={fullWidth} {...rest}>
                {t('OrderAgainButton.label')}
            </Button>

            <Dialog open={showReorderDialog} maxWidth="xs">
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="textLg" sx={{ fontWeight: 700 }}>
                            {t('OrderAgainDialog.title')}
                        </Typography>
                        <IconButton onClick={() => setShowReorderDialog(false)}>
                            <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography>{t('OrderAgainDialog.description')}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={() => navigateToCartCheckout('replace')}>
                        {t('OrderAgainDialog.replaceLabel')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => navigateToCartCheckout()}>
                        {t('OrderAgainDialog.addLabel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

OrderAgainButton.propTypes = {
    connectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    fullWidth: PropTypes.bool,
    orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
};
