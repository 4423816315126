import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import CategorySelectableList from '../../../../components/CategorySelectableList/CategorySelectableList.jsx';
import PriceSlider from '../../../../components/PriceSlider/PriceSlider.jsx';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const MIN_PRICE = 10;
const MAX_PRICE = 1000;

export default function ProductFacets(props) {
    const { t } = useTranslation();
    const [search, setSearch] = useSearchParams();

    const urlSearchParamsToObject = (urlSearchParams) => {
        return Object.fromEntries(urlSearchParams);
    };

    const handleCategorySelected = (event, categoryId) => {
        setSearch((prev) => ({ ...urlSearchParamsToObject(prev), categoryIds: categoryId }));
    };

    const handlePriceSliderChange = (value) => {
        const [min, max] = value;
        const minPrice = min === MIN_PRICE ? undefined : min;
        const maxPrice = max === MAX_PRICE ? undefined : max;
        setSearch((prev) => {
            const update = { ...urlSearchParamsToObject(prev), minPrice, maxPrice };
            if (minPrice === undefined) {
                delete update.minPrice;
            }
            if (maxPrice === undefined) {
                delete update.maxPrice;
            }
            return update;
        });
    };

    const categoryIds = search.get('categoryIds');
    const minPrice = search.get('minPrice');
    const maxPrice = search.get('maxPrice');
    const priceSliderValue = [Number(minPrice) || MIN_PRICE, Number(maxPrice) || MAX_PRICE];

    return (
        <Stack direction="column" gap={1} {...props}>
            <Box>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {t('ConnectedBrandProducts.categoryLabel')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <CategorySelectableList
                            value={categoryIds && Number(categoryIds)}
                            onChange={handleCategorySelected}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {t('ConnectedBrandProducts.priceLabel')}
                    </AccordionSummary>
                    <AccordionDetails>
                        <PriceSlider
                            value={priceSliderValue}
                            min={MIN_PRICE}
                            max={MAX_PRICE}
                            onChange={handlePriceSliderChange}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Stack>
    );
}
