import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

export default function LoadingButton({ isLoading = false, children, disabled, loadingColor = 'primary', ...props }) {
    const isDisabled = isLoading || disabled;
    return (
        <Button {...props} disabled={isDisabled}>
            {isLoading ? <CircularProgress size={24} sx={{ color: loadingColor }} /> : children}
        </Button>
    );
}

LoadingButton.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    loadingColor: PropTypes.string,
};
