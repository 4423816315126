import React, { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import { InputAdornment, InputLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import _ from 'lodash';

export default function CategorySelect({
    handleValidity,
    fieldsValidity,
    label,
    isLoading,
    category,
    subcategories,
    disabled,
    onChange,
    name,
    error,
    onBlur,
    ...props
}) {
    let selectedCategory = '';
    if (_.isArray(subcategories)) {
        selectedCategory = useMemo(() => {
            return subcategories.find((item) => item.id === category?.id) || null;
        }, [category, subcategories]);
    }

    return (
        <FormControl fullWidth variant="outlined" error={error}>
            <InputLabel id="category-select-label" shrink={true}>
                {label}
            </InputLabel>
            <Select
                onBlur={onBlur}
                {...props}
                fullWidth
                name={name}
                value={selectedCategory || ''}
                displayEmpty
                onChange={onChange}
                disabled={disabled}
                label="Main Category"
                labelId="category-select-label"
                endAdornment={
                    isLoading ? (
                        <InputAdornment position="end">
                            <CircularProgress size={24} />
                        </InputAdornment>
                    ) : null
                }
                renderValue={(category) => {
                    return category ? category?.name : 'Select Category';
                }}>
                {subcategories?.map((item) => (
                    <MenuItem key={item.id} value={item}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

CategorySelect.propTypes = {
    label: PropTypes.string,
    category: PropTypes.object,
    fieldsValidity: PropTypes.object,
    subcategories: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    handleValidity: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
};
