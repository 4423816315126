import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

export default function PriceSlider(props) {
    const { t } = useTranslation();
    const { max = 1000, min = 10 } = props;
    const [value, setValue] = React.useState(props.value ?? []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClick = () => {
        if (props?.onChange) {
            props.onChange(value);
        }
    };

    const minValue = Array.isArray(value) ? value[0] || min : value;
    const maxValue = Array.isArray(value) ? value[1] || max : value;

    return (
        <Box sx={{ width: 120 }} data-testid="price-slider">
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2">
                    <NumericFormat prefix="$" displayType="text" value={minValue} thousandSeparator />
                </Typography>
                <Typography variant="subtitle2">
                    <NumericFormat
                        prefix="$"
                        displayType="text"
                        value={maxValue}
                        thousandSeparator
                        suffix={maxValue === max ? '+' : ''}
                    />
                </Typography>
            </Box>
            <Slider
                defaultValue={props.defaultValue}
                min={min}
                max={max}
                getAriaLabel={() => 'Price'}
                getAriaValueText={() => `${value}`}
                value={value}
                onChange={handleChange}
            />
            <Button color="primary" variant="contained" size="small" onClick={handleClick}>
                {t('PriceSlider.goLabel')}
            </Button>
        </Box>
    );
}

PriceSlider.propTypes = {
    defaultValue: PropTypes.oneOfType([(PropTypes.number, PropTypes.string, PropTypes.array)]),
    value: PropTypes.oneOfType([(PropTypes.number, PropTypes.string, PropTypes.array)]),
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func,
};
