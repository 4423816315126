import React from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

export const tableCkBox = (id) => {
    return {
        renderCell: (params) => {
            const value = params.value;
            return (
                <div id={id}>{value ? <CheckBox>yes</CheckBox> : <CheckBoxOutlineBlank>no</CheckBoxOutlineBlank>}</div>
            );
        },
    };
};
