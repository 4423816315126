import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MapPinIcon from '../../icons/MapPinIcon.jsx';
import PencilSimpleIcon from '../../icons/PencilSimpleIcon.jsx';
import PhoneIcon from '../../icons/PhoneIcon.jsx';
import EnvelopeIcon from '../../icons/EnvelopeIcon.jsx';
import { Misty, Tide } from '../../theme.js';
import ItemEditMenu from '../ItemEditMenu/ItemEditMenu.jsx';
import { useTranslation } from 'react-i18next';

export default function AddressCell({ isPrimary, address, onEdit, onDelete }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handlePopoverClose();
        onEdit();
    };

    const handleDelete = () => {
        handlePopoverClose();
        onDelete();
    };

    const open = Boolean(anchorEl);
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const responsiveStyle = {
        sx: {
            alignItems: {
                xs: 'flex-start',
                sm: 'center',
            },
            justifyContent: {
                xs: 'flex-start',
                sm: 'center',
            },
            width: '100%',
        },
    };

    return (
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack gap={1.5}>
                <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                    <Typography variant="textMd" sx={{ fontWeight: 700 }}>
                        {address.label}
                    </Typography>
                    {isPrimary ? (
                        <Chip
                            variant="outlined"
                            label={t('AddAddressInput.primaryLabel')}
                            sx={{ color: Tide, height: 30 }}
                        />
                    ) : null}
                </Stack>
                <Stack gap={2} direction={responsiveDirection} sx={responsiveStyle}>
                    <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                        <MapPinIcon sx={{ width: 16, height: 16 }} />
                        <Typography variant="textSm">
                            {address.street1} {address.city}, {address.state} {address.postalCode}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                        <PhoneIcon sx={{ width: 16, height: 16 }} />
                        <Typography variant="textSm">{address.phones?.[0].primary ?? ''}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                    <EnvelopeIcon sx={{ width: 16, height: 16 }} />
                    <Typography variant="textSm">{address.emails?.[0].default ?? ''}</Typography>
                </Stack>
            </Stack>
            <IconButton onClick={handlePopoverOpen}>
                <PencilSimpleIcon sx={{ width: 24, height: 24, color: Misty }} />
            </IconButton>
            <ItemEditMenu
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
        </Stack>
    );
}

AddressCell.propTypes = {
    isPrimary: PropTypes.any,
    address: PropTypes.any,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};
