import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';

export default function ChartBarHorizontalIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M28 12H23V7C23 6.73478 22.8946 6.48043 22.7071 6.29289C22.5196 6.10536 22.2652 6 22 6H7V5C7 4.73478 6.89464 4.48043 6.70711 4.29289C6.51957 4.10536 6.26522 4 6 4C5.73478 4 5.48043 4.10536 5.29289 4.29289C5.10536 4.48043 5 4.73478 5 5V27C5 27.2652 5.10536 27.5196 5.29289 27.7071C5.48043 27.8946 5.73478 28 6 28C6.26522 28 6.51957 27.8946 6.70711 27.7071C6.89464 27.5196 7 27.2652 7 27V26H18C18.2652 26 18.5196 25.8946 18.7071 25.7071C18.8946 25.5196 19 25.2652 19 25V20H28C28.2652 20 28.5196 19.8946 28.7071 19.7071C28.8946 19.5196 29 19.2652 29 19V13C29 12.7348 28.8946 12.4804 28.7071 12.2929C28.5196 12.1054 28.2652 12 28 12ZM21 8V12H7V8H21ZM17 24H7V20H17V24ZM27 18H7V14H27V18Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

ChartBarHorizontalIcon.propTypes = {
    sx: PropTypes.any,
};
