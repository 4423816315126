import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ChartDonutIcon from '../../icons/ChartDonutIcon.jsx';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Midnight } from '../../theme.js';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';

export default function DealerActivity({ dealer }) {
    const { t } = useTranslation();
    const formatNumber = (value, disableDecimals = false, fallback = 'NA', prefix = '$') => {
        if (value === null || value === undefined) {
            return fallback;
        }
        return (
            <NumericFormat
                value={value}
                displayType="text"
                thousandSeparator
                decimalScale={disableDecimals ? 0 : 2}
                fixedDecimalScale={!disableDecimals}
                valueIsNumericString
                prefix={prefix}
            />
        );
    };

    return (
        <Card>
            <CardContent sx={{ paddingX: '24px', paddingY: '32px', height: '100%' }}>
                <Grid
                    container
                    spacing={2}
                    sx={{ alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>
                    <Grid item xs={12} sm={6} md={'auto'}>
                        <Stack gap={1} direction="row" sx={{ alignItems: 'center', height: '100%' }}>
                            <ChartDonutIcon />
                            <Typography variant="textLg" sx={{ fontWeight: 700, color: Midnight }}>
                                {t('Dealers.activityLabel')}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6} md={'auto'}>
                        <FormInputLabel text={t('Dealers.amountSpentLabel')} sx={{ fontSize: 12 }}>
                            <Typography variant="displayXs" sx={{ color: Midnight }}>
                                <NumericFormat
                                    value={dealer.totalSpent ?? 0}
                                    displayType="text"
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    valueIsNumericString
                                    prefix={'$'}
                                />
                            </Typography>
                        </FormInputLabel>
                    </Grid>
                    <Grid item xs={12} sm={6} md={'auto'}>
                        <FormInputLabel text={t('Dealers.totalOrdersLabel')} sx={{ fontSize: 12 }}>
                            <Typography variant="displayXs" sx={{ color: Midnight }}>
                                {dealer.totalOrders}
                            </Typography>
                        </FormInputLabel>
                    </Grid>
                    <Grid item xs={12} sm={6} md={'auto'}>
                        <FormInputLabel text={t('Dealers.lastOrderLabel')} sx={{ fontSize: 12 }}>
                            <Typography variant="displayXs" sx={{ color: Midnight }}>
                                {formatNumber(dealer.lastOrder?.total)}
                            </Typography>
                        </FormInputLabel>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

DealerActivity.propTypes = {
    dealer: PropTypes.object.isRequired,
};
