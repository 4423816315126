import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useState } from 'react';
import { useFetcher, useNavigate, useNavigation, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import * as api from '../../../../api';
import SearchBar from '../../../../components/SearchBar/SearchBar';
import { createCurrencyColumn } from '../../../../helpers/HelperFunctions.jsx';
import { EmptyTable } from '../../../../components/EmptyTable/EmptyTable.jsx';
import ProductIcon from '../../../../components/ProductIcon.jsx';
import ProductSortBySelect from '../../../../components/ProductSortBySelect/ProductSortBySelect.jsx';
import shopifyIcon from '../../../../assets/shopify-logo.svg';
import Stack from '@mui/material/Stack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as businessProvider from '../../../../providers/businessProvider.js';
import _, { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../../components/PageHeader/PageHeader.jsx';
import Typography from '@mui/material/Typography';
import { getWholesaleRangeString } from '../../../../components/PricingGroupsList/pricingGroupUtils.js';

const LIMIT = 10;

export async function productsApiLoader({ request }) {
    const url = new URL(request.url);
    const offset = url.searchParams.get('offset') || 0;
    const sort = url.searchParams.get('sort') || 'title';
    const searchString = url.searchParams.get('searchString') || '';
    const active = url.searchParams.get('active') || '';

    const params = {
        query: {
            limit: LIMIT,
            offset,
            sort,
        },
    };

    if (searchString) {
        params.query.searchString = searchString;
    }

    if (active) {
        params.query.active = active;
    }

    const products = await api.getProducts(params);
    return { products };
}

export async function loader({ request }) {
    const url = new URL(request.url);
    const offset = url.searchParams.get('offset') || 0;
    const sort = url.searchParams.get('sort') || 'title';
    const searchString = url.searchParams.get('searchString') || '';
    const active = url.searchParams.get('active') || '';

    const params = {
        query: {
            limit: LIMIT,
            offset,
            sort,
        },
    };

    if (searchString) {
        params.query.searchString = searchString;
    }

    if (active) {
        params.query.active = active;
    }

    const products = await api.getProducts(params);
    const pricingGroups = await api.getPricingGroups({ signal: request.signal });
    const sortedPricingGroups =
        pricingGroups?.data.sort((a, b) => {
            return b.is_system_generated - a.is_system_generated;
        }) ?? [];
    const { signal } = request;
    const business = await businessProvider.getBusiness({ signal });
    return {
        business,
        products,
        pricingGroups: sortedPricingGroups,
    };
}

export const setQuery = ({ active, searchString, sort }, func) => {
    let params = {};
    if (_.isString(searchString)) {
        if (_.isEmpty(searchString)) {
            params = {};
        } else {
            params = { searchString };
        }
    }
    if (_.isString(sort) && !_.isEmpty(sort) && sort !== 'title') {
        params.sort = sort;
    }
    params.active = _.toString(active);
    func(params);
};

export default function BrandProductTable() {
    const data = useRouteLoaderData('brand-products');
    const { business, pricingGroups } = data;
    const linkedShops = business?.shopifySessions;

    const navigate = useNavigate();
    const { state } = useNavigation();
    const isLoading = state === 'loading';
    const [products, setProducts] = useState(data?.products?.rows);
    const [hasMore, setHasMore] = useState(data?.products?.hasMore);
    const [offset, setOffset] = useState(data?.products?.offset || 0);
    const [search, setParams] = useSearchParams();
    const activeDefault = search.get('active') === 'true' || search.get('active') === null;
    const [activeFilter, setActiveFilter] = useState(activeDefault);
    const [searchValue, setSearchValue] = useState(search.get('searchString'));
    const sort = search.get('sort') || 'title';
    const flags = useFlags();
    const fetcher = useFetcher();
    const { t } = useTranslation();

    const queryProducts = useMemo(function () {
        return debounce(setQuery, 400);
    }, []);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setOffset(0);
        queryProducts({ active: activeFilter, searchString: value, sort }, setParams);
    };

    const handleShowArchived = () => {
        const value = !activeFilter;
        setActiveFilter(value);
        setOffset(0);
        queryProducts({ active: value, searchString: searchValue, sort }, setParams);
    };

    const handleSortByChange = (e) => {
        const sort = e.target.value;
        setOffset(0);
        queryProducts({ active: activeFilter, searchString: searchValue, sort }, setParams);
    };

    useEffect(() => {
        if (data?.products?.rows) {
            setProducts(data.products.rows);
            setHasMore(data.products.hasMore);
        }
    }, [data]);

    useEffect(() => {
        if (!fetcher.data || fetcher.state !== 'idle') {
            return;
        }
        const { products } = fetcher.data;
        if (products) {
            setHasMore(products.hasMore);
            setProducts((prev) => [...prev, ...products.rows]);
        }
    }, [fetcher]);

    const loadMoreRows = () => {
        if (!hasMore) {
            return;
        }
        const newOffset = offset + LIMIT;
        setOffset(newOffset);

        const queryString = new URLSearchParams();
        queryString.set('offset', newOffset);
        if (activeFilter) {
            queryString.set('active', activeFilter);
        }
        if (searchValue) {
            queryString.set('searchString', searchValue);
        }
        if (sort) {
            queryString.set('sort', sort);
        }
        const query = `/api/brand/products/?${queryString.toString()}`;
        fetcher.load(query);
    };

    const handleOnRowsScrollEnd = () => {
        loadMoreRows();
    };

    const columns = [
        {
            field: 'image',
            headerName: 'Image',
            width: 130,
            renderCell: (params) => {
                return (
                    <ProductIcon
                        src={params.row.imageLinks?.[0]}
                        objectFit="cover"
                        sx={{ width: '45px', height: '45px' }}
                    />
                );
            },
        },
        { field: 'mpn', headerName: 'MPN', width: 150 },
        { field: 'title', headerName: 'Product Name', width: 250 },
        {
            field: 'year',
            headerName: 'Year',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <Typography>{params.value === 0 ? '' : params.value}</Typography>,
        },
        {
            field: 'wholesale',
            headerName: 'Wholesale',
            width: 250,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <Typography>{getWholesaleRangeString(params.row, pricingGroups)}</Typography>,
        },
        createCurrencyColumn({ field: 'msrp', headerName: 'MSRP', width: 120, currency: 'USD' }),
        createCurrencyColumn({ field: 'retailPrice', headerName: 'Default', width: 120, currency: 'USD' }),
        { field: 'upc', headerName: 'UPC', width: 150, align: 'center', headerAlign: 'center' },
        {
            field: 'productVariants',
            headerName: 'Variants',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <Typography>{params.value.length ?? 0}</Typography>,
        },
        {
            field: 'active',
            headerName: 'Status',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <Typography>{params.value ? 'Active' : 'Inactive'}</Typography>,
        },
    ];

    const navigateToCreateProductPage = () => {
        navigate('/brand/products/create-product');
    };

    const importShopify = () => {
        navigate('/brand/products/syncShopify');
    };

    const handleCellClick = (params, event) => {
        if (params.field === 'Variants') {
            // Stop the click event from propagating to the row click handler
            event.stopPropagation();
            // Navigate to the variants page for the clicked product
            navigate(`/brand/products/${params.id}/variants`);
        }
    };

    const handleRowClick = (params) => {
        navigate(`/brand/products/${params.id}`);
    };

    const shouldShowShopifySync = () => {
        return flags.shopify && !_.isEmpty(linkedShops);
    };
    const showText = activeFilter ? t('BrandProductTable.showArchived') : t('BrandProductTable.hideArchived');
    return (
        <>
            <Box>
                {flags.isWholeshopEnabled ? <PageHeader title={t('BrandProductTable.title')} sx={{ mb: 3 }} /> : null}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        gap: 3,
                        mb: 2,
                    }}>
                    <SearchBar
                        onChange={handleSearchInputChange}
                        value={_.toString(searchValue)}
                        placeholder="Search products"
                        sx={{ maxWidth: 360 }}
                    />
                    <Button onClick={navigateToCreateProductPage} color="primary" variant="contained" size="xs">
                        {t('BrandProductTable.createProductBtn')}
                    </Button>
                    <Button
                        size={'small'}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        id="show-archive"
                        component="span"
                        onClick={handleShowArchived}>
                        {showText}
                    </Button>
                    {shouldShowShopifySync() ? (
                        <Button onClick={importShopify} sx={{ color: 'black', fontWeight: 'bold' }}>
                            <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                                <Box>
                                    <img src={shopifyIcon} alt="Shopify" style={{ width: '30px', height: '30px' }} />
                                </Box>
                                {t('BrandProductTable.syncWithShopify')}
                            </Stack>
                        </Button>
                    ) : null}
                    <Box sx={{ flex: '1 0 auto', display: 'flex', justifyContent: 'flex-end' }}>
                        <ProductSortBySelect value={sort} label={'Sort by'} onChange={handleSortByChange} />
                    </Box>
                </Box>
                <Stack flexDirection="column" sx={{ minHeight: '200px' }}>
                    <DataGridPro
                        columns={columns}
                        rows={products}
                        loading={isLoading}
                        onRowsScrollEnd={handleOnRowsScrollEnd}
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => <EmptyTable message="No products found" />,
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
                        }
                        onRowClick={(params) => {
                            handleRowClick(params);
                        }}
                        onCellClick={(params, event) => handleCellClick(params, event)}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                outlineOffset: '0',
                                outline: 'none !important',
                            },
                        }}
                    />
                </Stack>
            </Box>
        </>
    );
}
