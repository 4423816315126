import Typography from '@mui/material/Typography';
import * as React from 'react';
import AppLayout from '../components/AppLayout.jsx';
import Box from '@mui/material/Box';

function TermsOfUse() {
    return (
        <AppLayout>
            <Typography variant="h4" align="center" gutterBottom>
                Terms of Service
            </Typography>
            <Box sx={{ marginLeft: 2 }}>
                <Typography variant="body1" paragraph>
                    By signing up for the Thatch service (&quot;Service&quot;) or any of the services of Thatch
                    Corporation (&quot;Thatch&quot;) you are agreeing to be bound by the following terms and conditions
                    (&quot;Terms of Service&quot;). Thatch reserves the right to update and change the Terms of Service
                    by posting updates and changes to the Thatch website. You are advised to check the Terms of Service
                    from time to time for any updates or changes that may impact you.
                </Typography>
                <Typography variant="body1" paragraph>
                    You must read, agree with, and accept all of the terms and conditions contained in this Terms of
                    Service agreement, including Thatch’s Privacy Policy before you may become a Thatch retailer user.
                    Additionally, upon completing the Thatch Plan Sign-up / Merchant Setup, you must agree to Stripe’s
                    submerchant terms and conditions.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Terms and Conditions
                </Typography>
                <Box sx={{ marginLeft: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        1. SAAS Service and Support
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            1. Subject to the terms of this Agreement, Company will use commercially reasonable efforts
                            to provide Customer the Services. As part of the registration process, Customer will
                            identify an administrative username and password for Customer’s Company account. Company
                            reserves the right to refuse registration or to cancel passwords it deems inappropriate.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2. Subject to the terms hereof, Company will provide Customer with reasonable technical
                            support services in accordance with the terms set forth in Exhibit A.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        2. Restrictions and Responsibilities
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            1. Customer will not, directly or indirectly: reverse engineer, decompile, disassemble, or
                            otherwise attempt to discover the source code, object code, or underlying structure, ideas,
                            know-how, or algorithms relevant to the Services or any software, documentation, or data
                            related to the Services (&quot;Software&quot;); modify, translate, or create derivative
                            works based on the Services or any Software (except to the extent expressly permitted by
                            Company or authorized within the Services); use the Services or any Software for timesharing
                            or service-bureau purposes or otherwise for the benefit of a third party; or remove any
                            proprietary notices or labels. With respect to any Software that is distributed or provided
                            to Customer for use on Customer premises or devices, Company hereby grants Customer a
                            non-exclusive, non-transferable, non-sublicensable license to use such Software during the
                            Term only in connection with the Services.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2. Further, Customer may not remove or export from the United States or allow the export or
                            re-export of the Services, Software, or anything related thereto, or any direct product
                            thereof, in violation of any restrictions, laws, or regulations of the United States
                            Department of Commerce, the United States Department of Treasury Office of Foreign Assets
                            Control, or any other United States or foreign agency or authority. As defined in FAR
                            section 2.101, the Software and documentation are &quot;commercial items&quot; and according
                            to DFAR section 252.227 7014(a)(1) and (5) are deemed to be &quot;commercial computer
                            software&quot; and &quot;commercial computer software documentation.&quot; Consistent with
                            DFAR section 227.7202 and FAR section 12.212, any use, modification, reproduction, release,
                            performance, display, or disclosure of such commercial software or commercial software
                            documentation by the U.S. Government will be governed solely by the terms of this Agreement
                            and will be prohibited except to the extent expressly permitted by the terms of this
                            Agreement.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            3. Customer represents, covenants, and warrants that Customer will use the Services only in
                            compliance with Company’s standard published policies then in effect (the
                            &quot;Policy&quot;) and all applicable laws and regulations. Customer hereby agrees to
                            indemnify and hold harmless Company against any damages, losses, liabilities, settlements,
                            and expenses (including without limitation costs and attorneys’ fees) in connection with any
                            claim or action that arises from an alleged violation of the foregoing or otherwise from
                            Customer’s use of Services. Although Company has no obligation to monitor Customer’s use of
                            the Services, Company may do so and may prohibit any use of the Services it believes may be
                            (or alleged to be) in violation of the foregoing.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            4. Customer shall be responsible for obtaining and maintaining any equipment and ancillary
                            services needed to connect to, access, or otherwise use the Services, including without
                            limitation modems, hardware, servers, software, operating systems, networking, web servers,
                            and the like (collectively &quot;Equipment&quot;). Customer shall also be responsible for
                            maintaining the security of the Equipment, Customer account, passwords (including but not
                            limited to administrative and user passwords) and files, and for all uses of Customer
                            account or the Equipment with or without Customer’s knowledge or consent.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        3. Confidentiality; Proprietary Rights
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            1. Each party (the &quot;Receiving Party&quot;) understands that the other party (the
                            &quot;Disclosing Party&quot;) has disclosed or may disclose business, technical, or
                            financial information relating to the Disclosing Party’s business (hereinafter referred to
                            as &quot;Proprietary Information&quot; of the Disclosing Party). Proprietary Information of
                            Company includes non-public information regarding features, functionality, and performance
                            of the Service. Proprietary Information of Customer includes non-public data provided by
                            Customer to Company to enable the provision of the Services (&quot;Customer Data&quot;). The
                            Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary
                            Information, and (ii) not to use (except in performance of the Services or as otherwise
                            permitted herein) or divulge to any third person any such Proprietary Information. The
                            Disclosing Party agrees that the foregoing shall not apply with respect to any information
                            after five (5) years following the disclosure thereof or any information that the Receiving
                            Party can document (a) is or becomes generally available to the public, or (b) was in its
                            possession or known by it prior to receipt from the Disclosing Party, or (c) was rightfully
                            disclosed to it without restriction by a third party, or (d) was independently developed
                            without use of any Proprietary Information of the Disclosing Party, or (e) is required to be
                            disclosed by law.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2. Customer shall own all right, title, and interest in and to the Customer Data. Company
                            shall own and retain all right, title, and interest in and to (a) the Services and Software,
                            all improvements, enhancements, or modifications thereto, (b) any software applications,
                            inventions, or other technology developed in connection with support, (c) all intellectual
                            property rights related to any of the foregoing, and (d) all data related to sales and other
                            transactions including, but not exclusive to, names, email addresses, addresses, sold items,
                            prices, taxes, shipping, and payment.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            3. Notwithstanding anything to the contrary, Company shall have the right to collect and
                            analyze data and other information relating to the provision, use, and performance of
                            various aspects of the Services and related systems and technologies (including, without
                            limitation, information concerning Customer Data and data derived therefrom), and Company
                            will be free (during and after the term hereof) to (i) use such information and data to
                            improve and enhance the Services and for other development, diagnostic, and corrective
                            purposes in connection with the Services and other Company offerings, and (ii) disclose such
                            data solely in aggregate or other de-identified form in connection with its business. No
                            rights or licenses are granted except as expressly set forth herein.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        4. Payment of Fees
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            1. You will pay us: (a) the applicable Referral Fees; (b) the non-refundable Selling on
                            Thatch Subscription Fee in advance each month; and (c) any other applicable fees described
                            in this Agreement (including any applicable Program Policies). &quot;Selling on Thatch
                            Subscription Fee&quot; means the fee specified on the Thatch Fee Schedule. With respect to
                            each of Your Transactions: (i) &quot;Sales Proceeds&quot; has the meaning set out in this
                            Agreement; (ii) &quot;Referral Fee&quot; means the applicable fee based on the Sales
                            Proceeds from Your Transaction through the Thatch Marketplace and specified on the Thatch
                            Fee Schedule at the time of Your Transaction.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2. Company may choose to bill through an invoice, in which case full payment for invoices
                            issued in any given month must be received by Company thirty (30) days after the mailing
                            date of the invoice. Unpaid amounts are subject to a finance charge of 1.5% per month on any
                            outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses
                            of collection, and may result in immediate termination of Service. Customer shall be
                            responsible for all taxes associated with Services other than U.S. taxes based on Company’s
                            net income.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        5. Term and Termination
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            1. Subject to earlier termination as provided below, this Agreement is for the Initial
                            Service Term as specified in the Order Form and shall be automatically renewed monthly or in
                            accordance with a new service agreement unless either party requests termination at least
                            thirty (30) days prior to the end of the then-current term.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2. In addition to any other remedies it may have, either party may also terminate this
                            Agreement upon thirty (30) days’ notice (or without notice in the case of nonpayment) if the
                            other party materially breaches any of the terms or conditions of this Agreement. Customer
                            will pay in full for the Services up to and including the last day on which the Services are
                            provided. Upon any termination, Company will make all Customer Data available to Customer
                            for electronic retrieval for a period of thirty (30) days, but thereafter Company may, but
                            is not obligated to, delete stored Customer Data. All sections of this Agreement which by
                            their nature should survive termination will survive termination, including without
                            limitation accrued rights to payment, confidentiality obligations, warranty disclaimers, and
                            limitations of liability.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        6. Warranty and Disclaimer
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            Company shall use reasonable efforts consistent with prevailing industry standards to
                            maintain the Services in a manner which minimizes errors and interruptions in the Services.
                            Services may be temporarily unavailable for scheduled maintenance or for unscheduled
                            emergency maintenance either by Company or by third-party providers, or because of other
                            causes beyond Company’s reasonable control, but Company shall use reasonable efforts to
                            provide advance notice in writing or by e-mail of any scheduled service disruption. HOWEVER,
                            COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT
                            MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SERVICES. EXCEPT AS
                            EXPRESSLY SET FORTH IN THIS SECTION, THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND COMPANY
                            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
                            WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        7. Indemnity
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            Company shall hold Customer harmless from liability to third parties resulting from
                            infringement by the Service of any United States patent or any copyright or misappropriation
                            of any trade secret, provided Company is promptly notified of any and all threats, claims
                            and proceedings related thereto and given reasonable assistance and the opportunity to
                            assume sole control over defense and settlement; Company will not be responsible for any
                            settlement it does not approve in writing. The foregoing obligations do not apply with
                            respect to portions or components of the Service (i) not supplied by Company, (ii) made in
                            whole or in part in accordance with Customer specifications, (iii) that are modified after
                            delivery by Company, (iv) combined with other products, processes or materials where the
                            alleged infringement relates to such combination, (v) where Customer continues allegedly
                            infringing activity after being notified thereof or after being informed of modifications
                            that would have avoided the alleged infringement, or (vi) where Customer’s use of the
                            Service is not strictly in accordance with this Agreement. If, due to a claim of
                            infringement, the Services are held by a court of competent jurisdiction to be, or are
                            believed by Company to be infringing, Company may, at its option and expense (a) replace or
                            modify the Service to be non-infringing, provided that such modification or replacement
                            contains substantially similar features and functionality, (b) obtain for Customer a license
                            to continue using the Service, or (c) if neither of the foregoing is commercially
                            practicable, terminate this Agreement and Customer’s rights hereunder and provide Customer a
                            refund of any prepaid, unused fees for the Service.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        8. Limitation of Liability
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            NOTWITHSTANDING ANYTHING TO THE CONTRARY, EXCEPT FOR BODILY INJURY OF A PERSON, COMPANY AND
                            ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND TECHNOLOGY SUPPLIERS),
                            OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS, AND EMPLOYEES SHALL NOT BE RESPONSIBLE
                            OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS
                            RELATED THERETO UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR
                            ERROR OR INTERRUPTION OF USE OR FOR LOSS OR INACCURACY OR CORRUPTION OF DATA OR COST OF
                            PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF BUSINESS; (B) FOR ANY
                            INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR ANY MATTER BEYOND
                            COMPANY’S REASONABLE CONTROL; OR (D) FOR ANY AMOUNTS THAT, TOGETHER WITH AMOUNTS ASSOCIATED
                            WITH ALL OTHER CLAIMS, EXCEED THE FEES PAID BY CUSTOMER TO COMPANY FOR THE SERVICES UNDER
                            THIS AGREEMENT IN THE 12 MONTHS PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH
                            CASE WHETHER OR NOT COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        9. Miscellaneous
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            If any provision of this Agreement is found to be unenforceable or invalid, that provision
                            will be limited or eliminated to the minimum extent necessary so that this Agreement will
                            otherwise remain in full force and effect and enforceable. This Agreement is not assignable,
                            transferable, or sublicensable by Customer except with Company’s prior written consent.
                            Company may transfer and assign any of its rights and obligations under this Agreement
                            without consent. This Agreement is the complete and exclusive statement of the mutual
                            understanding of the parties and supersedes and cancels all previous written and oral
                            agreements, communications, and other understandings relating to the subject matter of this
                            Agreement, and that all waivers and modifications must be in a writing signed by both
                            parties, except as otherwise provided herein. No agency, partnership, joint venture, or
                            employment is created as a result of this Agreement and Customer does not have any authority
                            of any kind to bind Company in any respect whatsoever. In any action or proceeding to
                            enforce rights under this Agreement, the prevailing party will be entitled to recover costs
                            and attorneys’ fees. All notices under this Agreement will be in writing and will be deemed
                            to have been duly given when received if personally delivered; when receipt is
                            electronically confirmed if transmitted by facsimile or e-mail; the day after it is sent if
                            sent for next day delivery by recognized overnight delivery service; and upon receipt if
                            sent by certified or registered mail, return receipt requested. This Agreement shall be
                            governed by the laws of the State of Delaware without regard to its conflict-of-laws
                            provisions.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        Definitions
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            &quot;Sales Proceeds&quot; means the gross proceeds from any of Your Transactions, including
                            all shipping and handling, and other charges, but excluding taxes.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            &quot;Your Product&quot; means any product or service that you have offered through the
                            Selling on Thatch.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            &quot;Your Transaction&quot; means any sale of Your Product(s) through the Thatch site.
                        </Typography>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        Exhibit A
                    </Typography>
                    <Box sx={{ marginLeft: 2 }}>
                        <Typography variant="body1" paragraph>
                            Company will provide Technical Support to Customer via both telephone and electronic mail on
                            weekdays during the hours of 9:00 am through 5:00 pm Mountain time, with the exclusion of
                            Federal Holidays (&quot;Support Hours&quot;).
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Customer may initiate a helpdesk ticket during Support Hours by calling 801-609-8639 (phone
                            number is subject to change) or any time by emailing support@thatch.tech.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Company will use commercially reasonable efforts to respond to all Helpdesk tickets within
                            one (2) business days.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </AppLayout>
    );
}

export default TermsOfUse;
