import React, { useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import { useSettingsContext } from '../SettingsContext.jsx';
import { useError } from '../../../components/Error/ErrorProvider.jsx';
import Crumbs from '../../../components/Crumbs/Crumbs.jsx';
import MapPinIcon from '../../../icons/MapPinIcon.jsx';
import { useTranslation } from 'react-i18next';
import { Teal, White } from '../../../theme.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import AddressCell from '../../../components/AddressCell/AddressCell.jsx';
import PlusCircleIcon from '../../../icons/PlusCircleIcon.jsx';
import AddAddressDialog from '../../../components/AddAddressDialog/AddAddressDialog.jsx';

export default function ManageAddresses() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [addressIndexToEdit, setAddressIndexToEdit] = useState(null);
    const { business, saveChanges } = useSettingsContext();
    const { handleError } = useError();
    const addressToSave = useRef(null);

    // Dialog States
    const [singleAddressWarning, setSingleAddressWarning] = useState(false);
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);
    const [primaryAddressWarning, setPrimaryAddressWarning] = useState(false);
    const [newPrimaryAddressWarning, setNewPrimaryAddressWarning] = useState(false);

    const handleEdit = (index) => {
        setAddressIndexToEdit(index);
        setIsFormOpen(true);
    };

    // Dialog Methods
    const presentDialog = async (addressIndex) => {
        setAddressIndexToEdit(addressIndex);
        if (business?.addresses.length === 1) {
            await setSingleAddressWarning(true);
        } else if (addressIndexToEdit === 0) {
            await setPrimaryAddressWarning(true);
        } else {
            await setDeleteWarningOpen(true);
        }
    };
    const handleCloseSingleAddressWarning = () => {
        setSingleAddressWarning(false);
    };

    const handleClosePrimaryAddressWarning = () => {
        setPrimaryAddressWarning(false);
    };

    const handleCloseDeleteWarning = () => {
        setDeleteWarningOpen(false);
    };

    const handleCloseNewPrimaryAddressWarning = () => {
        setNewPrimaryAddressWarning(false);
    };

    const handleOpenForm = (type) => {
        setAddressIndexToEdit(null);
        setIsFormOpen(true);
    };

    const handleCloseForm = () => {
        setIsFormOpen(false);
    };

    const saveAddress = async () => {
        handleCloseNewPrimaryAddressWarning();
        handleCloseForm();
        const address = addressToSave.current;
        const formattedAddress = getFormattedAddress(address);
        if (addressIndexToEdit !== null) {
            if (address.isPrimaryAddress && addressIndexToEdit !== 0) {
                // Move to first slot
                const newAddresses = [...business.addresses];
                const itemToMove = newAddresses.splice(addressIndexToEdit, 1)[0];
                newAddresses.unshift(itemToMove);
                business.addresses = newAddresses;
            } else {
                // Address edited. Replace at index
                business.addresses[addressIndexToEdit] = formattedAddress;
            }
        } else if (address.isPrimaryAddress) {
            // Insert new address to primary position (0)
            const newAddresses = [...business.addresses];
            newAddresses.unshift(formattedAddress);
            business.addresses = newAddresses;
        } else {
            // Append new address to array
            business.addresses = [...business.addresses, formattedAddress];
        }
        await update();
    };

    const deleteAddress = async (index) => {
        setDeleteWarningOpen(false);
        business?.addresses?.splice(index, 1);
        setAddressIndexToEdit(null);
        setIsFormOpen(false);
        await update();
    };

    async function update() {
        setIsLoading(true);
        try {
            await saveChanges('Business');
        } catch (error) {
            handleError(error);
        }
        setIsLoading(false);
    }

    // Helpers
    const getFormattedAddress = (address) => {
        return {
            label: address.label,
            street1: address.street1,
            street2: address.street2,
            street3: '',
            city: address.city,
            state: address.state,
            postalCode: address.postalCode,
            countryCode: address.countryCode,
            emails: [
                {
                    default: address.email,
                },
            ],
            phones: [
                {
                    primary: address.phoneNumber,
                    secondary: address.secondaryPhoneNumber,
                },
            ],
        };
    };

    const handleSubmitAddress = async (address) => {
        addressToSave.current = address;
        if (address.isPrimaryAddress && addressIndexToEdit !== 0) {
            // Changing primary address
            setNewPrimaryAddressWarning(true);
        } else {
            await saveAddress();
        }
    };

    return (
        <>
            <Stack gap={3} sx={{ maxWidth: '600px' }}>
                <Crumbs />
                <Stack gap={1}>
                    <Stack direction="row" gap={2}>
                        <MapPinIcon sx={{ width: 32, height: 32, color: Teal }} />
                        <Typography variant="displaySm" fontWeight={700}>
                            {t('ManageAddresses.titleLabel')}
                        </Typography>
                    </Stack>
                    <Typography variant="textMd" sx={{ fontWeight: 500 }}>
                        {t('ManageAddresses.promptLabel')}
                    </Typography>
                </Stack>
                <Card>
                    <CardContent>
                        <Stack gap={3}>
                            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="textLg" sx={{ fontWeight: 'bold' }}>
                                    {t('ManageAddresses.subTitleLabel')}
                                </Typography>
                                <Button color="secondary" variant="outlined" sx={{ paddingX: '16px', height: 40 }}>
                                    <Stack
                                        onClick={() => handleOpenForm()}
                                        gap={1}
                                        direction="row"
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            px: { xs: 2, sm: 0 },
                                        }}>
                                        <PlusCircleIcon sx={{ width: 24, height: 24 }} />
                                        <Typography
                                            noWrap
                                            variant="textMd"
                                            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {t('ManageAddresses.addAddressButtonLabel')}
                                        </Typography>
                                    </Stack>
                                </Button>
                            </Stack>
                            <List>
                                <Stack>
                                    {business.addresses?.map((address, index) => (
                                        <Stack gap={3} key={index}>
                                            {index !== 0 ? <Divider sx={{ mt: 3 }} /> : null}
                                            <AddressCell
                                                isPrimary={index === 0}
                                                address={address}
                                                onEdit={() => handleEdit(index)}
                                                onDelete={() => presentDialog(index)}
                                            />
                                        </Stack>
                                    ))}
                                </Stack>
                            </List>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <AddAddressDialog
                addressToEdit={
                    addressIndexToEdit !== null
                        ? { ...business.addresses[addressIndexToEdit], isPrimary: addressIndexToEdit === 0 }
                        : undefined
                }
                onClose={() => {
                    setAddressIndexToEdit(null);
                    setIsFormOpen(false);
                }}
                open={isFormOpen}
                onSubmitAddress={handleSubmitAddress}
            />
            <Dialog open={singleAddressWarning} onClose={handleCloseSingleAddressWarning}>
                <DialogTitle>Hold Up!</DialogTitle>
                <DialogContent>
                    <Typography>
                        Thatch requires at least one address to remain on file. Please create a second address if you
                        need to remove this one.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSingleAddressWarning} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteWarningOpen} onClose={handleCloseDeleteWarning}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure? Removing this address will delete it for everyone and is permanent.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteWarning} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => deleteAddress(addressIndexToEdit)}
                        variant="contained"
                        sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: 'darkred' } }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={primaryAddressWarning} onClose={handleClosePrimaryAddressWarning}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <Typography>
                        This address is the active listed address for {business?.name}. Please select another primary
                        address for your business in order to delete this one.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePrimaryAddressWarning} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={newPrimaryAddressWarning} onClose={handleClosePrimaryAddressWarning}>
                <DialogTitle>Warning</DialogTitle>
                <DialogContent>
                    <Typography>
                        You have selected this address to be your new primary address. Changing this address will change
                        it for everyone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewPrimaryAddressWarning} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveAddress} color="primary" variant="contained">
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop sx={{ color: White }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
