import { Collapse, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { OrderDiscountTypes } from '../../../constants/OrderDiscountTypes';

export default function OrderSummaryDetails(props) {
    const flags = useFlags();
    const { t } = useTranslation();
    const order = props.order;
    const { discountAmount, discountType, orderItems = [], shippingCost, subTotal, total } = order;
    const totalQuantity = orderItems?.reduce((acc, curr) => acc + parseInt(curr.quantity), 0);
    const OrderTotalStyle = {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: 'normal',
    };
    const ItemLayoutStyle = {
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const theme = useTheme();
    const isMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
    const paymentTermData = order?.paymentTermData || props?.paymentTermData || { id: 0 };
    const paymentTermOptions = {
        0: t('OrderSummaryCard.dueNowLabel'),
        [-1]: t('OrderSummaryCard.payOnScanLabel'),
        [-2]: t('OrderSummaryCard.consignmentLabel'),
    };
    if (!paymentTermData?.title) {
        paymentTermData.title = paymentTermOptions[paymentTermData?.id || 0];
    }
    return (
        <>
            <Collapse in={isMdBreakpoint || props.expanded}>
                <Divider sx={{ mb: '30px' }} />
                <Stack
                    gap={1}
                    sx={{
                        '& .MuiTypography-root': { fontSize: '12px', fontWeight: 600, lineHeight: 'normal' },
                    }}>
                    <Stack direction="row" sx={{ ...ItemLayoutStyle }}>
                        <Typography>{t('OrderSummaryDetails.totalQuantityLabel')}</Typography>
                        <Typography>{totalQuantity}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ ...ItemLayoutStyle }}>
                        <Typography>{t('OrderSummaryDetails.subtotalLabel')}</Typography>
                        <Typography>
                            <NumericFormat
                                prefix="$"
                                displayType="text"
                                value={subTotal}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ ...ItemLayoutStyle }}>
                        <Typography>{t('OrderSummaryDetails.shippingLabel')}</Typography>
                        <Typography>
                            {!shippingCost || shippingCost === '0' ? (
                                '-'
                            ) : (
                                <NumericFormat
                                    prefix="$"
                                    displayType="text"
                                    value={shippingCost}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                            )}
                        </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ ...ItemLayoutStyle }}>
                        <Typography>{t('OrderSummaryDetails.discountLabel')}</Typography>
                        <Typography>
                            {!discountType ? (
                                '-'
                            ) : (
                                <NumericFormat
                                    prefix={discountType === OrderDiscountTypes.DOLLARS ? '$' : undefined}
                                    suffix={discountType === OrderDiscountTypes.PERCENT ? '%' : undefined}
                                    displayType="text"
                                    value={discountAmount}
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                            )}
                        </Typography>
                    </Stack>
                    {flags.paymentTerms ? (
                        <Stack direction="row" sx={{ ...ItemLayoutStyle }}>
                            <Typography>{t('OrderSummaryDetails.termsLabel')}</Typography>
                            <Typography>{paymentTermData?.title}</Typography>
                        </Stack>
                    ) : null}
                </Stack>
                <Divider sx={{ my: '30px' }} />
            </Collapse>
            <Stack
                direction="row"
                sx={{
                    ...ItemLayoutStyle,
                    color: 'common.Tide',
                }}>
                <Typography sx={{ ...OrderTotalStyle }}>{t('OrderSummaryDetails.orderTotalLabel')}</Typography>
                <Typography sx={{ ...OrderTotalStyle, fontSize: '16px', fontWeight: 600 }}>
                    <NumericFormat
                        prefix="$"
                        displayType="text"
                        value={total}
                        thousandSeparator
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </Typography>
            </Stack>
        </>
    );
}
OrderSummaryDetails.propTypes = {
    expanded: PropTypes.bool,
    order: PropTypes.object,
    paymentTermData: PropTypes.object,
};
