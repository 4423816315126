import * as React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import _ from 'lodash';

const ProductIDs = ({ product, onChange, fieldsValidity, handleValidity }) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <TextField
                    onBlur={handleValidity}
                    label={t('BrandProductCard.mpnLabel')}
                    value={_.toString(product?.mpn)}
                    onChange={onChange}
                    name={'mpn'}
                    id={'MPN'}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    onBlur={handleValidity}
                    label={t('BrandProductCard.skuLabel')}
                    value={_.toString(product?.sku)}
                    onChange={onChange}
                    name={'sku'}
                    id={'SKU'}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    onBlur={handleValidity}
                    label={t('BrandProductCard.upcLabel')}
                    value={_.toString(product?.upc)}
                    onChange={onChange}
                    name={'upc'}
                    id={'UPC'}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    disabled
                    onBlur={handleValidity}
                    label={t('BrandProductCard.thirdPartyIdLabel')}
                    value={_.toString(product?.thirdPartyId)}
                    onChange={onChange}
                    name={'thirdPartyId'}
                    id={'thirdPartyId'}
                />
            </Grid>
        </Grid>
    );
};

ProductIDs.propTypes = {
    product: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    handleValidity: PropTypes.func.isRequired,
    fieldsValidity: PropTypes.object,
};

export default ProductIDs;
