import React from 'react';
import StickyHeader from '../../components/StickyHeader/StickyHeader.jsx';
import ThatchHeader from '../../components/StickyHeader/ThatchHeader.jsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import backgroundImage from '../../assets/LandingPageBG.webp';
import newsImage from '../../assets/news.svg';
import screenShot from '../../assets/screenShot.webp';
import { Midnight, SoftBG, Straw, White } from '../../theme.js';
import Card from '@mui/material/Card';
import LandingFooter from '../../components/LandingFooter/LandingFooter.jsx';
import { Trans } from 'react-i18next';
import GoogleAnalytics from '../../components/GoogleAnalytics/GoogleAnalytics.jsx';
import PropTypes from 'prop-types';
import HubSpotAnalytic from '../../components/HubSpotAnalytic/HubSpotAnalytic.jsx';

export const LinkText = (props) => {
    return (
        <a href={props.to || '#'} target="_blank" title={props.title || ''} rel="noreferrer">
            {props.children}
        </a>
    );
};

LinkText.propTypes = {
    to: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};
export default function NewsPage() {
    const responsiveDirection = { xs: 'column', sm: 'row' };
    const link1 = 'https://whole.shop/';
    const link2 = 'mailto:hello@whole.shop';
    return (
        <Stack sx={{ display: 'div', width: '100%', height: '100%' }}>
            <GoogleAnalytics trackingId={'G-TJVBNR01V6'} />
            <HubSpotAnalytic />
            <ThatchHeader />
            <StickyHeader />
            <Stack
                sx={{
                    width: '100%',
                    height: '100%',
                    flexGrow: 1,
                    overflow: 'auto',
                }}>
                {/* News Banner */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: { xs: 'flex-end', sm: 'center' },
                        width: '100%',
                        height: { xs: 700, sm: 350 },
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        paddingTop: 5,
                    }}>
                    <Stack
                        direction={responsiveDirection}
                        sx={{
                            gap: { xs: 4, sm: 0 },
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mx: 10,
                            width: '100%',
                        }}>
                        <Stack
                            gap={1}
                            sx={{
                                marginTop: '100px',
                                paddingBottom: '16px',
                                mr: { xs: 0, sm: 5 },
                                ml: { xs: 2, sm: 0 },
                            }}>
                            <Typography variant="displayMd" sx={{ color: Straw }}>
                                <Trans i18nKey="NewsPage.title" />
                            </Typography>
                            <Typography variant="textLg" sx={{ color: White, lineHeight: '32px' }}>
                                <Trans i18nKey="NewsPage.subTitle" />
                            </Typography>
                        </Stack>
                        <Box
                            component="img"
                            src={newsImage}
                            alt="Image1"
                            sx={{
                                marginTop: '80px',
                                paddingBottom: '36px',
                                height: '180px',
                                width: 'auto',
                            }}
                        />
                    </Stack>
                </Box>
                {/* News Section */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 950, md: 553 },
                        boxSizing: 'border-box',
                    }}>
                    <Stack gap={{ xs: 2, sm: 6 }} direction={responsiveDirection} sx={{ alignItems: 'center' }}>
                        <Stack gap={1} sx={{ maxWidth: 1360, mx: 3, paddingTop: '36px' }}>
                            <Typography variant="h5" sx={{ color: Midnight }}>
                                <Trans i18nKey="NewsPage.articleDate" />
                            </Typography>
                            <Typography variant="displayMd" sx={{ color: Midnight }}>
                                <Trans i18nKey="NewsPage.articleTitle" />
                            </Typography>
                            <Box
                                component="img"
                                src={screenShot}
                                alt="Image1"
                                sx={{
                                    width: { xs: '100%', sm: '100%' }, // Full width on small screens, half width on larger
                                    height: 'auto', // Maintains aspect ratio
                                    maxWidth: '100%',
                                }}
                            />
                            <Stack gap={4}>
                                <Typography variant="textLg" sx={{ color: Midnight, lineHeight: '32px' }}>
                                    <Trans i18nKey="NewsPage.article" />
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
                {/* Section 2 */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: { xs: 1000, sm: 900, md: 575 },
                        paddingX: { xs: 3, sm: 7, md: 12 },
                        paddingY: { xs: 5, sm: 7, md: 10 },
                        boxSizing: 'border-box',
                    }}>
                    <Card
                        sx={{
                            backgroundColor: SoftBG,
                            height: '100%',
                            width: '100%',
                        }}>
                        <Stack
                            direction={responsiveDirection}
                            sx={{
                                height: '100%',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}>
                            <Stack gap={1} sx={{ m: 5 }}>
                                <Typography variant="displayMd">
                                    <Trans i18nKey="NewsPage.aboutWholeShopTitle" />
                                </Typography>
                                <Typography variant="textLg" sx={{ lineHeight: '32px' }}>
                                    <Trans
                                        i18nKey="NewsPage.aboutWholeShop"
                                        components={{
                                            link1: <LinkText to={link1} title="whole.shop" />,
                                            link2: <LinkText to={link2} title="email us" />,
                                        }}
                                    />
                                </Typography>
                            </Stack>
                        </Stack>
                    </Card>
                </Box>

                {/* Footer */}
                <LandingFooter />
            </Stack>
        </Stack>
    );
}
