import React, { useEffect } from 'react';
import SettingsList from './SettingsList/SettingsList.jsx';
import { SettingsType } from '../../constants/SettingsType.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettingsContext } from '../../routes/settings/SettingsContext.jsx';

export default function SettingsSidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { business } = useSettingsContext();

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const lastSegment = pathSegments[pathSegments.length - 1];
        const settingsPaths = [
            'user-profile',
            'notifications',
            'payment',
            'company-profile',
            'company-users',
            'invite',
            'manage-addresses',
            'integrations',
            'dealer-settings',
        ];

        if (!settingsPaths.includes(lastSegment)) {
            navigate('/settings/user-profile');
        }
    }, [location.pathname]);

    const handleSettingsSelect = (newSetting) => {
        switch (newSetting) {
            case SettingsType.UserProfile:
                navigate('user-profile');
                break;
            case SettingsType.Notifications:
                navigate('notifications');
                break;
            case SettingsType.Payment:
                navigate('payment');
                break;
            case SettingsType.CompanyProfile:
                navigate('company-profile');
                break;
            case SettingsType.CompanyUsers:
                navigate('company-users');
                break;
            case SettingsType.Invite:
                navigate('invite');
                break;
            case SettingsType.ManageAddresses:
                navigate('manage-addresses');
                break;
            case SettingsType.Integrations:
                navigate('integrations');
                break;
            case SettingsType.DealerSettings:
                navigate('dealer-settings');
                break;
            default:
                break;
        }
    };

    return <SettingsList handleSettingsSelect={handleSettingsSelect} businessType={business.type} />;
}
