import React from 'react';
import { awaitAuth } from '../../providers/auth.js';
import CompanyProfile, { action as companyAction } from './CompanyProfile/CompanyProfile.jsx';
import Invite, { action as inviteAction } from './Invite/Invite.jsx';
import ManageAddresses from './ManageAddresses/ManageAddresses.jsx';
import Payment, { loader as paymentLoader, action as paymentAction } from './Payment/Payment.jsx';
import ThirdPartyIntegrations, {
    action as integrationsAction,
    loader as integrationsLoader,
} from '../ThirdPartyIntegrations/ThirdPartyIntegrations.jsx';
import SettingsCrumbs from '../../components/SettingsCrumbs/SettingsCrumbs.jsx';
import retailTheme from '../../theme.retail.js';
import { ThemeProvider } from '@mui/material/styles';
import { loader as settingsLoader } from './Settings.jsx';
import DealerSettings, {
    loader as dealerSettingsLoader,
    action as dealerSettingsAction,
} from './DealerSettings/DealerSettings.jsx';
import { action as paymentTermsAction } from './PaymentTerms/PaymentTerms.jsx';

export const SettingsRoutes = (type) => [
    {
        path: 'payment',
        element: (
            <ThemeProvider theme={retailTheme}>
                <Payment />
            </ThemeProvider>
        ),
        loader: awaitAuth(paymentLoader),
        action: paymentAction,
        handle: {
            crumb: () => <SettingsCrumbs key={'payment'} routeName={'Payment Details'} />,
        },
    },
    {
        path: 'company-profile',
        element: (
            <ThemeProvider theme={retailTheme}>
                <CompanyProfile />
            </ThemeProvider>
        ),
        loader: awaitAuth(settingsLoader),
        action: companyAction,
        handle: {
            crumb: () => <SettingsCrumbs key={'company-profile'} routeName={'Company Profile'} />,
        },
    },
    {
        path: 'invite',
        element: (
            <ThemeProvider theme={retailTheme}>
                <Invite businessType={type} />
            </ThemeProvider>
        ),
        action: inviteAction,
        handle: {
            crumb: () => <SettingsCrumbs key={'invite'} routeName={'Invite a Brand'} />,
        },
    },
    {
        path: 'manage-addresses',
        element: (
            <ThemeProvider theme={retailTheme}>
                <ManageAddresses />
            </ThemeProvider>
        ),
        handle: {
            crumb: () => <SettingsCrumbs key={'manage-addresses'} routeName={'Manage Addresses'} />,
        },
    },
    {
        path: 'integrations',
        loader: awaitAuth(integrationsLoader),
        action: integrationsAction,
        element: (
            <ThemeProvider theme={retailTheme}>
                <ThirdPartyIntegrations />
            </ThemeProvider>
        ),
        handle: {
            crumb: () => <SettingsCrumbs key={'integrations'} routeName={'Integrations'} />,
        },
    },
    {
        path: 'dealer-settings',
        loader: awaitAuth(dealerSettingsLoader),
        action: dealerSettingsAction,
        element: (
            <ThemeProvider theme={retailTheme}>
                <DealerSettings />
            </ThemeProvider>
        ),
        handle: {
            crumb: () => <SettingsCrumbs key={'dealer-settings'} routeName={'Dealer Settings'} />,
        },
    },
    { path: 'payment-terms', action: paymentTermsAction },
];
