import { Box } from '@mui/material';
import * as React from 'react';
import { Outlet, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import ConnectionsDrawer from './connections/products/ConnectionsDrawer';

export default function RetailLayout() {
    const data = useRouteLoaderData('root');
    const [search] = useSearchParams();
    const business = data?.businessesByBusinessId?.[search.get('businessId')] || data?.business?.connectedBusiness?.[0];
    return (
        <Box className="RetailLayout">
            <Box sx={{ display: { xs: 'block', sm: 'flex' } }}>
                <ConnectionsDrawer activeBusiness={business} />
                <Box
                    component="main"
                    sx={{ flexGrow: 1, pt: { xs: 4, sm: 'initial' }, width: { sm: 'calc(100vw - 80px)' } }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}
