import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Form, redirect, useActionData, useNavigate, useNavigation } from 'react-router-dom';
import { useTheme, useMediaQuery, DialogActions, DialogTitle, Dialog, DialogContent } from '@mui/material';
import _ from 'lodash';
import LoadingButton from '../../components/LoadingButton/LoadingButton.jsx';
import thatchLogo from '../../assets/ThatchLogo--Tide.svg';
import { confirmSignIn } from 'aws-amplify/auth';
import FormInputLabel from '../../components/FormInputLabel/FormInputLabel.jsx';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField.jsx';
import List from '@mui/material/List';
import { validatePasswords } from '../../helpers/HelperFunctions.jsx';
import { useTranslation } from 'react-i18next';
import FingerprintIcon from '../../icons/FingerprintIcon.jsx';
import Button from '@mui/material/Button';
import { BorderRadius } from '../../theme.js';
import { useError } from '../../components/Error/ErrorProvider.jsx';

export async function action({ request }) {
    try {
        const formData = await request.formData();
        const newPassword = formData.get('password');
        await confirmSignIn({ challengeResponse: newPassword });
        return redirect('/');
    } catch (error) {
        return { error };
    }
}

export default function CreateNewPassword() {
    const theme = useTheme();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
    const isLoading = navigation.state === 'submitting' || navigation.state === 'loading';
    const actionData = useActionData();
    const { handleError } = useError();

    const submitButtonEnabled = () => {
        return !_.isEmpty(password) && !_.isEmpty(confirmPassword) && _.isEmpty(passwordErrors);
    };

    useEffect(() => {
        setPasswordErrors(validatePasswords({ password, confirmPassword }));
    }, [password, confirmPassword]);

    useEffect(() => {
        if (actionData && actionData.error) {
            const error = actionData.error;
            if (error.name === 'SignInException') {
                setErrorMessage(t('CreateNewPassword.errorMessage'));
                setDialogOpen(true);
            } else {
                handleError(error);
            }
        }
    }, [actionData]);

    const handleDialogClose = () => {
        setDialogOpen(false);
        navigateBack();
    };

    const navigateBack = () => {
        navigate('/login');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
            }}>
            <Form method="POST">
                <Stack gap={10} sx={{ height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Stack
                        gap={2}
                        sx={{
                            alignItems: 'flex-start',
                            width: {
                                xs: '90%',
                                sm: '400px',
                            },
                            mt: 15,
                        }}>
                        <FingerprintIcon sx={{ color: 'common.Midnight', width: 48, height: 48 }} />
                        <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                            <Typography variant="displayMd">{t('CreateNewPassword.title')}</Typography>
                            <Typography sx={{ color: theme.palette.common.LightGray }}>
                                {t('CreateNewPassword.newPasswordPrompt')}
                            </Typography>
                            <Stack gap={3}>
                                <FormInputLabel text={t('CreateNewPassword.newPasswordLabel')}>
                                    <PasswordTextField
                                        fullWidth
                                        placeholder={t('CreateNewPassword.newPasswordPlaceholder')}
                                        name="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        autoComplete="new-password"
                                    />
                                </FormInputLabel>
                                {passwordErrors.length > 0 ? (
                                    <List>
                                        {passwordErrors.map((error, index) => (
                                            <Typography sx={{ color: 'common.Teal' }} key={index}>
                                                {error}
                                            </Typography>
                                        ))}
                                    </List>
                                ) : null}
                                <FormInputLabel text={t('CreateNewPassword.confirmNewPassword')}>
                                    <PasswordTextField
                                        fullWidth
                                        placeholder={t('CreateNewPassword.confirmPasswordPlaceholder')}
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        autoComplete="new-password"
                                    />
                                </FormInputLabel>
                                <Stack
                                    gap={1}
                                    direction="row"
                                    sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <Button
                                        onClick={navigateBack}
                                        variant="outlined"
                                        color="secondary"
                                        size={isSmBreakpoint ? undefined : 'sm'}
                                        sx={{ flex: 1 }}>
                                        {t('CreateNewPassword.backToSignInButton')}
                                    </Button>
                                    <LoadingButton
                                        isLoading={isLoading}
                                        disabled={!submitButtonEnabled()}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size={isSmBreakpoint ? undefined : 'sm'}
                                        sx={{ flex: 1 }}>
                                        {t('CreateNewPassword.updatePasswordButton')}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box component="img" src={thatchLogo} alt="Logo" sx={{ height: '25.7px', width: '150px', mb: 5 }} />
                </Stack>
            </Form>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                sx={{ '& .MuiPaper-root': { borderRadius: BorderRadius } }}>
                <DialogTitle>
                    <Typography variant="textLg" sx={{ fontWeight: 500 }}>
                        {t('CreateNewPassword.errorTitle')}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>{errorMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleDialogClose} color="primary" autoFocus>
                        {t('CreateNewPassword.backToSignInButton')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
