import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import PropTypes from 'prop-types';

export const sortByOptions = [
    { value: 'title', label: 'Name A-Z' },
    { value: '-title', label: 'Name Z-A' },
    { value: 'wholesale,title', label: 'Wholesale $ ∨' },
    { value: '-wholesale,title', label: 'Wholesale $ ∧' },
    { value: 'mpn,title', label: 'MPN A-Z/1-999' },
    { value: '-mpn,title', label: 'MPN Z-A/999-1' },
];

export default function ProductSortBySelect(props) {
    const { value, label, onChange, selectProps, defaultValue = 'title' } = props;

    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="product-sort-by-select-label">{label}</InputLabel>
                <Select
                    label={label}
                    labelId="product-sort-by-select-label"
                    id="product-sort-by-select"
                    value={value}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    {...selectProps}>
                    {sortByOptions.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                            {i.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

ProductSortBySelect.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};
