import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

export default function ProductSpecsTable(props) {
    const { specs: initialSpecs, updateSpecs } = props;
    const [specs, setSpecs] = useState(initialSpecs);
    const [rows, setRows] = useState([{ id: 0, name: '', value: '' }]);

    const renderCell = (params, isNameField) => {
        const isError = params.value === '' && params.id !== rows.length - 1;
        const cellStyle = {
            border: isError ? '1px solid red' : 'none',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        };

        return (
            <Box style={cellStyle}>
                <TextField
                    fullWidth
                    value={params.value ?? ''}
                    onChange={(e) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: e.target.value }, e)
                    }
                    placeholder={`Enter another ${isNameField ? 'name' : 'value'}`}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    inputProps={{
                        style: {
                            textAlign: 'center',
                        },
                    }}
                />
            </Box>
        );
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            editable: true,
            renderCell: (params) => renderCell(params, true),
        },
        {
            field: 'value',
            headerName: 'Value',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            editable: true,
            renderCell: (params) => renderCell(params, false),
        },
    ];

    useEffect(() => {
        // Always ensure there's an empty row at the end after any changes
        const nonEmptyRows = specs.filter((spec) => spec.name !== '' || spec.value !== '');
        const newRows = nonEmptyRows.map((spec, index) => ({ ...spec, id: index }));
        newRows.push({ id: newRows.length, name: '', value: '' }); // Ensure last row is empty
        setRows(newRows);
    }, [specs]);

    const handleProcessRowUpdate = (newRow, oldRow) => {
        const updatedSpecs = rows.map((row) => (row.id === newRow.id ? newRow : row));
        setSpecs(updatedSpecs.filter((spec) => spec.name !== '' || spec.value !== '')); // Filter out empty before setting
        setUpdatedSpecs(updatedSpecs);
        return newRow;
    };

    const handleRowOrderChange = (event) => {
        const oldIndex = event.oldIndex;
        const newIndex = event.targetIndex;
        const reorderedSpecs = [...specs];

        // Move the spec to the new index
        reorderedSpecs.splice(newIndex, 0, reorderedSpecs.splice(oldIndex, 1)[0]);

        // Update the 'id' of each element to match its new index
        const updatedSpecs = reorderedSpecs.map((spec, index) => ({
            ...spec,
            id: index,
        }));

        setSpecs(updatedSpecs);
        setUpdatedSpecs(updatedSpecs);
    };

    const setUpdatedSpecs = (specs) => {
        updateSpecs(
            specs
                .filter((spec) => spec.name !== '' || spec.value !== '')
                .map((row) => ({ name: row.name, value: row.value }))
        );
    };

    const pinnedRows = {
        bottom: [rows[rows.length - 1]],
    };

    return (
        <Box style={{ width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                processRowUpdate={handleProcessRowUpdate}
                onRowOrderChange={handleRowOrderChange}
                pinnedRows={pinnedRows}
                hideFooter
                disableColumnMenu
                rowReordering
                editMode="row"
                sx={{
                    '& .MuiDataGrid-cell': {
                        padding: 0,
                    },
                }}
            />
        </Box>
    );
}

ProductSpecsTable.propTypes = {
    specs: PropTypes.array.isRequired,
    updateSpecs: PropTypes.func.isRequired,
};
