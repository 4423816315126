/**
 * @file ThatchHeader.jsx
 * This is a temporary notification header that will be removed in a few months
 */

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Straw, White } from '../../theme.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import megaphone from '../../assets/megaphone.svg';

import Box from '@mui/material/Box';

const ThatchHeader = () => {
    const { t } = useTranslation();
    const [secondaryButtonTitleColor] = useState(White);
    const [headerColor] = useState(Straw);

    const navigate = useNavigate();

    const handleLearnSelect = () => {
        navigate('/news');
    };

    return (
        <>
            <Box
                position="fixed"
                elevation={0}
                style={{ backgroundColor: headerColor, height: '45px', width: '100%', zIndex: '101' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        minHeight: '45px',
                        mx: { xs: 1, sm: 5 },
                    }}>
                    <Box
                        component="img"
                        src={megaphone}
                        alt="megaphone"
                        sx={{
                            height: '32px',
                            width: 'auto',
                        }}
                    />
                    <Button
                        sx={{ color: secondaryButtonTitleColor, my: 2, display: 'block', textTransform: 'uppercase' }}>
                        {t('ThatchHeader.title')}
                    </Button>
                    <Button
                        onClick={handleLearnSelect}
                        sx={{ color: secondaryButtonTitleColor, my: 2, display: 'block' }}>
                        {t('LandingPage.learnMoreButton')}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default ThatchHeader;
