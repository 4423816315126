import React, { useEffect, useState } from 'react';
import { Alert, Grid, Snackbar, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useActionData, useLoaderData, useNavigation, useSubmit } from 'react-router-dom';
import VaryingProductPricingGroupRow from '../VariantPricingGroupList/VaryingProductPricingGroupRow.jsx';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import { useError } from '../Error/ErrorProvider.jsx';
import ItemPricingGroupRow from './ItemPricingGroupRow/ItemPricingGroupRow.jsx';
import {
    hasMadeChanges,
    mapOverridesByVariantId,
    mapOverridesForSubmit,
} from '../PricingGroupsList/pricingGroupUtils.js';

export const pricingGroupHeaderStyle = {
    fontSize: '14px',
    fontWeight: 800,
    letterSpacing: '2.8px',
    color: 'common.Teal',
    textTransform: 'uppercase',
};

export default function ProductPricingGroupList({ product }) {
    const { pricingGroups = [] } = useLoaderData();
    // filter out inactive variants to prevent confusing data
    const submit = useSubmit();
    const navigation = useNavigation();
    const { handleError } = useError();
    const intentName = 'savePricingGroups';
    const [showSuccess, setShowSuccess] = useState(false);
    const [deletes, setDeletes] = useState([]);
    const [initialOverrides, setInitialOverrides] = useState(mapOverridesByVariantId(product.productVariants));
    const [overrides, setOverrides] = useState(initialOverrides);
    const isLoading = navigation.state === 'submitting' || navigation.state === 'loading';
    const isSaving = isLoading && navigation.json?.intent === intentName;
    const actionData = useActionData();

    const isXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const syncOnProductVariantChange = () => {
        const newInitialOverrides = mapOverridesByVariantId(product.productVariants);
        setInitialOverrides(newInitialOverrides);

        // Get the valid variant IDs
        const validVariantIds = product.productVariants.map((variant) => variant.id);

        // Filter overrides to remove those with invalid variantIds
        const filteredOverrides = Object.keys(overrides)
            .filter((variantId) => validVariantIds.map(String).includes(variantId))
            .reduce((acc, variantId) => {
                acc[variantId] = overrides[variantId];
                return acc;
            }, {});

        // Update overrides state
        setOverrides(filteredOverrides);

        // Filter deletes to remove those with invalid variantIds (matching the same logic as above)
        const filteredDeletes = deletes.filter((variantId) => validVariantIds.map(String).includes(String(variantId)));
        // Set the filtered deletes state
        setDeletes(filteredDeletes);
    };

    useEffect(() => {
        syncOnProductVariantChange();
    }, [product]);

    const createOverridesCopy = () => {
        return Object.keys(overrides).reduce((acc, variantId) => {
            // Create a deep copy of each array of overrides for the given variantId
            acc[variantId] = overrides[variantId].map((override) => ({ ...override }));
            return acc;
        }, {});
    };

    const handleChange = (pricingGroupId, discountPercentage) => {
        const updatedOverrides = createOverridesCopy();
        let updatedDeletes = [...deletes];

        product.productVariants?.forEach((variant) => {
            const variantOverrides = updatedOverrides[variant.id] || []; // If no overrides exist, default to an empty array

            const existingOverrideIndex = variantOverrides.findIndex(
                (override) => override.pricingGroupId === pricingGroupId
            );

            if (existingOverrideIndex !== -1) {
                // If editing an existing override
                if (discountPercentage === '' || discountPercentage === 0) {
                    // Remove existing override
                    updatedOverrides[variant.id] = variantOverrides.filter(
                        (override) => override.pricingGroupId !== pricingGroupId
                    );
                    // Queue up any existing overrides to be deleted in the DB
                    const newDeletes = Object.values(initialOverrides)
                        .flat()
                        .filter((group) => group.pricingGroupId === pricingGroupId);
                    updatedDeletes = [...deletes, ...newDeletes];
                } else {
                    updatedOverrides[variant.id][existingOverrideIndex].discountPercentage = discountPercentage;
                    updatedDeletes = updatedDeletes.filter(
                        (deleteItem) => deleteItem.pricingGroupId !== pricingGroupId
                    );
                }
            } else {
                // If no override exists, create a new one
                const newOverride = {
                    variantId: variant.id,
                    pricingGroupId,
                    discountPercentage,
                };

                // If no array for this variantId, create one
                if (!updatedOverrides[variant.id]) {
                    updatedOverrides[variant.id] = [];
                }

                updatedDeletes = updatedDeletes.filter((deleteItem) => deleteItem.pricingGroupId !== pricingGroupId);
                // Add the new override to the variant's overrides array
                updatedOverrides[variant.id].push(newOverride);
            }
        });
        setDeletes(updatedDeletes);
        setOverrides(updatedOverrides);
    };
    const overrideForGroup = (groupId) => {
        const override = Object.values(overrides)
            .flat()
            .find((override) => override.pricingGroupId === groupId);
        if (override) return override;
        return null;
    };

    const hasMultipleVariantOverrides = (groupId) => {
        const matchingOverrides = variantOverridesForGroup(groupId);
        if (_.isEmpty(matchingOverrides)) return false;
        const discountPercentages = matchingOverrides.map((override) => override.discountPercentage);
        const allSame =
            product.productVariants.length === discountPercentages.length &&
            discountPercentages.every((discount) => discount === discountPercentages[0]);

        return !allSame;
    };

    const variantOverridesForGroup = (groupId) => {
        return Object.values(overrides)
            .flat()
            .filter((override) => override.pricingGroupId === groupId);
    };

    const discardChanges = () => {
        setOverrides(initialOverrides);
    };
    const handleSave = () => {
        submit(
            {
                intent: intentName,
                updates: mapOverridesForSubmit({
                    overrides: Object.values(overrides).flat(),
                    initialOverrides: Object.values(initialOverrides).flat(),
                }),
                deletes: deletes.flat().map((del) => ({ id: del.id, variantId: del.variantId })),
                productId: product.id,
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    useEffect(() => {
        if (actionData && navigation.state === 'loading') {
            if (actionData.error) {
                handleError(actionData.error);
            } else {
                setDeletes([]);
                setShowSuccess(true);
            }
        }
    }, [actionData]);

    return (
        <>
            {!isXs && (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="label" sx={{ ...pricingGroupHeaderStyle, textAlign: 'left' }}>
                            {t('ProductPricingGroup.titleLabel')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="label" sx={{ ...pricingGroupHeaderStyle, textAlign: 'left' }}>
                            {t('ProductPricingGroup.percentOffLabel')}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="label" sx={{ ...pricingGroupHeaderStyle, textAlign: 'left' }}>
                            {t('ProductPricingGroup.priceLabel')}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {pricingGroups.map((group, index) =>
                hasMultipleVariantOverrides(group.id) ? (
                    <VaryingProductPricingGroupRow product={product} pricingGroup={group} key={index} />
                ) : (
                    <ItemPricingGroupRow
                        pricingGroup={group}
                        itemPrice={Number(product.msrp)}
                        override={overrideForGroup(group.id)}
                        key={index}
                        onChange={(event) => handleChange(group.id, event.discountPercentage)}
                    />
                )
            )}
            {hasMadeChanges(Object.values(overrides).flat(), Object.values(initialOverrides).flat()) ? (
                <Stack gap={2} direction="row" sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button onClick={discardChanges} variant="outlined" color="secondary" sx={{ height: 40 }}>
                        Discard Changes
                    </Button>
                    <LoadingButton
                        isLoading={isSaving}
                        onClick={handleSave}
                        variant="contained"
                        sx={{ height: 40, width: 100 }}>
                        Save
                    </LoadingButton>
                </Stack>
            ) : null}
            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Pricing Groups Updated
                </Alert>
            </Snackbar>
        </>
    );
}

ProductPricingGroupList.propTypes = {
    product: PropTypes.object,
};
