import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetcher, useRouteLoaderData, useSearchParams } from 'react-router-dom';
import * as api from '../../../api';
import AccountMenu from '../../../components/AccountMenu/AccountMenu';
import OrderStatusSelect from '../../../components/OrderStatusSelect/OrderStatusSelect';
import SearchBar from '../../../components/SearchBar/SearchBar';
import InfiniteScroller from '../../../helpers/InfiniteScroller';
import * as businessProvider from '../../../providers/businessProvider';
import RetailOrderListItem from './RetailOrderListItem';
import { useEffect, useMemo, useState } from 'react';
import _, { debounce } from 'lodash';
import Stack from '@mui/material/Stack';
import OrderSortSelect from '../../../components/OrderSortSelect/OrderSortSelect.jsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BusinessSelect from '../../../components/BusinessSelect/BusinessSelect.jsx';

const PAGE_LIMIT = 10;

export async function loader({ request }) {
    const url = new URL(request.url);
    const offset = url.searchParams.get('offset') || 0;
    const status = url.searchParams.get('status') || undefined;
    const businessId = url.searchParams.get('businessId') || undefined;
    const sort = url.searchParams.get('sort') || '-createdAt';
    const defaultBusiness = await businessProvider.getBusiness();
    // TODO: add searchString back in when BE fixed
    const [orders] = await Promise.all([
        api.getOrders({
            query: { businessId, retailBusinessId: defaultBusiness?.businessId, offset, status, sort },
            signal: request.signal,
        }),
    ]);
    return {
        business: defaultBusiness,
        orders,
    };
}

export default function RetailOrders(props) {
    const { t } = useTranslation();
    const root = useRouteLoaderData('root');
    const data = useRouteLoaderData('retail-orders');
    const fetcher = useFetcher();
    const [search, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState('');
    const [brand, setBrand] = React.useState('');
    const [sort, setSort] = React.useState('-createdAt');
    const flags = useFlags();

    const [orders, setOrders] = React.useState(data?.orders?.rows || []);
    const [hasMore, setHasMore] = React.useState(data?.orders?.hasMore);
    const [status, setStatus] = React.useState(search.get('status'));
    const [offset, setOffset] = React.useState(data?.orders.offset || 0);

    React.useEffect(() => {
        setHasMore(data?.orders?.hasMore);
        setOffset(data?.orders.offset);
        setOrders(data?.orders?.rows);
    }, [data]);

    React.useEffect(() => {
        if (!fetcher.data || fetcher.state !== 'idle') {
            return;
        }
        if (fetcher.data?.orders) {
            const newRows = fetcher.data.orders.rows;
            setHasMore(fetcher.data.orders.hasMore);
            setOrders((prev) => [...prev, ...newRows]);
        }
    }, [fetcher]);

    const selectBusiness = (id) => {
        return root?.businessesByBusinessId?.[id];
    };

    const loadMore = () => {
        let offsetAmount = offset;
        if (orders.length) {
            offsetAmount = Number(fetcher.data?.orders ? fetcher.data.orders.offset : offset) + PAGE_LIMIT;
        }
        loadOrders({ businessId: search.get('businessId'), offset: offsetAmount, status });
    };

    const loadOrders = ({ businessId, offset, status }) => {
        const queryString = new URLSearchParams();
        if (businessId) {
            queryString.set('businessId', businessId);
        }
        if (status && status !== t('OrderStatusSelect.emptyLabel')) {
            queryString.set('status', status);
        }
        queryString.set('offset', offset);
        const query = `?${queryString.toString()}`;
        fetcher.load('/retail/orders/' + query);
    };

    const handleStatusChange = (e) => {
        const status = e.target.value;
        setStatus(status);

        const query = { ...search, status };
        if (status === t('OrderStatusSelect.emptyLabel')) {
            delete query.status;
        }
        setSearchParams(query);
    };

    const handleBrandChange = (businessId) => {
        setBrand(businessId);

        const query = { ...search, businessId };
        if (_.isEmpty(businessId)) {
            delete query.businessId;
        }
        setSearchParams(query);
    };

    const handleSortChange = (e) => {
        const sort = e.target.value;
        setSort(sort);

        const query = { ...search, sort };
        setSearchParams(query);
    };

    const queryOrders = useMemo(function () {
        return debounce((value) => {
            if (_.isString(value)) {
                if (_.isEmpty(value)) {
                    setSearchParams({ ...search });
                } else {
                    setSearchParams({ ...search, searchString: value });
                }
            }
        }, 400);
    }, []);

    useEffect(() => {
        queryOrders(searchValue);
    }, [searchValue]);

    return (
        <Box sx={{ px: { xs: 3, sm: 7 }, pt: { sm: 6 }, pb: 8 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap-reverse',
                    gap: 1,
                    alignItems: { sm: 'center' },
                    mb: 4,
                    flexDirection: {
                        xs: 'column-reverse',
                        sm: 'row',
                    },
                }}>
                <Typography component="p" variant="displayMd">
                    {t('RetailOrders.title')}
                </Typography>
                <Box sx={{ flex: '1 0 auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ visibility: flags.orderSearch ? 'visible' : 'hidden' }}>
                        <SearchBar
                            placeholder={t('RetailOrders.searchPlaceholder')}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </Box>
                    <AccountMenu />
                </Box>
            </Box>
            <Box sx={{ maxWidth: '800px' }}>
                <Stack gap={2} direction="row" sx={{ mb: 6 }}>
                    <OrderStatusSelect
                        defaultValue={status}
                        selectProps={{ size: 'small' }}
                        onChange={handleStatusChange}
                    />
                    <BusinessSelect
                        businesses={data?.business?.connectedBusiness || []}
                        defaultValue={brand}
                        selectProps={{ size: 'small' }}
                        onChange={handleBrandChange}
                    />
                    <OrderSortSelect defaultValue={sort} selectProps={{ size: 'small' }} onChange={handleSortChange} />
                </Stack>
                <InfiniteScroller loadNext={loadMore} loading={fetcher.state === 'loading'} hasMore={hasMore}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        {orders?.map((o, index) => (
                            <RetailOrderListItem business={selectBusiness(o.businessId)} order={o} key={o.id} />
                        ))}
                    </Box>
                </InfiniteScroller>
            </Box>
        </Box>
    );
}
