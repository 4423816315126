import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, isEmpty, isString, toString } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import * as api from '../../../api';
import { EmptyTable } from '../../../components/EmptyTable/EmptyTable.jsx';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { createCurrencyColumn } from '../../../helpers/HelperFunctions.jsx';
import PageHeader from '../../../components/PageHeader/PageHeader.jsx';
import PlusCircleIcon from '../../../icons/PlusCircleIcon.jsx';
import Stack from '@mui/material/Stack';
import BusinessSelect from '../../../components/BusinessSelect/BusinessSelect.jsx';
import * as businessProvider from '../../../providers/businessProvider.js';
import { BusinessTypes } from '../../../constants/BusinessTypes.jsx';
import { PaymentStatuses } from '../../../constants/PaymentStatuses.jsx';
import OrderStatusSelect from '../../../components/OrderStatusSelect/OrderStatusSelect.jsx';
import OrderSortSelect from '../../../components/OrderSortSelect/OrderSortSelect.jsx';
import ThatchGridPro from '../../../components/ThatchGridPro/ThatchGridPro.jsx';
import { Fog, Midnight, Teal } from '../../../theme.js';
import BusinessLogo from '../../../components/BusinessLogo.jsx';
import Typography from '@mui/material/Typography';
import OrderStatusChip from '../../../components/OrderStatusChip/OrderStatusChip.jsx';
import BrandOrdersList from '../../../components/BrandOrdersList/BrandOrdersList.jsx';
import QuickActionButton from '../../../components/QuickActionButton/QuickActionButton.jsx';

export async function loader({ request }) {
    try {
        const url = new URL(request.url);
        const searchString = url.searchParams.get('searchString') || '';
        const dealerId = url.searchParams.get('businessId');
        const status = url.searchParams.get('status');
        const sort = url.searchParams.get('sort');
        const params = {
            query: {
                offset: 0,
                limit: 20,
            },
        };

        if (searchString) {
            params.query.searchString = searchString;
        }

        if (dealerId) {
            params.query.businessId = dealerId;
        }

        if (status) {
            params.query.status = status;
        }

        if (sort) {
            params.query.sort = sort;
        }

        const orders = await api.getOrders(params);
        const business = await businessProvider.getBusiness();
        return { orders, business };
    } catch (error) {
        console.error('Failed to load orders:', error);
        Sentry.captureException(error);
        return { orders: { rows: [], hasMore: false } };
    }
}

export default function BrandOrders() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const flags = useFlags();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [search, setParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(search.get('searchString'));
    const [hasMore, setHasMore] = useState(false);
    const offset = useRef(0);
    const [status, setStatus] = React.useState(search.get('status'));
    const [sort, setSort] = React.useState('-createdAt');

    const data = useLoaderData();
    const initialOrders = data?.orders;
    const [error, setError] = useState(null);
    const [dealer, setDealer] = useState(
        data?.business?.connectedBusiness?.find((business) => business.businessId === search.get('businessId'))
            ?.businessId
    );

    const queryProducts = useMemo(function () {
        return debounce((value) => {
            if (isString(value)) {
                if (isEmpty(value)) {
                    const newParams = new URLSearchParams(search);
                    newParams.delete('searchString');
                    setParams(newParams);
                } else {
                    setParams({ ...search, searchString: value });
                }
            }
        }, 400);
    }, []);

    useEffect(() => {
        queryProducts(searchValue);
    }, [searchValue]);

    const handleSearchInputChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    useEffect(() => {
        if (initialOrders) {
            setOrders(initialOrders.rows.map(mapOrder));
            setHasMore(initialOrders.hasMore);
            offset.current = initialOrders.limit;
        }
    }, [initialOrders]);

    const mapOrder = (order) => {
        // Function to format date to MM/DD/YYYY
        const formatDate = (date) => {
            return new Date(date).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            });
        };

        // Function to sum the quantities of all order items
        const qty = order?.orderItems || [];
        const totalQuantity = qty.reduce((sum, item) => {
            return sum + parseInt(item.quantity, 10);
        }, 0);

        return {
            dealer: order.retailBusiness,
            id: order?.id || '',
            orderNumber: order?.orderNumber > 0 ? order?.orderNumber : order?.id,
            orderType: order?.type || '',
            createdAt: formatDate(order.createdAt) || '',
            updatedAt: formatDate(order.updatedAt) || '',
            store: order?.addresses?.[0]?.city || '',
            payments: order.payments,
            quantity: totalQuantity,
            total: order?.total || '',
            terms: order?.terms || '',
            status: order?.status || '',
            paid: mutateUIStatus(order),
            paymentTermData: order?.paymentTermData,
            paymentTermId: order?.paymentTermId,
            shippedOn: order?.shippedOn,
        };
    };

    const loadMoreRows = async () => {
        if (!hasMore || loading) return;

        try {
            setLoading(true);
            const newData = await api.getOrders({
                query: {
                    offset: offset.current,
                    limit: 20,
                    businessId: dealer,
                },
            });

            const newRows = newData.rows.map(mapOrder);
            setOrders((prevOrders) => [...prevOrders, ...newRows]);
            setHasMore(newData.hasMore);
            offset.current += newData.rows.length;
        } catch (error) {
            console.error('Failed to load more orders:', error);
            Sentry.captureException(error);
            setError('Failed to load more orders');
        } finally {
            setLoading(false);
        }
    };

    const handleOnRowsScrollEnd = () => {
        loadMoreRows();
    };

    const columns = [
        { field: 'orderNumber', headerName: 'ID', width: 70 },
        {
            field: 'dealer',
            headerName: 'DEALER',
            minWidth: 275,
            flex: 1,
            renderCell: (params) => (
                <Stack direction="row" gap={2} sx={{ alignItems: 'center' }}>
                    <Box
                        sx={{
                            overflow: 'hidden',
                            borderRadius: '8px',
                            border: '1px solid',
                            borderColor: Fog,
                        }}>
                        <BusinessLogo business={params.row.dealer} sx={{ width: 48, height: 48 }} />
                    </Box>
                    <Stack>
                        <Typography variant="textMd" sx={{ color: Midnight }}>
                            {params.row.dealer.name}
                        </Typography>
                        <Typography variant="textMd" sx={{ color: Teal }}>
                            {params.row.dealer.addresses[0].city}
                        </Typography>
                    </Stack>
                </Stack>
            ),
        },
        {
            field: 'status',
            headerName: 'STATUS',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => <OrderStatusChip order={params.row} />,
        },
        { field: 'createdAt', headerName: 'CREATED', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'quantity', headerName: 'QTY', width: 150, align: 'center', headerAlign: 'center' },
        createCurrencyColumn({
            field: 'total',
            headerName: 'TOTAL',
            width: 150,
            currency: 'USD',
            align: 'right',
            headerAlign: 'right',
            flex: 1,
        }),
    ];

    const handleCellClick = (params, event) => {};

    const handleRowClick = (params) => {
        navigate(`/brand/orders/${params.id}`);
    };

    const handleDealerChange = (businessId) => {
        setDealer(businessId);
        if (isEmpty(businessId)) {
            const newParams = new URLSearchParams(search);
            newParams.delete('businessId');
            setParams(newParams);
        } else {
            search.set('businessId', businessId);
            setParams(search);
        }
    };
    const handleStatusChange = (e) => {
        const status = e.target.value;
        setStatus(status);

        if (status === t('OrderStatusSelect.emptyLabel')) {
            search.delete('status');
        } else {
            search.set('status', status);
        }

        setParams(search);
    };

    const handleSortChange = (e) => {
        const sort = e.target.value;
        setSort(sort);
        search.set('sort', sort);
        setParams(search);
    };

    const theme = useTheme();
    const isSmBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box>
            {flags.isWholeshopEnabled ? (
                <PageHeader
                    title={t('BrandOrders.title')}
                    actions={
                        flags.newOrder ? (
                            <Button
                                startIcon={<PlusCircleIcon sx={{ width: 24, height: 24 }} />}
                                variant="outlined"
                                color="secondary"
                                size={isSmBreakpoint ? 'sm' : 'xs'}
                                onClick={() => navigate('/brand/new-order')}>
                                {t('BrandOrders.addAnOrderButtonLabel')}
                            </Button>
                        ) : null
                    }
                    sx={{ mb: 3 }}
                />
            ) : null}
            {error && <p>Error: {error}</p>}
            <Stack gap={1} sx={{ width: '100%' }}>
                <Stack gap={{ xs: 2, sm: 1 }} direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }}>
                    <SearchBar
                        onChange={handleSearchInputChange}
                        value={toString(searchValue)}
                        placeholder="Search orders"
                        sx={{ width: { xs: '100%', sm: '45%' } }}
                    />
                    <Stack
                        gap={1}
                        direction="row"
                        sx={{
                            overflowX: { xs: 'auto', sm: 'none' },
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            width: { xs: '100%', sm: 'auto' },
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                        <OrderStatusSelect
                            defaultValue={status}
                            selectProps={{ size: 'small' }}
                            onChange={handleStatusChange}
                        />
                        <BusinessSelect
                            businesses={data?.business?.connectedBusiness ?? []}
                            defaultValue={dealer}
                            selectProps={{ size: 'small' }}
                            onChange={handleDealerChange}
                            type={BusinessTypes.RETAIL}
                        />
                        <OrderSortSelect
                            defaultValue={sort}
                            selectProps={{ size: 'small' }}
                            onChange={handleSortChange}
                        />
                    </Stack>
                </Stack>
                <Box>
                    {!flags.newOrder || flags.isWholeshopEnabled ? null : (
                        <Button
                            size="xs"
                            color="primary"
                            variant="contained"
                            onClick={() => navigate('/brand/new-order')}>
                            {t('BrandOrders.newOrderButtonLabel')}
                        </Button>
                    )}
                </Box>
                {isSmBreakpoint ? (
                    <ThatchGridPro
                        columns={columns}
                        rows={orders}
                        loading={loading}
                        onRowsScrollEnd={handleOnRowsScrollEnd}
                        slots={{
                            loadingOverlay: LinearProgress,
                            noRowsOverlay: () => <EmptyTable message="No orders found" />,
                        }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
                        }
                        onRowClick={(params) => {
                            handleRowClick(params);
                        }}
                        onCellClick={(params, event) => {
                            handleCellClick(params, event);
                            event.defaultMuiPrevented = true;
                        }}
                        rowHeight={70}
                        sx={{
                            height: 1000,
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                                outlineOffset: '0',
                                outline: 'none !important',
                            },
                        }}
                    />
                ) : (
                    <BrandOrdersList orders={orders} onRowClick={handleRowClick} />
                )}
            </Stack>
            <Box sx={{ padding: 0, position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>
                <QuickActionButton />
            </Box>
        </Box>
    );
}

function mutateUIStatus(order) {
    // const status = order?.status || '';
    const mostRecentPayment = order?.payments?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
    if (mostRecentPayment?.status === PaymentStatuses.COMPLETE) {
        return 'Paid';
    }
    return 'Unpaid';
}
