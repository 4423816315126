export const CompanyProfileInputTypes = {
    BUSINESSNAME: 'BusinessName',
    BUSINESSTYPE: 'BusinessType',
    ESTABLISHED: 'Established',
    ABOUTUS: 'AboutUs',
    HEADQUARTERS: 'HeadQuarters',
    STREET1: 'Street1',
    CITY: 'City',
    ZIP: 'Zip',
    STATE: 'State',
    EMAIL: 'Email',
    PHONE: 'Phone',
    SECONDARYPHONE: 'SecondaryPhone',
    LOCATIONS: 'Locations',
    MINORDER: 'MinOrder',
};
