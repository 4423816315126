import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';

export default function TreeIcon(props) {
    return (
        <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M20 14H26C26.5304 14 27.0391 13.7893 27.4142 13.4142C27.7893 13.0391 28 12.5304 28 12V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4H20C19.4696 4 18.9609 4.21071 18.5858 4.58579C18.2107 4.96086 18 5.46957 18 6V8H16C15.2044 8 14.4413 8.31607 13.8787 8.87868C13.3161 9.44129 13 10.2044 13 11V15H9V14C9 13.4696 8.78929 12.9609 8.41421 12.5858C8.03914 12.2107 7.53043 12 7 12H3C2.46957 12 1.96086 12.2107 1.58579 12.5858C1.21071 12.9609 1 13.4696 1 14V18C1 18.5304 1.21071 19.0391 1.58579 19.4142C1.96086 19.7893 2.46957 20 3 20H7C7.53043 20 8.03914 19.7893 8.41421 19.4142C8.78929 19.0391 9 18.5304 9 18V17H13V21C13 21.7956 13.3161 22.5587 13.8787 23.1213C14.4413 23.6839 15.2044 24 16 24H18V26C18 26.5304 18.2107 27.0391 18.5858 27.4142C18.9609 27.7893 19.4696 28 20 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V20C28 19.4696 27.7893 18.9609 27.4142 18.5858C27.0391 18.2107 26.5304 18 26 18H20C19.4696 18 18.9609 18.2107 18.5858 18.5858C18.2107 18.9609 18 19.4696 18 20V22H16C15.7348 22 15.4804 21.8946 15.2929 21.7071C15.1054 21.5196 15 21.2652 15 21V11C15 10.7348 15.1054 10.4804 15.2929 10.2929C15.4804 10.1054 15.7348 10 16 10H18V12C18 12.5304 18.2107 13.0391 18.5858 13.4142C18.9609 13.7893 19.4696 14 20 14ZM7 18H3V14H7V18ZM20 20H26V26H20V20ZM20 6H26V12H20V6Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
}

TreeIcon.propTypes = {
    sx: PropTypes.any,
};
