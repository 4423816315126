import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import BusinessLogo from '../BusinessLogo.jsx';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import RadioGroup from '@mui/material/RadioGroup';
import DealerAddressListItem from './DealerAddressListItem.jsx';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import ListItem from '@mui/material/ListItem';
import _ from 'lodash';

export default function DealerListItem({ dealerInfo, isSelected, onClick, onAddressSelect }) {
    const imageHeight = '80px';
    const dealer = dealerInfo.dealer;
    const address = dealerInfo.address;
    const numberOfAddresses = dealer.addresses.length ?? 0;
    const preselectedAddressIndex = () => {
        if (_.isEmpty(dealerInfo.address)) {
            return 0;
        } else {
            return _.findIndex(dealer.addresses, (a) => _.isEqual(a, address));
        }
    };
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(preselectedAddressIndex);

    const handleAddressSelect = (index) => {
        setSelectedAddressIndex(index);
        onAddressSelect(dealer.addresses[index]);
    };
    return (
        <Stack sx={{ borderBottom: '1px solid gray', borderBottomColor: 'common.borderColor' }}>
            <ListItemButton
                onClick={!isSelected ? onClick : null}
                disableRipple
                disableTouchRipple
                sx={{
                    paddingX: 0,
                    paddingY: '3px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                }}>
                <Stack direction="row">
                    <Box sx={{ border: '1px solid black' }}>
                        <BusinessLogo business={dealer} sx={{ width: imageHeight, height: imageHeight }} />
                    </Box>
                    <Stack gap={0.5} sx={{ paddingX: 1 }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>{dealer.name}</Typography>
                        <Typography sx={{ fontSize: '16px' }}>
                            {numberOfAddresses + (numberOfAddresses === 1 ? 'Address' : ' Addresses')}
                        </Typography>
                    </Stack>
                </Stack>
            </ListItemButton>
            {isSelected ? (
                <List>
                    <RadioGroup>
                        {orderedAddresses().map((address, index) => (
                            <ListItem key={index} sx={{ padding: 0 }}>
                                <FormControlLabel
                                    value={dealer.id}
                                    control={<Radio checked={selectedAddressIndex === index} />}
                                    label={
                                        <DealerAddressListItem
                                            address={address}
                                            onClick={() => handleAddressSelect(index)}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                    </RadioGroup>
                </List>
            ) : null}
        </Stack>
    );

    function orderedAddresses() {
        return dealer.addresses.sort(function (x, y) {
            return x.label === 'Primary' ? -1 : y.label === 'Primary' ? 1 : 0;
        });
    }
}

DealerListItem.propTypes = {
    dealerInfo: PropTypes.object,
    isSelected: PropTypes.bool,
    onAddressSelect: PropTypes.func,
    onClick: PropTypes.func,
};
