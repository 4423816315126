import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Midnight, Teal } from '../../theme.js';
import PropTypes from 'prop-types';
import * as api from '../../api.js';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import { useError } from '../Error/ErrorProvider.jsx';
import CircleXIcon from '../../icons/CircleXIcon.jsx';
import FormInputLabel from '../FormInputLabel/FormInputLabel.jsx';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';

export default function AddUser({ businessId, didAddUser, onClose }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [buttonState, setButtonState] = useState('idle');
    const { handleError } = useError();

    const submit = async () => {
        setIsLoading(true);
        try {
            const user = {
                username: email.toLowerCase(),
                firstName,
                lastName,
                businessId,
                contacts: [],
            };
            await api.createUser(user);
            setButtonState('success');
            setTimeout(() => {
                clearInputs();
                didAddUser();
            }, 1000);
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleTextChange = (event) => {
        const input = event.target.value;
        switch (event.target.name) {
            case 'firstName':
                setFirstName(input);
                break;
            case 'lastName':
                setLastName(input);
                break;
            case 'email':
                setEmail(input);
                break;
        }
    };

    const validateInputs = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(email);
        const isValidFirstName = firstName !== '';
        const isValidLastName = lastName !== '';
        setIsValidEmail(email === '' || isValidEmail);
        return setIsButtonEnabled(isValidEmail && isValidFirstName && isValidLastName);
    };

    const clearInputs = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
    };

    useEffect(() => {
        validateInputs();
    }, [email, firstName, lastName]);
    return (
        <>
            <Stack gap={3}>
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="textLg" fontWeight="bold">
                        {t('AddUserInput.addUserTitle')}
                    </Typography>
                    <IconButton onClick={onClose} sx={{ padding: 0 }}>
                        <CircleXIcon sx={{ color: Midnight, width: 32, height: 32 }} />
                    </IconButton>
                </Stack>
                <Stack direction="row" gap={2}>
                    <Box sx={{ flexGrow: 1 }}>
                        <FormInputLabel text={t('AddUserInput.firstNameInputLabel')}>
                            <TextField
                                placeholder={t('AddUserInput.firstNameInputPlaceholder')}
                                size="small"
                                fullWidth
                                name="firstName"
                                value={firstName}
                                onChange={handleTextChange}
                            />
                        </FormInputLabel>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <FormInputLabel text={t('AddUserInput.lastNameInputLabel')}>
                            <TextField
                                placeholder={t('AddUserInput.lastNameInputPlaceholder')}
                                size="small"
                                fullWidth
                                name="lastName"
                                value={lastName}
                                onChange={handleTextChange}
                            />
                        </FormInputLabel>
                    </Box>
                </Stack>
                <FormInputLabel text={t('AddUserInput.emailInputLabel')}>
                    <TextField
                        placeholder={t('AddUserInput.emailInputPlaceholder')}
                        size="small"
                        fullWidth
                        name="email"
                        error={!isValidEmail}
                        value={email}
                        onChange={handleTextChange}
                    />
                </FormInputLabel>
                <LoadingButton
                    isLoading={isLoading}
                    variant="contained"
                    size="small"
                    disabled={!isButtonEnabled}
                    onClick={submit}
                    sx={{ backgroundColor: buttonState === 'success' ? Teal : Midnight }}>
                    {buttonState === 'idle' ? t('AddUserInput.addNewUserButtonLabel') : 'Invite Sent'}
                </LoadingButton>
            </Stack>
        </>
    );
}

AddUser.propTypes = {
    businessId: PropTypes.string.isRequired,
    didAddUser: PropTypes.func,
    onClose: PropTypes.func,
};
