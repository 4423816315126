import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function LoadingModal(props) {
    return (
        <Modal open={props.open} slotProps={{ backdrop: { invisible: true } }}>
            <CircularProgress
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            />
        </Modal>
    );
}

LoadingModal.propTypes = {
    open: PropTypes.bool,
};
