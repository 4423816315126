import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import * as api from '../../api.js';
import CircularProgress from '@mui/material/CircularProgress';
import { useError } from '../../components/Error/ErrorProvider.jsx';
import Box from '@mui/material/Box';
import OurAppBar from '../../components/OurAppBar/OurAppBar.jsx';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSignOut } from '../../hooks/useSignOut.js';
import { getAuthToken } from '../../providers/auth.js';

export async function loader({ request }) {
    const urlParams = new URLSearchParams(request.url.split('?')[1]);
    const linkParams = {};
    for (const [key, value] of urlParams.entries()) {
        linkParams[key] = value;
    }

    const { signature, id, name } = linkParams;
    try {
        await api.linkExistingBusiness({
            body: { signature, inviteId: id },
            options: {},
        });
        // Force refresh of token with updated business link
        await getAuthToken(true);
        return { error: null, businessName: name };
    } catch (error) {
        return { error, businessName: name };
    }
}

export default function LinkBusiness() {
    const navigate = useNavigate();
    const { handleError } = useError();
    const data = useLoaderData();
    const { businessName, error } = data || {};
    const typographyStyle = { textAlign: 'center' };
    const typographyVariant = 'h5';
    const signOut = useSignOut();

    useEffect(() => {
        if (error) {
            handleError(error);
        } else {
            onSuccess();
        }
    }, [data]);

    const onSuccess = () => {
        setTimeout(() => navigate('/'), 3000);
    };
    function handleLogout() {
        signOut();
    }

    return (
        <Box>
            <OurAppBar />
            <Container maxWidth="sm" sx={{ mt: 5 }}>
                {error ? (
                    <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant={typographyVariant} sx={typographyStyle}>
                            Failed to link to {businessName}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleLogout} sx={{ width: '150px' }}>
                            Log Out
                        </Button>
                    </Stack>
                ) : (
                    <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant={typographyVariant} sx={typographyStyle}>
                            Business Linked!
                        </Typography>
                        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="h7" sx={typographyStyle}>
                                Redirecting
                            </Typography>
                            <CircularProgress size={20} />
                        </Stack>
                    </Stack>
                )}
            </Container>
        </Box>
    );
}
