import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import MapPinIcon from '../../icons/MapPinIcon';
import { LightGray } from '../../theme.js';

export default function BusinessPrimaryAddress({ business }) {
    if (!business?.addresses?.length) {
        return null;
    }
    const address = business.addresses[0];
    return (
        <Typography variant="textSm" sx={{ display: 'flex', alignItems: 'center', color: LightGray, fontWeight: 500 }}>
            <MapPinIcon sx={{ width: 16, height: 16, mr: 1 }} />
            {address.city}, {address.state}, {address.countryCode}
        </Typography>
    );
}

BusinessPrimaryAddress.propTypes = {
    business: PropTypes.object.isRequired,
};
