import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { BorderRadius, BoxShadow, Midnight, Tide, White } from '../../theme.js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';

export default function SaveChangesFooter({ isLoading, onSave, onDiscard }) {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1300,
                boxShadow: BoxShadow,
                backgroundColor: Midnight,
                height: 100,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Stack
                gap={2}
                direction="row"
                sx={{
                    borderRadius: BorderRadius,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: { xs: 'transparent', sm: Tide },
                    width: { xs: 'auto', sm: 780 },
                    height: 68,
                }}>
                <Button
                    variant="outlined"
                    onClick={onDiscard}
                    sx={{
                        color: White,
                        borderColor: White,
                        borderWidth: '2px',
                        '&:hover': {
                            borderColor: White,
                        },
                    }}>
                    Discard
                </Button>
                <LoadingButton
                    isLoading={isLoading}
                    onClick={onSave}
                    variant="contained"
                    sx={{ backgroundColor: White, color: Tide, width: 200 }}>
                    Save Changes
                </LoadingButton>
            </Stack>
        </Box>
    );
}

SaveChangesFooter.propTypes = {
    isLoading: PropTypes.bool,
    onSave: PropTypes.func,
    onDiscard: PropTypes.func,
};
