import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import AddUser from '../AddUser/AddUser.jsx';

export default function AddUserDialog({ businessId, open, onClose, onUserAdded }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <AddUser onClose={onClose} didAddUser={onUserAdded} businessId={businessId} />
            </DialogContent>
        </Dialog>
    );
}

AddUserDialog.propTypes = {
    businessId: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUserAdded: PropTypes.func,
};
