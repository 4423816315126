import PropTypes from 'prop-types';
import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import AddressForm from '../AddressForm/AddressForm.jsx';

export default function AddAddressDialog({ open, onClose, onSubmitAddress, addressToEdit, isLoading }) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogContent>
                <AddressForm
                    address={addressToEdit}
                    onClose={onClose}
                    onSubmit={onSubmitAddress}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    );
}

AddAddressDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmitAddress: PropTypes.func,
    addressToEdit: PropTypes.any,
    isLoading: PropTypes.bool,
};
