import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns-tz';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Fog, Misty, Tide } from '../../theme';

function Marker() {
    return (
        <Box
            className="order-note-marker"
            sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: Tide,
                border: `1px solid ${Fog}`,
                position: 'absolute',
                left: '-5px',
                top: 7,
            }}
        />
    );
}

export default function OrderNote(props) {
    const { note, ...rest } = props;
    // Helper function to format date
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        return format(new Date(dateString), 'MM/dd/yyyy hh:mm a zzz');
    };
    return (
        <Box sx={props.sx} {...rest}>
            <Box sx={{ borderLeft: `1px solid ${Fog}`, pl: 3, position: 'relative' }}>
                <Marker />
                <Stack direction="column" gap="4px" sx={{ pb: 2 }}>
                    {note?.createdByBusinessName ? (
                        <Typography sx={{ fontWeight: 700, lineHeight: '26px' }} component="span" variant="textMd">
                            {note.createdByBusinessName}{' '}
                        </Typography>
                    ) : null}
                    {note?.createdAt ? (
                        <Typography
                            sx={{ fontWeight: 500, textWrap: 'nowrap', color: Misty }}
                            component="span"
                            variant="textXs">
                            ({formatDate(note.createdAt)})
                        </Typography>
                    ) : null}
                    <Typography variant="textSm" sx={{ fontWeight: 500 }}>
                        {note?.content}
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
}

OrderNote.propTypes = {
    'data-testid': PropTypes.string,
    note: PropTypes.any,
    sx: PropTypes.any,
};
