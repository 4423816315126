import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import PropTypes from 'prop-types';

export const values = [{ value: 'Pay Up Front', label: 'Pay upfront' }];

function OrderTermsSelect(props) {
    const { label, onChange, selectProps, defaultValue = '', disabled, size } = props;
    const [value, setValue] = React.useState(defaultValue || '');

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="order-terms-select-label">{label}</InputLabel>
                <Select
                    disabled={disabled}
                    labelId="order-terms-select-label"
                    id="order-terms-select"
                    value={value}
                    label={label}
                    onChange={handleChange}
                    size={size}
                    {...selectProps}>
                    {values.map((i) => (
                        <MenuItem key={i.value} value={i.value}>
                            {i.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

OrderTermsSelect.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.string,
    selectProps: PropTypes.any,
    defaultValue: PropTypes.string,
};

export default OrderTermsSelect;
