import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import ProductIcon from '../../components/ProductIcon.jsx';
import ProductQuantityTextField from '../../components/ProductQuantityTextField/ProductQuantityTextField.jsx';

export default function OrderItemCard(props) {
    const { t } = useTranslation();
    const { product: variant, editable, editablePrice, onChange, onRemove } = props;

    const handleRemoveClick = (id) => (e) => {
        if (onRemove) {
            onRemove(e, id);
        }
    };

    const handleOnQuantityChange = (id) => (value) => {
        if (onChange) {
            onChange({ id, quantity: value });
        }
    };

    const handleOnPriceChange = (id) => (value) => {
        if (onChange) {
            onChange({ id, price: value });
        }
    };

    return (
        <Card sx={{ display: 'flex' }}>
            <ProductIcon
                src={variant.images?.[0] || variant.imageLinks?.[0] || variant?.product?.imageLinks?.[0]}
                sx={{ width: '151px', pl: '12px' }}
            />
            <CardContent sx={{ width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: {
                            xs: 'wrap',
                            sm: 'unset',
                        },
                        width: '100%',
                        justifyContent: 'space-between',
                        gap: 2,
                    }}>
                    <Box>
                        <Typography component="div" variant="h5">
                            <b>{variant.title}</b>
                        </Typography>
                        {variant?.variantTags?.map((t) => (
                            <Typography key={t.name}>{t.value}</Typography>
                        ))}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: { xs: 'unset', sm: 1 },
                                flexWrap: 'wrap',
                                flexDirection: { xs: 'column', sm: 'row' },
                            }}>
                            {variant.upc ? (
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {t('OrderItemCard.upcLabel')} {variant.upc} <br />
                                </Typography>
                            ) : null}
                            {variant.sku ? (
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {t('OrderItemCard.skuLabel')} {variant.sku} <br />
                                </Typography>
                            ) : null}
                            {variant.mpn ? (
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {t('OrderItemCard.mpnLabel')} {variant.mpn}
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            width: { xs: '100%', sm: 'unset' },
                            flexWrap: { xs: 'wrap', sm: 'unset' },
                            gap: 2,
                        }}>
                        {editable ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}>
                                <IconButton
                                    sx={{ color: 'common.removeColor' }}
                                    onClick={handleRemoveClick(variant.id)}>
                                    <DeleteOutline />
                                </IconButton>
                                <ProductQuantityTextField
                                    quantity={variant.quantity}
                                    onChange={handleOnQuantityChange(variant.id)}
                                    sx={{ width: '110px' }}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                                <Typography>{t('OrderItemCard.qtyLabel')}</Typography>
                                <Typography>{variant.quantity}</Typography>
                            </Box>
                        )}
                        <Box>
                            <NumericFormat
                                prefix="$"
                                displayType={editablePrice ? 'input' : 'text'}
                                style={{
                                    textAlign: 'center',
                                    borderRadius: '5px',
                                    border: editablePrice ? '1px solid #ccc' : 'none',
                                    fontWeight: 'bold',
                                    fontSize: '1.25rem',
                                    maxWidth: 120,
                                }}
                                onValueChange={(values) => {
                                    const { value } = values;
                                    handleOnPriceChange(variant.id)(value);
                                }}
                                value={variant?.price ?? variant?.wholesale}
                                thousandSeparator
                                decimalScale={2}
                                fixedDecimalScale
                                valueIsNumericString
                            />
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
}

OrderItemCard.propTypes = {
    editable: PropTypes.bool,
    editablePrice: PropTypes.bool,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    product: PropTypes.shape({
        id: PropTypes.number,
        imageLinks: PropTypes.array,
        images: PropTypes.array,
        mpn: PropTypes.string,
        upc: PropTypes.string,
        quantity: PropTypes.number,
        sku: PropTypes.string,
        title: PropTypes.string,
        variantTags: PropTypes.array,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        product: PropTypes.shape({
            imageLinks: PropTypes.array,
        }),
        wholesale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
};
