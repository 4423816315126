export const EmptyAddress = (name) => {
    return {
        name: name ?? '',
        label: '',
        street1: '',
        street2: '',
        street3: '',
        city: '',
        state: '',
        postalCode: '',
        countryCode: '',
        phones: [
            {
                primary: '',
            },
        ],
        emails: [
            {
                default: '',
            },
        ],
    };
};
