import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderStatuses } from '../../constants/OrderStatuses.jsx';
import CalendarCheckIcon from '../../icons/CalendarCheckIcon.jsx';
import CalendarXIcon from '../../icons/CalendarXIcon.jsx';
import CreditCardIcon from '../../icons/CreditCardIcon.jsx';
import { formatDate } from '../../routes/retail/orders/OrderDetailsHeader.jsx';
import { LightGray } from '../../theme.js';
import OrderStatusChip from '../OrderStatusChip/OrderStatusChip.jsx';
import PaymentDueDate from '../PaymentDueDate/PaymentDueDate.jsx';

export default function BrandOrderDetailsHeader({ order }) {
    const { t } = useTranslation();
    const paidOn = !order.payments?.[0]?.updatedAt ? null : formatDate(order.payments?.[0]?.updatedAt);
    const cancelledOn = order.cancelledOn ?? (order.status === OrderStatuses.CANCELLED ? order.updatedAt : null);
    const labelStyle = {
        whiteSpace: 'noWrap',
        color: LightGray,
        fontWeight: 500,
    };

    return (
        <Stack sx={{ alignItems: 'left' }}>
            <Stack gap={3} sx={{ flexDirection: 'row', flexWrap: { xs: 'wrap-reverse', md: 'nowrap' } }}>
                <Stack direction="row" gap={3}>
                    <Box>
                        <Typography
                            variant="displayMd"
                            sx={{
                                lineHeight: 'normal',
                            }}>
                            {t('OrderDetailsHeader.title')}
                            {order.orderNumber > 0 ? order.orderNumber : order.id}
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={'8px 16px'} flexWrap="wrap">
                            <OrderStatusChip order={order} />
                            <Stack direction="row" alignItems="center" gap={1}>
                                <CalendarCheckIcon sx={{ color: LightGray, width: '24px', height: '24px' }} />
                                <Typography variant="textSm" sx={labelStyle}>
                                    {t('OrderDetailsHeader.createdOnLabel')} {formatDate(order.createdAt)}
                                </Typography>
                            </Stack>
                            {paidOn ? (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <CreditCardIcon sx={{ color: LightGray, width: '24px', height: '24px' }} />
                                    <Typography variant="textSm" sx={labelStyle}>
                                        {t('OrderDetailsHeader.paidOnLabel')} {paidOn}
                                    </Typography>
                                </Stack>
                            ) : null}
                            {cancelledOn ? (
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <CalendarXIcon sx={{ color: 'common.LightGray', width: '18px', height: '20px' }} />
                                    <Typography variant="textSm" sx={labelStyle}>
                                        {t('OrderDetailsHeader.cancelledOnLabel')} {formatDate(cancelledOn)}
                                    </Typography>
                                </Stack>
                            ) : null}
                            <PaymentDueDate order={order} />
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
}

BrandOrderDetailsHeader.propTypes = {
    order: PropTypes.object.isRequired,
};
