import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Drawer,
    IconButton,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { selectItems, selectProducts, selectSubtotalValue, selectTotalQuantity, useCart } from '../../cart/useCart';
import ProductIcon from '../../components/ProductIcon';
import QuantityInput from '../../components/QuantityInput/QuantityInput';
import CaretCircleDownIcon from '../../icons/CaretCircleDownIcon';
import CartIcon from '../../icons/ShoppingCartIcon';
import WholeshopLogo from '../../icons/WholeshopLogo.jsx';

const drawerWidth = 318;

function SideCartIcon(props) {
    const { showBadge } = props;
    const { state } = useCart();
    const items = selectItems(state);
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 12,
                height: 48,
                width: 48,
                bgcolor: 'common.lightGrey',
                p: 1.5,
            }}>
            {items.length && showBadge ? (
                <Badge
                    badgeContent={items.length}
                    variant="cart"
                    sx={{
                        '& .MuiBadge-badge': {
                            top: '-5px',
                            right: '-5px',
                        },
                    }}>
                    <CartIcon sx={{ width: 24, height: 24 }} />
                </Badge>
            ) : (
                <CartIcon sx={{ width: 24, height: 24 }} />
            )}
        </Box>
    );
}
SideCartIcon.propTypes = {
    showBadge: PropTypes.bool,
};

function VariantTags(props) {
    const { value: variantTags = [] } = props;
    const colorTags = variantTags.filter((tag) => tag?.name?.toLowerCase() === 'color');
    const otherTags = variantTags.filter((tag) => tag?.name?.toLowerCase() !== 'color');
    const [firstTag, ...rest] = [...colorTags, ...otherTags];
    return (
        <>
            <Typography sx={{ fontSize: '12px', fontWeight: 400, lineHeight: 'normal' }}>{firstTag?.value}</Typography>
            <Typography sx={{ color: 'common.Teal', fontSize: '10px', fontWeight: 400, lineHeight: 'normal' }}>
                {rest?.map((tag) => tag.value).join(', ')}
            </Typography>
        </>
    );
}
VariantTags.propTypes = {
    value: PropTypes.array,
};

function CartContent(props) {
    const { business: businessId, sx } = props;
    const rootData = useRouteLoaderData('root');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state = {}, updateItem } = useCart();
    const business = rootData?.businessesById?.[businessId];

    const products = selectProducts(state);
    const subtotal = selectSubtotalValue(state);
    const totalQuantity = selectTotalQuantity(state);

    const handleQuantityChange = (id) => (event, value) => {
        updateItem({ id, quantity: value });
    };
    return (
        <Card sx={sx}>
            <Box sx={{ display: 'flex', px: 2, pt: 4, pb: 1.5, alignItems: 'center' }}>
                <Box sx={{ mr: 2 }}>
                    <SideCartIcon />
                </Box>
                <Box>
                    <Typography
                        variant="caption"
                        sx={{
                            color: 'common.Teal',
                            fontWeight: 700,
                            lineHeight: 'normal',
                            letterSpacing: '2px',
                            textTransform: 'uppercase',
                        }}>
                        {t('Cart.title')}
                    </Typography>
                    <Typography variant="displayXs" sx={{ lineHeight: 'normal' }}>
                        {business?.name}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    flex: { xs: '1 1 auto', md: 'unset' },
                    maxHeight: { md: 'calc(100vh - 590px)' },
                    overflowY: 'scroll',
                }}>
                {products?.map((product) => (
                    <Accordion defaultExpanded key={product.id} disableGutters>
                        <AccordionSummary
                            sx={{
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                    alignSelf: 'flex-start',
                                    py: 1.5,
                                },
                            }}
                            expandIcon={
                                <CaretCircleDownIcon sx={{ color: 'common.Midnight', width: 24, height: 24 }} />
                            }>
                            <Box sx={{ display: 'flex' }}>
                                <Badge
                                    badgeContent={selectTotalQuantity(state, { productId: product.id })}
                                    variant="cart">
                                    <ProductIcon
                                        src={product.imageLinks?.[0]}
                                        objectFit="cover"
                                        sx={{ width: '45px', height: '45px' }}
                                        CardMediaProps={{ sx: { borderRadius: '10px' } }}
                                    />
                                </Badge>
                                <Typography
                                    sx={{
                                        ml: 2,
                                        maxWidth: 171,
                                        fontWeight: 'bold',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>
                                    {product.title}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {selectItems(state, { productId: product.id }).map((item) => (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }} key={item.id}>
                                    <Box>
                                        <VariantTags value={item?.variantTags} />
                                    </Box>
                                    <Box>
                                        <QuantityInput
                                            min={0}
                                            value={item.quantity}
                                            onChange={handleQuantityChange(item.id)}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
            <Box sx={{ px: 3, pt: 4 }}>
                <Divider />
            </Box>
            <Box sx={{ px: 3, py: 4 }}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography sx={{ color: 'common.Tide', fontSize: '12px', fontWeight: 700 }}>
                        {t('SideCart.subtotalLabel')} ({totalQuantity} {t('SideCart.itemsLabel')})
                    </Typography>
                    <Typography sx={{ fontWeight: 600, lineHeight: 'normal' }}>
                        <NumericFormat
                            prefix="$"
                            displayType="text"
                            value={subtotal}
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>
                </Stack>
            </Box>
            <Box sx={{ display: 'flex', px: 3, pb: 4 }}>
                <Button
                    disabled={!totalQuantity}
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    onClick={() => navigate(`/retail/connections/${businessId}/cart/checkout`)}>
                    {t('SideCart.reviewOrderButtonLabel')}
                </Button>
            </Box>
            <Box sx={{ display: 'flex', px: 3, pb: 4, justifyContent: 'center' }}>
                <Typography variant="body2" sx={{ color: 'common.LightGray', fontWeight: 500 }}>
                    {t('SideCart.poweredByLabel')}
                </Typography>
                <WholeshopLogo sx={{ mx: 1, width: 115 }} />
            </Box>
        </Card>
    );
}
CartContent.propTypes = {
    business: PropTypes.any,
    sx: PropTypes.any,
};

const cartTopSm = 152;

export default function SideCart(props) {
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    return (
        <>
            <Paper
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    position: 'fixed',
                    zIndex: { xs: 110, sm: 'unset' },
                    top: { xs: 83, sm: cartTopSm },
                    right: 0,
                    borderRadius: 2.5,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: 80,
                    height: 76,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                elevation={2}>
                <Box>
                    <IconButton onClick={() => setOpen(true)}>
                        <SideCartIcon showBadge />
                    </IconButton>
                </Box>
            </Paper>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block',
                    },
                    width: drawerWidth,
                    minWidth: drawerWidth,
                }}>
                <CartContent
                    business={id}
                    sx={{
                        width: drawerWidth,
                        borderRadius: 4,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        position: { sm: 'fixed' },
                        right: 0,
                        top: { sm: cartTopSm },
                        boxShadow: '1px 1px 30px 0px rgba(0, 0, 0, 0.15)',
                    }}
                />
            </Box>
            <Drawer
                anchor="right"
                variant="temporary"
                open={open}
                onClose={() => setOpen(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}>
                <CartContent business={id} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} />
            </Drawer>
        </>
    );
}
