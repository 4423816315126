import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function OrderNoteInput(props) {
    const { label, name = 'note', onChange, onSubmit, ...rest } = props;
    const [value, setValue] = React.useState('');

    const handleOnChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };
    const handleOnKeyDown = (event) => {
        if (event.key === 'Enter' || event.which === 13) {
            event.preventDefault();
            event.stopPropagation();
            if (onSubmit) {
                onSubmit(event, (value || '').trim());
            }
            setValue('');
        }
    };
    return (
        <TextField
            onKeyDown={handleOnKeyDown}
            name={name}
            label={label}
            value={props.value || value}
            onChange={handleOnChange}
            {...rest}
        />
    );
}

OrderNoteInput.propTypes = {
    ...TextField.propTypes,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
};
