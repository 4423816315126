import DeleteIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import * as React from 'react';
import PropTypes from 'prop-types';
import { selectItems, selectItemById, selectTotalQuantity, useCart } from '../../../../cart/useCart';
import ProductIcon from '../../../../components/ProductIcon';
import { renderCurrencyCell } from '../../../../helpers/HelperFunctions';
import CustomDetailPanelToggle from './CustomDetailPanelToggle';
import ProductVariantQuantityInput from './ProductVariantQuantityInput';
import { difference } from 'lodash';

const ROW_HEIGHT = 26;

function DetailPanelContent({ row }) {
    const columns = [
        { field: 'mpn', headerClassName: 'thatch-table-header--blue', headerName: 'MPN', width: 158 },
        {
            field: 'variantTags',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'Variant Option(s)',
            valueFormatter: (params) => {
                return params?.value?.map((tag) => tag.value).join(', ');
            },
            width: 244,
        },
        {
            field: 'msrp',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'MSRP',
            renderCell: renderCurrencyCell,
        },
        {
            field: 'wholesale',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'Wholesale',
            flex: 1,
            renderCell: renderCurrencyCell,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            headerClassName: 'thatch-table-header--blue',
            resizable: false,
            renderCell: (params) => <ProductVariantQuantityInput renderCellParams={params} />,
        },
        {
            field: 'gridPlaceholder',
            headerName: '',
            resizable: false,
            width: 50,
        },
    ];

    const rows = row?.productVariants?.filter((variant) => variant.active);

    return (
        <DataGridPro
            sx={{ pl: 16, border: 'none', pt: 1, pb: 3 }}
            columnHeaderHeight={ROW_HEIGHT}
            rowHeight={ROW_HEIGHT}
            columns={columns}
            rows={rows}
            hideFooter
            disableColumnMenu
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow--blue' : 'oddRow')}
        />
    );
}

DetailPanelContent.propTypes = {
    row: PropTypes.any,
};

export default function ManageOrderDataGrid(props) {
    const { state, removeItem, updateItem } = useCart();

    const handleRemoveItem = (row) => () => {
        removeItem(row.id);
    };

    /**
     * Toggle the expaned field of a data grid row
     * @param {*} ids the current state of the data grid after clicking dropdown button
     */
    const handleToggleRow = (ids) => {
        const expanded = selectItems(state)
            .filter((i) => i.expanded)
            .map((i) => i.id);
        const toOpen = difference(ids, expanded);
        const toClose = difference(expanded, ids);

        [...toOpen, ...toClose].forEach((id) => {
            const item = selectItemById(state, id);
            const update = {
                ...item,
                expanded: !item.expanded,
            };
            updateItem(update);
        });
    };

    const columns = [
        {
            field: 'remove',
            width: 66,
            headerName: '',
            renderCell: (params) => (
                <IconButton color="error" onClick={handleRemoveItem(params.row)}>
                    <DeleteIcon />
                </IconButton>
            ),
        },
        {
            field: 'imageLinks',
            headerName: '',
            width: 66,
            renderCell: (params) => (
                <>
                    <ProductIcon
                        key={params.id}
                        src={params.value?.[0]}
                        objectFit="cover"
                        sx={{ width: '45px', height: '45px' }}
                    />
                </>
            ),
        },
        { field: 'mpn', headerClassName: 'thatch-table-header--blue', headerName: 'MPN', width: 120 },
        { field: 'title', headerClassName: 'thatch-table-header--blue', headerName: 'Product Name', width: 180 },
        { field: 'year', headerClassName: 'thatch-table-header--blue', headerName: 'Year' },
        {
            field: 'msrp',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'MSRP',
            renderCell: renderCurrencyCell,
        },
        {
            field: 'wholesale',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'Wholesale',
            width: 120,
            renderCell: renderCurrencyCell,
        },
        {
            field: 'retailPrice',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'Retail Default Price',
            width: 160,
            renderCell: renderCurrencyCell,
        },
        { field: 'upc', headerClassName: 'thatch-table-header--blue', headerName: 'UPC', flex: 1 },
        {
            field: 'quantity',
            headerClassName: 'thatch-table-header--blue',
            headerName: 'Total Qty',
            resizable: false,
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            resizable: false,
            renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
        },
    ];
    const items = selectItems(state);

    const getDetailPanelContent = React.useCallback(({ row }) => <DetailPanelContent row={row} />, []);

    const getDetailPanelHeight = React.useCallback(() => {
        return 'auto';
    }, []);

    const mappedItems = items.map((product) => ({
        ...product,
        quantity: selectTotalQuantity(state, { id: product.id }),
    }));
    return (
        <DataGridPro
            detailPanelExpandedRowIds={items.filter(({ expanded }) => expanded).map(({ id }) => id)}
            onDetailPanelExpandedRowIdsChange={handleToggleRow}
            autoHeight
            columns={columns}
            rows={mappedItems}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            hideFooter
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow')}
            {...props}
        />
    );
}

ManageOrderDataGrid.propTypes = {
    ...DataGridPro.propTypes,
};
