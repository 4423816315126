import React, { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import PricingGroupRow from './PricingGroupRow/PricingGroupRow.jsx';
import Stack from '@mui/material/Stack';
import PlusCircleIcon from '../../icons/PlusCircleIcon.jsx';
import _ from 'lodash';
import LoadingButton from '../LoadingButton/LoadingButton.jsx';
import { useActionData, useNavigation, useSubmit } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useError } from '../Error/ErrorProvider.jsx';
import Dialog from '@mui/material/Dialog';
import PricingGroupEditDialog from '../PricingGroupEditDialog/PricingGroupEditDialog.jsx';
import { useTranslation } from 'react-i18next';

export default function PricingGroupList({ groups = [] }) {
    const [pricingGroups, setPricingGroups] = useState(groups);
    const [newPricingGroups, setNewPricingGroups] = useState([]);
    const [deletedGroupIds, setDeletedGroupIds] = useState([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const submit = useSubmit();
    const navigation = useNavigation();
    const isSaving =
        navigation.json?.intent === 'savePricingGroups' &&
        (navigation.state === 'loading' || navigation.state === 'submitting');
    const [showSuccess, setShowSuccess] = useState(false);
    const actionData = useActionData();
    const { handleError } = useError();
    const [showEditDialog, setShowEditDialog] = useState(false);
    const { t } = useTranslation();

    const handleAddNewPricingGroup = () => {
        setNewPricingGroups([...newPricingGroups, { id: Date.now(), name: '', discountPercentage: 0 }]);
    };

    const handleRemoveGroup = (id) => {
        setPricingGroups(pricingGroups.filter((group) => group.id !== id));
        setNewPricingGroups(newPricingGroups.filter((group) => group.id !== id));
        setDeletedGroupIds([...deletedGroupIds, id]);
    };

    const handleChange = (id, field, value) => {
        setPricingGroups(pricingGroups.map((group) => (group.id === id ? { ...group, [field]: value } : group)));
        setNewPricingGroups(newPricingGroups.map((group) => (group.id === id ? { ...group, [field]: value } : group)));
    };

    const isGroupValid = (group) => {
        return group.name.trim() !== '' && !isNaN(group.discountPercentage) && group.discountPercentage >= 0;
    };

    const checkIfChangesMade = () => {
        const groupsValid = pricingGroups.every(isGroupValid) && newPricingGroups.every(isGroupValid);
        const groupsChanged = !_.isEqual(pricingGroups, groups) || newPricingGroups.length > 0;
        return groupsChanged && groupsValid;
    };

    const getChangedPricingGroups = () => {
        return pricingGroups.filter((group, index) => {
            return !_.isEqual(group, groups[index]);
        });
    };

    const mapPricingGroupPayload = (groups) => {
        return groups.map((group) => ({
            ...(group.id && { id: group.id }),
            name: group.name,
            discountPercentage: group.discountPercentage,
        }));
    };

    const hasMadeChangesToExistingGroups = () => {
        return pricingGroups.some((existingGroup) => {
            const matchingNewGroup = groups.find((initialGroup) => initialGroup.id === existingGroup.id);
            return matchingNewGroup && !_.isEqual(_.omit(existingGroup, 'id'), _.omit(matchingNewGroup, 'id'));
        });
    };

    const handleSaveChanges = () => {
        if (hasMadeChangesToExistingGroups()) {
            setShowEditDialog(true);
        } else {
            submitChanges();
        }
    };

    const submitChanges = (deleteVariantGroups = false) => {
        submit(
            {
                intent: 'savePricingGroups',
                newGroups: mapPricingGroupPayload(newPricingGroups),
                editedGroups: mapPricingGroupPayload(getChangedPricingGroups()),
                deletedGroupIds,
                deleteVariantGroups,
            },
            { method: 'put', encType: 'application/json' }
        );
    };

    const resetChanges = () => {
        setPricingGroups(groups);
        setNewPricingGroups([]);
        setDeletedGroupIds([]);
    };

    useEffect(() => {
        setIsSaveEnabled(checkIfChangesMade());
    }, [pricingGroups, newPricingGroups]);

    useEffect(() => {
        setPricingGroups(groups);
        setNewPricingGroups([]);
        setDeletedGroupIds([]);
    }, [groups]);

    useEffect(() => {
        if (actionData) {
            if (actionData.error) {
                handleError(actionData.error);
            } else {
                setShowSuccess(true);
            }
        }
    }, [actionData]);

    const handleDiscardAllOverrides = () => {
        setShowEditDialog(false);
        submitChanges(true);
    };

    const handleKeepOverrides = () => {
        setShowEditDialog(false);
        submitChanges();
    };

    return (
        <>
            <Stack gap={2}>
                <Stack>
                    <Box display="flex" alignItems="flex-start" gap={2} mb={1}>
                        <Typography variant="label" sx={{ flex: 3, textAlign: 'left', fontSize: 12 }}>
                            {t('PricingGroupList.labels.pricingGroup')}
                        </Typography>
                        <Typography variant="label" sx={{ flex: 1, textAlign: 'left', fontSize: 12 }}>
                            {t('PricingGroupList.labels.discountPercentage')}
                        </Typography>
                        <Box sx={{ width: '48px' }}></Box>
                    </Box>
                    {pricingGroups.map((group) => (
                        <PricingGroupRow
                            key={group.id}
                            group={group}
                            onChange={handleChange}
                            onRemove={handleRemoveGroup}
                        />
                    ))}
                    {newPricingGroups.map((group) => (
                        <PricingGroupRow
                            key={group.id}
                            group={group}
                            onChange={handleChange}
                            onRemove={handleRemoveGroup}
                        />
                    ))}
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                        onClick={handleAddNewPricingGroup}
                        color="secondary"
                        variant="outlined"
                        sx={{ paddingX: '16px', height: 32 }}>
                        <Stack gap={1} direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                            <PlusCircleIcon sx={{ width: 16, height: 16 }} />
                            <Typography
                                noWrap
                                variant="textMd"
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontSize: 12,
                                }}>
                                {t('PricingGroupList.actions.addOption')}
                            </Typography>
                        </Stack>
                    </Button>
                    {isSaveEnabled && (
                        <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
                            <Button
                                onClick={resetChanges}
                                variant="outlined"
                                color="secondary"
                                sx={{ height: 40, width: 100 }}>
                                {t('PricingGroupList.actions.discard')}
                            </Button>
                            <LoadingButton
                                isLoading={isSaving}
                                onClick={handleSaveChanges}
                                variant="contained"
                                sx={{ height: 40, width: 100 }}>
                                {t('PricingGroupList.actions.save')}
                            </LoadingButton>
                        </Stack>
                    )}
                </Stack>
            </Stack>
            <Snackbar
                open={showSuccess}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setShowSuccess(false)} sx={{ width: '100%' }}>
                    {t('PricingGroupList.messages.updateSuccess')}
                </Alert>
            </Snackbar>
            <Dialog open={showEditDialog} onClose={() => setShowEditDialog(false)}>
                <PricingGroupEditDialog
                    onDiscardAllOverrides={handleDiscardAllOverrides}
                    onKeepOverrides={handleKeepOverrides}
                    onDismiss={() => setShowEditDialog(false)}
                />
            </Dialog>
        </>
    );
}

PricingGroupList.propTypes = {
    groups: PropTypes.array,
};
