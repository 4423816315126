import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import _ from 'lodash';

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, disableDecimals, ...other } = props;

    return (
        <NumericFormat
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            decimalScale={disableDecimals ? 0 : 2}
            valueIsNumericString
            prefix="$"
            fixedDecimalScale={!disableDecimals}
            {...other}
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disableDecimals: PropTypes.bool,
};

export default function CurrencyInput(props) {
    const {
        autoFocus,
        id,
        label,
        name,
        onChange,
        size,
        value,
        variant,
        fullWidth,
        inputProps,
        disableDecimals,
        onBlur,
        ...textFieldProps
    } = props;

    function handleChange(event) {
        const { value, name } = event.target;
        if (_.isEmpty(value)) {
            onChange({ target: { value: null, name } });
        } else {
            onChange({ target: { value: _.toNumber(value), name } });
        }
    }

    return (
        <TextField
            data-testid="currency-input"
            autoFocus={autoFocus}
            label={label}
            value={_.toString(value)}
            onChange={handleChange}
            onBlur={onBlur}
            name={name}
            id={id}
            fullWidth={fullWidth}
            InputProps={{
                inputComponent: NumericFormatCustom,
                inputProps: { ...inputProps, disableDecimals },
            }}
            size={size}
            variant={variant}
            {...textFieldProps}
        />
    );
}

CurrencyInput.propTypes = {
    autoFocus: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.string,
    disableDecimals: PropTypes.bool,
    onBlur: PropTypes.func,
};
